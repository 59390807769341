import React from "react";
import { Flex } from "@rebass/grid";

import ReactSlider from "react-slider";
import styled from "styled-components/macro";
import { backgroundDark, backgroundPrimary } from "../../styles/colors";

class NumberSlider extends ReactSlider<number> {}

const StyledSlider = styled(NumberSlider)`
  width: 100%;
  height: 25px;
`;

const StyledTrack = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  top: 10px;
  bottom: 10px;
  background: #ddd;
`;

const Track: React.FC = props => <StyledTrack {...props} />;

export const VOLTAGE_MIN = 1;
export const VOLTAGE_MAX = 10;
export const VOLTAGE_STEP = 0.1;
export const VOLTAGE_MARKS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const DEFAULT_VOLTAGE = 5.6;

const StyledMark = styled.div`
  margin-left: 7px;
  margin-top: 7px;
  height: 11px;
  line-height: 5px;
  width: 11px;
  background-color: #fff;
  border-color: #ddd;
  border-style: solid;
  border-radius: 50%;
`;

const Mark: React.FC = props => <StyledMark {...props} />;

const StyledThumb = styled.div<{ disabled?: boolean }>`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  background-color: ${props =>
    props.disabled ? backgroundDark : backgroundPrimary};
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const Thumb: React.FC = (props, state: { valueNow: number }) => (
  <StyledThumb {...props}>{Math.round(state.valueNow * 10) / 10}</StyledThumb>
);

const DisabledThumb: React.FC = (props, state: { valueNow: number }) => (
  <StyledThumb {...props} disabled={true}>
    {Math.round(state.valueNow * 10) / 10}
  </StyledThumb>
);

export type RecentMetricsDisplay = {
  startDateString: string;
  endDateString: string;
  costString: string;
  clicksString: string;
  revenueString: string;
  aacosString: string;
};

const TargetVoltageSlider: React.FC<{
  disabled: boolean;
  targetVoltage: number;
  setDragTargetVoltage: (voltage: number) => void;
  setTargetVoltage: (voltage: number) => void;
  voltageMin: number;
  voltageMax: number;
  voltageStep: number;
}> = ({
  disabled,
  targetVoltage,
  setDragTargetVoltage,
  setTargetVoltage,
  voltageMin,
  voltageMax,
  voltageStep
}) => {
  if (!voltageMin) {
    voltageMin = VOLTAGE_MIN;
  }
  if (!voltageMax) {
    voltageMax = VOLTAGE_MAX;
  }
  if (!voltageStep) {
    voltageStep = VOLTAGE_STEP;
  }

  let marks = VOLTAGE_MARKS;
  if (voltageMin !== VOLTAGE_MIN || voltageMax !== VOLTAGE_MAX) {
    marks = [voltageMin];
    for (
      let voltage = voltageMin;
      voltage <= voltageMax;
      voltage += voltageStep
    ) {
      if (Math.floor(voltage) !== Math.floor(marks[marks.length - 1])) {
        marks.push(Math.floor(voltage));
      }
    }
  }

  return (
    <div>
      <div
        style={{ fontSize: voltageMax > VOLTAGE_MAX ? "smaller" : "inherit" }}
      >
        <StyledSlider
          min={voltageMin || VOLTAGE_MIN}
          max={voltageMax || VOLTAGE_MAX}
          step={voltageStep || VOLTAGE_STEP}
          marks={marks}
          disabled={disabled}
          value={targetVoltage || DEFAULT_VOLTAGE}
          renderTrack={Track}
          renderThumb={disabled ? DisabledThumb : Thumb}
          renderMark={Mark}
          onChange={setDragTargetVoltage}
          onAfterChange={voltage => {
            setDragTargetVoltage(0);
            setTargetVoltage(voltage);
          }}
        />
      </div>
      <Flex flexDirection="row" justifyContent="space-between">
        <small>Prioritize ACOS</small>
        <small>Prioritize Revenue</small>
      </Flex>
    </div>
  );
};

export default TargetVoltageSlider;
