// source: google/ads/googleads/v18/resources/ad_group_criterion.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_criteria_pb = require('../../../../../google/ads/googleads/v18/common/criteria_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_criteria_pb);
var google_ads_googleads_v18_common_custom_parameter_pb = require('../../../../../google/ads/googleads/v18/common/custom_parameter_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_custom_parameter_pb);
var google_ads_googleads_v18_enums_ad_group_criterion_approval_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_criterion_approval_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_criterion_approval_status_pb);
var google_ads_googleads_v18_enums_ad_group_criterion_primary_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_criterion_primary_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_criterion_primary_status_pb);
var google_ads_googleads_v18_enums_ad_group_criterion_primary_status_reason_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_criterion_primary_status_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_criterion_primary_status_reason_pb);
var google_ads_googleads_v18_enums_ad_group_criterion_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_criterion_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_criterion_status_pb);
var google_ads_googleads_v18_enums_bidding_source_pb = require('../../../../../google/ads/googleads/v18/enums/bidding_source_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_bidding_source_pb);
var google_ads_googleads_v18_enums_criterion_system_serving_status_pb = require('../../../../../google/ads/googleads/v18/enums/criterion_system_serving_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_criterion_system_serving_status_pb);
var google_ads_googleads_v18_enums_criterion_type_pb = require('../../../../../google/ads/googleads/v18/enums/criterion_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_criterion_type_pb);
var google_ads_googleads_v18_enums_quality_score_bucket_pb = require('../../../../../google/ads/googleads/v18/enums/quality_score_bucket_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_quality_score_bucket_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupCriterion', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupCriterion.CriterionCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.AdGroupCriterion.repeatedFields_, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupCriterion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupCriterion.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupCriterion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.repeatedFields_ = [59,60,70,71,14,86];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_ = [[27,28,29,30,32,36,37,38,39,42,40,41,43,45,46,47,48,49,74,75,79,82,83]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.CriterionCase = {
  CRITERION_NOT_SET: 0,
  KEYWORD: 27,
  PLACEMENT: 28,
  MOBILE_APP_CATEGORY: 29,
  MOBILE_APPLICATION: 30,
  LISTING_GROUP: 32,
  AGE_RANGE: 36,
  GENDER: 37,
  INCOME_RANGE: 38,
  PARENTAL_STATUS: 39,
  USER_LIST: 42,
  YOUTUBE_VIDEO: 40,
  YOUTUBE_CHANNEL: 41,
  TOPIC: 43,
  USER_INTEREST: 45,
  WEBPAGE: 46,
  APP_PAYMENT_MODEL: 47,
  CUSTOM_AFFINITY: 48,
  CUSTOM_INTENT: 49,
  CUSTOM_AUDIENCE: 74,
  COMBINED_AUDIENCE: 75,
  AUDIENCE: 79,
  LOCATION: 82,
  LANGUAGE: 83
};

/**
 * @return {proto.google.ads.googleads.v18.resources.AdGroupCriterion.CriterionCase}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCriterionCase = function() {
  return /** @type {proto.google.ads.googleads.v18.resources.AdGroupCriterion.CriterionCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupCriterion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    criterionId: jspb.Message.getFieldWithDefault(msg, 56, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 77, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    qualityInfo: (f = msg.getQualityInfo()) && proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.toObject(includeInstance, f),
    adGroup: jspb.Message.getFieldWithDefault(msg, 57, ""),
    type: jspb.Message.getFieldWithDefault(msg, 25, 0),
    negative: jspb.Message.getBooleanFieldWithDefault(msg, 58, false),
    systemServingStatus: jspb.Message.getFieldWithDefault(msg, 52, 0),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 53, 0),
    disapprovalReasonsList: (f = jspb.Message.getRepeatedField(msg, 59)) == null ? undefined : f,
    labelsList: (f = jspb.Message.getRepeatedField(msg, 60)) == null ? undefined : f,
    bidModifier: jspb.Message.getFloatingPointFieldWithDefault(msg, 61, 0.0),
    cpcBidMicros: jspb.Message.getFieldWithDefault(msg, 62, 0),
    cpmBidMicros: jspb.Message.getFieldWithDefault(msg, 63, 0),
    cpvBidMicros: jspb.Message.getFieldWithDefault(msg, 64, 0),
    percentCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 65, 0),
    effectiveCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 66, 0),
    effectiveCpmBidMicros: jspb.Message.getFieldWithDefault(msg, 67, 0),
    effectiveCpvBidMicros: jspb.Message.getFieldWithDefault(msg, 68, 0),
    effectivePercentCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 69, 0),
    effectiveCpcBidSource: jspb.Message.getFieldWithDefault(msg, 21, 0),
    effectiveCpmBidSource: jspb.Message.getFieldWithDefault(msg, 22, 0),
    effectiveCpvBidSource: jspb.Message.getFieldWithDefault(msg, 23, 0),
    effectivePercentCpcBidSource: jspb.Message.getFieldWithDefault(msg, 35, 0),
    positionEstimates: (f = msg.getPositionEstimates()) && proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.toObject(includeInstance, f),
    finalUrlsList: (f = jspb.Message.getRepeatedField(msg, 70)) == null ? undefined : f,
    finalMobileUrlsList: (f = jspb.Message.getRepeatedField(msg, 71)) == null ? undefined : f,
    finalUrlSuffix: jspb.Message.getFieldWithDefault(msg, 72, ""),
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 73, ""),
    urlCustomParametersList: jspb.Message.toObjectList(msg.getUrlCustomParametersList(),
    google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.toObject, includeInstance),
    primaryStatus: jspb.Message.getFieldWithDefault(msg, 85, 0),
    primaryStatusReasonsList: (f = jspb.Message.getRepeatedField(msg, 86)) == null ? undefined : f,
    keyword: (f = msg.getKeyword()) && google_ads_googleads_v18_common_criteria_pb.KeywordInfo.toObject(includeInstance, f),
    placement: (f = msg.getPlacement()) && google_ads_googleads_v18_common_criteria_pb.PlacementInfo.toObject(includeInstance, f),
    mobileAppCategory: (f = msg.getMobileAppCategory()) && google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo.toObject(includeInstance, f),
    mobileApplication: (f = msg.getMobileApplication()) && google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo.toObject(includeInstance, f),
    listingGroup: (f = msg.getListingGroup()) && google_ads_googleads_v18_common_criteria_pb.ListingGroupInfo.toObject(includeInstance, f),
    ageRange: (f = msg.getAgeRange()) && google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo.toObject(includeInstance, f),
    gender: (f = msg.getGender()) && google_ads_googleads_v18_common_criteria_pb.GenderInfo.toObject(includeInstance, f),
    incomeRange: (f = msg.getIncomeRange()) && google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo.toObject(includeInstance, f),
    parentalStatus: (f = msg.getParentalStatus()) && google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo.toObject(includeInstance, f),
    userList: (f = msg.getUserList()) && google_ads_googleads_v18_common_criteria_pb.UserListInfo.toObject(includeInstance, f),
    youtubeVideo: (f = msg.getYoutubeVideo()) && google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo.toObject(includeInstance, f),
    youtubeChannel: (f = msg.getYoutubeChannel()) && google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo.toObject(includeInstance, f),
    topic: (f = msg.getTopic()) && google_ads_googleads_v18_common_criteria_pb.TopicInfo.toObject(includeInstance, f),
    userInterest: (f = msg.getUserInterest()) && google_ads_googleads_v18_common_criteria_pb.UserInterestInfo.toObject(includeInstance, f),
    webpage: (f = msg.getWebpage()) && google_ads_googleads_v18_common_criteria_pb.WebpageInfo.toObject(includeInstance, f),
    appPaymentModel: (f = msg.getAppPaymentModel()) && google_ads_googleads_v18_common_criteria_pb.AppPaymentModelInfo.toObject(includeInstance, f),
    customAffinity: (f = msg.getCustomAffinity()) && google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo.toObject(includeInstance, f),
    customIntent: (f = msg.getCustomIntent()) && google_ads_googleads_v18_common_criteria_pb.CustomIntentInfo.toObject(includeInstance, f),
    customAudience: (f = msg.getCustomAudience()) && google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo.toObject(includeInstance, f),
    combinedAudience: (f = msg.getCombinedAudience()) && google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo.toObject(includeInstance, f),
    audience: (f = msg.getAudience()) && google_ads_googleads_v18_common_criteria_pb.AudienceInfo.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && google_ads_googleads_v18_common_criteria_pb.LocationInfo.toObject(includeInstance, f),
    language: (f = msg.getLanguage()) && google_ads_googleads_v18_common_criteria_pb.LanguageInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupCriterion;
  return proto.google.ads.googleads.v18.resources.AdGroupCriterion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriterionId(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.deserializeBinaryFromReader);
      msg.setQualityInfo(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroup(value);
      break;
    case 25:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNegative(value);
      break;
    case 52:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CriterionSystemServingStatusEnum.CriterionSystemServingStatus} */ (reader.readEnum());
      msg.setSystemServingStatus(value);
      break;
    case 53:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionApprovalStatusEnum.AdGroupCriterionApprovalStatus} */ (reader.readEnum());
      msg.setApprovalStatus(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisapprovalReasons(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBidModifier(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidMicros(value);
      break;
    case 63:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpmBidMicros(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpvBidMicros(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentCpcBidMicros(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveCpcBidMicros(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveCpmBidMicros(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveCpvBidMicros(value);
      break;
    case 69:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectivePercentCpcBidMicros(value);
      break;
    case 21:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (reader.readEnum());
      msg.setEffectiveCpcBidSource(value);
      break;
    case 22:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (reader.readEnum());
      msg.setEffectiveCpmBidSource(value);
      break;
    case 23:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (reader.readEnum());
      msg.setEffectiveCpvBidSource(value);
      break;
    case 35:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (reader.readEnum());
      msg.setEffectivePercentCpcBidSource(value);
      break;
    case 10:
      var value = new proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.deserializeBinaryFromReader);
      msg.setPositionEstimates(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalUrls(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalMobileUrls(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrlSuffix(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 14:
      var value = new google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter;
      reader.readMessage(value,google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.deserializeBinaryFromReader);
      msg.addUrlCustomParameters(value);
      break;
    case 85:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusEnum.AdGroupCriterionPrimaryStatus} */ (reader.readEnum());
      msg.setPrimaryStatus(value);
      break;
    case 86:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusReasonEnum.AdGroupCriterionPrimaryStatusReason>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrimaryStatusReasons(values[i]);
      }
      break;
    case 27:
      var value = new google_ads_googleads_v18_common_criteria_pb.KeywordInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.KeywordInfo.deserializeBinaryFromReader);
      msg.setKeyword(value);
      break;
    case 28:
      var value = new google_ads_googleads_v18_common_criteria_pb.PlacementInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.PlacementInfo.deserializeBinaryFromReader);
      msg.setPlacement(value);
      break;
    case 29:
      var value = new google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo.deserializeBinaryFromReader);
      msg.setMobileAppCategory(value);
      break;
    case 30:
      var value = new google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo.deserializeBinaryFromReader);
      msg.setMobileApplication(value);
      break;
    case 32:
      var value = new google_ads_googleads_v18_common_criteria_pb.ListingGroupInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.ListingGroupInfo.deserializeBinaryFromReader);
      msg.setListingGroup(value);
      break;
    case 36:
      var value = new google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo.deserializeBinaryFromReader);
      msg.setAgeRange(value);
      break;
    case 37:
      var value = new google_ads_googleads_v18_common_criteria_pb.GenderInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.GenderInfo.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 38:
      var value = new google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo.deserializeBinaryFromReader);
      msg.setIncomeRange(value);
      break;
    case 39:
      var value = new google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo.deserializeBinaryFromReader);
      msg.setParentalStatus(value);
      break;
    case 42:
      var value = new google_ads_googleads_v18_common_criteria_pb.UserListInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.UserListInfo.deserializeBinaryFromReader);
      msg.setUserList(value);
      break;
    case 40:
      var value = new google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo.deserializeBinaryFromReader);
      msg.setYoutubeVideo(value);
      break;
    case 41:
      var value = new google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo.deserializeBinaryFromReader);
      msg.setYoutubeChannel(value);
      break;
    case 43:
      var value = new google_ads_googleads_v18_common_criteria_pb.TopicInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.TopicInfo.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    case 45:
      var value = new google_ads_googleads_v18_common_criteria_pb.UserInterestInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.UserInterestInfo.deserializeBinaryFromReader);
      msg.setUserInterest(value);
      break;
    case 46:
      var value = new google_ads_googleads_v18_common_criteria_pb.WebpageInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.WebpageInfo.deserializeBinaryFromReader);
      msg.setWebpage(value);
      break;
    case 47:
      var value = new google_ads_googleads_v18_common_criteria_pb.AppPaymentModelInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AppPaymentModelInfo.deserializeBinaryFromReader);
      msg.setAppPaymentModel(value);
      break;
    case 48:
      var value = new google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo.deserializeBinaryFromReader);
      msg.setCustomAffinity(value);
      break;
    case 49:
      var value = new google_ads_googleads_v18_common_criteria_pb.CustomIntentInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CustomIntentInfo.deserializeBinaryFromReader);
      msg.setCustomIntent(value);
      break;
    case 74:
      var value = new google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo.deserializeBinaryFromReader);
      msg.setCustomAudience(value);
      break;
    case 75:
      var value = new google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo.deserializeBinaryFromReader);
      msg.setCombinedAudience(value);
      break;
    case 79:
      var value = new google_ads_googleads_v18_common_criteria_pb.AudienceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AudienceInfo.deserializeBinaryFromReader);
      msg.setAudience(value);
      break;
    case 82:
      var value = new google_ads_googleads_v18_common_criteria_pb.LocationInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.LocationInfo.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 83:
      var value = new google_ads_googleads_v18_common_criteria_pb.LanguageInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.LanguageInfo.deserializeBinaryFromReader);
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupCriterion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 56));
  if (f != null) {
    writer.writeInt64(
      56,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getQualityInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 57));
  if (f != null) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 58));
  if (f != null) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getSystemServingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      52,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      53,
      f
    );
  }
  f = message.getDisapprovalReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      59,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      60,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 61));
  if (f != null) {
    writer.writeDouble(
      61,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 62));
  if (f != null) {
    writer.writeInt64(
      62,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 63));
  if (f != null) {
    writer.writeInt64(
      63,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 64));
  if (f != null) {
    writer.writeInt64(
      64,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 65));
  if (f != null) {
    writer.writeInt64(
      65,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 66));
  if (f != null) {
    writer.writeInt64(
      66,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 67));
  if (f != null) {
    writer.writeInt64(
      67,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 68));
  if (f != null) {
    writer.writeInt64(
      68,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 69));
  if (f != null) {
    writer.writeInt64(
      69,
      f
    );
  }
  f = message.getEffectiveCpcBidSource();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getEffectiveCpmBidSource();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getEffectiveCpvBidSource();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getEffectivePercentCpcBidSource();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
  f = message.getPositionEstimates();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.serializeBinaryToWriter
    );
  }
  f = message.getFinalUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      70,
      f
    );
  }
  f = message.getFinalMobileUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      71,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 72));
  if (f != null) {
    writer.writeString(
      72,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 73));
  if (f != null) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getUrlCustomParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusEnum.AdGroupCriterionPrimaryStatus} */ (jspb.Message.getField(message, 85));
  if (f != null) {
    writer.writeEnum(
      85,
      f
    );
  }
  f = message.getPrimaryStatusReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      86,
      f
    );
  }
  f = message.getKeyword();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_ads_googleads_v18_common_criteria_pb.KeywordInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlacement();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_ads_googleads_v18_common_criteria_pb.PlacementInfo.serializeBinaryToWriter
    );
  }
  f = message.getMobileAppCategory();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo.serializeBinaryToWriter
    );
  }
  f = message.getMobileApplication();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo.serializeBinaryToWriter
    );
  }
  f = message.getListingGroup();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_ads_googleads_v18_common_criteria_pb.ListingGroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getAgeRange();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_ads_googleads_v18_common_criteria_pb.GenderInfo.serializeBinaryToWriter
    );
  }
  f = message.getIncomeRange();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentalStatus();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserList();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_ads_googleads_v18_common_criteria_pb.UserListInfo.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeVideo();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeChannel();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo.serializeBinaryToWriter
    );
  }
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_ads_googleads_v18_common_criteria_pb.TopicInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserInterest();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_ads_googleads_v18_common_criteria_pb.UserInterestInfo.serializeBinaryToWriter
    );
  }
  f = message.getWebpage();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_ads_googleads_v18_common_criteria_pb.WebpageInfo.serializeBinaryToWriter
    );
  }
  f = message.getAppPaymentModel();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_ads_googleads_v18_common_criteria_pb.AppPaymentModelInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomAffinity();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomIntent();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      google_ads_googleads_v18_common_criteria_pb.CustomIntentInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomAudience();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo.serializeBinaryToWriter
    );
  }
  f = message.getCombinedAudience();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo.serializeBinaryToWriter
    );
  }
  f = message.getAudience();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      google_ads_googleads_v18_common_criteria_pb.AudienceInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      82,
      f,
      google_ads_googleads_v18_common_criteria_pb.LocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getLanguage();
  if (f != null) {
    writer.writeMessage(
      83,
      f,
      google_ads_googleads_v18_common_criteria_pb.LanguageInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    qualityScore: jspb.Message.getFieldWithDefault(msg, 5, 0),
    creativeQualityScore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    postClickQualityScore: jspb.Message.getFieldWithDefault(msg, 3, 0),
    searchPredictedCtr: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo;
  return proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQualityScore(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} */ (reader.readEnum());
      msg.setCreativeQualityScore(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} */ (reader.readEnum());
      msg.setPostClickQualityScore(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} */ (reader.readEnum());
      msg.setSearchPredictedCtr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreativeQualityScore();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPostClickQualityScore();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSearchPredictedCtr();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 quality_score = 5;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.getQualityScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.setQualityScore = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.clearQualityScore = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.hasQualityScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket creative_quality_score = 2;
 * @return {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.getCreativeQualityScore = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.setCreativeQualityScore = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket post_click_quality_score = 3;
 * @return {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.getPostClickQualityScore = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.setPostClickQualityScore = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket search_predicted_ctr = 4;
 * @return {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.getSearchPredictedCtr = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.QualityScoreBucketEnum.QualityScoreBucket} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo.prototype.setSearchPredictedCtr = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstPageCpcMicros: jspb.Message.getFieldWithDefault(msg, 6, 0),
    firstPositionCpcMicros: jspb.Message.getFieldWithDefault(msg, 7, 0),
    topOfPageCpcMicros: jspb.Message.getFieldWithDefault(msg, 8, 0),
    estimatedAddClicksAtFirstPositionCpc: jspb.Message.getFieldWithDefault(msg, 9, 0),
    estimatedAddCostAtFirstPositionCpc: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates;
  return proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstPageCpcMicros(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstPositionCpcMicros(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTopOfPageCpcMicros(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedAddClicksAtFirstPositionCpc(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedAddCostAtFirstPositionCpc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 first_page_cpc_micros = 6;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.getFirstPageCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.setFirstPageCpcMicros = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.clearFirstPageCpcMicros = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.hasFirstPageCpcMicros = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 first_position_cpc_micros = 7;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.getFirstPositionCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.setFirstPositionCpcMicros = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.clearFirstPositionCpcMicros = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.hasFirstPositionCpcMicros = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 top_of_page_cpc_micros = 8;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.getTopOfPageCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.setTopOfPageCpcMicros = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.clearTopOfPageCpcMicros = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.hasTopOfPageCpcMicros = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 estimated_add_clicks_at_first_position_cpc = 9;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.getEstimatedAddClicksAtFirstPositionCpc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.setEstimatedAddClicksAtFirstPositionCpc = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.clearEstimatedAddClicksAtFirstPositionCpc = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.hasEstimatedAddClicksAtFirstPositionCpc = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 estimated_add_cost_at_first_position_cpc = 10;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.getEstimatedAddCostAtFirstPositionCpc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.setEstimatedAddCostAtFirstPositionCpc = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.clearEstimatedAddCostAtFirstPositionCpc = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates.prototype.hasEstimatedAddCostAtFirstPositionCpc = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 criterion_id = 56;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCriterionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCriterionId = function(value) {
  return jspb.Message.setField(this, 56, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCriterionId = function() {
  return jspb.Message.setField(this, 56, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCriterionId = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string display_name = 77;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus status = 3;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional QualityInfo quality_info = 4;
 * @return {?proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getQualityInfo = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.AdGroupCriterion.QualityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setQualityInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearQualityInfo = function() {
  return this.setQualityInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasQualityInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string ad_group = 57;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getAdGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setAdGroup = function(value) {
  return jspb.Message.setField(this, 57, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearAdGroup = function() {
  return jspb.Message.setField(this, 57, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasAdGroup = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType type = 25;
 * @return {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional bool negative = 58;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getNegative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 58, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setNegative = function(value) {
  return jspb.Message.setField(this, 58, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearNegative = function() {
  return jspb.Message.setField(this, 58, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasNegative = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional google.ads.googleads.v18.enums.CriterionSystemServingStatusEnum.CriterionSystemServingStatus system_serving_status = 52;
 * @return {!proto.google.ads.googleads.v18.enums.CriterionSystemServingStatusEnum.CriterionSystemServingStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getSystemServingStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CriterionSystemServingStatusEnum.CriterionSystemServingStatus} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CriterionSystemServingStatusEnum.CriterionSystemServingStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setSystemServingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 52, value);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupCriterionApprovalStatusEnum.AdGroupCriterionApprovalStatus approval_status = 53;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupCriterionApprovalStatusEnum.AdGroupCriterionApprovalStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getApprovalStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionApprovalStatusEnum.AdGroupCriterionApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupCriterionApprovalStatusEnum.AdGroupCriterionApprovalStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 53, value);
};


/**
 * repeated string disapproval_reasons = 59;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getDisapprovalReasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 59));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setDisapprovalReasonsList = function(value) {
  return jspb.Message.setField(this, 59, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.addDisapprovalReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 59, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearDisapprovalReasonsList = function() {
  return this.setDisapprovalReasonsList([]);
};


/**
 * repeated string labels = 60;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 60));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 60, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 60, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional double bid_modifier = 61;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getBidModifier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 61, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setBidModifier = function(value) {
  return jspb.Message.setField(this, 61, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearBidModifier = function() {
  return jspb.Message.setField(this, 61, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasBidModifier = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional int64 cpc_bid_micros = 62;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 62, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCpcBidMicros = function() {
  return jspb.Message.setField(this, 62, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCpcBidMicros = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional int64 cpm_bid_micros = 63;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCpmBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 63, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCpmBidMicros = function(value) {
  return jspb.Message.setField(this, 63, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCpmBidMicros = function() {
  return jspb.Message.setField(this, 63, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCpmBidMicros = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional int64 cpv_bid_micros = 64;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCpvBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCpvBidMicros = function(value) {
  return jspb.Message.setField(this, 64, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCpvBidMicros = function() {
  return jspb.Message.setField(this, 64, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCpvBidMicros = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional int64 percent_cpc_bid_micros = 65;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getPercentCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setPercentCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 65, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearPercentCpcBidMicros = function() {
  return jspb.Message.setField(this, 65, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasPercentCpcBidMicros = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional int64 effective_cpc_bid_micros = 66;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectiveCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectiveCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 66, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearEffectiveCpcBidMicros = function() {
  return jspb.Message.setField(this, 66, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasEffectiveCpcBidMicros = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional int64 effective_cpm_bid_micros = 67;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectiveCpmBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectiveCpmBidMicros = function(value) {
  return jspb.Message.setField(this, 67, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearEffectiveCpmBidMicros = function() {
  return jspb.Message.setField(this, 67, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasEffectiveCpmBidMicros = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional int64 effective_cpv_bid_micros = 68;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectiveCpvBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectiveCpvBidMicros = function(value) {
  return jspb.Message.setField(this, 68, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearEffectiveCpvBidMicros = function() {
  return jspb.Message.setField(this, 68, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasEffectiveCpvBidMicros = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional int64 effective_percent_cpc_bid_micros = 69;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectivePercentCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectivePercentCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 69, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearEffectivePercentCpcBidMicros = function() {
  return jspb.Message.setField(this, 69, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasEffectivePercentCpcBidMicros = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource effective_cpc_bid_source = 21;
 * @return {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectiveCpcBidSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectiveCpcBidSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource effective_cpm_bid_source = 22;
 * @return {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectiveCpmBidSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectiveCpmBidSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource effective_cpv_bid_source = 23;
 * @return {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectiveCpvBidSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectiveCpvBidSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource effective_percent_cpc_bid_source = 35;
 * @return {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getEffectivePercentCpcBidSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setEffectivePercentCpcBidSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};


/**
 * optional PositionEstimates position_estimates = 10;
 * @return {?proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getPositionEstimates = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates, 10));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.AdGroupCriterion.PositionEstimates|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setPositionEstimates = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearPositionEstimates = function() {
  return this.setPositionEstimates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasPositionEstimates = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated string final_urls = 70;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getFinalUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 70));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setFinalUrlsList = function(value) {
  return jspb.Message.setField(this, 70, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.addFinalUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 70, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearFinalUrlsList = function() {
  return this.setFinalUrlsList([]);
};


/**
 * repeated string final_mobile_urls = 71;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getFinalMobileUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 71));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setFinalMobileUrlsList = function(value) {
  return jspb.Message.setField(this, 71, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.addFinalMobileUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 71, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearFinalMobileUrlsList = function() {
  return this.setFinalMobileUrlsList([]);
};


/**
 * optional string final_url_suffix = 72;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getFinalUrlSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setFinalUrlSuffix = function(value) {
  return jspb.Message.setField(this, 72, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearFinalUrlSuffix = function() {
  return jspb.Message.setField(this, 72, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasFinalUrlSuffix = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional string tracking_url_template = 73;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setField(this, 73, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearTrackingUrlTemplate = function() {
  return jspb.Message.setField(this, 73, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasTrackingUrlTemplate = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * repeated google.ads.googleads.v18.common.CustomParameter url_custom_parameters = 14;
 * @return {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getUrlCustomParametersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter, 14));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setUrlCustomParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.CustomParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.CustomParameter}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.addUrlCustomParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.google.ads.googleads.v18.common.CustomParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearUrlCustomParametersList = function() {
  return this.setUrlCustomParametersList([]);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusEnum.AdGroupCriterionPrimaryStatus primary_status = 85;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusEnum.AdGroupCriterionPrimaryStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getPrimaryStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusEnum.AdGroupCriterionPrimaryStatus} */ (jspb.Message.getFieldWithDefault(this, 85, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusEnum.AdGroupCriterionPrimaryStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setPrimaryStatus = function(value) {
  return jspb.Message.setField(this, 85, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearPrimaryStatus = function() {
  return jspb.Message.setField(this, 85, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasPrimaryStatus = function() {
  return jspb.Message.getField(this, 85) != null;
};


/**
 * repeated google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusReasonEnum.AdGroupCriterionPrimaryStatusReason primary_status_reasons = 86;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusReasonEnum.AdGroupCriterionPrimaryStatusReason>}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getPrimaryStatusReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusReasonEnum.AdGroupCriterionPrimaryStatusReason>} */ (jspb.Message.getRepeatedField(this, 86));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusReasonEnum.AdGroupCriterionPrimaryStatusReason>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setPrimaryStatusReasonsList = function(value) {
  return jspb.Message.setField(this, 86, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupCriterionPrimaryStatusReasonEnum.AdGroupCriterionPrimaryStatusReason} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.addPrimaryStatusReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 86, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearPrimaryStatusReasonsList = function() {
  return this.setPrimaryStatusReasonsList([]);
};


/**
 * optional google.ads.googleads.v18.common.KeywordInfo keyword = 27;
 * @return {?proto.google.ads.googleads.v18.common.KeywordInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getKeyword = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.KeywordInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.KeywordInfo, 27));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.KeywordInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setKeyword = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearKeyword = function() {
  return this.setKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasKeyword = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.ads.googleads.v18.common.PlacementInfo placement = 28;
 * @return {?proto.google.ads.googleads.v18.common.PlacementInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getPlacement = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PlacementInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.PlacementInfo, 28));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PlacementInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setPlacement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearPlacement = function() {
  return this.setPlacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasPlacement = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.ads.googleads.v18.common.MobileAppCategoryInfo mobile_app_category = 29;
 * @return {?proto.google.ads.googleads.v18.common.MobileAppCategoryInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getMobileAppCategory = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo, 29));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MobileAppCategoryInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setMobileAppCategory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearMobileAppCategory = function() {
  return this.setMobileAppCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasMobileAppCategory = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.ads.googleads.v18.common.MobileApplicationInfo mobile_application = 30;
 * @return {?proto.google.ads.googleads.v18.common.MobileApplicationInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getMobileApplication = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MobileApplicationInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo, 30));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MobileApplicationInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setMobileApplication = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearMobileApplication = function() {
  return this.setMobileApplication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasMobileApplication = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.ads.googleads.v18.common.ListingGroupInfo listing_group = 32;
 * @return {?proto.google.ads.googleads.v18.common.ListingGroupInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getListingGroup = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ListingGroupInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.ListingGroupInfo, 32));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ListingGroupInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setListingGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearListingGroup = function() {
  return this.setListingGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasListingGroup = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.ads.googleads.v18.common.AgeRangeInfo age_range = 36;
 * @return {?proto.google.ads.googleads.v18.common.AgeRangeInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getAgeRange = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AgeRangeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo, 36));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AgeRangeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setAgeRange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearAgeRange = function() {
  return this.setAgeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasAgeRange = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.ads.googleads.v18.common.GenderInfo gender = 37;
 * @return {?proto.google.ads.googleads.v18.common.GenderInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getGender = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.GenderInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.GenderInfo, 37));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.GenderInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setGender = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasGender = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.ads.googleads.v18.common.IncomeRangeInfo income_range = 38;
 * @return {?proto.google.ads.googleads.v18.common.IncomeRangeInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getIncomeRange = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.IncomeRangeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo, 38));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.IncomeRangeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setIncomeRange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearIncomeRange = function() {
  return this.setIncomeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasIncomeRange = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional google.ads.googleads.v18.common.ParentalStatusInfo parental_status = 39;
 * @return {?proto.google.ads.googleads.v18.common.ParentalStatusInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getParentalStatus = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ParentalStatusInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo, 39));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ParentalStatusInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setParentalStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearParentalStatus = function() {
  return this.setParentalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasParentalStatus = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional google.ads.googleads.v18.common.UserListInfo user_list = 42;
 * @return {?proto.google.ads.googleads.v18.common.UserListInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getUserList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.UserListInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.UserListInfo, 42));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.UserListInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setUserList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearUserList = function() {
  return this.setUserList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasUserList = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.ads.googleads.v18.common.YouTubeVideoInfo youtube_video = 40;
 * @return {?proto.google.ads.googleads.v18.common.YouTubeVideoInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getYoutubeVideo = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.YouTubeVideoInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo, 40));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.YouTubeVideoInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setYoutubeVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearYoutubeVideo = function() {
  return this.setYoutubeVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasYoutubeVideo = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.ads.googleads.v18.common.YouTubeChannelInfo youtube_channel = 41;
 * @return {?proto.google.ads.googleads.v18.common.YouTubeChannelInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getYoutubeChannel = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.YouTubeChannelInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo, 41));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.YouTubeChannelInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setYoutubeChannel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearYoutubeChannel = function() {
  return this.setYoutubeChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasYoutubeChannel = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.ads.googleads.v18.common.TopicInfo topic = 43;
 * @return {?proto.google.ads.googleads.v18.common.TopicInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getTopic = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.TopicInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.TopicInfo, 43));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.TopicInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setTopic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional google.ads.googleads.v18.common.UserInterestInfo user_interest = 45;
 * @return {?proto.google.ads.googleads.v18.common.UserInterestInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getUserInterest = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.UserInterestInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.UserInterestInfo, 45));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.UserInterestInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setUserInterest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearUserInterest = function() {
  return this.setUserInterest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasUserInterest = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional google.ads.googleads.v18.common.WebpageInfo webpage = 46;
 * @return {?proto.google.ads.googleads.v18.common.WebpageInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getWebpage = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.WebpageInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.WebpageInfo, 46));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.WebpageInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setWebpage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearWebpage = function() {
  return this.setWebpage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasWebpage = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.ads.googleads.v18.common.AppPaymentModelInfo app_payment_model = 47;
 * @return {?proto.google.ads.googleads.v18.common.AppPaymentModelInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getAppPaymentModel = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AppPaymentModelInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AppPaymentModelInfo, 47));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AppPaymentModelInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setAppPaymentModel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearAppPaymentModel = function() {
  return this.setAppPaymentModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasAppPaymentModel = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional google.ads.googleads.v18.common.CustomAffinityInfo custom_affinity = 48;
 * @return {?proto.google.ads.googleads.v18.common.CustomAffinityInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCustomAffinity = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CustomAffinityInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo, 48));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CustomAffinityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCustomAffinity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCustomAffinity = function() {
  return this.setCustomAffinity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCustomAffinity = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.ads.googleads.v18.common.CustomIntentInfo custom_intent = 49;
 * @return {?proto.google.ads.googleads.v18.common.CustomIntentInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCustomIntent = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CustomIntentInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CustomIntentInfo, 49));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CustomIntentInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCustomIntent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCustomIntent = function() {
  return this.setCustomIntent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCustomIntent = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional google.ads.googleads.v18.common.CustomAudienceInfo custom_audience = 74;
 * @return {?proto.google.ads.googleads.v18.common.CustomAudienceInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCustomAudience = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CustomAudienceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo, 74));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CustomAudienceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCustomAudience = function(value) {
  return jspb.Message.setOneofWrapperField(this, 74, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCustomAudience = function() {
  return this.setCustomAudience(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCustomAudience = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional google.ads.googleads.v18.common.CombinedAudienceInfo combined_audience = 75;
 * @return {?proto.google.ads.googleads.v18.common.CombinedAudienceInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getCombinedAudience = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CombinedAudienceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo, 75));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CombinedAudienceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setCombinedAudience = function(value) {
  return jspb.Message.setOneofWrapperField(this, 75, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearCombinedAudience = function() {
  return this.setCombinedAudience(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasCombinedAudience = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional google.ads.googleads.v18.common.AudienceInfo audience = 79;
 * @return {?proto.google.ads.googleads.v18.common.AudienceInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getAudience = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AudienceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AudienceInfo, 79));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AudienceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setAudience = function(value) {
  return jspb.Message.setOneofWrapperField(this, 79, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearAudience = function() {
  return this.setAudience(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasAudience = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional google.ads.googleads.v18.common.LocationInfo location = 82;
 * @return {?proto.google.ads.googleads.v18.common.LocationInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getLocation = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LocationInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.LocationInfo, 82));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LocationInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setLocation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 82, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 82) != null;
};


/**
 * optional google.ads.googleads.v18.common.LanguageInfo language = 83;
 * @return {?proto.google.ads.googleads.v18.common.LanguageInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.getLanguage = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LanguageInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.LanguageInfo, 83));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LanguageInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.setLanguage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 83, proto.google.ads.googleads.v18.resources.AdGroupCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.clearLanguage = function() {
  return this.setLanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupCriterion.prototype.hasLanguage = function() {
  return jspb.Message.getField(this, 83) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
