import { AdTextAsset } from "Common/google/ads/googleads/v18/common/ad_asset_pb";
import { ResponsiveSearchAdInfo } from "Common/google/ads/googleads/v18/common/ad_type_infos_pb";
import { KeywordInfo } from "Common/google/ads/googleads/v18/common/criteria_pb";
import { KeywordMatchTypeEnum } from "Common/google/ads/googleads/v18/enums/keyword_match_type_pb";
import { ServedAssetFieldTypeEnum } from "Common/google/ads/googleads/v18/enums/served_asset_field_type_pb";
import { ProductDetails } from "Common/proto/common/productDetails_pb";
import {
  GetCampaignConfigurationsReply,
  GetCampaignConfigurationsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import {
  CreateGoogleAdsCampaignReply,
  CreateGoogleAdsCampaignRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { GRPCWebCallbackClient, GRPCWebClient } from "Common/utils/grpc";
import { roundMicrosToCurrencyMinimumUnit } from "Common/utils/googleAds";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import {
  AdDescription,
  AdHeadline,
  PausingAutomationConfig
} from "ExtensionV2/pages/CampaignSetupPage/CampaignSetupPageState";
import { useUpdatePausingAutomation } from "./useUpdatePausingAutomation";
import { streamProcessor } from "Common/utils/grpcStreams";
import { PAUSING_AUTOMATION_CONFIG_ERROR } from "ExtensionV2/pages/CampaignSetupPage/CampaignSetupPageControls";
import { Error as AmpdError } from "Common/errors/error";
import { useHasAmpdProtectFeature } from "Common/utils/featureFlags";
import {
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";
import { extractWalmartURLInfoFromString } from "Common/utils/walmart";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";

export const AMPD_USE_SCRUBBED_CAMPAIGN_NAMES_WITH_ID =
  "ampd:useScrubbedCampaignNamesWithId";

export interface CreateGoogleAdsCampaignArgs {
  // In Google a campaign is created then the keywords are added to it as two separate steps. If any
  // step fails the entire transaction is rolled back. Switch this to true to allow partial
  // failures. This is useful for allowing a user to submit create a campaign with a keyword that
  // has a policy violation.
  allowPartialFailures: boolean;
  // customer id
  googleAdsCustomerId: string;
  // site alias
  siteAlias: string;
  // currency of Google Ads account
  currencyCode: string;
  // Special purpose flag to indicate that we want the campaign and ad group
  // names to match how they would appear in Amazon Attribution, that is
  // scrubbed of non-alphanumeric characters and with their ids at the end.
  scrubCampaignNamesAndAddId: boolean;
  // returns any would-be errors without actually creating the campaign
  validateOnly: boolean;
  // callback to run immediately after the campaign is created
  onCreateCampaign?: (campaign: CreateGoogleAdsCampaignReply.AsObject) => void;

  // Search Campaign Fields

  // user configured
  budgetMicros: number;
  // user configured
  campaignName: string;
  // user configured
  descriptions: Array<AdDescription>;
  // based on the targetURL which comes from rainforest via getAmazonProduct, but can be edited by
  // the user to any amazon URL
  finalURL: string;
  // user configured
  geotargetsList: Array<number>;
  // user configured
  headlines: Array<AdHeadline>;
  // user configured
  keywords: Array<string>;
  // hard coded to 2.00 USD equivalent
  maxCpcMicros: number;

  // Product Info

  // user configured
  marketplaceInfo: MarketplaceInfo;

  // Amazon Product Info
  // user configured
  asin: string;
  attributionAdvertiserIdStr: string;
  attributionProfileIdStr: string;

  // Walmart Product Info
  // user configured
  walmartProfileName: string;
  walmartItemId: string;
  // retrieved from Bluecart, if item id target.
  walmartItemBrand: string;
  // user configured
  walmartSearchPage: string;
  // retrieved from Bluecart, if search page target.
  walmartSearchItemIds: Array<string>;

  // retrieved from Rainforest/Bluecart, if available.
  productTitle: string;
  // product link retrieved from Rainforest/Bluecart or generated from user input
  targetURL: string;

  // Search terms to track for performance over time.
  searchTermsToTrack: Array<string>;

  minPrice: number;
  maxPrice: number;

  // Ampd Configurations

  // user configured
  pausingAutomation: PausingAutomationConfig;
}

export const useCreateGoogleAdsCampaign = (): UseMutationResult<
  CreateGoogleAdsCampaignReply.AsObject,
  unknown,
  CreateGoogleAdsCampaignArgs,
  unknown
> => {
  const { mutateAsync: updatePausingAutomation } = useUpdatePausingAutomation();
  const hasAmpdProtectFeature = useHasAmpdProtectFeature();

  const submitNewCampaign = async (
    createCampaignArgs: CreateGoogleAdsCampaignArgs
  ): Promise<CreateGoogleAdsCampaignReply.AsObject> => {
    for (const [key, value] of Object.entries(createCampaignArgs)) {
      if (
        key === "asin" ||
        key === "attributionProfileIdStr" ||
        key === "attributionAdvertiserIdStr" ||
        key === "walmartProfileName" ||
        key === "walmartItemId" ||
        key === "walmartItemBrand" ||
        key === "walmartSearchPage" ||
        key === "walmartSearchItemIds" ||
        key === "onCreateCampaign"
      ) {
        // While discouraged, it is technically possible to create a campaign without attribution
        // info.
        continue;
      }

      if (value == null || value?.length === 0) {
        throw new Error(`Missing required argument: ${key}`);
      }
    }

    const { allowPartialFailures, validateOnly } = createCampaignArgs;

    const req = getCreateGoogleAdsCampaignRequest(createCampaignArgs);

    req.setValidateOnly(true);

    if (validateOnly === false) {
      req.setValidateOnly(false);
    }

    req.setAllowPartialFailures(allowPartialFailures);

    const reply = await GRPCWebClient.createGoogleAdsCampaign(req, {});

    if (createCampaignArgs.onCreateCampaign) {
      createCampaignArgs.onCreateCampaign(reply.toObject());
    }
    return reply.toObject();
  };

  // Setup the automation config after the campaign is created.
  const onSuccess = async (
    response: CreateGoogleAdsCampaignReply.AsObject,
    args: CreateGoogleAdsCampaignArgs
  ) => {
    if (!hasAmpdProtectFeature || !args.pausingAutomation?.enabled) {
      return;
    }

    // Call getCampaignConfigurations. This will force a write to the campaigns configuration table
    // with the newly created campaign.
    const req = new GetCampaignConfigurationsRequest();
    req.setSiteAlias(args.siteAlias);
    req.setCampaignPlatform(CampaignPlatform.Option.GOOGLE_ADS);
    req.setDoUpdateStoredAmpdCampaigns(true);

    try {
      await streamProcessor(
        GRPCWebCallbackClient.getCampaignConfigurations(req),
        (_reply: GetCampaignConfigurationsReply) => {
          return;
        }
      );

      // Now that the campaign configuration is in the database, we can update it with an auto pausing
      // configuration.
      return await updatePausingAutomation({
        siteAlias: args.siteAlias,
        gaCategory: "Ampd: Campaign Setup",
        googleAdsCustomerId: args.googleAdsCustomerId,
        campaignId: String(response.campaignId),
        currencyCode: args.currencyCode,

        pausingAutomationEnabled: args.pausingAutomation.enabled,
        pausingAutomationCostThresholdMicros:
          args.pausingAutomation.costThresholdMicros,

        pausingAutomationAACOSThresholdPoints:
          args.pausingAutomation.AACOSThresholdPoints,

        pausingAutomationReevaluatePausedKeywords:
          args.pausingAutomation.reevaluatePausedKeywords,

        pausingAutomationMinNumActiveKeywords:
          args.pausingAutomation.minNumActiveKeywords
      });
    } catch (_e) {
      throw new AmpdError({
        publicText: PAUSING_AUTOMATION_CONFIG_ERROR
      });
    }
  };

  return useMutation({
    mutationFn: submitNewCampaign,
    onSuccess
  });
};

export const getCreateGoogleAdsCampaignRequest = (
  args: CreateGoogleAdsCampaignArgs
): CreateGoogleAdsCampaignRequest => {
  const {
    budgetMicros,
    campaignName,
    currencyCode,
    geotargetsList,
    googleAdsCustomerId,
    maxCpcMicros,
    siteAlias,
    scrubCampaignNamesAndAddId
  } = args;

  const productSearchCampaign = getProductSearchCampaignProto(args);

  const req = new CreateGoogleAdsCampaignRequest();
  req.setCampaignName(campaignName);
  req.setSiteAlias(siteAlias);
  req.setCustomerId(googleAdsCustomerId);
  req.setBudgetMicros(
    roundMicrosToCurrencyMinimumUnit(budgetMicros, currencyCode)
  );
  req.setMaxCpcMicros(
    roundMicrosToCurrencyMinimumUnit(maxCpcMicros, currencyCode)
  );
  req.setGeotargetsList(geotargetsList);
  req.setProductSearchCampaign(productSearchCampaign);

  if (scrubCampaignNamesAndAddId) {
    req.setScrubNamesAndAddId(true);
  }

  return req;
};

function convertToParameterProtos(
  nameValueList: Array<[string, string]>
): Array<ProductDetails.Parameter> {
  return nameValueList.map(([name, value]) => {
    const parameter = new ProductDetails.Parameter();
    parameter.setName(name);
    parameter.setValue(value);
    return parameter;
  });
}

const getProductDetailsProto = (
  args: CreateGoogleAdsCampaignArgs
): ProductDetails => {
  const {
    marketplaceInfo,
    asin,
    attributionAdvertiserIdStr,
    attributionProfileIdStr,
    walmartProfileName,
    walmartItemId,
    walmartItemBrand,
    walmartSearchPage,
    walmartSearchItemIds,
    productTitle,
    targetURL,
    headlines,
    searchTermsToTrack
  } = args;
  const productHeadline = headlines[0]?.text || "";

  const productDetails = new ProductDetails();

  if (isAmazonMarketplaceInfo(marketplaceInfo)) {
    const amazonProduct = new ProductDetails.Amazon();
    amazonProduct.setMarketplace(marketplaceInfo.marketplace);
    amazonProduct.setAsin(asin);
    amazonProduct.setProductUrl(targetURL);
    amazonProduct.setProductHeadline(productHeadline);
    amazonProduct.setProductTitle(productTitle);
    amazonProduct.setAttributionProfileIdStr(attributionProfileIdStr);
    amazonProduct.setAttributionAdvertiserIdStr(attributionAdvertiserIdStr);
    amazonProduct.setSearchTermsToTrackList(searchTermsToTrack);

    productDetails.setAmazon(amazonProduct);
  } else if (isWalmartMarketplaceInfo(marketplaceInfo)) {
    const walmartProduct = new ProductDetails.Walmart();

    walmartProduct.setMarketplace(marketplaceInfo.marketplace);
    walmartProduct.setTargetUrl(targetURL);

    const searchTermMap = walmartProduct.getSearchTermTrackingMap();
    for (const searchTerm of searchTermsToTrack) {
      searchTermMap.set(searchTerm, true);
    }

    if (walmartItemId) {
      walmartProduct.setItemIdsList([walmartItemId]);
      walmartProduct.setItemBrand(walmartItemBrand);
    } else if (walmartSearchPage) {
      walmartProduct.setItemIdsList(walmartSearchItemIds);
      const walmartUrlInfo = extractWalmartURLInfoFromString(walmartSearchPage);
      const searchPhrase = walmartUrlInfo.searchPhrase;
      if (searchPhrase) {
        walmartProduct.setUrlSearchTerm(walmartUrlInfo.searchPhrase || "");
        searchTermMap.set(searchPhrase, true);
      }
      walmartProduct.setBrandNameFacetsList(walmartUrlInfo.searchBrands);
      walmartProduct.setOtherSearchFacetsList(
        convertToParameterProtos(walmartUrlInfo.searchOtherFacets)
      );
      walmartProduct.setOtherSearchParametersList(
        convertToParameterProtos(walmartUrlInfo.searchOtherParameters)
      );
    }

    const walmartAttribution = new ProductDetails.Walmart.Attribution();
    walmartAttribution.setProfileName(walmartProfileName);
    walmartProduct.setAttribution(walmartAttribution);

    productDetails.setWalmart(walmartProduct);
  }

  return productDetails;
};

const getProductSearchCampaignProto = (
  args: CreateGoogleAdsCampaignArgs
): CreateGoogleAdsCampaignRequest.ProductSearchCampaign => {
  const { descriptions, finalURL, headlines, keywords } = args;
  const productDetails = getProductDetailsProto(args);

  const rsaInfo = getRsaInfoProto(headlines, descriptions);
  const keywordInfo = getKeywordsProtos(keywords);

  const productSearchCampaign = new CreateGoogleAdsCampaignRequest.ProductSearchCampaign();
  productSearchCampaign.setRsaInfo(rsaInfo);
  productSearchCampaign.setKeywordsList(keywordInfo);
  productSearchCampaign.setProductDetails(productDetails);
  productSearchCampaign.setFinalUrl(finalURL);

  return productSearchCampaign;
};

const getKeywordsProtos = (keywords: Array<string>): Array<KeywordInfo> => {
  const keywordInfo = keywords.map(kw => {
    const info = new KeywordInfo();
    info.setText(kw);
    info.setMatchType(KeywordMatchTypeEnum.KeywordMatchType.EXACT);
    return info;
  });

  return keywordInfo;
};

const getRsaInfoProto = (
  headlines: Array<AdHeadline>,
  descriptions: Array<AdDescription>
): ResponsiveSearchAdInfo => {
  const headlineAssets = getHeadlineProtos(headlines);
  const descriptionAssets = getDescriptionProtos(descriptions);
  const rsaInfo = new ResponsiveSearchAdInfo();
  rsaInfo.setHeadlinesList(headlineAssets);
  rsaInfo.setDescriptionsList(descriptionAssets);

  return rsaInfo;
};

export const getHeadlineProtos = (
  headlines: Array<AdHeadline>
): Array<AdTextAsset> => {
  return headlines.map(headline => {
    const headlineAsset = new AdTextAsset();
    headlineAsset.setText(headline.text);

    switch (headline.position) {
      case 1: {
        headlineAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.HEADLINE_1
        );
        break;
      }
      case 2: {
        headlineAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.HEADLINE_2
        );
        break;
      }
      case 3: {
        headlineAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.HEADLINE_3
        );
        break;
      }
      default: {
        headlineAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.UNSPECIFIED
        );
      }
    }
    // Also available:
    // headline.setAssetPerformanceLabel();
    // headline.setPolicySummaryInfo();

    return headlineAsset;
  });
};

export const getDescriptionProtos = (
  descriptions: Array<AdDescription>
): Array<AdTextAsset> => {
  return descriptions.map(description => {
    const descriptionAsset = new AdTextAsset();
    descriptionAsset.setText(description.text);

    switch (description.position) {
      case 1: {
        descriptionAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.DESCRIPTION_1
        );
        break;
      }
      case 2: {
        descriptionAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.DESCRIPTION_2
        );
        break;
      }
      default: {
        descriptionAsset.setPinnedField(
          ServedAssetFieldTypeEnum.ServedAssetFieldType.UNSPECIFIED
        );
      }
    }
    return descriptionAsset;
  });
};
