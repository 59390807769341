// source: google/ads/googleads/v18/common/criteria.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_enums_age_range_type_pb = require('../../../../../google/ads/googleads/v18/enums/age_range_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_age_range_type_pb);
var google_ads_googleads_v18_enums_app_payment_model_type_pb = require('../../../../../google/ads/googleads/v18/enums/app_payment_model_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_app_payment_model_type_pb);
var google_ads_googleads_v18_enums_brand_request_rejection_reason_pb = require('../../../../../google/ads/googleads/v18/enums/brand_request_rejection_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_brand_request_rejection_reason_pb);
var google_ads_googleads_v18_enums_brand_state_pb = require('../../../../../google/ads/googleads/v18/enums/brand_state_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_brand_state_pb);
var google_ads_googleads_v18_enums_content_label_type_pb = require('../../../../../google/ads/googleads/v18/enums/content_label_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_content_label_type_pb);
var google_ads_googleads_v18_enums_day_of_week_pb = require('../../../../../google/ads/googleads/v18/enums/day_of_week_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_day_of_week_pb);
var google_ads_googleads_v18_enums_device_pb = require('../../../../../google/ads/googleads/v18/enums/device_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_device_pb);
var google_ads_googleads_v18_enums_gender_type_pb = require('../../../../../google/ads/googleads/v18/enums/gender_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_gender_type_pb);
var google_ads_googleads_v18_enums_hotel_date_selection_type_pb = require('../../../../../google/ads/googleads/v18/enums/hotel_date_selection_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_hotel_date_selection_type_pb);
var google_ads_googleads_v18_enums_income_range_type_pb = require('../../../../../google/ads/googleads/v18/enums/income_range_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_income_range_type_pb);
var google_ads_googleads_v18_enums_interaction_type_pb = require('../../../../../google/ads/googleads/v18/enums/interaction_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_interaction_type_pb);
var google_ads_googleads_v18_enums_keyword_match_type_pb = require('../../../../../google/ads/googleads/v18/enums/keyword_match_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_keyword_match_type_pb);
var google_ads_googleads_v18_enums_listing_group_type_pb = require('../../../../../google/ads/googleads/v18/enums/listing_group_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_listing_group_type_pb);
var google_ads_googleads_v18_enums_location_group_radius_units_pb = require('../../../../../google/ads/googleads/v18/enums/location_group_radius_units_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_location_group_radius_units_pb);
var google_ads_googleads_v18_enums_minute_of_hour_pb = require('../../../../../google/ads/googleads/v18/enums/minute_of_hour_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_minute_of_hour_pb);
var google_ads_googleads_v18_enums_parental_status_type_pb = require('../../../../../google/ads/googleads/v18/enums/parental_status_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_parental_status_type_pb);
var google_ads_googleads_v18_enums_product_category_level_pb = require('../../../../../google/ads/googleads/v18/enums/product_category_level_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_product_category_level_pb);
var google_ads_googleads_v18_enums_product_channel_pb = require('../../../../../google/ads/googleads/v18/enums/product_channel_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_product_channel_pb);
var google_ads_googleads_v18_enums_product_channel_exclusivity_pb = require('../../../../../google/ads/googleads/v18/enums/product_channel_exclusivity_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_product_channel_exclusivity_pb);
var google_ads_googleads_v18_enums_product_condition_pb = require('../../../../../google/ads/googleads/v18/enums/product_condition_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_product_condition_pb);
var google_ads_googleads_v18_enums_product_custom_attribute_index_pb = require('../../../../../google/ads/googleads/v18/enums/product_custom_attribute_index_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_product_custom_attribute_index_pb);
var google_ads_googleads_v18_enums_product_type_level_pb = require('../../../../../google/ads/googleads/v18/enums/product_type_level_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_product_type_level_pb);
var google_ads_googleads_v18_enums_proximity_radius_units_pb = require('../../../../../google/ads/googleads/v18/enums/proximity_radius_units_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_proximity_radius_units_pb);
var google_ads_googleads_v18_enums_webpage_condition_operand_pb = require('../../../../../google/ads/googleads/v18/enums/webpage_condition_operand_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_webpage_condition_operand_pb);
var google_ads_googleads_v18_enums_webpage_condition_operator_pb = require('../../../../../google/ads/googleads/v18/enums/webpage_condition_operator_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_webpage_condition_operator_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ActivityCityInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ActivityCountryInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ActivityIdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ActivityRatingInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ActivityStateInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdScheduleInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AddressInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AgeRangeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AppPaymentModelInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AudienceInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.BrandInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.BrandListInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CarrierInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CombinedAudienceInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ContentLabelInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CustomAffinityInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CustomAudienceInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CustomIntentInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DeviceInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.GenderInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.GeoPointInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelCheckInDayInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelCityInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelClassInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelCountryRegionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelIdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelStateInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.IncomeRangeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.InteractionTypeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.IpBlockInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.KeywordInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.KeywordThemeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.KeywordThemeInfo.KeywordThemeCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LanguageInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ListingDimensionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ListingDimensionInfo.DimensionCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ListingDimensionPath', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ListingGroupInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ListingScopeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LocalServiceIdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LocationGroupInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LocationInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.MobileAppCategoryInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.MobileApplicationInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.MobileDeviceInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.NegativeKeywordListInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ParentalStatusInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PlacementInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductBrandInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductCategoryInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductChannelInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductConditionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductGroupingInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductItemIdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductLabelsInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductTypeFullInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProductTypeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ProximityInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.SearchThemeInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.TopicInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.UserInterestInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.UserListInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.WebpageConditionInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.WebpageInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.WebpageSampleInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.YouTubeChannelInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.YouTubeVideoInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.KeywordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.KeywordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.KeywordInfo.displayName = 'proto.google.ads.googleads.v18.common.KeywordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PlacementInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PlacementInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PlacementInfo.displayName = 'proto.google.ads.googleads.v18.common.PlacementInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.NegativeKeywordListInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.displayName = 'proto.google.ads.googleads.v18.common.NegativeKeywordListInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.MobileAppCategoryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.displayName = 'proto.google.ads.googleads.v18.common.MobileAppCategoryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.MobileApplicationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.MobileApplicationInfo.displayName = 'proto.google.ads.googleads.v18.common.MobileApplicationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LocationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LocationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LocationInfo.displayName = 'proto.google.ads.googleads.v18.common.LocationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DeviceInfo.displayName = 'proto.google.ads.googleads.v18.common.DeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ListingGroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ListingGroupInfo.displayName = 'proto.google.ads.googleads.v18.common.ListingGroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.ListingDimensionPath.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ListingDimensionPath, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ListingDimensionPath.displayName = 'proto.google.ads.googleads.v18.common.ListingDimensionPath';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.ListingScopeInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ListingScopeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ListingScopeInfo.displayName = 'proto.google.ads.googleads.v18.common.ListingScopeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.ListingDimensionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ListingDimensionInfo.displayName = 'proto.google.ads.googleads.v18.common.ListingDimensionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelIdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelIdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelIdInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelIdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelClassInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelClassInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelClassInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelClassInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelCountryRegionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelCountryRegionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelStateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelStateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelStateInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelStateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelCityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelCityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelCityInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelCityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductCategoryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductCategoryInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductCategoryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductBrandInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductBrandInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductBrandInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductChannelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductChannelInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductChannelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductConditionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductConditionInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductConditionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductItemIdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductItemIdInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductItemIdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductTypeInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductGroupingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductGroupingInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductGroupingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductLabelsInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductLabelsInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductLabelsInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProductTypeFullInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProductTypeFullInfo.displayName = 'proto.google.ads.googleads.v18.common.ProductTypeFullInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.displayName = 'proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelCheckInDayInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelCheckInDayInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ActivityIdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ActivityIdInfo.displayName = 'proto.google.ads.googleads.v18.common.ActivityIdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ActivityRatingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ActivityRatingInfo.displayName = 'proto.google.ads.googleads.v18.common.ActivityRatingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ActivityCountryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ActivityCountryInfo.displayName = 'proto.google.ads.googleads.v18.common.ActivityCountryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ActivityStateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ActivityStateInfo.displayName = 'proto.google.ads.googleads.v18.common.ActivityStateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ActivityCityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ActivityCityInfo.displayName = 'proto.google.ads.googleads.v18.common.ActivityCityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.InteractionTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.InteractionTypeInfo.displayName = 'proto.google.ads.googleads.v18.common.InteractionTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdScheduleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdScheduleInfo.displayName = 'proto.google.ads.googleads.v18.common.AdScheduleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AgeRangeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AgeRangeInfo.displayName = 'proto.google.ads.googleads.v18.common.AgeRangeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.GenderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.GenderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.GenderInfo.displayName = 'proto.google.ads.googleads.v18.common.GenderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.IncomeRangeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.IncomeRangeInfo.displayName = 'proto.google.ads.googleads.v18.common.IncomeRangeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ParentalStatusInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ParentalStatusInfo.displayName = 'proto.google.ads.googleads.v18.common.ParentalStatusInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.YouTubeVideoInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.YouTubeVideoInfo.displayName = 'proto.google.ads.googleads.v18.common.YouTubeVideoInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.YouTubeChannelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.YouTubeChannelInfo.displayName = 'proto.google.ads.googleads.v18.common.YouTubeChannelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.UserListInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.UserListInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.UserListInfo.displayName = 'proto.google.ads.googleads.v18.common.UserListInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ProximityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ProximityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ProximityInfo.displayName = 'proto.google.ads.googleads.v18.common.ProximityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.GeoPointInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.GeoPointInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.GeoPointInfo.displayName = 'proto.google.ads.googleads.v18.common.GeoPointInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AddressInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AddressInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AddressInfo.displayName = 'proto.google.ads.googleads.v18.common.AddressInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.TopicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.TopicInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.TopicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.TopicInfo.displayName = 'proto.google.ads.googleads.v18.common.TopicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LanguageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LanguageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LanguageInfo.displayName = 'proto.google.ads.googleads.v18.common.LanguageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.IpBlockInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.IpBlockInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.IpBlockInfo.displayName = 'proto.google.ads.googleads.v18.common.IpBlockInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ContentLabelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ContentLabelInfo.displayName = 'proto.google.ads.googleads.v18.common.ContentLabelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CarrierInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CarrierInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CarrierInfo.displayName = 'proto.google.ads.googleads.v18.common.CarrierInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.UserInterestInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.UserInterestInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.UserInterestInfo.displayName = 'proto.google.ads.googleads.v18.common.UserInterestInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.WebpageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.WebpageInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.WebpageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.WebpageInfo.displayName = 'proto.google.ads.googleads.v18.common.WebpageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.WebpageConditionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.WebpageConditionInfo.displayName = 'proto.google.ads.googleads.v18.common.WebpageConditionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.WebpageSampleInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.WebpageSampleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.WebpageSampleInfo.displayName = 'proto.google.ads.googleads.v18.common.WebpageSampleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.displayName = 'proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AppPaymentModelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AppPaymentModelInfo.displayName = 'proto.google.ads.googleads.v18.common.AppPaymentModelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.MobileDeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.MobileDeviceInfo.displayName = 'proto.google.ads.googleads.v18.common.MobileDeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CustomAffinityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CustomAffinityInfo.displayName = 'proto.google.ads.googleads.v18.common.CustomAffinityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CustomIntentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CustomIntentInfo.displayName = 'proto.google.ads.googleads.v18.common.CustomIntentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.LocationGroupInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LocationGroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LocationGroupInfo.displayName = 'proto.google.ads.googleads.v18.common.LocationGroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CustomAudienceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CustomAudienceInfo.displayName = 'proto.google.ads.googleads.v18.common.CustomAudienceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CombinedAudienceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CombinedAudienceInfo.displayName = 'proto.google.ads.googleads.v18.common.CombinedAudienceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AudienceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AudienceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AudienceInfo.displayName = 'proto.google.ads.googleads.v18.common.AudienceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.KeywordThemeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.KeywordThemeInfo.displayName = 'proto.google.ads.googleads.v18.common.KeywordThemeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LocalServiceIdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LocalServiceIdInfo.displayName = 'proto.google.ads.googleads.v18.common.LocalServiceIdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.SearchThemeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.SearchThemeInfo.displayName = 'proto.google.ads.googleads.v18.common.SearchThemeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.BrandInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.BrandInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.BrandInfo.displayName = 'proto.google.ads.googleads.v18.common.BrandInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.BrandListInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.BrandListInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.BrandListInfo.displayName = 'proto.google.ads.googleads.v18.common.BrandListInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.KeywordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.KeywordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.KeywordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    matchType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.KeywordInfo}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.KeywordInfo;
  return proto.google.ads.googleads.v18.common.KeywordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.KeywordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.KeywordInfo}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.KeywordMatchTypeEnum.KeywordMatchType} */ (reader.readEnum());
      msg.setMatchType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.KeywordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.KeywordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.KeywordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMatchType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.KeywordInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.setText = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.KeywordInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.clearText = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.hasText = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.ads.googleads.v18.enums.KeywordMatchTypeEnum.KeywordMatchType match_type = 2;
 * @return {!proto.google.ads.googleads.v18.enums.KeywordMatchTypeEnum.KeywordMatchType}
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.getMatchType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.KeywordMatchTypeEnum.KeywordMatchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.KeywordMatchTypeEnum.KeywordMatchType} value
 * @return {!proto.google.ads.googleads.v18.common.KeywordInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordInfo.prototype.setMatchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PlacementInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PlacementInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PlacementInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PlacementInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PlacementInfo}
 */
proto.google.ads.googleads.v18.common.PlacementInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PlacementInfo;
  return proto.google.ads.googleads.v18.common.PlacementInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PlacementInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PlacementInfo}
 */
proto.google.ads.googleads.v18.common.PlacementInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PlacementInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PlacementInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PlacementInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PlacementInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PlacementInfo.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PlacementInfo} returns this
 */
proto.google.ads.googleads.v18.common.PlacementInfo.prototype.setUrl = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PlacementInfo} returns this
 */
proto.google.ads.googleads.v18.common.PlacementInfo.prototype.clearUrl = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PlacementInfo.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sharedSet: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo}
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.NegativeKeywordListInfo;
  return proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo}
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string shared_set = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.prototype.getSharedSet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo} returns this
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.prototype.setSharedSet = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.NegativeKeywordListInfo} returns this
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.prototype.clearSharedSet = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.NegativeKeywordListInfo.prototype.hasSharedSet = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobileAppCategoryConstant: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo}
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.MobileAppCategoryInfo;
  return proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo}
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileAppCategoryConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string mobile_app_category_constant = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.prototype.getMobileAppCategoryConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.prototype.setMobileAppCategoryConstant = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.prototype.clearMobileAppCategoryConstant = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.MobileAppCategoryInfo.prototype.hasMobileAppCategoryConstant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.MobileApplicationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.MobileApplicationInfo}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.MobileApplicationInfo;
  return proto.google.ads.googleads.v18.common.MobileApplicationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.MobileApplicationInfo}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.MobileApplicationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string app_id = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.setAppId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.clearAppId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.hasAppId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.setName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.MobileApplicationInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.clearName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.MobileApplicationInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LocationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LocationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LocationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    geoTargetConstant: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LocationInfo}
 */
proto.google.ads.googleads.v18.common.LocationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LocationInfo;
  return proto.google.ads.googleads.v18.common.LocationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LocationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LocationInfo}
 */
proto.google.ads.googleads.v18.common.LocationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeoTargetConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LocationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LocationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LocationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string geo_target_constant = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LocationInfo.prototype.getGeoTargetConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LocationInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationInfo.prototype.setGeoTargetConstant = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LocationInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationInfo.prototype.clearGeoTargetConstant = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocationInfo.prototype.hasGeoTargetConstant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DeviceInfo}
 */
proto.google.ads.googleads.v18.common.DeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DeviceInfo;
  return proto.google.ads.googleads.v18.common.DeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DeviceInfo}
 */
proto.google.ads.googleads.v18.common.DeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.DeviceEnum.Device type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device}
 */
proto.google.ads.googleads.v18.common.DeviceInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device} value
 * @return {!proto.google.ads.googleads.v18.common.DeviceInfo} returns this
 */
proto.google.ads.googleads.v18.common.DeviceInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ListingGroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ListingGroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    caseValue: (f = msg.getCaseValue()) && proto.google.ads.googleads.v18.common.ListingDimensionInfo.toObject(includeInstance, f),
    parentAdGroupCriterion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    path: (f = msg.getPath()) && proto.google.ads.googleads.v18.common.ListingDimensionPath.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ListingGroupInfo;
  return proto.google.ads.googleads.v18.common.ListingGroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ListingGroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ListingGroupTypeEnum.ListingGroupType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.ListingDimensionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ListingDimensionInfo.deserializeBinaryFromReader);
      msg.setCaseValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentAdGroupCriterion(value);
      break;
    case 5:
      var value = new proto.google.ads.googleads.v18.common.ListingDimensionPath;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ListingDimensionPath.deserializeBinaryFromReader);
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ListingGroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ListingGroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCaseValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.ListingDimensionInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPath();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.google.ads.googleads.v18.common.ListingDimensionPath.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.ListingGroupTypeEnum.ListingGroupType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.ListingGroupTypeEnum.ListingGroupType}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ListingGroupTypeEnum.ListingGroupType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ListingGroupTypeEnum.ListingGroupType} value
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ListingDimensionInfo case_value = 2;
 * @return {?proto.google.ads.googleads.v18.common.ListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.getCaseValue = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ListingDimensionInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ListingDimensionInfo, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ListingDimensionInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.setCaseValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.clearCaseValue = function() {
  return this.setCaseValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.hasCaseValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string parent_ad_group_criterion = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.getParentAdGroupCriterion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.setParentAdGroupCriterion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.clearParentAdGroupCriterion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.hasParentAdGroupCriterion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ListingDimensionPath path = 5;
 * @return {?proto.google.ads.googleads.v18.common.ListingDimensionPath}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.getPath = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ListingDimensionPath} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ListingDimensionPath, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ListingDimensionPath|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.setPath = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.clearPath = function() {
  return this.setPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingGroupInfo.prototype.hasPath = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ListingDimensionPath.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionPath} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimensionsList: jspb.Message.toObjectList(msg.getDimensionsList(),
    proto.google.ads.googleads.v18.common.ListingDimensionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionPath}
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ListingDimensionPath;
  return proto.google.ads.googleads.v18.common.ListingDimensionPath.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionPath} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionPath}
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v18.common.ListingDimensionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ListingDimensionInfo.deserializeBinaryFromReader);
      msg.addDimensions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ListingDimensionPath.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionPath} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.google.ads.googleads.v18.common.ListingDimensionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ListingDimensionInfo dimensions = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.ListingDimensionInfo>}
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.prototype.getDimensionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.ListingDimensionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.ListingDimensionInfo, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.ListingDimensionInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionPath} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionPath.prototype.setDimensionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.prototype.addDimensions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.ListingDimensionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionPath} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionPath.prototype.clearDimensionsList = function() {
  return this.setDimensionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ListingScopeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ListingScopeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimensionsList: jspb.Message.toObjectList(msg.getDimensionsList(),
    proto.google.ads.googleads.v18.common.ListingDimensionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ListingScopeInfo}
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ListingScopeInfo;
  return proto.google.ads.googleads.v18.common.ListingScopeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ListingScopeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ListingScopeInfo}
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.google.ads.googleads.v18.common.ListingDimensionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ListingDimensionInfo.deserializeBinaryFromReader);
      msg.addDimensions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ListingScopeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ListingScopeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.ListingDimensionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ListingDimensionInfo dimensions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.ListingDimensionInfo>}
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.prototype.getDimensionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.ListingDimensionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.ListingDimensionInfo, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.ListingDimensionInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.ListingScopeInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingScopeInfo.prototype.setDimensionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.prototype.addDimensions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.ListingDimensionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ListingScopeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingScopeInfo.prototype.clearDimensionsList = function() {
  return this.setDimensionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_ = [[2,3,4,5,6,24,15,8,9,10,16,11,12,17,18,19,20,21,22,23,25,26,14]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.DimensionCase = {
  DIMENSION_NOT_SET: 0,
  HOTEL_ID: 2,
  HOTEL_CLASS: 3,
  HOTEL_COUNTRY_REGION: 4,
  HOTEL_STATE: 5,
  HOTEL_CITY: 6,
  PRODUCT_CATEGORY: 24,
  PRODUCT_BRAND: 15,
  PRODUCT_CHANNEL: 8,
  PRODUCT_CHANNEL_EXCLUSIVITY: 9,
  PRODUCT_CONDITION: 10,
  PRODUCT_CUSTOM_ATTRIBUTE: 16,
  PRODUCT_ITEM_ID: 11,
  PRODUCT_TYPE: 12,
  PRODUCT_GROUPING: 17,
  PRODUCT_LABELS: 18,
  PRODUCT_LEGACY_CONDITION: 19,
  PRODUCT_TYPE_FULL: 20,
  ACTIVITY_ID: 21,
  ACTIVITY_RATING: 22,
  ACTIVITY_COUNTRY: 23,
  ACTIVITY_STATE: 25,
  ACTIVITY_CITY: 26,
  UNKNOWN_LISTING_DIMENSION: 14
};

/**
 * @return {proto.google.ads.googleads.v18.common.ListingDimensionInfo.DimensionCase}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getDimensionCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.ListingDimensionInfo.DimensionCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ListingDimensionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: (f = msg.getHotelId()) && proto.google.ads.googleads.v18.common.HotelIdInfo.toObject(includeInstance, f),
    hotelClass: (f = msg.getHotelClass()) && proto.google.ads.googleads.v18.common.HotelClassInfo.toObject(includeInstance, f),
    hotelCountryRegion: (f = msg.getHotelCountryRegion()) && proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.toObject(includeInstance, f),
    hotelState: (f = msg.getHotelState()) && proto.google.ads.googleads.v18.common.HotelStateInfo.toObject(includeInstance, f),
    hotelCity: (f = msg.getHotelCity()) && proto.google.ads.googleads.v18.common.HotelCityInfo.toObject(includeInstance, f),
    productCategory: (f = msg.getProductCategory()) && proto.google.ads.googleads.v18.common.ProductCategoryInfo.toObject(includeInstance, f),
    productBrand: (f = msg.getProductBrand()) && proto.google.ads.googleads.v18.common.ProductBrandInfo.toObject(includeInstance, f),
    productChannel: (f = msg.getProductChannel()) && proto.google.ads.googleads.v18.common.ProductChannelInfo.toObject(includeInstance, f),
    productChannelExclusivity: (f = msg.getProductChannelExclusivity()) && proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.toObject(includeInstance, f),
    productCondition: (f = msg.getProductCondition()) && proto.google.ads.googleads.v18.common.ProductConditionInfo.toObject(includeInstance, f),
    productCustomAttribute: (f = msg.getProductCustomAttribute()) && proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.toObject(includeInstance, f),
    productItemId: (f = msg.getProductItemId()) && proto.google.ads.googleads.v18.common.ProductItemIdInfo.toObject(includeInstance, f),
    productType: (f = msg.getProductType()) && proto.google.ads.googleads.v18.common.ProductTypeInfo.toObject(includeInstance, f),
    productGrouping: (f = msg.getProductGrouping()) && proto.google.ads.googleads.v18.common.ProductGroupingInfo.toObject(includeInstance, f),
    productLabels: (f = msg.getProductLabels()) && proto.google.ads.googleads.v18.common.ProductLabelsInfo.toObject(includeInstance, f),
    productLegacyCondition: (f = msg.getProductLegacyCondition()) && proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.toObject(includeInstance, f),
    productTypeFull: (f = msg.getProductTypeFull()) && proto.google.ads.googleads.v18.common.ProductTypeFullInfo.toObject(includeInstance, f),
    activityId: (f = msg.getActivityId()) && proto.google.ads.googleads.v18.common.ActivityIdInfo.toObject(includeInstance, f),
    activityRating: (f = msg.getActivityRating()) && proto.google.ads.googleads.v18.common.ActivityRatingInfo.toObject(includeInstance, f),
    activityCountry: (f = msg.getActivityCountry()) && proto.google.ads.googleads.v18.common.ActivityCountryInfo.toObject(includeInstance, f),
    activityState: (f = msg.getActivityState()) && proto.google.ads.googleads.v18.common.ActivityStateInfo.toObject(includeInstance, f),
    activityCity: (f = msg.getActivityCity()) && proto.google.ads.googleads.v18.common.ActivityCityInfo.toObject(includeInstance, f),
    unknownListingDimension: (f = msg.getUnknownListingDimension()) && proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ListingDimensionInfo;
  return proto.google.ads.googleads.v18.common.ListingDimensionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.google.ads.googleads.v18.common.HotelIdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.HotelIdInfo.deserializeBinaryFromReader);
      msg.setHotelId(value);
      break;
    case 3:
      var value = new proto.google.ads.googleads.v18.common.HotelClassInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.HotelClassInfo.deserializeBinaryFromReader);
      msg.setHotelClass(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.HotelCountryRegionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.deserializeBinaryFromReader);
      msg.setHotelCountryRegion(value);
      break;
    case 5:
      var value = new proto.google.ads.googleads.v18.common.HotelStateInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.HotelStateInfo.deserializeBinaryFromReader);
      msg.setHotelState(value);
      break;
    case 6:
      var value = new proto.google.ads.googleads.v18.common.HotelCityInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.HotelCityInfo.deserializeBinaryFromReader);
      msg.setHotelCity(value);
      break;
    case 24:
      var value = new proto.google.ads.googleads.v18.common.ProductCategoryInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductCategoryInfo.deserializeBinaryFromReader);
      msg.setProductCategory(value);
      break;
    case 15:
      var value = new proto.google.ads.googleads.v18.common.ProductBrandInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductBrandInfo.deserializeBinaryFromReader);
      msg.setProductBrand(value);
      break;
    case 8:
      var value = new proto.google.ads.googleads.v18.common.ProductChannelInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductChannelInfo.deserializeBinaryFromReader);
      msg.setProductChannel(value);
      break;
    case 9:
      var value = new proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.deserializeBinaryFromReader);
      msg.setProductChannelExclusivity(value);
      break;
    case 10:
      var value = new proto.google.ads.googleads.v18.common.ProductConditionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductConditionInfo.deserializeBinaryFromReader);
      msg.setProductCondition(value);
      break;
    case 16:
      var value = new proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.deserializeBinaryFromReader);
      msg.setProductCustomAttribute(value);
      break;
    case 11:
      var value = new proto.google.ads.googleads.v18.common.ProductItemIdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductItemIdInfo.deserializeBinaryFromReader);
      msg.setProductItemId(value);
      break;
    case 12:
      var value = new proto.google.ads.googleads.v18.common.ProductTypeInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductTypeInfo.deserializeBinaryFromReader);
      msg.setProductType(value);
      break;
    case 17:
      var value = new proto.google.ads.googleads.v18.common.ProductGroupingInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductGroupingInfo.deserializeBinaryFromReader);
      msg.setProductGrouping(value);
      break;
    case 18:
      var value = new proto.google.ads.googleads.v18.common.ProductLabelsInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductLabelsInfo.deserializeBinaryFromReader);
      msg.setProductLabels(value);
      break;
    case 19:
      var value = new proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.deserializeBinaryFromReader);
      msg.setProductLegacyCondition(value);
      break;
    case 20:
      var value = new proto.google.ads.googleads.v18.common.ProductTypeFullInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ProductTypeFullInfo.deserializeBinaryFromReader);
      msg.setProductTypeFull(value);
      break;
    case 21:
      var value = new proto.google.ads.googleads.v18.common.ActivityIdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ActivityIdInfo.deserializeBinaryFromReader);
      msg.setActivityId(value);
      break;
    case 22:
      var value = new proto.google.ads.googleads.v18.common.ActivityRatingInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ActivityRatingInfo.deserializeBinaryFromReader);
      msg.setActivityRating(value);
      break;
    case 23:
      var value = new proto.google.ads.googleads.v18.common.ActivityCountryInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ActivityCountryInfo.deserializeBinaryFromReader);
      msg.setActivityCountry(value);
      break;
    case 25:
      var value = new proto.google.ads.googleads.v18.common.ActivityStateInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ActivityStateInfo.deserializeBinaryFromReader);
      msg.setActivityState(value);
      break;
    case 26:
      var value = new proto.google.ads.googleads.v18.common.ActivityCityInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ActivityCityInfo.deserializeBinaryFromReader);
      msg.setActivityCity(value);
      break;
    case 14:
      var value = new proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.deserializeBinaryFromReader);
      msg.setUnknownListingDimension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ListingDimensionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.HotelIdInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelClass();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.google.ads.googleads.v18.common.HotelClassInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelCountryRegion();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelState();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.google.ads.googleads.v18.common.HotelStateInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelCity();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.google.ads.googleads.v18.common.HotelCityInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductCategory();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.google.ads.googleads.v18.common.ProductCategoryInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductBrand();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.google.ads.googleads.v18.common.ProductBrandInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductChannel();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.google.ads.googleads.v18.common.ProductChannelInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductChannelExclusivity();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductCondition();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.google.ads.googleads.v18.common.ProductConditionInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductCustomAttribute();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductItemId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.google.ads.googleads.v18.common.ProductItemIdInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductType();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.google.ads.googleads.v18.common.ProductTypeInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductGrouping();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.google.ads.googleads.v18.common.ProductGroupingInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductLabels();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.google.ads.googleads.v18.common.ProductLabelsInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductLegacyCondition();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductTypeFull();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.google.ads.googleads.v18.common.ProductTypeFullInfo.serializeBinaryToWriter
    );
  }
  f = message.getActivityId();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.google.ads.googleads.v18.common.ActivityIdInfo.serializeBinaryToWriter
    );
  }
  f = message.getActivityRating();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.google.ads.googleads.v18.common.ActivityRatingInfo.serializeBinaryToWriter
    );
  }
  f = message.getActivityCountry();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.google.ads.googleads.v18.common.ActivityCountryInfo.serializeBinaryToWriter
    );
  }
  f = message.getActivityState();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.google.ads.googleads.v18.common.ActivityStateInfo.serializeBinaryToWriter
    );
  }
  f = message.getActivityCity();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.google.ads.googleads.v18.common.ActivityCityInfo.serializeBinaryToWriter
    );
  }
  f = message.getUnknownListingDimension();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional HotelIdInfo hotel_id = 2;
 * @return {?proto.google.ads.googleads.v18.common.HotelIdInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getHotelId = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelIdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.HotelIdInfo, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelIdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setHotelId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearHotelId = function() {
  return this.setHotelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasHotelId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HotelClassInfo hotel_class = 3;
 * @return {?proto.google.ads.googleads.v18.common.HotelClassInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getHotelClass = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelClassInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.HotelClassInfo, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelClassInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setHotelClass = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearHotelClass = function() {
  return this.setHotelClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasHotelClass = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HotelCountryRegionInfo hotel_country_region = 4;
 * @return {?proto.google.ads.googleads.v18.common.HotelCountryRegionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getHotelCountryRegion = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelCountryRegionInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.HotelCountryRegionInfo, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelCountryRegionInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setHotelCountryRegion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearHotelCountryRegion = function() {
  return this.setHotelCountryRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasHotelCountryRegion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HotelStateInfo hotel_state = 5;
 * @return {?proto.google.ads.googleads.v18.common.HotelStateInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getHotelState = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelStateInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.HotelStateInfo, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelStateInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setHotelState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearHotelState = function() {
  return this.setHotelState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasHotelState = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional HotelCityInfo hotel_city = 6;
 * @return {?proto.google.ads.googleads.v18.common.HotelCityInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getHotelCity = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelCityInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.HotelCityInfo, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelCityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setHotelCity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearHotelCity = function() {
  return this.setHotelCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasHotelCity = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ProductCategoryInfo product_category = 24;
 * @return {?proto.google.ads.googleads.v18.common.ProductCategoryInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductCategory = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductCategoryInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductCategoryInfo, 24));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductCategoryInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductCategory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductCategory = function() {
  return this.setProductCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductCategory = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional ProductBrandInfo product_brand = 15;
 * @return {?proto.google.ads.googleads.v18.common.ProductBrandInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductBrand = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductBrandInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductBrandInfo, 15));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductBrandInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductBrand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductBrand = function() {
  return this.setProductBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductBrand = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ProductChannelInfo product_channel = 8;
 * @return {?proto.google.ads.googleads.v18.common.ProductChannelInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductChannel = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductChannelInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductChannelInfo, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductChannelInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductChannel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductChannel = function() {
  return this.setProductChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductChannel = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ProductChannelExclusivityInfo product_channel_exclusivity = 9;
 * @return {?proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductChannelExclusivity = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo, 9));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductChannelExclusivity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductChannelExclusivity = function() {
  return this.setProductChannelExclusivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductChannelExclusivity = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ProductConditionInfo product_condition = 10;
 * @return {?proto.google.ads.googleads.v18.common.ProductConditionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductCondition = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductConditionInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductConditionInfo, 10));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductConditionInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductCondition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductCondition = function() {
  return this.setProductCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductCondition = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ProductCustomAttributeInfo product_custom_attribute = 16;
 * @return {?proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductCustomAttribute = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo, 16));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductCustomAttribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductCustomAttribute = function() {
  return this.setProductCustomAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductCustomAttribute = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ProductItemIdInfo product_item_id = 11;
 * @return {?proto.google.ads.googleads.v18.common.ProductItemIdInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductItemId = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductItemIdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductItemIdInfo, 11));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductItemIdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductItemId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductItemId = function() {
  return this.setProductItemId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductItemId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ProductTypeInfo product_type = 12;
 * @return {?proto.google.ads.googleads.v18.common.ProductTypeInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductType = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductTypeInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductTypeInfo, 12));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductTypeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductType = function() {
  return this.setProductType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductType = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ProductGroupingInfo product_grouping = 17;
 * @return {?proto.google.ads.googleads.v18.common.ProductGroupingInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductGrouping = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductGroupingInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductGroupingInfo, 17));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductGroupingInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductGrouping = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductGrouping = function() {
  return this.setProductGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductGrouping = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional ProductLabelsInfo product_labels = 18;
 * @return {?proto.google.ads.googleads.v18.common.ProductLabelsInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductLabels = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductLabelsInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductLabelsInfo, 18));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductLabelsInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductLabels = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductLabels = function() {
  return this.setProductLabels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductLabels = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ProductLegacyConditionInfo product_legacy_condition = 19;
 * @return {?proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductLegacyCondition = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo, 19));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductLegacyCondition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductLegacyCondition = function() {
  return this.setProductLegacyCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductLegacyCondition = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ProductTypeFullInfo product_type_full = 20;
 * @return {?proto.google.ads.googleads.v18.common.ProductTypeFullInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getProductTypeFull = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProductTypeFullInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ProductTypeFullInfo, 20));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProductTypeFullInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setProductTypeFull = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearProductTypeFull = function() {
  return this.setProductTypeFull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasProductTypeFull = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional ActivityIdInfo activity_id = 21;
 * @return {?proto.google.ads.googleads.v18.common.ActivityIdInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getActivityId = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ActivityIdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ActivityIdInfo, 21));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ActivityIdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setActivityId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearActivityId = function() {
  return this.setActivityId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasActivityId = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional ActivityRatingInfo activity_rating = 22;
 * @return {?proto.google.ads.googleads.v18.common.ActivityRatingInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getActivityRating = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ActivityRatingInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ActivityRatingInfo, 22));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ActivityRatingInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setActivityRating = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearActivityRating = function() {
  return this.setActivityRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasActivityRating = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional ActivityCountryInfo activity_country = 23;
 * @return {?proto.google.ads.googleads.v18.common.ActivityCountryInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getActivityCountry = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ActivityCountryInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ActivityCountryInfo, 23));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ActivityCountryInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setActivityCountry = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearActivityCountry = function() {
  return this.setActivityCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasActivityCountry = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional ActivityStateInfo activity_state = 25;
 * @return {?proto.google.ads.googleads.v18.common.ActivityStateInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getActivityState = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ActivityStateInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ActivityStateInfo, 25));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ActivityStateInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setActivityState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearActivityState = function() {
  return this.setActivityState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasActivityState = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional ActivityCityInfo activity_city = 26;
 * @return {?proto.google.ads.googleads.v18.common.ActivityCityInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getActivityCity = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ActivityCityInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ActivityCityInfo, 26));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ActivityCityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setActivityCity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearActivityCity = function() {
  return this.setActivityCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasActivityCity = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional UnknownListingDimensionInfo unknown_listing_dimension = 14;
 * @return {?proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.getUnknownListingDimension = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo, 14));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
*/
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.setUnknownListingDimension = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.google.ads.googleads.v18.common.ListingDimensionInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ListingDimensionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.clearUnknownListingDimension = function() {
  return this.setUnknownListingDimension(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ListingDimensionInfo.prototype.hasUnknownListingDimension = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelIdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelIdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelIdInfo}
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelIdInfo;
  return proto.google.ads.googleads.v18.common.HotelIdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelIdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelIdInfo}
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelIdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelIdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelIdInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelClassInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelClassInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelClassInfo}
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelClassInfo;
  return proto.google.ads.googleads.v18.common.HotelClassInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelClassInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelClassInfo}
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelClassInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelClassInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.HotelClassInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelClassInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelClassInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryRegionCriterion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo}
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelCountryRegionInfo;
  return proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo}
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryRegionCriterion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string country_region_criterion = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.prototype.getCountryRegionCriterion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.prototype.setCountryRegionCriterion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelCountryRegionInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.prototype.clearCountryRegionCriterion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelCountryRegionInfo.prototype.hasCountryRegionCriterion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelStateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelStateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    stateCriterion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelStateInfo}
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelStateInfo;
  return proto.google.ads.googleads.v18.common.HotelStateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelStateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelStateInfo}
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCriterion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelStateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelStateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string state_criterion = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.prototype.getStateCriterion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelStateInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.prototype.setStateCriterion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelStateInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.prototype.clearStateCriterion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelStateInfo.prototype.hasStateCriterion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelCityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelCityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    cityCriterion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelCityInfo}
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelCityInfo;
  return proto.google.ads.googleads.v18.common.HotelCityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelCityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelCityInfo}
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityCriterion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelCityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelCityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string city_criterion = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.prototype.getCityCriterion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCityInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.prototype.setCityCriterion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelCityInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.prototype.clearCityCriterion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelCityInfo.prototype.hasCityCriterion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductCategoryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductCategoryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductCategoryInfo}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductCategoryInfo;
  return proto.google.ads.googleads.v18.common.ProductCategoryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductCategoryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductCategoryInfo}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCategoryId(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProductCategoryLevelEnum.ProductCategoryLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductCategoryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductCategoryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 category_id = 1;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ProductCategoryInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.setCategoryId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductCategoryInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.clearCategoryId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.hasCategoryId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.ads.googleads.v18.enums.ProductCategoryLevelEnum.ProductCategoryLevel level = 2;
 * @return {!proto.google.ads.googleads.v18.enums.ProductCategoryLevelEnum.ProductCategoryLevel}
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.getLevel = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProductCategoryLevelEnum.ProductCategoryLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProductCategoryLevelEnum.ProductCategoryLevel} value
 * @return {!proto.google.ads.googleads.v18.common.ProductCategoryInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductCategoryInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductBrandInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductBrandInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductBrandInfo}
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductBrandInfo;
  return proto.google.ads.googleads.v18.common.ProductBrandInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductBrandInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductBrandInfo}
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductBrandInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductBrandInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductBrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductBrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductBrandInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductChannelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductChannelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductChannelInfo}
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductChannelInfo;
  return proto.google.ads.googleads.v18.common.ProductChannelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductChannelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductChannelInfo}
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProductChannelEnum.ProductChannel} */ (reader.readEnum());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductChannelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductChannelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.ProductChannelEnum.ProductChannel channel = 1;
 * @return {!proto.google.ads.googleads.v18.enums.ProductChannelEnum.ProductChannel}
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.prototype.getChannel = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProductChannelEnum.ProductChannel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProductChannelEnum.ProductChannel} value
 * @return {!proto.google.ads.googleads.v18.common.ProductChannelInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductChannelInfo.prototype.setChannel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelExclusivity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo}
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo;
  return proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo}
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProductChannelExclusivityEnum.ProductChannelExclusivity} */ (reader.readEnum());
      msg.setChannelExclusivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelExclusivity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.ProductChannelExclusivityEnum.ProductChannelExclusivity channel_exclusivity = 1;
 * @return {!proto.google.ads.googleads.v18.enums.ProductChannelExclusivityEnum.ProductChannelExclusivity}
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.prototype.getChannelExclusivity = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProductChannelExclusivityEnum.ProductChannelExclusivity} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProductChannelExclusivityEnum.ProductChannelExclusivity} value
 * @return {!proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductChannelExclusivityInfo.prototype.setChannelExclusivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductConditionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductConditionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    condition: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductConditionInfo}
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductConditionInfo;
  return proto.google.ads.googleads.v18.common.ProductConditionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductConditionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductConditionInfo}
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProductConditionEnum.ProductCondition} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductConditionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductConditionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.ProductConditionEnum.ProductCondition condition = 1;
 * @return {!proto.google.ads.googleads.v18.enums.ProductConditionEnum.ProductCondition}
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.prototype.getCondition = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProductConditionEnum.ProductCondition} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProductConditionEnum.ProductCondition} value
 * @return {!proto.google.ads.googleads.v18.common.ProductConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductConditionInfo.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo;
  return proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProductCustomAttributeIndexEnum.ProductCustomAttributeIndex} */ (reader.readEnum());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.ads.googleads.v18.enums.ProductCustomAttributeIndexEnum.ProductCustomAttributeIndex index = 2;
 * @return {!proto.google.ads.googleads.v18.enums.ProductCustomAttributeIndexEnum.ProductCustomAttributeIndex}
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.getIndex = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProductCustomAttributeIndexEnum.ProductCustomAttributeIndex} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProductCustomAttributeIndexEnum.ProductCustomAttributeIndex} value
 * @return {!proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductCustomAttributeInfo.prototype.setIndex = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductItemIdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductItemIdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductItemIdInfo}
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductItemIdInfo;
  return proto.google.ads.googleads.v18.common.ProductItemIdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductItemIdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductItemIdInfo}
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductItemIdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductItemIdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductItemIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductItemIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductItemIdInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeInfo}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductTypeInfo;
  return proto.google.ads.googleads.v18.common.ProductTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeInfo}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProductTypeLevelEnum.ProductTypeLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.ads.googleads.v18.enums.ProductTypeLevelEnum.ProductTypeLevel level = 2;
 * @return {!proto.google.ads.googleads.v18.enums.ProductTypeLevelEnum.ProductTypeLevel}
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.getLevel = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProductTypeLevelEnum.ProductTypeLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProductTypeLevelEnum.ProductTypeLevel} value
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductTypeInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductGroupingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductGroupingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductGroupingInfo}
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductGroupingInfo;
  return proto.google.ads.googleads.v18.common.ProductGroupingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductGroupingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductGroupingInfo}
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductGroupingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductGroupingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductGroupingInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductGroupingInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductGroupingInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductLabelsInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductLabelsInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductLabelsInfo}
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductLabelsInfo;
  return proto.google.ads.googleads.v18.common.ProductLabelsInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductLabelsInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductLabelsInfo}
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductLabelsInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductLabelsInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductLabelsInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductLabelsInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductLabelsInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo}
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo;
  return proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo}
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductLegacyConditionInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProductTypeFullInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo}
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProductTypeFullInfo;
  return proto.google.ads.googleads.v18.common.ProductTypeFullInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo}
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProductTypeFullInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProductTypeFullInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProductTypeFullInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo;
  return proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo}
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.UnknownListingDimensionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo}
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo;
  return proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo}
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.HotelDateSelectionTypeEnum.HotelDateSelectionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.HotelDateSelectionTypeEnum.HotelDateSelectionType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.HotelDateSelectionTypeEnum.HotelDateSelectionType}
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.HotelDateSelectionTypeEnum.HotelDateSelectionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.HotelDateSelectionTypeEnum.HotelDateSelectionType} value
 * @return {!proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    minDays: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxDays: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo;
  return proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinDays(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 min_days = 3;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.getMinDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.setMinDays = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.clearMinDays = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.hasMinDays = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 max_days = 4;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.getMaxDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.setMaxDays = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.clearMaxDays = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo.prototype.hasMaxDays = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    minNights: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxNights: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo;
  return proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinNights(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxNights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 min_nights = 3;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.getMinNights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.setMinNights = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.clearMinNights = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.hasMinNights = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 max_nights = 4;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.getMaxNights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.setMaxNights = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.clearMaxNights = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo.prototype.hasMaxNights = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo;
  return proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelCheckInDayInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDayInfo}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelCheckInDayInfo;
  return proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelCheckInDayInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDayInfo}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek} */ (reader.readEnum());
      msg.setDayOfWeek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelCheckInDayInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDayOfWeek();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek day_of_week = 1;
 * @return {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek}
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.prototype.getDayOfWeek = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCheckInDayInfo} returns this
 */
proto.google.ads.googleads.v18.common.HotelCheckInDayInfo.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ActivityIdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ActivityIdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ActivityIdInfo}
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ActivityIdInfo;
  return proto.google.ads.googleads.v18.common.ActivityIdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ActivityIdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ActivityIdInfo}
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ActivityIdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ActivityIdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ActivityIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ActivityIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ActivityIdInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ActivityRatingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ActivityRatingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ActivityRatingInfo}
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ActivityRatingInfo;
  return proto.google.ads.googleads.v18.common.ActivityRatingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ActivityRatingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ActivityRatingInfo}
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ActivityRatingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ActivityRatingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ActivityRatingInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ActivityRatingInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ActivityRatingInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ActivityCountryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ActivityCountryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ActivityCountryInfo}
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ActivityCountryInfo;
  return proto.google.ads.googleads.v18.common.ActivityCountryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ActivityCountryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ActivityCountryInfo}
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ActivityCountryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ActivityCountryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ActivityCountryInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ActivityCountryInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ActivityCountryInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ActivityStateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ActivityStateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ActivityStateInfo}
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ActivityStateInfo;
  return proto.google.ads.googleads.v18.common.ActivityStateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ActivityStateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ActivityStateInfo}
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ActivityStateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ActivityStateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ActivityStateInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ActivityStateInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ActivityStateInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ActivityCityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ActivityCityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ActivityCityInfo}
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ActivityCityInfo;
  return proto.google.ads.googleads.v18.common.ActivityCityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ActivityCityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ActivityCityInfo}
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ActivityCityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ActivityCityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ActivityCityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ActivityCityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ActivityCityInfo.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.InteractionTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.InteractionTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.InteractionTypeInfo}
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.InteractionTypeInfo;
  return proto.google.ads.googleads.v18.common.InteractionTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.InteractionTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.InteractionTypeInfo}
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.InteractionTypeEnum.InteractionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.InteractionTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.InteractionTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.InteractionTypeEnum.InteractionType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.InteractionTypeEnum.InteractionType}
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.InteractionTypeEnum.InteractionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.InteractionTypeEnum.InteractionType} value
 * @return {!proto.google.ads.googleads.v18.common.InteractionTypeInfo} returns this
 */
proto.google.ads.googleads.v18.common.InteractionTypeInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdScheduleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    startMinute: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endMinute: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startHour: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endHour: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdScheduleInfo;
  return proto.google.ads.googleads.v18.common.AdScheduleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour} */ (reader.readEnum());
      msg.setStartMinute(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour} */ (reader.readEnum());
      msg.setEndMinute(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartHour(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndHour(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek} */ (reader.readEnum());
      msg.setDayOfWeek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdScheduleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartMinute();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEndMinute();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour start_minute = 1;
 * @return {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.getStartMinute = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour} value
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.setStartMinute = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour end_minute = 2;
 * @return {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.getEndMinute = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.MinuteOfHourEnum.MinuteOfHour} value
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.setEndMinute = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 start_hour = 6;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.getStartHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.setStartHour = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.clearStartHour = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.hasStartHour = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 end_hour = 7;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.getEndHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.setEndHour = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.clearEndHour = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.hasEndHour = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek day_of_week = 5;
 * @return {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek}
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.getDayOfWeek = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DayOfWeekEnum.DayOfWeek} value
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdScheduleInfo.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AgeRangeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AgeRangeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AgeRangeInfo}
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AgeRangeInfo;
  return proto.google.ads.googleads.v18.common.AgeRangeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AgeRangeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AgeRangeInfo}
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AgeRangeTypeEnum.AgeRangeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AgeRangeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AgeRangeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.AgeRangeTypeEnum.AgeRangeType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.AgeRangeTypeEnum.AgeRangeType}
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AgeRangeTypeEnum.AgeRangeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AgeRangeTypeEnum.AgeRangeType} value
 * @return {!proto.google.ads.googleads.v18.common.AgeRangeInfo} returns this
 */
proto.google.ads.googleads.v18.common.AgeRangeInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.GenderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.GenderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.GenderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.GenderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.GenderInfo}
 */
proto.google.ads.googleads.v18.common.GenderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.GenderInfo;
  return proto.google.ads.googleads.v18.common.GenderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.GenderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.GenderInfo}
 */
proto.google.ads.googleads.v18.common.GenderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.GenderTypeEnum.GenderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.GenderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.GenderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.GenderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.GenderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.GenderTypeEnum.GenderType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.GenderTypeEnum.GenderType}
 */
proto.google.ads.googleads.v18.common.GenderInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.GenderTypeEnum.GenderType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.GenderTypeEnum.GenderType} value
 * @return {!proto.google.ads.googleads.v18.common.GenderInfo} returns this
 */
proto.google.ads.googleads.v18.common.GenderInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.IncomeRangeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.IncomeRangeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.IncomeRangeInfo}
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.IncomeRangeInfo;
  return proto.google.ads.googleads.v18.common.IncomeRangeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.IncomeRangeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.IncomeRangeInfo}
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.IncomeRangeTypeEnum.IncomeRangeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.IncomeRangeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.IncomeRangeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.IncomeRangeTypeEnum.IncomeRangeType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.IncomeRangeTypeEnum.IncomeRangeType}
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.IncomeRangeTypeEnum.IncomeRangeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.IncomeRangeTypeEnum.IncomeRangeType} value
 * @return {!proto.google.ads.googleads.v18.common.IncomeRangeInfo} returns this
 */
proto.google.ads.googleads.v18.common.IncomeRangeInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ParentalStatusInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ParentalStatusInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ParentalStatusInfo}
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ParentalStatusInfo;
  return proto.google.ads.googleads.v18.common.ParentalStatusInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ParentalStatusInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ParentalStatusInfo}
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ParentalStatusTypeEnum.ParentalStatusType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ParentalStatusInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ParentalStatusInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.ParentalStatusTypeEnum.ParentalStatusType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.ParentalStatusTypeEnum.ParentalStatusType}
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ParentalStatusTypeEnum.ParentalStatusType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ParentalStatusTypeEnum.ParentalStatusType} value
 * @return {!proto.google.ads.googleads.v18.common.ParentalStatusInfo} returns this
 */
proto.google.ads.googleads.v18.common.ParentalStatusInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.YouTubeVideoInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo}
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.YouTubeVideoInfo;
  return proto.google.ads.googleads.v18.common.YouTubeVideoInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo}
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.YouTubeVideoInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string video_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.prototype.getVideoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo} returns this
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.prototype.setVideoId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.YouTubeVideoInfo} returns this
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.prototype.clearVideoId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.YouTubeVideoInfo.prototype.hasVideoId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.YouTubeChannelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo}
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.YouTubeChannelInfo;
  return proto.google.ads.googleads.v18.common.YouTubeChannelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo}
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.YouTubeChannelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo} returns this
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.prototype.setChannelId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.YouTubeChannelInfo} returns this
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.prototype.clearChannelId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.YouTubeChannelInfo.prototype.hasChannelId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.UserListInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.UserListInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.UserListInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.UserListInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userList: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.UserListInfo}
 */
proto.google.ads.googleads.v18.common.UserListInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.UserListInfo;
  return proto.google.ads.googleads.v18.common.UserListInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.UserListInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.UserListInfo}
 */
proto.google.ads.googleads.v18.common.UserListInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.UserListInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.UserListInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.UserListInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.UserListInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_list = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.UserListInfo.prototype.getUserList = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.UserListInfo} returns this
 */
proto.google.ads.googleads.v18.common.UserListInfo.prototype.setUserList = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.UserListInfo} returns this
 */
proto.google.ads.googleads.v18.common.UserListInfo.prototype.clearUserList = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.UserListInfo.prototype.hasUserList = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ProximityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ProximityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProximityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    geoPoint: (f = msg.getGeoPoint()) && proto.google.ads.googleads.v18.common.GeoPointInfo.toObject(includeInstance, f),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    radiusUnits: jspb.Message.getFieldWithDefault(msg, 3, 0),
    address: (f = msg.getAddress()) && proto.google.ads.googleads.v18.common.AddressInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ProximityInfo;
  return proto.google.ads.googleads.v18.common.ProximityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ProximityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v18.common.GeoPointInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.GeoPointInfo.deserializeBinaryFromReader);
      msg.setGeoPoint(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ProximityRadiusUnitsEnum.ProximityRadiusUnits} */ (reader.readEnum());
      msg.setRadiusUnits(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.AddressInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.AddressInfo.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ProximityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ProximityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ProximityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeoPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v18.common.GeoPointInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRadiusUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.AddressInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeoPointInfo geo_point = 1;
 * @return {?proto.google.ads.googleads.v18.common.GeoPointInfo}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.getGeoPoint = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.GeoPointInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.GeoPointInfo, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.GeoPointInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
*/
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.setGeoPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.clearGeoPoint = function() {
  return this.setGeoPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.hasGeoPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double radius = 5;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.setRadius = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.clearRadius = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.hasRadius = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.ads.googleads.v18.enums.ProximityRadiusUnitsEnum.ProximityRadiusUnits radius_units = 3;
 * @return {!proto.google.ads.googleads.v18.enums.ProximityRadiusUnitsEnum.ProximityRadiusUnits}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.getRadiusUnits = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ProximityRadiusUnitsEnum.ProximityRadiusUnits} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ProximityRadiusUnitsEnum.ProximityRadiusUnits} value
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.setRadiusUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional AddressInfo address = 4;
 * @return {?proto.google.ads.googleads.v18.common.AddressInfo}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.getAddress = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AddressInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.AddressInfo, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AddressInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
*/
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ProximityInfo} returns this
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ProximityInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.GeoPointInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.GeoPointInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    longitudeInMicroDegrees: jspb.Message.getFieldWithDefault(msg, 3, 0),
    latitudeInMicroDegrees: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.GeoPointInfo}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.GeoPointInfo;
  return proto.google.ads.googleads.v18.common.GeoPointInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.GeoPointInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.GeoPointInfo}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLongitudeInMicroDegrees(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatitudeInMicroDegrees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.GeoPointInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.GeoPointInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 longitude_in_micro_degrees = 3;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.getLongitudeInMicroDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.GeoPointInfo} returns this
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.setLongitudeInMicroDegrees = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.GeoPointInfo} returns this
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.clearLongitudeInMicroDegrees = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.hasLongitudeInMicroDegrees = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 latitude_in_micro_degrees = 4;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.getLatitudeInMicroDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.GeoPointInfo} returns this
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.setLatitudeInMicroDegrees = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.GeoPointInfo} returns this
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.clearLatitudeInMicroDegrees = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.GeoPointInfo.prototype.hasLatitudeInMicroDegrees = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AddressInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AddressInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AddressInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    provinceCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    provinceName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    streetAddress: jspb.Message.getFieldWithDefault(msg, 12, ""),
    streetAddress2: jspb.Message.getFieldWithDefault(msg, 13, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo}
 */
proto.google.ads.googleads.v18.common.AddressInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AddressInfo;
  return proto.google.ads.googleads.v18.common.AddressInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AddressInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo}
 */
proto.google.ads.googleads.v18.common.AddressInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress2(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AddressInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AddressInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AddressInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setPostalCode = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearPostalCode = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string province_code = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getProvinceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setProvinceCode = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearProvinceCode = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasProvinceCode = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string country_code = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setCountryCode = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearCountryCode = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasCountryCode = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string province_name = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getProvinceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setProvinceName = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearProvinceName = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasProvinceName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string street_address = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getStreetAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setStreetAddress = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearStreetAddress = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasStreetAddress = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string street_address2 = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getStreetAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setStreetAddress2 = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearStreetAddress2 = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasStreetAddress2 = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string city_name = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.setCityName = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AddressInfo} returns this
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.clearCityName = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AddressInfo.prototype.hasCityName = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.TopicInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.TopicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.TopicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TopicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicConstant: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pathList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo}
 */
proto.google.ads.googleads.v18.common.TopicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.TopicInfo;
  return proto.google.ads.googleads.v18.common.TopicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.TopicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo}
 */
proto.google.ads.googleads.v18.common.TopicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopicConstant(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.TopicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.TopicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TopicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string topic_constant = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.getTopicConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo} returns this
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.setTopicConstant = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo} returns this
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.clearTopicConstant = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.hasTopicConstant = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string path = 4;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.getPathList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo} returns this
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.setPathList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo} returns this
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.addPath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.TopicInfo} returns this
 */
proto.google.ads.googleads.v18.common.TopicInfo.prototype.clearPathList = function() {
  return this.setPathList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LanguageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LanguageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LanguageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LanguageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    languageConstant: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LanguageInfo}
 */
proto.google.ads.googleads.v18.common.LanguageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LanguageInfo;
  return proto.google.ads.googleads.v18.common.LanguageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LanguageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LanguageInfo}
 */
proto.google.ads.googleads.v18.common.LanguageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LanguageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LanguageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LanguageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LanguageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string language_constant = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LanguageInfo.prototype.getLanguageConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LanguageInfo} returns this
 */
proto.google.ads.googleads.v18.common.LanguageInfo.prototype.setLanguageConstant = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LanguageInfo} returns this
 */
proto.google.ads.googleads.v18.common.LanguageInfo.prototype.clearLanguageConstant = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LanguageInfo.prototype.hasLanguageConstant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.IpBlockInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.IpBlockInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipAddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.IpBlockInfo}
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.IpBlockInfo;
  return proto.google.ads.googleads.v18.common.IpBlockInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.IpBlockInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.IpBlockInfo}
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.IpBlockInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.IpBlockInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ip_address = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.IpBlockInfo} returns this
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.prototype.setIpAddress = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.IpBlockInfo} returns this
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.prototype.clearIpAddress = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.IpBlockInfo.prototype.hasIpAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ContentLabelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ContentLabelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ContentLabelInfo}
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ContentLabelInfo;
  return proto.google.ads.googleads.v18.common.ContentLabelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ContentLabelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ContentLabelInfo}
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ContentLabelTypeEnum.ContentLabelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ContentLabelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ContentLabelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.ContentLabelTypeEnum.ContentLabelType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.ContentLabelTypeEnum.ContentLabelType}
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ContentLabelTypeEnum.ContentLabelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ContentLabelTypeEnum.ContentLabelType} value
 * @return {!proto.google.ads.googleads.v18.common.ContentLabelInfo} returns this
 */
proto.google.ads.googleads.v18.common.ContentLabelInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CarrierInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CarrierInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CarrierInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CarrierInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    carrierConstant: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CarrierInfo}
 */
proto.google.ads.googleads.v18.common.CarrierInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CarrierInfo;
  return proto.google.ads.googleads.v18.common.CarrierInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CarrierInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CarrierInfo}
 */
proto.google.ads.googleads.v18.common.CarrierInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CarrierInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CarrierInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CarrierInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CarrierInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string carrier_constant = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CarrierInfo.prototype.getCarrierConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CarrierInfo} returns this
 */
proto.google.ads.googleads.v18.common.CarrierInfo.prototype.setCarrierConstant = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.CarrierInfo} returns this
 */
proto.google.ads.googleads.v18.common.CarrierInfo.prototype.clearCarrierConstant = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.CarrierInfo.prototype.hasCarrierConstant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.UserInterestInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.UserInterestInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInterestCategory: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.UserInterestInfo}
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.UserInterestInfo;
  return proto.google.ads.googleads.v18.common.UserInterestInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.UserInterestInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.UserInterestInfo}
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserInterestCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.UserInterestInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.UserInterestInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_interest_category = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.prototype.getUserInterestCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.UserInterestInfo} returns this
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.prototype.setUserInterestCategory = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.UserInterestInfo} returns this
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.prototype.clearUserInterestCategory = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.UserInterestInfo.prototype.hasUserInterestCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.WebpageInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.WebpageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.WebpageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebpageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    criterionName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.google.ads.googleads.v18.common.WebpageConditionInfo.toObject, includeInstance),
    coveragePercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    sample: (f = msg.getSample()) && proto.google.ads.googleads.v18.common.WebpageSampleInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.WebpageInfo;
  return proto.google.ads.googleads.v18.common.WebpageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.WebpageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriterionName(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.WebpageConditionInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.WebpageConditionInfo.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCoveragePercentage(value);
      break;
    case 5:
      var value = new proto.google.ads.googleads.v18.common.WebpageSampleInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.WebpageSampleInfo.deserializeBinaryFromReader);
      msg.setSample(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.WebpageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.WebpageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebpageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.WebpageConditionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoveragePercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSample();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.google.ads.googleads.v18.common.WebpageSampleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string criterion_name = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.getCriterionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.setCriterionName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.clearCriterionName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.hasCriterionName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated WebpageConditionInfo conditions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.WebpageConditionInfo>}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.WebpageConditionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.WebpageConditionInfo, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.WebpageConditionInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
*/
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.WebpageConditionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.WebpageConditionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional double coverage_percentage = 4;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.getCoveragePercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.setCoveragePercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional WebpageSampleInfo sample = 5;
 * @return {?proto.google.ads.googleads.v18.common.WebpageSampleInfo}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.getSample = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.WebpageSampleInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.WebpageSampleInfo, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.WebpageSampleInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
*/
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.setSample = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.WebpageInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.clearSample = function() {
  return this.setSample(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.WebpageInfo.prototype.hasSample = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.WebpageConditionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    operand: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
    argument: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.WebpageConditionInfo;
  return proto.google.ads.googleads.v18.common.WebpageConditionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.WebpageConditionOperandEnum.WebpageConditionOperand} */ (reader.readEnum());
      msg.setOperand(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.WebpageConditionOperatorEnum.WebpageConditionOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArgument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.WebpageConditionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperand();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.WebpageConditionOperandEnum.WebpageConditionOperand operand = 1;
 * @return {!proto.google.ads.googleads.v18.enums.WebpageConditionOperandEnum.WebpageConditionOperand}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.getOperand = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.WebpageConditionOperandEnum.WebpageConditionOperand} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.WebpageConditionOperandEnum.WebpageConditionOperand} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.setOperand = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.WebpageConditionOperatorEnum.WebpageConditionOperator operator = 2;
 * @return {!proto.google.ads.googleads.v18.enums.WebpageConditionOperatorEnum.WebpageConditionOperator}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.getOperator = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.WebpageConditionOperatorEnum.WebpageConditionOperator} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.WebpageConditionOperatorEnum.WebpageConditionOperator} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string argument = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.getArgument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.setArgument = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.WebpageConditionInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.clearArgument = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.WebpageConditionInfo.prototype.hasArgument = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.WebpageSampleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.WebpageSampleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sampleUrlsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.WebpageSampleInfo}
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.WebpageSampleInfo;
  return proto.google.ads.googleads.v18.common.WebpageSampleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.WebpageSampleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.WebpageSampleInfo}
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSampleUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.WebpageSampleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.WebpageSampleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSampleUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string sample_urls = 1;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.prototype.getSampleUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.WebpageSampleInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.prototype.setSampleUrlsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.WebpageSampleInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.prototype.addSampleUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.WebpageSampleInfo} returns this
 */
proto.google.ads.googleads.v18.common.WebpageSampleInfo.prototype.clearSampleUrlsList = function() {
  return this.setSampleUrlsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    operatingSystemVersionConstant: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo}
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo;
  return proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo}
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatingSystemVersionConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string operating_system_version_constant = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.prototype.getOperatingSystemVersionConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo} returns this
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.prototype.setOperatingSystemVersionConstant = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo} returns this
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.prototype.clearOperatingSystemVersionConstant = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo.prototype.hasOperatingSystemVersionConstant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AppPaymentModelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AppPaymentModelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AppPaymentModelInfo}
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AppPaymentModelInfo;
  return proto.google.ads.googleads.v18.common.AppPaymentModelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AppPaymentModelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AppPaymentModelInfo}
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AppPaymentModelTypeEnum.AppPaymentModelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AppPaymentModelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AppPaymentModelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.AppPaymentModelTypeEnum.AppPaymentModelType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.AppPaymentModelTypeEnum.AppPaymentModelType}
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AppPaymentModelTypeEnum.AppPaymentModelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AppPaymentModelTypeEnum.AppPaymentModelType} value
 * @return {!proto.google.ads.googleads.v18.common.AppPaymentModelInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppPaymentModelInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.MobileDeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.MobileDeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobileDeviceConstant: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.MobileDeviceInfo}
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.MobileDeviceInfo;
  return proto.google.ads.googleads.v18.common.MobileDeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.MobileDeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.MobileDeviceInfo}
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileDeviceConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.MobileDeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.MobileDeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string mobile_device_constant = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.prototype.getMobileDeviceConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileDeviceInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.prototype.setMobileDeviceConstant = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.MobileDeviceInfo} returns this
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.prototype.clearMobileDeviceConstant = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.MobileDeviceInfo.prototype.hasMobileDeviceConstant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CustomAffinityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CustomAffinityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    customAffinity: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CustomAffinityInfo}
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CustomAffinityInfo;
  return proto.google.ads.googleads.v18.common.CustomAffinityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CustomAffinityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CustomAffinityInfo}
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomAffinity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CustomAffinityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CustomAffinityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string custom_affinity = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.prototype.getCustomAffinity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CustomAffinityInfo} returns this
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.prototype.setCustomAffinity = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.CustomAffinityInfo} returns this
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.prototype.clearCustomAffinity = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.CustomAffinityInfo.prototype.hasCustomAffinity = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CustomIntentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CustomIntentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    customIntent: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CustomIntentInfo}
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CustomIntentInfo;
  return proto.google.ads.googleads.v18.common.CustomIntentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CustomIntentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CustomIntentInfo}
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomIntent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CustomIntentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CustomIntentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string custom_intent = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.prototype.getCustomIntent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CustomIntentInfo} returns this
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.prototype.setCustomIntent = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.CustomIntentInfo} returns this
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.prototype.clearCustomIntent = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.CustomIntentInfo.prototype.hasCustomIntent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.repeatedFields_ = [6,8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LocationGroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LocationGroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    feed: jspb.Message.getFieldWithDefault(msg, 5, ""),
    geoTargetConstantsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    radius: jspb.Message.getFieldWithDefault(msg, 7, 0),
    radiusUnits: jspb.Message.getFieldWithDefault(msg, 4, 0),
    feedItemSetsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    enableCustomerLevelLocationAssetSet: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    locationGroupAssetSetsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LocationGroupInfo;
  return proto.google.ads.googleads.v18.common.LocationGroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LocationGroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeed(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addGeoTargetConstants(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRadius(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LocationGroupRadiusUnitsEnum.LocationGroupRadiusUnits} */ (reader.readEnum());
      msg.setRadiusUnits(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeedItemSets(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCustomerLevelLocationAssetSet(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocationGroupAssetSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LocationGroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LocationGroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGeoTargetConstantsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getRadiusUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFeedItemSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLocationGroupAssetSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string feed = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getFeed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setFeed = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.clearFeed = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.hasFeed = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string geo_target_constants = 6;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getGeoTargetConstantsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setGeoTargetConstantsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.addGeoTargetConstants = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.clearGeoTargetConstantsList = function() {
  return this.setGeoTargetConstantsList([]);
};


/**
 * optional int64 radius = 7;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setRadius = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.clearRadius = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.hasRadius = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.enums.LocationGroupRadiusUnitsEnum.LocationGroupRadiusUnits radius_units = 4;
 * @return {!proto.google.ads.googleads.v18.enums.LocationGroupRadiusUnitsEnum.LocationGroupRadiusUnits}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getRadiusUnits = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LocationGroupRadiusUnitsEnum.LocationGroupRadiusUnits} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LocationGroupRadiusUnitsEnum.LocationGroupRadiusUnits} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setRadiusUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string feed_item_sets = 8;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getFeedItemSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setFeedItemSetsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.addFeedItemSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.clearFeedItemSetsList = function() {
  return this.setFeedItemSetsList([]);
};


/**
 * optional bool enable_customer_level_location_asset_set = 9;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getEnableCustomerLevelLocationAssetSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setEnableCustomerLevelLocationAssetSet = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.clearEnableCustomerLevelLocationAssetSet = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.hasEnableCustomerLevelLocationAssetSet = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string location_group_asset_sets = 10;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.getLocationGroupAssetSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.setLocationGroupAssetSetsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.addLocationGroupAssetSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocationGroupInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocationGroupInfo.prototype.clearLocationGroupAssetSetsList = function() {
  return this.setLocationGroupAssetSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CustomAudienceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CustomAudienceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    customAudience: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CustomAudienceInfo}
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CustomAudienceInfo;
  return proto.google.ads.googleads.v18.common.CustomAudienceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CustomAudienceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CustomAudienceInfo}
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomAudience(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CustomAudienceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CustomAudienceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomAudience();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string custom_audience = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.prototype.getCustomAudience = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CustomAudienceInfo} returns this
 */
proto.google.ads.googleads.v18.common.CustomAudienceInfo.prototype.setCustomAudience = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CombinedAudienceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CombinedAudienceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    combinedAudience: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CombinedAudienceInfo}
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CombinedAudienceInfo;
  return proto.google.ads.googleads.v18.common.CombinedAudienceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CombinedAudienceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CombinedAudienceInfo}
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCombinedAudience(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CombinedAudienceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CombinedAudienceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCombinedAudience();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string combined_audience = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.prototype.getCombinedAudience = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CombinedAudienceInfo} returns this
 */
proto.google.ads.googleads.v18.common.CombinedAudienceInfo.prototype.setCombinedAudience = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AudienceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AudienceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AudienceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AudienceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    audience: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AudienceInfo}
 */
proto.google.ads.googleads.v18.common.AudienceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AudienceInfo;
  return proto.google.ads.googleads.v18.common.AudienceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AudienceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AudienceInfo}
 */
proto.google.ads.googleads.v18.common.AudienceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudience(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AudienceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AudienceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AudienceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AudienceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudience();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audience = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AudienceInfo.prototype.getAudience = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AudienceInfo} returns this
 */
proto.google.ads.googleads.v18.common.AudienceInfo.prototype.setAudience = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.KeywordThemeCase = {
  KEYWORD_THEME_NOT_SET: 0,
  KEYWORD_THEME_CONSTANT: 1,
  FREE_FORM_KEYWORD_THEME: 2
};

/**
 * @return {proto.google.ads.googleads.v18.common.KeywordThemeInfo.KeywordThemeCase}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.getKeywordThemeCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.KeywordThemeInfo.KeywordThemeCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.KeywordThemeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    keywordThemeConstant: jspb.Message.getFieldWithDefault(msg, 1, ""),
    freeFormKeywordTheme: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.KeywordThemeInfo}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.KeywordThemeInfo;
  return proto.google.ads.googleads.v18.common.KeywordThemeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.KeywordThemeInfo}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywordThemeConstant(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFreeFormKeywordTheme(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.KeywordThemeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keyword_theme_constant = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.getKeywordThemeConstant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.setKeywordThemeConstant = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.clearKeywordThemeConstant = function() {
  return jspb.Message.setOneofField(this, 1, proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.hasKeywordThemeConstant = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string free_form_keyword_theme = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.getFreeFormKeywordTheme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.setFreeFormKeywordTheme = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.KeywordThemeInfo} returns this
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.clearFreeFormKeywordTheme = function() {
  return jspb.Message.setOneofField(this, 2, proto.google.ads.googleads.v18.common.KeywordThemeInfo.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.KeywordThemeInfo.prototype.hasFreeFormKeywordTheme = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LocalServiceIdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LocalServiceIdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LocalServiceIdInfo}
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LocalServiceIdInfo;
  return proto.google.ads.googleads.v18.common.LocalServiceIdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LocalServiceIdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LocalServiceIdInfo}
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LocalServiceIdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LocalServiceIdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LocalServiceIdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalServiceIdInfo.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.SearchThemeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.SearchThemeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.SearchThemeInfo}
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.SearchThemeInfo;
  return proto.google.ads.googleads.v18.common.SearchThemeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.SearchThemeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.SearchThemeInfo}
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.SearchThemeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.SearchThemeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.SearchThemeInfo} returns this
 */
proto.google.ads.googleads.v18.common.SearchThemeInfo.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.BrandInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.BrandInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BrandInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    primaryUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rejectionReason: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo}
 */
proto.google.ads.googleads.v18.common.BrandInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.BrandInfo;
  return proto.google.ads.googleads.v18.common.BrandInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.BrandInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo}
 */
proto.google.ads.googleads.v18.common.BrandInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BrandRequestRejectionReasonEnum.BrandRequestRejectionReason} */ (reader.readEnum());
      msg.setRejectionReason(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BrandStateEnum.BrandState} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.BrandInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.BrandInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BrandInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v18.enums.BrandRequestRejectionReasonEnum.BrandRequestRejectionReason} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v18.enums.BrandStateEnum.BrandState} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.setDisplayName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.clearDisplayName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.setEntityId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.clearEntityId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.hasEntityId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string primary_url = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.getPrimaryUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.setPrimaryUrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.clearPrimaryUrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.hasPrimaryUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.ads.googleads.v18.enums.BrandRequestRejectionReasonEnum.BrandRequestRejectionReason rejection_reason = 4;
 * @return {!proto.google.ads.googleads.v18.enums.BrandRequestRejectionReasonEnum.BrandRequestRejectionReason}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.getRejectionReason = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BrandRequestRejectionReasonEnum.BrandRequestRejectionReason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BrandRequestRejectionReasonEnum.BrandRequestRejectionReason} value
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.setRejectionReason = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.clearRejectionReason = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.hasRejectionReason = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.ads.googleads.v18.enums.BrandStateEnum.BrandState status = 5;
 * @return {!proto.google.ads.googleads.v18.enums.BrandStateEnum.BrandState}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BrandStateEnum.BrandState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BrandStateEnum.BrandState} value
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.BrandInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.BrandInfo.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.BrandListInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.BrandListInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.BrandListInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BrandListInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sharedSet: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.BrandListInfo}
 */
proto.google.ads.googleads.v18.common.BrandListInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.BrandListInfo;
  return proto.google.ads.googleads.v18.common.BrandListInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.BrandListInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.BrandListInfo}
 */
proto.google.ads.googleads.v18.common.BrandListInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.BrandListInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.BrandListInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.BrandListInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BrandListInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string shared_set = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.BrandListInfo.prototype.getSharedSet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.BrandListInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandListInfo.prototype.setSharedSet = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.BrandListInfo} returns this
 */
proto.google.ads.googleads.v18.common.BrandListInfo.prototype.clearSharedSet = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.BrandListInfo.prototype.hasSharedSet = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.common);
