// source: proto/googleAdsPb/adGroupCriteria.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_enums_ad_group_criterion_status_pb = require('../../google/ads/googleads/v18/enums/ad_group_criterion_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_criterion_status_pb);
goog.exportSymbol('proto.googleAdsPb.AdGroupCriteria', null, global);
goog.exportSymbol('proto.googleAdsPb.RemoveAdGroupCriteriaAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupCriteriaAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupCriteriaAction.ActionsCase', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.AdGroupCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.AdGroupCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.AdGroupCriteria.displayName = 'proto.googleAdsPb.AdGroupCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.googleAdsPb.UpdateAdGroupCriteriaAction.oneofGroups_);
};
goog.inherits(proto.googleAdsPb.UpdateAdGroupCriteriaAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateAdGroupCriteriaAction.displayName = 'proto.googleAdsPb.UpdateAdGroupCriteriaAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.displayName = 'proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.displayName = 'proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.RemoveAdGroupCriteriaAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.RemoveAdGroupCriteriaAction.displayName = 'proto.googleAdsPb.RemoveAdGroupCriteriaAction';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.AdGroupCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.AdGroupCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.AdGroupCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdGroupCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.AdGroupCriteria}
 */
proto.googleAdsPb.AdGroupCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.AdGroupCriteria;
  return proto.googleAdsPb.AdGroupCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.AdGroupCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.AdGroupCriteria}
 */
proto.googleAdsPb.AdGroupCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.AdGroupCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.AdGroupCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.AdGroupCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdGroupCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string criteria_id = 1;
 * @return {string}
 */
proto.googleAdsPb.AdGroupCriteria.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdGroupCriteria} returns this
 */
proto.googleAdsPb.AdGroupCriteria.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string campaign_id = 2;
 * @return {string}
 */
proto.googleAdsPb.AdGroupCriteria.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdGroupCriteria} returns this
 */
proto.googleAdsPb.AdGroupCriteria.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_group_id = 3;
 * @return {string}
 */
proto.googleAdsPb.AdGroupCriteria.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdGroupCriteria} returns this
 */
proto.googleAdsPb.AdGroupCriteria.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.ActionsCase = {
  ACTIONS_NOT_SET: 0,
  UPDATE_CPC_BID: 1,
  UPDATE_STATUS: 2,
  REMOVE: 3
};

/**
 * @return {proto.googleAdsPb.UpdateAdGroupCriteriaAction.ActionsCase}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.getActionsCase = function() {
  return /** @type {proto.googleAdsPb.UpdateAdGroupCriteriaAction.ActionsCase} */(jspb.Message.computeOneofCase(this, proto.googleAdsPb.UpdateAdGroupCriteriaAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateAdGroupCriteriaAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateCpcBid: (f = msg.getUpdateCpcBid()) && proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.toObject(includeInstance, f),
    updateStatus: (f = msg.getUpdateStatus()) && proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.toObject(includeInstance, f),
    remove: (f = msg.getRemove()) && proto.googleAdsPb.RemoveAdGroupCriteriaAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateAdGroupCriteriaAction;
  return proto.googleAdsPb.UpdateAdGroupCriteriaAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.deserializeBinaryFromReader);
      msg.setUpdateCpcBid(value);
      break;
    case 2:
      var value = new proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.deserializeBinaryFromReader);
      msg.setUpdateStatus(value);
      break;
    case 3:
      var value = new proto.googleAdsPb.RemoveAdGroupCriteriaAction;
      reader.readMessage(value,proto.googleAdsPb.RemoveAdGroupCriteriaAction.deserializeBinaryFromReader);
      msg.setRemove(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateAdGroupCriteriaAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateCpcBid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.serializeBinaryToWriter
    );
  }
  f = message.getRemove();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.googleAdsPb.RemoveAdGroupCriteriaAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAdGroupCriteriaCPCBidAction update_cpc_bid = 1;
 * @return {?proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.getUpdateCpcBid = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction, 1));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} returns this
*/
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.setUpdateCpcBid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.googleAdsPb.UpdateAdGroupCriteriaAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.clearUpdateCpcBid = function() {
  return this.setUpdateCpcBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.hasUpdateCpcBid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateAdGroupCriteriaStatusAction update_status = 2;
 * @return {?proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.getUpdateStatus = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction, 2));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} returns this
*/
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.setUpdateStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.googleAdsPb.UpdateAdGroupCriteriaAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.clearUpdateStatus = function() {
  return this.setUpdateStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.hasUpdateStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RemoveAdGroupCriteriaAction remove = 3;
 * @return {?proto.googleAdsPb.RemoveAdGroupCriteriaAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.getRemove = function() {
  return /** @type{?proto.googleAdsPb.RemoveAdGroupCriteriaAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.RemoveAdGroupCriteriaAction, 3));
};


/**
 * @param {?proto.googleAdsPb.RemoveAdGroupCriteriaAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} returns this
*/
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.setRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.googleAdsPb.UpdateAdGroupCriteriaAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.clearRemove = function() {
  return this.setRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaAction.prototype.hasRemove = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction;
  return proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewCpcBidMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional string ad_group_id = 2;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string criteria_id = 3;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 new_cpc_bid_micros = 1;
 * @return {number}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.getNewCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction.prototype.setNewCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction;
  return proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string ad_group_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string criteria_id = 2;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus new_status = 3;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus}
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.getNewStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} value
 * @return {!proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction.prototype.setNewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.RemoveAdGroupCriteriaAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.RemoveAdGroupCriteriaAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.RemoveAdGroupCriteriaAction}
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.RemoveAdGroupCriteriaAction;
  return proto.googleAdsPb.RemoveAdGroupCriteriaAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.RemoveAdGroupCriteriaAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.RemoveAdGroupCriteriaAction}
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.RemoveAdGroupCriteriaAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.RemoveAdGroupCriteriaAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ad_group_id = 1;
 * @return {string}
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.RemoveAdGroupCriteriaAction} returns this
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string criteria_id = 2;
 * @return {string}
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.RemoveAdGroupCriteriaAction} returns this
 */
proto.googleAdsPb.RemoveAdGroupCriteriaAction.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.googleAdsPb);
