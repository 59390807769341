import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import { WantsAmpdProOperatorFeaturesContext } from "ExtensionV2";
import { Site } from "ExtensionV2/queries/useSession";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useSessionUser } from "ExtensionV2/queries/useSessionUser";
import { useContext } from "react";

// Occasionally the Facebook team may log into our app to review our
// use of their API. This is the email address they will use to log in.
const FACEBOOK_APP_REVIEW_USER_EMAIL = "jake+fb_app_review@ampd.io";

const isEnterpriseAccount = (site: Site): boolean => {
  return (
    site.billingAccountType ===
      DashboardSite.BillingAccountType.Option.ENTERPRISE ||
    site.billingAccountType === DashboardSite.BillingAccountType.Option.LINKED
  );
};

// "Pro Operators" are company employees. They can toggle their global access to all features on/off
// in the UI.
export const useWantsProOperatorFeatures = (): boolean => {
  const user = useSessionUser();
  const [wantAmpdProOperatorFeatures] = useContext(
    WantsAmpdProOperatorFeaturesContext
  );

  return user.isAmpdOperator && wantAmpdProOperatorFeatures;
};

export const useHasIndividualOnboardingFeature = (): boolean => {
  const site = useSessionSite();

  return site.siteFeatures.privateOnboarding;
};

// AKA Auto Pausing. This only gates the feature in the UI, ampd:automatePausingUIEnabled needs to
// also be enabled for the site to enable the feature in the back end.
export const useHasAmpdProtectFeature = (): boolean => {
  const site = useSessionSite();

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();
  if (wantsProOperatorFeatures) {
    return true;
  }

  return site.siteFeatures.ampdProtection;
};

export const useHasAmpdBidAutomationFeature = (): boolean => {
  const site = useSessionSite();

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();
  if (wantsProOperatorFeatures) {
    return true;
  }

  if (isEnterpriseAccount(site)) {
    return true;
  }

  return site.siteFeatures.ampdAutoBidding;
};

export const useBrandOverviewPageEnabled = (): boolean => {
  const site = useSessionSite();

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();
  if (wantsProOperatorFeatures) {
    return true;
  }

  return site.siteFeatures.brandOverviewPageEnabled;
};

// Returns whether the site is a self-serve site, that is, it does not have
// enterprise or enterprise-linked billing and, it is not managed by Ampd Pro/
// Ampd Management.
export const useHasSelfServeBilling = (): boolean => {
  const site = useSessionSite();

  if (isEnterpriseAccount(site)) {
    return false;
  }

  return !site.siteFeatures.isManaged;
};

export function useShouldShowFacebookFeatures(): boolean {
  const site = useSessionSite();
  const user = useSessionUser();
  const wantsProOperatorFeatures = useWantsProOperatorFeatures();

  return (
    user.userEmail === FACEBOOK_APP_REVIEW_USER_EMAIL ||
    wantsProOperatorFeatures ||
    site.siteFeatures.facebookIntegrationAccess
  );
}

// Only show the page after the user has connected their Facebook account.
export const useShouldShowFacebookPage = (): boolean => {
  const site = useSessionSite();
  const showFacebookFeatures = useShouldShowFacebookFeatures();
  if (!showFacebookFeatures) {
    return false;
  }

  return site.facebookAccount.hasAuthToken;
};

export const useHasWalmartUIEnabledFeature = (): boolean => {
  const site = useSessionSite();

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();
  if (wantsProOperatorFeatures) {
    return true;
  }

  return site.siteFeatures.walmartUiEnabled;
};

// Returns whether the user wants to see the Products Page calculated data charts
// even when the data appears to be misaligned (eg: total AmazonAds revenues on a
// parent product is higher than total sales for that product).
export const useWantsProductsPageMisalignedDataEnabled = (): boolean => {
  const site = useSessionSite();

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();
  if (wantsProOperatorFeatures) {
    return true;
  }

  return site.siteFeatures.productsPageMisalignedDataEnabled;
};
