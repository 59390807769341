// source: google/ads/googleads/v18/resources/campaign_criterion.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_criteria_pb = require('../../../../../google/ads/googleads/v18/common/criteria_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_criteria_pb);
var google_ads_googleads_v18_enums_campaign_criterion_status_pb = require('../../../../../google/ads/googleads/v18/enums/campaign_criterion_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_campaign_criterion_status_pb);
var google_ads_googleads_v18_enums_criterion_type_pb = require('../../../../../google/ads/googleads/v18/enums/criterion_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_criterion_type_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.CampaignCriterion', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.CampaignCriterion.CriterionCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.resources.CampaignCriterion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.CampaignCriterion.displayName = 'proto.google.ads.googleads.v18.resources.CampaignCriterion';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_ = [[8,9,10,11,12,13,15,16,17,18,19,22,20,21,23,24,25,26,27,28,29,30,31,32,33,34,36,41,42,45,46,47]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.CriterionCase = {
  CRITERION_NOT_SET: 0,
  KEYWORD: 8,
  PLACEMENT: 9,
  MOBILE_APP_CATEGORY: 10,
  MOBILE_APPLICATION: 11,
  LOCATION: 12,
  DEVICE: 13,
  AD_SCHEDULE: 15,
  AGE_RANGE: 16,
  GENDER: 17,
  INCOME_RANGE: 18,
  PARENTAL_STATUS: 19,
  USER_LIST: 22,
  YOUTUBE_VIDEO: 20,
  YOUTUBE_CHANNEL: 21,
  PROXIMITY: 23,
  TOPIC: 24,
  LISTING_SCOPE: 25,
  LANGUAGE: 26,
  IP_BLOCK: 27,
  CONTENT_LABEL: 28,
  CARRIER: 29,
  USER_INTEREST: 30,
  WEBPAGE: 31,
  OPERATING_SYSTEM_VERSION: 32,
  MOBILE_DEVICE: 33,
  LOCATION_GROUP: 34,
  CUSTOM_AFFINITY: 36,
  CUSTOM_AUDIENCE: 41,
  COMBINED_AUDIENCE: 42,
  KEYWORD_THEME: 45,
  LOCAL_SERVICE_ID: 46,
  BRAND_LIST: 47
};

/**
 * @return {proto.google.ads.googleads.v18.resources.CampaignCriterion.CriterionCase}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCriterionCase = function() {
  return /** @type {proto.google.ads.googleads.v18.resources.CampaignCriterion.CriterionCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.CampaignCriterion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.CampaignCriterion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaign: jspb.Message.getFieldWithDefault(msg, 37, ""),
    criterionId: jspb.Message.getFieldWithDefault(msg, 38, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 43, ""),
    bidModifier: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    negative: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 35, 0),
    keyword: (f = msg.getKeyword()) && google_ads_googleads_v18_common_criteria_pb.KeywordInfo.toObject(includeInstance, f),
    placement: (f = msg.getPlacement()) && google_ads_googleads_v18_common_criteria_pb.PlacementInfo.toObject(includeInstance, f),
    mobileAppCategory: (f = msg.getMobileAppCategory()) && google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo.toObject(includeInstance, f),
    mobileApplication: (f = msg.getMobileApplication()) && google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && google_ads_googleads_v18_common_criteria_pb.LocationInfo.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && google_ads_googleads_v18_common_criteria_pb.DeviceInfo.toObject(includeInstance, f),
    adSchedule: (f = msg.getAdSchedule()) && google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.toObject(includeInstance, f),
    ageRange: (f = msg.getAgeRange()) && google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo.toObject(includeInstance, f),
    gender: (f = msg.getGender()) && google_ads_googleads_v18_common_criteria_pb.GenderInfo.toObject(includeInstance, f),
    incomeRange: (f = msg.getIncomeRange()) && google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo.toObject(includeInstance, f),
    parentalStatus: (f = msg.getParentalStatus()) && google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo.toObject(includeInstance, f),
    userList: (f = msg.getUserList()) && google_ads_googleads_v18_common_criteria_pb.UserListInfo.toObject(includeInstance, f),
    youtubeVideo: (f = msg.getYoutubeVideo()) && google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo.toObject(includeInstance, f),
    youtubeChannel: (f = msg.getYoutubeChannel()) && google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo.toObject(includeInstance, f),
    proximity: (f = msg.getProximity()) && google_ads_googleads_v18_common_criteria_pb.ProximityInfo.toObject(includeInstance, f),
    topic: (f = msg.getTopic()) && google_ads_googleads_v18_common_criteria_pb.TopicInfo.toObject(includeInstance, f),
    listingScope: (f = msg.getListingScope()) && google_ads_googleads_v18_common_criteria_pb.ListingScopeInfo.toObject(includeInstance, f),
    language: (f = msg.getLanguage()) && google_ads_googleads_v18_common_criteria_pb.LanguageInfo.toObject(includeInstance, f),
    ipBlock: (f = msg.getIpBlock()) && google_ads_googleads_v18_common_criteria_pb.IpBlockInfo.toObject(includeInstance, f),
    contentLabel: (f = msg.getContentLabel()) && google_ads_googleads_v18_common_criteria_pb.ContentLabelInfo.toObject(includeInstance, f),
    carrier: (f = msg.getCarrier()) && google_ads_googleads_v18_common_criteria_pb.CarrierInfo.toObject(includeInstance, f),
    userInterest: (f = msg.getUserInterest()) && google_ads_googleads_v18_common_criteria_pb.UserInterestInfo.toObject(includeInstance, f),
    webpage: (f = msg.getWebpage()) && google_ads_googleads_v18_common_criteria_pb.WebpageInfo.toObject(includeInstance, f),
    operatingSystemVersion: (f = msg.getOperatingSystemVersion()) && google_ads_googleads_v18_common_criteria_pb.OperatingSystemVersionInfo.toObject(includeInstance, f),
    mobileDevice: (f = msg.getMobileDevice()) && google_ads_googleads_v18_common_criteria_pb.MobileDeviceInfo.toObject(includeInstance, f),
    locationGroup: (f = msg.getLocationGroup()) && google_ads_googleads_v18_common_criteria_pb.LocationGroupInfo.toObject(includeInstance, f),
    customAffinity: (f = msg.getCustomAffinity()) && google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo.toObject(includeInstance, f),
    customAudience: (f = msg.getCustomAudience()) && google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo.toObject(includeInstance, f),
    combinedAudience: (f = msg.getCombinedAudience()) && google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo.toObject(includeInstance, f),
    keywordTheme: (f = msg.getKeywordTheme()) && google_ads_googleads_v18_common_criteria_pb.KeywordThemeInfo.toObject(includeInstance, f),
    localServiceId: (f = msg.getLocalServiceId()) && google_ads_googleads_v18_common_criteria_pb.LocalServiceIdInfo.toObject(includeInstance, f),
    brandList: (f = msg.getBrandList()) && google_ads_googleads_v18_common_criteria_pb.BrandListInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.CampaignCriterion;
  return proto.google.ads.googleads.v18.resources.CampaignCriterion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.CampaignCriterion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriterionId(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBidModifier(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNegative(value);
      break;
    case 6:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 35:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CampaignCriterionStatusEnum.CampaignCriterionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new google_ads_googleads_v18_common_criteria_pb.KeywordInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.KeywordInfo.deserializeBinaryFromReader);
      msg.setKeyword(value);
      break;
    case 9:
      var value = new google_ads_googleads_v18_common_criteria_pb.PlacementInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.PlacementInfo.deserializeBinaryFromReader);
      msg.setPlacement(value);
      break;
    case 10:
      var value = new google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo.deserializeBinaryFromReader);
      msg.setMobileAppCategory(value);
      break;
    case 11:
      var value = new google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo.deserializeBinaryFromReader);
      msg.setMobileApplication(value);
      break;
    case 12:
      var value = new google_ads_googleads_v18_common_criteria_pb.LocationInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.LocationInfo.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 13:
      var value = new google_ads_googleads_v18_common_criteria_pb.DeviceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.DeviceInfo.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 15:
      var value = new google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.deserializeBinaryFromReader);
      msg.setAdSchedule(value);
      break;
    case 16:
      var value = new google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo.deserializeBinaryFromReader);
      msg.setAgeRange(value);
      break;
    case 17:
      var value = new google_ads_googleads_v18_common_criteria_pb.GenderInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.GenderInfo.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 18:
      var value = new google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo.deserializeBinaryFromReader);
      msg.setIncomeRange(value);
      break;
    case 19:
      var value = new google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo.deserializeBinaryFromReader);
      msg.setParentalStatus(value);
      break;
    case 22:
      var value = new google_ads_googleads_v18_common_criteria_pb.UserListInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.UserListInfo.deserializeBinaryFromReader);
      msg.setUserList(value);
      break;
    case 20:
      var value = new google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo.deserializeBinaryFromReader);
      msg.setYoutubeVideo(value);
      break;
    case 21:
      var value = new google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo.deserializeBinaryFromReader);
      msg.setYoutubeChannel(value);
      break;
    case 23:
      var value = new google_ads_googleads_v18_common_criteria_pb.ProximityInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.ProximityInfo.deserializeBinaryFromReader);
      msg.setProximity(value);
      break;
    case 24:
      var value = new google_ads_googleads_v18_common_criteria_pb.TopicInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.TopicInfo.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    case 25:
      var value = new google_ads_googleads_v18_common_criteria_pb.ListingScopeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.ListingScopeInfo.deserializeBinaryFromReader);
      msg.setListingScope(value);
      break;
    case 26:
      var value = new google_ads_googleads_v18_common_criteria_pb.LanguageInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.LanguageInfo.deserializeBinaryFromReader);
      msg.setLanguage(value);
      break;
    case 27:
      var value = new google_ads_googleads_v18_common_criteria_pb.IpBlockInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.IpBlockInfo.deserializeBinaryFromReader);
      msg.setIpBlock(value);
      break;
    case 28:
      var value = new google_ads_googleads_v18_common_criteria_pb.ContentLabelInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.ContentLabelInfo.deserializeBinaryFromReader);
      msg.setContentLabel(value);
      break;
    case 29:
      var value = new google_ads_googleads_v18_common_criteria_pb.CarrierInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CarrierInfo.deserializeBinaryFromReader);
      msg.setCarrier(value);
      break;
    case 30:
      var value = new google_ads_googleads_v18_common_criteria_pb.UserInterestInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.UserInterestInfo.deserializeBinaryFromReader);
      msg.setUserInterest(value);
      break;
    case 31:
      var value = new google_ads_googleads_v18_common_criteria_pb.WebpageInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.WebpageInfo.deserializeBinaryFromReader);
      msg.setWebpage(value);
      break;
    case 32:
      var value = new google_ads_googleads_v18_common_criteria_pb.OperatingSystemVersionInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.OperatingSystemVersionInfo.deserializeBinaryFromReader);
      msg.setOperatingSystemVersion(value);
      break;
    case 33:
      var value = new google_ads_googleads_v18_common_criteria_pb.MobileDeviceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.MobileDeviceInfo.deserializeBinaryFromReader);
      msg.setMobileDevice(value);
      break;
    case 34:
      var value = new google_ads_googleads_v18_common_criteria_pb.LocationGroupInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.LocationGroupInfo.deserializeBinaryFromReader);
      msg.setLocationGroup(value);
      break;
    case 36:
      var value = new google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo.deserializeBinaryFromReader);
      msg.setCustomAffinity(value);
      break;
    case 41:
      var value = new google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo.deserializeBinaryFromReader);
      msg.setCustomAudience(value);
      break;
    case 42:
      var value = new google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo.deserializeBinaryFromReader);
      msg.setCombinedAudience(value);
      break;
    case 45:
      var value = new google_ads_googleads_v18_common_criteria_pb.KeywordThemeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.KeywordThemeInfo.deserializeBinaryFromReader);
      msg.setKeywordTheme(value);
      break;
    case 46:
      var value = new google_ads_googleads_v18_common_criteria_pb.LocalServiceIdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.LocalServiceIdInfo.deserializeBinaryFromReader);
      msg.setLocalServiceId(value);
      break;
    case 47:
      var value = new google_ads_googleads_v18_common_criteria_pb.BrandListInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.BrandListInfo.deserializeBinaryFromReader);
      msg.setBrandList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.CampaignCriterion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.CampaignCriterion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 37));
  if (f != null) {
    writer.writeString(
      37,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 38));
  if (f != null) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 39));
  if (f != null) {
    writer.writeFloat(
      39,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
  f = message.getKeyword();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_ads_googleads_v18_common_criteria_pb.KeywordInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlacement();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_ads_googleads_v18_common_criteria_pb.PlacementInfo.serializeBinaryToWriter
    );
  }
  f = message.getMobileAppCategory();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo.serializeBinaryToWriter
    );
  }
  f = message.getMobileApplication();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_ads_googleads_v18_common_criteria_pb.LocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_ads_googleads_v18_common_criteria_pb.DeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getAdSchedule();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.serializeBinaryToWriter
    );
  }
  f = message.getAgeRange();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_ads_googleads_v18_common_criteria_pb.GenderInfo.serializeBinaryToWriter
    );
  }
  f = message.getIncomeRange();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentalStatus();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserList();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_ads_googleads_v18_common_criteria_pb.UserListInfo.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeVideo();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeChannel();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo.serializeBinaryToWriter
    );
  }
  f = message.getProximity();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_ads_googleads_v18_common_criteria_pb.ProximityInfo.serializeBinaryToWriter
    );
  }
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_ads_googleads_v18_common_criteria_pb.TopicInfo.serializeBinaryToWriter
    );
  }
  f = message.getListingScope();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_ads_googleads_v18_common_criteria_pb.ListingScopeInfo.serializeBinaryToWriter
    );
  }
  f = message.getLanguage();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_ads_googleads_v18_common_criteria_pb.LanguageInfo.serializeBinaryToWriter
    );
  }
  f = message.getIpBlock();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_ads_googleads_v18_common_criteria_pb.IpBlockInfo.serializeBinaryToWriter
    );
  }
  f = message.getContentLabel();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_ads_googleads_v18_common_criteria_pb.ContentLabelInfo.serializeBinaryToWriter
    );
  }
  f = message.getCarrier();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_ads_googleads_v18_common_criteria_pb.CarrierInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserInterest();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_ads_googleads_v18_common_criteria_pb.UserInterestInfo.serializeBinaryToWriter
    );
  }
  f = message.getWebpage();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_ads_googleads_v18_common_criteria_pb.WebpageInfo.serializeBinaryToWriter
    );
  }
  f = message.getOperatingSystemVersion();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_ads_googleads_v18_common_criteria_pb.OperatingSystemVersionInfo.serializeBinaryToWriter
    );
  }
  f = message.getMobileDevice();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_ads_googleads_v18_common_criteria_pb.MobileDeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocationGroup();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_ads_googleads_v18_common_criteria_pb.LocationGroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomAffinity();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomAudience();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo.serializeBinaryToWriter
    );
  }
  f = message.getCombinedAudience();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo.serializeBinaryToWriter
    );
  }
  f = message.getKeywordTheme();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_ads_googleads_v18_common_criteria_pb.KeywordThemeInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocalServiceId();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_ads_googleads_v18_common_criteria_pb.LocalServiceIdInfo.serializeBinaryToWriter
    );
  }
  f = message.getBrandList();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_ads_googleads_v18_common_criteria_pb.BrandListInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string campaign = 37;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setCampaign = function(value) {
  return jspb.Message.setField(this, 37, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearCampaign = function() {
  return jspb.Message.setField(this, 37, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional int64 criterion_id = 38;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCriterionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setCriterionId = function(value) {
  return jspb.Message.setField(this, 38, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearCriterionId = function() {
  return jspb.Message.setField(this, 38, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasCriterionId = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional string display_name = 43;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional float bid_modifier = 39;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getBidModifier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setBidModifier = function(value) {
  return jspb.Message.setField(this, 39, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearBidModifier = function() {
  return jspb.Message.setField(this, 39, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasBidModifier = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional bool negative = 40;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getNegative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setNegative = function(value) {
  return jspb.Message.setField(this, 40, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearNegative = function() {
  return jspb.Message.setField(this, 40, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasNegative = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType type = 6;
 * @return {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CriterionTypeEnum.CriterionType} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.ads.googleads.v18.enums.CampaignCriterionStatusEnum.CampaignCriterionStatus status = 35;
 * @return {!proto.google.ads.googleads.v18.enums.CampaignCriterionStatusEnum.CampaignCriterionStatus}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CampaignCriterionStatusEnum.CampaignCriterionStatus} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CampaignCriterionStatusEnum.CampaignCriterionStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};


/**
 * optional google.ads.googleads.v18.common.KeywordInfo keyword = 8;
 * @return {?proto.google.ads.googleads.v18.common.KeywordInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getKeyword = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.KeywordInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.KeywordInfo, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.KeywordInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setKeyword = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearKeyword = function() {
  return this.setKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasKeyword = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.ads.googleads.v18.common.PlacementInfo placement = 9;
 * @return {?proto.google.ads.googleads.v18.common.PlacementInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getPlacement = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PlacementInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.PlacementInfo, 9));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PlacementInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setPlacement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearPlacement = function() {
  return this.setPlacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasPlacement = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.ads.googleads.v18.common.MobileAppCategoryInfo mobile_app_category = 10;
 * @return {?proto.google.ads.googleads.v18.common.MobileAppCategoryInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getMobileAppCategory = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MobileAppCategoryInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.MobileAppCategoryInfo, 10));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MobileAppCategoryInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setMobileAppCategory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearMobileAppCategory = function() {
  return this.setMobileAppCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasMobileAppCategory = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.ads.googleads.v18.common.MobileApplicationInfo mobile_application = 11;
 * @return {?proto.google.ads.googleads.v18.common.MobileApplicationInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getMobileApplication = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MobileApplicationInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.MobileApplicationInfo, 11));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MobileApplicationInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setMobileApplication = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearMobileApplication = function() {
  return this.setMobileApplication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasMobileApplication = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.ads.googleads.v18.common.LocationInfo location = 12;
 * @return {?proto.google.ads.googleads.v18.common.LocationInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getLocation = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LocationInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.LocationInfo, 12));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LocationInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setLocation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.ads.googleads.v18.common.DeviceInfo device = 13;
 * @return {?proto.google.ads.googleads.v18.common.DeviceInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getDevice = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DeviceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.DeviceInfo, 13));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DeviceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setDevice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.ads.googleads.v18.common.AdScheduleInfo ad_schedule = 15;
 * @return {?proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getAdSchedule = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdScheduleInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo, 15));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdScheduleInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setAdSchedule = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearAdSchedule = function() {
  return this.setAdSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasAdSchedule = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.ads.googleads.v18.common.AgeRangeInfo age_range = 16;
 * @return {?proto.google.ads.googleads.v18.common.AgeRangeInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getAgeRange = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AgeRangeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AgeRangeInfo, 16));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AgeRangeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setAgeRange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearAgeRange = function() {
  return this.setAgeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasAgeRange = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.ads.googleads.v18.common.GenderInfo gender = 17;
 * @return {?proto.google.ads.googleads.v18.common.GenderInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getGender = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.GenderInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.GenderInfo, 17));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.GenderInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setGender = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasGender = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.ads.googleads.v18.common.IncomeRangeInfo income_range = 18;
 * @return {?proto.google.ads.googleads.v18.common.IncomeRangeInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getIncomeRange = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.IncomeRangeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.IncomeRangeInfo, 18));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.IncomeRangeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setIncomeRange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearIncomeRange = function() {
  return this.setIncomeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasIncomeRange = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.ads.googleads.v18.common.ParentalStatusInfo parental_status = 19;
 * @return {?proto.google.ads.googleads.v18.common.ParentalStatusInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getParentalStatus = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ParentalStatusInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.ParentalStatusInfo, 19));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ParentalStatusInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setParentalStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearParentalStatus = function() {
  return this.setParentalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasParentalStatus = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.ads.googleads.v18.common.UserListInfo user_list = 22;
 * @return {?proto.google.ads.googleads.v18.common.UserListInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getUserList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.UserListInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.UserListInfo, 22));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.UserListInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setUserList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearUserList = function() {
  return this.setUserList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasUserList = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.ads.googleads.v18.common.YouTubeVideoInfo youtube_video = 20;
 * @return {?proto.google.ads.googleads.v18.common.YouTubeVideoInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getYoutubeVideo = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.YouTubeVideoInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.YouTubeVideoInfo, 20));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.YouTubeVideoInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setYoutubeVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearYoutubeVideo = function() {
  return this.setYoutubeVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasYoutubeVideo = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.ads.googleads.v18.common.YouTubeChannelInfo youtube_channel = 21;
 * @return {?proto.google.ads.googleads.v18.common.YouTubeChannelInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getYoutubeChannel = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.YouTubeChannelInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.YouTubeChannelInfo, 21));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.YouTubeChannelInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setYoutubeChannel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearYoutubeChannel = function() {
  return this.setYoutubeChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasYoutubeChannel = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.ads.googleads.v18.common.ProximityInfo proximity = 23;
 * @return {?proto.google.ads.googleads.v18.common.ProximityInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getProximity = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ProximityInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.ProximityInfo, 23));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ProximityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setProximity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearProximity = function() {
  return this.setProximity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasProximity = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.ads.googleads.v18.common.TopicInfo topic = 24;
 * @return {?proto.google.ads.googleads.v18.common.TopicInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getTopic = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.TopicInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.TopicInfo, 24));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.TopicInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setTopic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.ads.googleads.v18.common.ListingScopeInfo listing_scope = 25;
 * @return {?proto.google.ads.googleads.v18.common.ListingScopeInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getListingScope = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ListingScopeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.ListingScopeInfo, 25));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ListingScopeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setListingScope = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearListingScope = function() {
  return this.setListingScope(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasListingScope = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.ads.googleads.v18.common.LanguageInfo language = 26;
 * @return {?proto.google.ads.googleads.v18.common.LanguageInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getLanguage = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LanguageInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.LanguageInfo, 26));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LanguageInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setLanguage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearLanguage = function() {
  return this.setLanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasLanguage = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.ads.googleads.v18.common.IpBlockInfo ip_block = 27;
 * @return {?proto.google.ads.googleads.v18.common.IpBlockInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getIpBlock = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.IpBlockInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.IpBlockInfo, 27));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.IpBlockInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setIpBlock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearIpBlock = function() {
  return this.setIpBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasIpBlock = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.ads.googleads.v18.common.ContentLabelInfo content_label = 28;
 * @return {?proto.google.ads.googleads.v18.common.ContentLabelInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getContentLabel = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ContentLabelInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.ContentLabelInfo, 28));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ContentLabelInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setContentLabel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearContentLabel = function() {
  return this.setContentLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasContentLabel = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.ads.googleads.v18.common.CarrierInfo carrier = 29;
 * @return {?proto.google.ads.googleads.v18.common.CarrierInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCarrier = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CarrierInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CarrierInfo, 29));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CarrierInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setCarrier = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearCarrier = function() {
  return this.setCarrier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasCarrier = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.ads.googleads.v18.common.UserInterestInfo user_interest = 30;
 * @return {?proto.google.ads.googleads.v18.common.UserInterestInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getUserInterest = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.UserInterestInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.UserInterestInfo, 30));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.UserInterestInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setUserInterest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearUserInterest = function() {
  return this.setUserInterest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasUserInterest = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.ads.googleads.v18.common.WebpageInfo webpage = 31;
 * @return {?proto.google.ads.googleads.v18.common.WebpageInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getWebpage = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.WebpageInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.WebpageInfo, 31));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.WebpageInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setWebpage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearWebpage = function() {
  return this.setWebpage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasWebpage = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.ads.googleads.v18.common.OperatingSystemVersionInfo operating_system_version = 32;
 * @return {?proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getOperatingSystemVersion = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.OperatingSystemVersionInfo, 32));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.OperatingSystemVersionInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setOperatingSystemVersion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearOperatingSystemVersion = function() {
  return this.setOperatingSystemVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasOperatingSystemVersion = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.ads.googleads.v18.common.MobileDeviceInfo mobile_device = 33;
 * @return {?proto.google.ads.googleads.v18.common.MobileDeviceInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getMobileDevice = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MobileDeviceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.MobileDeviceInfo, 33));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MobileDeviceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setMobileDevice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearMobileDevice = function() {
  return this.setMobileDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasMobileDevice = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.ads.googleads.v18.common.LocationGroupInfo location_group = 34;
 * @return {?proto.google.ads.googleads.v18.common.LocationGroupInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getLocationGroup = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LocationGroupInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.LocationGroupInfo, 34));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LocationGroupInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setLocationGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearLocationGroup = function() {
  return this.setLocationGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasLocationGroup = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.ads.googleads.v18.common.CustomAffinityInfo custom_affinity = 36;
 * @return {?proto.google.ads.googleads.v18.common.CustomAffinityInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCustomAffinity = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CustomAffinityInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CustomAffinityInfo, 36));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CustomAffinityInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setCustomAffinity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearCustomAffinity = function() {
  return this.setCustomAffinity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasCustomAffinity = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.ads.googleads.v18.common.CustomAudienceInfo custom_audience = 41;
 * @return {?proto.google.ads.googleads.v18.common.CustomAudienceInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCustomAudience = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CustomAudienceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CustomAudienceInfo, 41));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CustomAudienceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setCustomAudience = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearCustomAudience = function() {
  return this.setCustomAudience(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasCustomAudience = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.ads.googleads.v18.common.CombinedAudienceInfo combined_audience = 42;
 * @return {?proto.google.ads.googleads.v18.common.CombinedAudienceInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getCombinedAudience = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CombinedAudienceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.CombinedAudienceInfo, 42));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CombinedAudienceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setCombinedAudience = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearCombinedAudience = function() {
  return this.setCombinedAudience(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasCombinedAudience = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.ads.googleads.v18.common.KeywordThemeInfo keyword_theme = 45;
 * @return {?proto.google.ads.googleads.v18.common.KeywordThemeInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getKeywordTheme = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.KeywordThemeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.KeywordThemeInfo, 45));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.KeywordThemeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setKeywordTheme = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearKeywordTheme = function() {
  return this.setKeywordTheme(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasKeywordTheme = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional google.ads.googleads.v18.common.LocalServiceIdInfo local_service_id = 46;
 * @return {?proto.google.ads.googleads.v18.common.LocalServiceIdInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getLocalServiceId = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LocalServiceIdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.LocalServiceIdInfo, 46));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LocalServiceIdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setLocalServiceId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearLocalServiceId = function() {
  return this.setLocalServiceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasLocalServiceId = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.ads.googleads.v18.common.BrandListInfo brand_list = 47;
 * @return {?proto.google.ads.googleads.v18.common.BrandListInfo}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.getBrandList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.BrandListInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.BrandListInfo, 47));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.BrandListInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.setBrandList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.google.ads.googleads.v18.resources.CampaignCriterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignCriterion} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.clearBrandList = function() {
  return this.setBrandList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignCriterion.prototype.hasBrandList = function() {
  return jspb.Message.getField(this, 47) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
