import { UseMutationResult, useMutation } from "@tanstack/react-query";

import {
  UpdateCampaignsRequest,
  UpdateCampaignsReply,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { GRPCWebClient } from "Common/utils/grpc";

import { sendGAEvent } from "../components/GA";

interface UpdateWalmartAttributionCategoriesArgs {
  siteAlias: string;
  gaCategory: string;
  googleAdsAccountId: string;
  actionId: string;
  newCategoriesToInfer: Array<string>;
  newCategoriesToIgnore: Array<string>;
}

export const useUpdateWalmartAttributionCategories = (): UseMutationResult<
  UpdateCampaignsReply.AsObject,
  unknown,
  UpdateWalmartAttributionCategoriesArgs,
  unknown
> => {
  return useMutation(
    async (
      updateWalmartAttributionCategoriesArgs: UpdateWalmartAttributionCategoriesArgs
    ): Promise<UpdateCampaignsReply.AsObject> => {
      for (const [key, value] of Object.entries(
        updateWalmartAttributionCategoriesArgs
      )) {
        if (key === "gaCategory") {
          continue;
        }
        if (value == null) {
          throw new Error(`Missing required argument: ${key}`);
        }
      }

      const {
        siteAlias,
        gaCategory,
        googleAdsAccountId,
        actionId,
        newCategoriesToInfer,
        newCategoriesToIgnore
      } = updateWalmartAttributionCategoriesArgs;

      if (gaCategory) {
        sendGAEvent(
          gaCategory,
          "Update Walmart Attribution Categories",
          siteAlias
        );
      }

      const updateAttributionCategories = new UpdateCampaignAction.UpdateWalmartAttributionCategoriesAction();
      updateAttributionCategories.setActionId(actionId);
      updateAttributionCategories.setCategoriesToInferList(
        newCategoriesToInfer
      );
      updateAttributionCategories.setCategoriesToIgnoreList(
        newCategoriesToIgnore
      );

      const updateAction = new UpdateCampaignAction();
      updateAction.setUpdateWalmartAttributionCategories(
        updateAttributionCategories
      );

      const updateReq = new UpdateCampaignsRequest();
      updateReq.setSiteAlias(siteAlias);
      updateReq.setCustomerId(googleAdsAccountId);
      updateReq.setActionsList([updateAction]);

      const reply = await GRPCWebClient.updateCampaigns(updateReq, {});
      return reply.toObject();
    }
  );
};
