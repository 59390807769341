// source: proto/googleAdsPb/campaign.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.googleAdsPb.AdvertisingChannelSubType', null, global);
goog.exportSymbol('proto.googleAdsPb.AdvertisingChannelSubType.Option', null, global);
goog.exportSymbol('proto.googleAdsPb.AdvertisingChannelType', null, global);
goog.exportSymbol('proto.googleAdsPb.AdvertisingChannelType.Option', null, global);
goog.exportSymbol('proto.googleAdsPb.BiddingStrategy', null, global);
goog.exportSymbol('proto.googleAdsPb.BiddingStrategy.Option', null, global);
goog.exportSymbol('proto.googleAdsPb.Campaign', null, global);
goog.exportSymbol('proto.googleAdsPb.CampaignStatus', null, global);
goog.exportSymbol('proto.googleAdsPb.CampaignStatus.Option', null, global);
goog.exportSymbol('proto.googleAdsPb.GeoTargetType', null, global);
goog.exportSymbol('proto.googleAdsPb.GeoTargetType.Option', null, global);
goog.exportSymbol('proto.googleAdsPb.NewCampaign', null, global);
goog.exportSymbol('proto.googleAdsPb.NewCampaign.DynamicSearch', null, global);
goog.exportSymbol('proto.googleAdsPb.NewCampaign.DynamicSearchReply', null, global);
goog.exportSymbol('proto.googleAdsPb.NewCampaign.TypeCase', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignAction.ActionCase', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignBiddingStrategyAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignMaxCPCAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignNameAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateCampaignStatusAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.Campaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.Campaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.Campaign.displayName = 'proto.googleAdsPb.Campaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.NewCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.googleAdsPb.NewCampaign.oneofGroups_);
};
goog.inherits(proto.googleAdsPb.NewCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.NewCampaign.displayName = 'proto.googleAdsPb.NewCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.NewCampaign.DynamicSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.googleAdsPb.NewCampaign.DynamicSearch.repeatedFields_, null);
};
goog.inherits(proto.googleAdsPb.NewCampaign.DynamicSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.NewCampaign.DynamicSearch.displayName = 'proto.googleAdsPb.NewCampaign.DynamicSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.NewCampaign.DynamicSearchReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.NewCampaign.DynamicSearchReply.displayName = 'proto.googleAdsPb.NewCampaign.DynamicSearchReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignAction.displayName = 'proto.googleAdsPb.UpdateCampaignAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignStatusAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignStatusAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignStatusAction.displayName = 'proto.googleAdsPb.UpdateCampaignStatusAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignMaxCPCAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignMaxCPCAction.displayName = 'proto.googleAdsPb.UpdateCampaignMaxCPCAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.repeatedFields_, null);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignBiddingStrategyAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.displayName = 'proto.googleAdsPb.UpdateCampaignBiddingStrategyAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.repeatedFields_, null);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.displayName = 'proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.displayName = 'proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateCampaignNameAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateCampaignNameAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateCampaignNameAction.displayName = 'proto.googleAdsPb.UpdateCampaignNameAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.CampaignStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.CampaignStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.CampaignStatus.displayName = 'proto.googleAdsPb.CampaignStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.AdvertisingChannelType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.AdvertisingChannelType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.AdvertisingChannelType.displayName = 'proto.googleAdsPb.AdvertisingChannelType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.AdvertisingChannelSubType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.AdvertisingChannelSubType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.AdvertisingChannelSubType.displayName = 'proto.googleAdsPb.AdvertisingChannelSubType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.BiddingStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.BiddingStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.BiddingStrategy.displayName = 'proto.googleAdsPb.BiddingStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.GeoTargetType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.GeoTargetType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.GeoTargetType.displayName = 'proto.googleAdsPb.GeoTargetType';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.Campaign.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.Campaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.Campaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.Campaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    advertisingChannelType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    advertisingChannelSubType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxCpcMicros: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.Campaign}
 */
proto.googleAdsPb.Campaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.Campaign;
  return proto.googleAdsPb.Campaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.Campaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.Campaign}
 */
proto.googleAdsPb.Campaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.googleAdsPb.CampaignStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.googleAdsPb.AdvertisingChannelType.Option} */ (reader.readEnum());
      msg.setAdvertisingChannelType(value);
      break;
    case 5:
      var value = /** @type {!proto.googleAdsPb.AdvertisingChannelSubType.Option} */ (reader.readEnum());
      msg.setAdvertisingChannelSubType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxCpcMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.Campaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.Campaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.Campaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.Campaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAdvertisingChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAdvertisingChannelSubType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMaxCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.googleAdsPb.Campaign.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.Campaign} returns this
 */
proto.googleAdsPb.Campaign.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.googleAdsPb.Campaign.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.Campaign} returns this
 */
proto.googleAdsPb.Campaign.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CampaignStatus.Option status = 3;
 * @return {!proto.googleAdsPb.CampaignStatus.Option}
 */
proto.googleAdsPb.Campaign.prototype.getStatus = function() {
  return /** @type {!proto.googleAdsPb.CampaignStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.googleAdsPb.CampaignStatus.Option} value
 * @return {!proto.googleAdsPb.Campaign} returns this
 */
proto.googleAdsPb.Campaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional AdvertisingChannelType.Option advertising_channel_type = 4;
 * @return {!proto.googleAdsPb.AdvertisingChannelType.Option}
 */
proto.googleAdsPb.Campaign.prototype.getAdvertisingChannelType = function() {
  return /** @type {!proto.googleAdsPb.AdvertisingChannelType.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.googleAdsPb.AdvertisingChannelType.Option} value
 * @return {!proto.googleAdsPb.Campaign} returns this
 */
proto.googleAdsPb.Campaign.prototype.setAdvertisingChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional AdvertisingChannelSubType.Option advertising_channel_sub_type = 5;
 * @return {!proto.googleAdsPb.AdvertisingChannelSubType.Option}
 */
proto.googleAdsPb.Campaign.prototype.getAdvertisingChannelSubType = function() {
  return /** @type {!proto.googleAdsPb.AdvertisingChannelSubType.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.googleAdsPb.AdvertisingChannelSubType.Option} value
 * @return {!proto.googleAdsPb.Campaign} returns this
 */
proto.googleAdsPb.Campaign.prototype.setAdvertisingChannelSubType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 max_cpc_micros = 6;
 * @return {number}
 */
proto.googleAdsPb.Campaign.prototype.getMaxCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.Campaign} returns this
 */
proto.googleAdsPb.Campaign.prototype.setMaxCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.googleAdsPb.NewCampaign.oneofGroups_ = [[4]];

/**
 * @enum {number}
 */
proto.googleAdsPb.NewCampaign.TypeCase = {
  TYPE_NOT_SET: 0,
  DYNAMIC_SEARCH: 4
};

/**
 * @return {proto.googleAdsPb.NewCampaign.TypeCase}
 */
proto.googleAdsPb.NewCampaign.prototype.getTypeCase = function() {
  return /** @type {proto.googleAdsPb.NewCampaign.TypeCase} */(jspb.Message.computeOneofCase(this, proto.googleAdsPb.NewCampaign.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.NewCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.NewCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.NewCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.NewCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    budgetMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dynamicSearch: (f = msg.getDynamicSearch()) && proto.googleAdsPb.NewCampaign.DynamicSearch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.NewCampaign}
 */
proto.googleAdsPb.NewCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.NewCampaign;
  return proto.googleAdsPb.NewCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.NewCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.NewCampaign}
 */
proto.googleAdsPb.NewCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.googleAdsPb.CampaignStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBudgetMicros(value);
      break;
    case 4:
      var value = new proto.googleAdsPb.NewCampaign.DynamicSearch;
      reader.readMessage(value,proto.googleAdsPb.NewCampaign.DynamicSearch.deserializeBinaryFromReader);
      msg.setDynamicSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.NewCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.NewCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.NewCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.NewCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBudgetMicros();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDynamicSearch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.googleAdsPb.NewCampaign.DynamicSearch.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.NewCampaign.DynamicSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.NewCampaign.DynamicSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    languageCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    biddingStrategy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    defaultCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 4, 0),
    newAdGroupName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adDescriptionLine1: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adDescriptionLine2: jspb.Message.getFieldWithDefault(msg, 7, ""),
    targetPartialUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    geoTargetsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.NewCampaign.DynamicSearch;
  return proto.googleAdsPb.NewCampaign.DynamicSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.NewCampaign.DynamicSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 3:
      var value = /** @type {!proto.googleAdsPb.BiddingStrategy.Option} */ (reader.readEnum());
      msg.setBiddingStrategy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultCpcBidMicros(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewAdGroupName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdDescriptionLine1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdDescriptionLine2(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetPartialUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addGeoTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.NewCampaign.DynamicSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.NewCampaign.DynamicSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBiddingStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDefaultCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNewAdGroupName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdDescriptionLine1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdDescriptionLine2();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTargetPartialUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGeoTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language_code = 2;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BiddingStrategy.Option bidding_strategy = 3;
 * @return {!proto.googleAdsPb.BiddingStrategy.Option}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getBiddingStrategy = function() {
  return /** @type {!proto.googleAdsPb.BiddingStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.googleAdsPb.BiddingStrategy.Option} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setBiddingStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 default_cpc_bid_micros = 4;
 * @return {number}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getDefaultCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setDefaultCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string new_ad_group_name = 5;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getNewAdGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setNewAdGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ad_description_line_1 = 6;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getAdDescriptionLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setAdDescriptionLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ad_description_line_2 = 7;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getAdDescriptionLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setAdDescriptionLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string target_partial_url = 8;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getTargetPartialUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setTargetPartialUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string geo_targets = 9;
 * @return {!Array<string>}
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.getGeoTargetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.setGeoTargetsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.addGeoTargets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearch} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearch.prototype.clearGeoTargetsList = function() {
  return this.setGeoTargetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.NewCampaign.DynamicSearchReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    newCampaignBudgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newAdGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newAdId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newCriteriaId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearchReply}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.NewCampaign.DynamicSearchReply;
  return proto.googleAdsPb.NewCampaign.DynamicSearchReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearchReply}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewCampaignBudgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewAdGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewAdId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewCriteriaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.NewCampaign.DynamicSearchReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewCampaignBudgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewAdId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string new_campaign_budget_id = 1;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.getNewCampaignBudgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.setNewCampaignBudgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_ad_group_id = 2;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.getNewAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.setNewAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_ad_id = 3;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.getNewAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.setNewAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string new_criteria_id = 4;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.getNewCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign.DynamicSearchReply} returns this
 */
proto.googleAdsPb.NewCampaign.DynamicSearchReply.prototype.setNewCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.googleAdsPb.NewCampaign.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.NewCampaign} returns this
 */
proto.googleAdsPb.NewCampaign.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CampaignStatus.Option status = 2;
 * @return {!proto.googleAdsPb.CampaignStatus.Option}
 */
proto.googleAdsPb.NewCampaign.prototype.getStatus = function() {
  return /** @type {!proto.googleAdsPb.CampaignStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.googleAdsPb.CampaignStatus.Option} value
 * @return {!proto.googleAdsPb.NewCampaign} returns this
 */
proto.googleAdsPb.NewCampaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 budget_micros = 3;
 * @return {number}
 */
proto.googleAdsPb.NewCampaign.prototype.getBudgetMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.NewCampaign} returns this
 */
proto.googleAdsPb.NewCampaign.prototype.setBudgetMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional DynamicSearch dynamic_search = 4;
 * @return {?proto.googleAdsPb.NewCampaign.DynamicSearch}
 */
proto.googleAdsPb.NewCampaign.prototype.getDynamicSearch = function() {
  return /** @type{?proto.googleAdsPb.NewCampaign.DynamicSearch} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.NewCampaign.DynamicSearch, 4));
};


/**
 * @param {?proto.googleAdsPb.NewCampaign.DynamicSearch|undefined} value
 * @return {!proto.googleAdsPb.NewCampaign} returns this
*/
proto.googleAdsPb.NewCampaign.prototype.setDynamicSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.googleAdsPb.NewCampaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.NewCampaign} returns this
 */
proto.googleAdsPb.NewCampaign.prototype.clearDynamicSearch = function() {
  return this.setDynamicSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.NewCampaign.prototype.hasDynamicSearch = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.googleAdsPb.UpdateCampaignAction.oneofGroups_ = [[1,3,4,2,5]];

/**
 * @enum {number}
 */
proto.googleAdsPb.UpdateCampaignAction.ActionCase = {
  ACTION_NOT_SET: 0,
  UPDATE_STATUS: 1,
  UPDATE_NAME: 3,
  UPDATE_BIDDING_STRATEGY: 4,
  UPDATE_MAX_CPC: 2,
  UPDATE_GEO_TARGET_TYPE: 5
};

/**
 * @return {proto.googleAdsPb.UpdateCampaignAction.ActionCase}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.getActionCase = function() {
  return /** @type {proto.googleAdsPb.UpdateCampaignAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateStatus: (f = msg.getUpdateStatus()) && proto.googleAdsPb.UpdateCampaignStatusAction.toObject(includeInstance, f),
    updateName: (f = msg.getUpdateName()) && proto.googleAdsPb.UpdateCampaignNameAction.toObject(includeInstance, f),
    updateBiddingStrategy: (f = msg.getUpdateBiddingStrategy()) && proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.toObject(includeInstance, f),
    updateMaxCpc: (f = msg.getUpdateMaxCpc()) && proto.googleAdsPb.UpdateCampaignMaxCPCAction.toObject(includeInstance, f),
    updateGeoTargetType: (f = msg.getUpdateGeoTargetType()) && proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignAction}
 */
proto.googleAdsPb.UpdateCampaignAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignAction;
  return proto.googleAdsPb.UpdateCampaignAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignAction}
 */
proto.googleAdsPb.UpdateCampaignAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.googleAdsPb.UpdateCampaignStatusAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateCampaignStatusAction.deserializeBinaryFromReader);
      msg.setUpdateStatus(value);
      break;
    case 3:
      var value = new proto.googleAdsPb.UpdateCampaignNameAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateCampaignNameAction.deserializeBinaryFromReader);
      msg.setUpdateName(value);
      break;
    case 4:
      var value = new proto.googleAdsPb.UpdateCampaignBiddingStrategyAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.deserializeBinaryFromReader);
      msg.setUpdateBiddingStrategy(value);
      break;
    case 2:
      var value = new proto.googleAdsPb.UpdateCampaignMaxCPCAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateCampaignMaxCPCAction.deserializeBinaryFromReader);
      msg.setUpdateMaxCpc(value);
      break;
    case 5:
      var value = new proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.deserializeBinaryFromReader);
      msg.setUpdateGeoTargetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.googleAdsPb.UpdateCampaignStatusAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.googleAdsPb.UpdateCampaignNameAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBiddingStrategy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMaxCpc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.googleAdsPb.UpdateCampaignMaxCPCAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateGeoTargetType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCampaignStatusAction update_status = 1;
 * @return {?proto.googleAdsPb.UpdateCampaignStatusAction}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.getUpdateStatus = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignStatusAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateCampaignStatusAction, 1));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignStatusAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
*/
proto.googleAdsPb.UpdateCampaignAction.prototype.setUpdateStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.clearUpdateStatus = function() {
  return this.setUpdateStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.hasUpdateStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateCampaignNameAction update_name = 3;
 * @return {?proto.googleAdsPb.UpdateCampaignNameAction}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.getUpdateName = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignNameAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateCampaignNameAction, 3));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignNameAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
*/
proto.googleAdsPb.UpdateCampaignAction.prototype.setUpdateName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.clearUpdateName = function() {
  return this.setUpdateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.hasUpdateName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UpdateCampaignBiddingStrategyAction update_bidding_strategy = 4;
 * @return {?proto.googleAdsPb.UpdateCampaignBiddingStrategyAction}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.getUpdateBiddingStrategy = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateCampaignBiddingStrategyAction, 4));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignBiddingStrategyAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
*/
proto.googleAdsPb.UpdateCampaignAction.prototype.setUpdateBiddingStrategy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.clearUpdateBiddingStrategy = function() {
  return this.setUpdateBiddingStrategy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.hasUpdateBiddingStrategy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdateCampaignMaxCPCAction update_max_cpc = 2;
 * @return {?proto.googleAdsPb.UpdateCampaignMaxCPCAction}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.getUpdateMaxCpc = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignMaxCPCAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateCampaignMaxCPCAction, 2));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignMaxCPCAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
*/
proto.googleAdsPb.UpdateCampaignAction.prototype.setUpdateMaxCpc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.clearUpdateMaxCpc = function() {
  return this.setUpdateMaxCpc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.hasUpdateMaxCpc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpdateCampaignGeoTargetTypeAction update_geo_target_type = 5;
 * @return {?proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.getUpdateGeoTargetType = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction, 5));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
*/
proto.googleAdsPb.UpdateCampaignAction.prototype.setUpdateGeoTargetType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.googleAdsPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateCampaignAction} returns this
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.clearUpdateGeoTargetType = function() {
  return this.setUpdateGeoTargetType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateCampaignAction.prototype.hasUpdateGeoTargetType = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignStatusAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignStatusAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignStatusAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignStatusAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignStatusAction}
 */
proto.googleAdsPb.UpdateCampaignStatusAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignStatusAction;
  return proto.googleAdsPb.UpdateCampaignStatusAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignStatusAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignStatusAction}
 */
proto.googleAdsPb.UpdateCampaignStatusAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {!proto.googleAdsPb.CampaignStatus.Option} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignStatusAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignStatusAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignStatusAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignStatusAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignStatusAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignStatusAction} returns this
 */
proto.googleAdsPb.UpdateCampaignStatusAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CampaignStatus.Option new_status = 2;
 * @return {!proto.googleAdsPb.CampaignStatus.Option}
 */
proto.googleAdsPb.UpdateCampaignStatusAction.prototype.getNewStatus = function() {
  return /** @type {!proto.googleAdsPb.CampaignStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.googleAdsPb.CampaignStatus.Option} value
 * @return {!proto.googleAdsPb.UpdateCampaignStatusAction} returns this
 */
proto.googleAdsPb.UpdateCampaignStatusAction.prototype.setNewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignMaxCPCAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignMaxCPCAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxCpcMicros: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignMaxCPCAction}
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignMaxCPCAction;
  return proto.googleAdsPb.UpdateCampaignMaxCPCAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignMaxCPCAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignMaxCPCAction}
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxCpcMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignMaxCPCAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignMaxCPCAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignMaxCPCAction} returns this
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 max_cpc_micros = 2;
 * @return {number}
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.prototype.getMaxCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.UpdateCampaignMaxCPCAction} returns this
 */
proto.googleAdsPb.UpdateCampaignMaxCPCAction.prototype.setMaxCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newBiddingStrategy: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignMaxCpcMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adGroupCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 5, 0),
    keywordGroupBidsList: jspb.Message.toObjectList(msg.getKeywordGroupBidsList(),
    proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignBiddingStrategyAction;
  return proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {!proto.googleAdsPb.BiddingStrategy.Option} */ (reader.readEnum());
      msg.setNewBiddingStrategy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignMaxCpcMicros(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupCpcBidMicros(value);
      break;
    case 6:
      var value = new proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid;
      reader.readMessage(value,proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.deserializeBinaryFromReader);
      msg.addKeywordGroupBids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewBiddingStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCampaignMaxCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdGroupCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getKeywordGroupBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    keywordCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid;
  return proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCriteriaIds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKeywordCpcBidMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getKeywordCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated string criteria_ids = 1;
 * @return {!Array<string>}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.getCriteriaIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.setCriteriaIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.addCriteriaIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.clearCriteriaIdsList = function() {
  return this.setCriteriaIdsList([]);
};


/**
 * optional int64 keyword_cpc_bid_micros = 2;
 * @return {number}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.getKeywordCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid.prototype.setKeywordCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BiddingStrategy.Option new_bidding_strategy = 2;
 * @return {!proto.googleAdsPb.BiddingStrategy.Option}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.getNewBiddingStrategy = function() {
  return /** @type {!proto.googleAdsPb.BiddingStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.googleAdsPb.BiddingStrategy.Option} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.setNewBiddingStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 campaign_max_cpc_micros = 3;
 * @return {number}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.getCampaignMaxCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.setCampaignMaxCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string ad_group_id = 4;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 ad_group_cpc_bid_micros = 5;
 * @return {number}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.getAdGroupCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.setAdGroupCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated KeywordGroupBid keyword_group_bids = 6;
 * @return {!Array<!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid>}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.getKeywordGroupBidsList = function() {
  return /** @type{!Array<!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid, 6));
};


/**
 * @param {!Array<!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid>} value
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
*/
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.setKeywordGroupBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid}
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.addKeywordGroupBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.KeywordGroupBid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} returns this
 */
proto.googleAdsPb.UpdateCampaignBiddingStrategyAction.prototype.clearKeywordGroupBidsList = function() {
  return this.setKeywordGroupBidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newGeoTargetType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction}
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction;
  return proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction}
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {!proto.googleAdsPb.GeoTargetType.Option} */ (reader.readEnum());
      msg.setNewGeoTargetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewGeoTargetType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction} returns this
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GeoTargetType.Option new_geo_target_type = 2;
 * @return {!proto.googleAdsPb.GeoTargetType.Option}
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.prototype.getNewGeoTargetType = function() {
  return /** @type {!proto.googleAdsPb.GeoTargetType.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.googleAdsPb.GeoTargetType.Option} value
 * @return {!proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction} returns this
 */
proto.googleAdsPb.UpdateCampaignGeoTargetTypeAction.prototype.setNewGeoTargetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateCampaignNameAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateCampaignNameAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateCampaignNameAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignNameAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateCampaignNameAction}
 */
proto.googleAdsPb.UpdateCampaignNameAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateCampaignNameAction;
  return proto.googleAdsPb.UpdateCampaignNameAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateCampaignNameAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateCampaignNameAction}
 */
proto.googleAdsPb.UpdateCampaignNameAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateCampaignNameAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateCampaignNameAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateCampaignNameAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateCampaignNameAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignNameAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignNameAction} returns this
 */
proto.googleAdsPb.UpdateCampaignNameAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_name = 2;
 * @return {string}
 */
proto.googleAdsPb.UpdateCampaignNameAction.prototype.getNewName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateCampaignNameAction} returns this
 */
proto.googleAdsPb.UpdateCampaignNameAction.prototype.setNewName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.CampaignStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.CampaignStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.CampaignStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.CampaignStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.CampaignStatus}
 */
proto.googleAdsPb.CampaignStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.CampaignStatus;
  return proto.googleAdsPb.CampaignStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.CampaignStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.CampaignStatus}
 */
proto.googleAdsPb.CampaignStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.CampaignStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.CampaignStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.CampaignStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.CampaignStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.googleAdsPb.CampaignStatus.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ENABLED: 2,
  PAUSED: 3,
  REMOVED: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.AdvertisingChannelType.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.AdvertisingChannelType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.AdvertisingChannelType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdvertisingChannelType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.AdvertisingChannelType}
 */
proto.googleAdsPb.AdvertisingChannelType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.AdvertisingChannelType;
  return proto.googleAdsPb.AdvertisingChannelType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.AdvertisingChannelType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.AdvertisingChannelType}
 */
proto.googleAdsPb.AdvertisingChannelType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.AdvertisingChannelType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.AdvertisingChannelType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.AdvertisingChannelType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdvertisingChannelType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.googleAdsPb.AdvertisingChannelType.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  SEARCH: 2,
  DISPLAY: 3,
  SHOPPING: 4,
  HOTEL: 5,
  VIDEO: 6,
  MULTI_CHANNEL: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.AdvertisingChannelSubType.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.AdvertisingChannelSubType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.AdvertisingChannelSubType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdvertisingChannelSubType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.AdvertisingChannelSubType}
 */
proto.googleAdsPb.AdvertisingChannelSubType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.AdvertisingChannelSubType;
  return proto.googleAdsPb.AdvertisingChannelSubType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.AdvertisingChannelSubType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.AdvertisingChannelSubType}
 */
proto.googleAdsPb.AdvertisingChannelSubType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.AdvertisingChannelSubType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.AdvertisingChannelSubType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.AdvertisingChannelSubType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdvertisingChannelSubType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.googleAdsPb.AdvertisingChannelSubType.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  SEARCH_MOBILE_APP: 2,
  DISPLAY_MOBILE_APP: 3,
  SEARCH_EXPRESS: 4,
  DISPLAY_EXPRESS: 5,
  SHOPPING_SMART_ADS: 6,
  DISPLAY_GMAIL_AD: 7,
  DISPLAY_SMART_CAMPAIGN: 8,
  VIDEO_OUTSTREAM: 9,
  VIDEO_ACTION: 10,
  VIDEO_NON_SKIPPABLE: 11,
  APP_CAMPAIGN: 12,
  APP_CAMPAIGN_FOR_ENGAGEMENT: 13,
  SHOPPING_COMPARISON_LISTING_ADS: 15
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.BiddingStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.BiddingStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.BiddingStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.BiddingStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.BiddingStrategy}
 */
proto.googleAdsPb.BiddingStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.BiddingStrategy;
  return proto.googleAdsPb.BiddingStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.BiddingStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.BiddingStrategy}
 */
proto.googleAdsPb.BiddingStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.BiddingStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.BiddingStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.BiddingStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.BiddingStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.googleAdsPb.BiddingStrategy.Option = {
  UNSPECIFIED: 0,
  MAXIMIZE_CONVERSIONS: 1,
  MANUAL_CPC_ENHANCED_BID: 2,
  TARGET_SPEND: 3,
  MANUAL_CPC: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.GeoTargetType.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.GeoTargetType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.GeoTargetType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.GeoTargetType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.GeoTargetType}
 */
proto.googleAdsPb.GeoTargetType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.GeoTargetType;
  return proto.googleAdsPb.GeoTargetType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.GeoTargetType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.GeoTargetType}
 */
proto.googleAdsPb.GeoTargetType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.GeoTargetType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.GeoTargetType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.GeoTargetType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.GeoTargetType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.googleAdsPb.GeoTargetType.Option = {
  UNSPECIFIED: 0,
  PRESENCE: 1,
  SEARCH_INTEREST: 2,
  PRESENCE_OR_INTEREST: 3
};

goog.object.extend(exports, proto.googleAdsPb);
