// source: google/ads/googleads/v18/common/ad_asset.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_asset_policy_pb = require('../../../../../google/ads/googleads/v18/common/asset_policy_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_asset_policy_pb);
var google_ads_googleads_v18_enums_asset_performance_label_pb = require('../../../../../google/ads/googleads/v18/enums/asset_performance_label_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_performance_label_pb);
var google_ads_googleads_v18_enums_served_asset_field_type_pb = require('../../../../../google/ads/googleads/v18/enums/served_asset_field_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_served_asset_field_type_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdCallToActionAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdImageAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdMediaBundleAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdTextAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdVideoAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdVideoAssetInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdTextAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdTextAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdTextAsset.displayName = 'proto.google.ads.googleads.v18.common.AdTextAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdImageAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdImageAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdImageAsset.displayName = 'proto.google.ads.googleads.v18.common.AdImageAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdVideoAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdVideoAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdVideoAsset.displayName = 'proto.google.ads.googleads.v18.common.AdVideoAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdVideoAssetInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdVideoAssetInfo.displayName = 'proto.google.ads.googleads.v18.common.AdVideoAssetInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.displayName = 'proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdMediaBundleAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdMediaBundleAsset.displayName = 'proto.google.ads.googleads.v18.common.AdMediaBundleAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.displayName = 'proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AdCallToActionAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AdCallToActionAsset.displayName = 'proto.google.ads.googleads.v18.common.AdCallToActionAsset';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdTextAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdTextAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pinnedField: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetPerformanceLabel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    policySummaryInfo: (f = msg.getPolicySummaryInfo()) && google_ads_googleads_v18_common_asset_policy_pb.AdAssetPolicySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdTextAsset;
  return proto.google.ads.googleads.v18.common.AdTextAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ServedAssetFieldTypeEnum.ServedAssetFieldType} */ (reader.readEnum());
      msg.setPinnedField(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetPerformanceLabelEnum.AssetPerformanceLabel} */ (reader.readEnum());
      msg.setAssetPerformanceLabel(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_asset_policy_pb.AdAssetPolicySummary;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_policy_pb.AdAssetPolicySummary.deserializeBinaryFromReader);
      msg.setPolicySummaryInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdTextAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdTextAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPinnedField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAssetPerformanceLabel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPolicySummaryInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_asset_policy_pb.AdAssetPolicySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.setText = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.clearText = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.hasText = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.ads.googleads.v18.enums.ServedAssetFieldTypeEnum.ServedAssetFieldType pinned_field = 2;
 * @return {!proto.google.ads.googleads.v18.enums.ServedAssetFieldTypeEnum.ServedAssetFieldType}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.getPinnedField = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ServedAssetFieldTypeEnum.ServedAssetFieldType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ServedAssetFieldTypeEnum.ServedAssetFieldType} value
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.setPinnedField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.AssetPerformanceLabelEnum.AssetPerformanceLabel asset_performance_label = 5;
 * @return {!proto.google.ads.googleads.v18.enums.AssetPerformanceLabelEnum.AssetPerformanceLabel}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.getAssetPerformanceLabel = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetPerformanceLabelEnum.AssetPerformanceLabel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetPerformanceLabelEnum.AssetPerformanceLabel} value
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.setAssetPerformanceLabel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional AdAssetPolicySummary policy_summary_info = 6;
 * @return {?proto.google.ads.googleads.v18.common.AdAssetPolicySummary}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.getPolicySummaryInfo = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdAssetPolicySummary} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_policy_pb.AdAssetPolicySummary, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdAssetPolicySummary|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset} returns this
*/
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.setPolicySummaryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.clearPolicySummaryInfo = function() {
  return this.setPolicySummaryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdTextAsset.prototype.hasPolicySummaryInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdImageAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdImageAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdImageAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.AdImageAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdImageAsset;
  return proto.google.ads.googleads.v18.common.AdImageAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.AdImageAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdImageAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdImageAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdImageAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string asset = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AdImageAsset.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdImageAsset.prototype.setAsset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdImageAsset.prototype.clearAsset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdImageAsset.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdVideoAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adVideoAssetInfo: (f = msg.getAdVideoAssetInfo()) && proto.google.ads.googleads.v18.common.AdVideoAssetInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdVideoAsset;
  return proto.google.ads.googleads.v18.common.AdVideoAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.AdVideoAssetInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.AdVideoAssetInfo.deserializeBinaryFromReader);
      msg.setAdVideoAssetInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdVideoAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdVideoAssetInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.AdVideoAssetInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string asset = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.setAsset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.clearAsset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdVideoAssetInfo ad_video_asset_info = 4;
 * @return {?proto.google.ads.googleads.v18.common.AdVideoAssetInfo}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.getAdVideoAssetInfo = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdVideoAssetInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.AdVideoAssetInfo, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdVideoAssetInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset} returns this
*/
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.setAdVideoAssetInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.clearAdVideoAssetInfo = function() {
  return this.setAdVideoAssetInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAsset.prototype.hasAdVideoAssetInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdVideoAssetInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    adVideoAssetInventoryPreferences: (f = msg.getAdVideoAssetInventoryPreferences()) && proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdVideoAssetInfo;
  return proto.google.ads.googleads.v18.common.AdVideoAssetInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.deserializeBinaryFromReader);
      msg.setAdVideoAssetInventoryPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdVideoAssetInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdVideoAssetInventoryPreferences();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdVideoAssetInventoryPreferences ad_video_asset_inventory_preferences = 1;
 * @return {?proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.prototype.getAdVideoAssetInventoryPreferences = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo} returns this
*/
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.prototype.setAdVideoAssetInventoryPreferences = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInfo} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.prototype.clearAdVideoAssetInventoryPreferences = function() {
  return this.setAdVideoAssetInventoryPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInfo.prototype.hasAdVideoAssetInventoryPreferences = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.toObject = function(includeInstance, msg) {
  var f, obj = {
    inFeedPreference: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    inStreamPreference: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    shortsPreference: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences;
  return proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInFeedPreference(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInStreamPreference(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShortsPreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool in_feed_preference = 1;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.getInFeedPreference = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.setInFeedPreference = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.clearInFeedPreference = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.hasInFeedPreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool in_stream_preference = 2;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.getInStreamPreference = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.setInStreamPreference = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.clearInStreamPreference = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.hasInStreamPreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool shorts_preference = 3;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.getShortsPreference = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.setShortsPreference = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences} returns this
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.clearShortsPreference = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdVideoAssetInventoryPreferences.prototype.hasShortsPreference = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdMediaBundleAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset}
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdMediaBundleAsset;
  return proto.google.ads.googleads.v18.common.AdMediaBundleAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset}
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdMediaBundleAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string asset = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.prototype.setAsset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.prototype.clearAsset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdMediaBundleAsset.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset}
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset;
  return proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset}
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string asset = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.prototype.setAsset = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.prototype.clearAsset = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AdCallToActionAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AdCallToActionAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AdCallToActionAsset}
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AdCallToActionAsset;
  return proto.google.ads.googleads.v18.common.AdCallToActionAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AdCallToActionAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AdCallToActionAsset}
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AdCallToActionAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AdCallToActionAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string asset = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.AdCallToActionAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.prototype.setAsset = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AdCallToActionAsset} returns this
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.prototype.clearAsset = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AdCallToActionAsset.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.common);
