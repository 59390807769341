import React, { useMemo, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom-v5-compat";
import styled from "styled-components/macro";
import { Flex } from "@rebass/grid";

import {
  COLUMN_DATA_KEYS,
  COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY,
  InlineLoadingSpinner,
  SORT_NONE
} from "./MetricColumns";

import { CampaignNameCell, KeywordTextFlexWrapper } from "./CampaignsTableRow";
import SimpleTooltip from "./SimpleTooltip";
import { pluralize } from "Common/utils/strings";
import {
  DataTableRowCell,
  SelectedAdditionalDataTableRow,
  SortingIcon
} from "./AmpdDataTable";

import { Amazon } from "Common/proto/common/amazon_pb";
import { stringForEnum } from "Common/utils/proto";
import { CAMPAIGNS_PATH, PRODUCTS_PATH } from "../ExtensionV2";
import {
  PRODUCT_ID_QUERY_PARAM,
  MARKETPLACE_QUERY_PARAM
} from "../pages/ProductsPage/ProductsPage";
import { AddKeywordsPicker } from "./AdvancedKeywordPicker";
import { getTopPerformingKeywordSeeds } from "Common/utils/getTopPerformingKeywords";
import {
  COLUMN_DISPLAY_NAME_FOR_CAMPAIGN_KEYWORDS,
  COLUMN_DISPLAY_NAME_FOR_IMPACTED_PRODUCTS
} from "../pages/CampaignsPage/CampaignsPage";
import {
  ProductHeaderTooltip,
  ProductCategoryHeaderTooltip
} from "./CampaignsTableImpactedProductRow";
import {
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo
} from "../../Common/utils/marketplace";
import { useCanUserAddKeywords } from "../../Common/utils/siteUIBehavior";

export const DEFAULT_SAMPLE_ROWS = 10;
export const INCREMENT_SAMPLE_ROWS = 100;
const MARKETPLACE_UNKNOWN = Amazon.Marketplace.Option.UNKNOWN;

const LinkButton = styled.a`
  font-style: normal;
  font-weight: bold;
`;

const DataTableInnerHeaderCell = styled(DataTableRowCell)`
  text-align: center;
`;

const SortHeaderTextWrapper = styled.div`
  font-weight: bold;
  font-size: smaller;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CampaignsTableMenuRow = ({
  allKeywords,
  siteAlias,
  customerId,
  campaignId,
  columns,
  keywordsLoading,
  sampleKeywordRows,
  enabledKeywordCount,
  pausedKeywordCount,
  sampleKeywordsSortState,
  setMaxSampleKeywords,
  impactedProductsSortState,
  maxImpactedProducts,
  impactedProductsTableFlags,
  setImpactedProductsTableFlags,
  setMaxImpactedProducts,
  showNonBrandProducts,
  setShowNonBrandProducts,
  impactedProductsLoading,
  impactedProductCount,
  sampleImpactedProductCount,
  refetchCampaignKeyword,
  rowIndex,
  itemizedCampaignConfiguration
}) => {
  const showImpactedProducts = maxImpactedProducts > 0;
  const sortState = showImpactedProducts
    ? impactedProductsSortState
    : sampleKeywordsSortState;
  const columnTitles = showImpactedProducts
    ? COLUMN_DISPLAY_NAME_FOR_IMPACTED_PRODUCTS
    : COLUMN_DISPLAY_NAME_FOR_CAMPAIGN_KEYWORDS;

  const [keywordModalOpen, setKeywordModalOpen] = useState(false);

  const {
    geotargets,
    currencyCode,
    adGroupId,
    keywords,
    asin,
    marketplaceInfo,
    isAmpdCampaign
  } = itemizedCampaignConfiguration;

  const sampleEnabledCount = (sampleKeywordRows || []).filter(
    kw => kw.status === "ENABLED"
  ).length;
  const samplePausedCount =
    (sampleKeywordRows?.length || 0) - sampleEnabledCount;

  const retailer = isWalmartMarketplaceInfo(marketplaceInfo)
    ? "walmart"
    : "amazon";

  const topPerformingKeywords = useMemo(() => {
    return getTopPerformingKeywordSeeds(allKeywords);
  }, [allKeywords]);

  return (
    <>
      {keywordModalOpen && (
        <Modal
          className="disablePerspective"
          closeOnDocumentClick={false}
          closeOnDimmerClick={false}
          style={{
            overflow: "hidden",
            height: "85vh",
            width: "85vw",
            padding: "1em"
          }}
          open={keywordModalOpen}
          onClose={() => {
            setKeywordModalOpen(false);
          }}
        >
          <AddKeywordsPicker
            siteAlias={siteAlias}
            adGroupId={String(adGroupId)}
            customerId={String(customerId)}
            geotargets={geotargets}
            googleAdsCurrencyCode={currencyCode}
            campaignKeywords={keywords}
            initialNewKeywords={[]}
            initialSeedKeywords={[
              ...topPerformingKeywords.map(kw => kw.criteriaText)
            ]}
            retailerName={retailer}
            onCancel={() => {
              setKeywordModalOpen(false);
            }}
            onSave={_keywords => {
              setKeywordModalOpen(false);
              refetchCampaignKeyword(campaignId, adGroupId);
            }}
          />
        </Modal>
      )}

      <SelectedAdditionalDataTableRow style={{ fontSize: "small" }}>
        {(columns || []).map(columnName => {
          let tooltip;
          if (showImpactedProducts && columnName === COLUMN_DATA_KEYS.status) {
            tooltip = (
              <ProductHeaderTooltip
                impactedProduct={{}}
                flags={impactedProductsTableFlags}
                setFlags={setImpactedProductsTableFlags}
                isAmazonCampaign={isAmazonMarketplaceInfo(marketplaceInfo)}
                isWalmartCampaign={isWalmartMarketplaceInfo(marketplaceInfo)}
              />
            );
          } else if (
            showImpactedProducts &&
            columnName === COLUMN_DATA_KEYS.dailyBudget
          ) {
            tooltip = isWalmartMarketplaceInfo(marketplaceInfo) ? (
              ""
            ) : (
              <ProductCategoryHeaderTooltip
                impactedProduct={{}}
                flags={impactedProductsTableFlags}
                setFlags={setImpactedProductsTableFlags}
                isAmazonCampaign={isAmazonMarketplaceInfo(marketplaceInfo)}
                isWalmartCampaign={isWalmartMarketplaceInfo(marketplaceInfo)}
              />
            );
          }

          switch (columnName) {
            case COLUMN_DATA_KEYS.campaignName:
              return (
                <CampaignNameCell
                  key={columnName}
                  rowIndex={rowIndex}
                  style={{ cursor: "pointer" }}
                >
                  {showImpactedProducts ? (
                    <ProductsMenu
                      campaignId={campaignId}
                      marketplaceInfo={marketplaceInfo}
                      asin={asin}
                      maxImpactedProducts={maxImpactedProducts}
                      setMaxImpactedProducts={setMaxImpactedProducts}
                      showNonBrandProducts={showNonBrandProducts}
                      setShowNonBrandProducts={setShowNonBrandProducts}
                      impactedProductsLoading={impactedProductsLoading}
                      impactedProductCount={impactedProductCount}
                      sampleImpactedProductCount={sampleImpactedProductCount}
                      sortState={sortState}
                    />
                  ) : (
                    <KeywordsMenu
                      campaignId={campaignId}
                      isAmpdCampaign={isAmpdCampaign}
                      setMaxSampleKeywords={setMaxSampleKeywords}
                      setMaxImpactedProducts={setMaxImpactedProducts}
                      setKeywordModalOpen={setKeywordModalOpen}
                      keywordsLoading={keywordsLoading}
                      keywordCount={enabledKeywordCount + pausedKeywordCount}
                      sampleKeywordCount={
                        sampleEnabledCount + samplePausedCount
                      }
                      sortState={sortState}
                    />
                  )}
                </CampaignNameCell>
              );

            default: {
              const isSortable =
                !!columnTitles[columnName] &&
                COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY[columnName] !==
                  SORT_NONE;
              const isSelected = sortState.sortColumn === columnName;

              return (
                <DataTableInnerHeaderCell
                  key={columnName}
                  name={columnName}
                  rowIndex={rowIndex}
                  selected={isSelected}
                  onClick={
                    isSortable
                      ? () => sortState.handleSortColumn?.(columnName)
                      : undefined
                  }
                >
                  {isAmpdCampaign && (
                    <SortHeaderText
                      title={columnTitles[columnName]}
                      tooltip={tooltip}
                      isSortable={isSortable}
                      isSelected={isSelected}
                      isAscending={sortState.sortIsAscending}
                    />
                  )}
                </DataTableInnerHeaderCell>
              );
            }
          }
        })}
      </SelectedAdditionalDataTableRow>
    </>
  );
};

const KeywordsMenu = ({
  campaignId,
  isAmpdCampaign,
  setMaxSampleKeywords,
  setMaxImpactedProducts,
  setKeywordModalOpen,
  keywordsLoading,
  keywordCount,
  sampleKeywordCount,
  sortState
}) => {
  const canAddKeywords = useCanUserAddKeywords();

  return (
    <>
      <Flex
        style={{ padding: ".5em" }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div
          onClick={() =>
            sortState.handleSortColumn?.(COLUMN_DATA_KEYS.campaignName)
          }
        >
          <SortHeaderText
            title="Keyword Text"
            isSortable={true}
            isSelected={sortState.sortColumn === COLUMN_DATA_KEYS.campaignName}
            isAscending={sortState.sortIsAscending}
          />
        </div>
        <SwitchToProductsMenuItem
          setMaxImpactedProducts={setMaxImpactedProducts}
        />
      </Flex>

      <Flex
        style={{ float: "right" }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {keywordCount > 0 && (
          <>
            {sampleKeywordCount < keywordCount && (
              <ShowMoreKeywordsMenuItem
                setMaxSampleKeywords={setMaxSampleKeywords}
              />
            )}
            {sampleKeywordCount > 0 && (
              <ShowFewerKeywordsMenuItem
                setMaxSampleKeywords={setMaxSampleKeywords}
              />
            )}
          </>
        )}
        {canAddKeywords && isAmpdCampaign && (
          <AddNewKeywordMenuItem setKeywordModalOpen={setKeywordModalOpen} />
        )}
        <DrillDownMenuItem
          campaignId={campaignId}
          isAmpdCampaign={isAmpdCampaign}
        />
      </Flex>

      <KeywordTextFlexWrapper>
        {keywordsLoading ? (
          <InlineLoadingSpinner />
        ) : (
          keywordCount > 0 && (
            <p
              style={{
                margin: 0,
                fontStyle: "italic",
                paddingLeft: "1em"
              }}
            >
              {sampleKeywordCount}&nbsp;/&nbsp;
              {pluralize(keywordCount, "keyword")}
            </p>
          )
        )}
      </KeywordTextFlexWrapper>
    </>
  );
};

const SwitchToProductsMenuItem = ({ setMaxImpactedProducts }) => {
  const handleShowProducts = () => {
    setMaxImpactedProducts(DEFAULT_SAMPLE_ROWS);
  };

  return (
    <span>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Show products impacted by this campaign">
        <span>
          <LinkButton onClick={handleShowProducts}>
            Switch to Impacted Product Metrics
            <Icon style={{ paddingLeft: "0.4em", margin: 0 }} name="exchange" />
          </LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};

const ShowMoreKeywordsMenuItem = ({ setMaxSampleKeywords }) => {
  const handleMoreKeywords = () =>
    setMaxSampleKeywords(max =>
      max ? max + INCREMENT_SAMPLE_ROWS : DEFAULT_SAMPLE_ROWS
    );

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Show more keywords">
        <span>
          <LinkButton onClick={handleMoreKeywords}>Show More</LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};
const ShowFewerKeywordsMenuItem = ({ setMaxSampleKeywords }) => {
  const handleFewerKeywords = () =>
    setMaxSampleKeywords(max => Math.max(max - INCREMENT_SAMPLE_ROWS, 0));

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Show fewer keywords">
        <span>
          <LinkButton onClick={handleFewerKeywords}>Show Fewer</LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};
const AddNewKeywordMenuItem = ({ setKeywordModalOpen }) => {
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Add new keywords">
        <LinkButton onClick={() => setKeywordModalOpen(isOpen => !isOpen)}>
          Add New
        </LinkButton>
      </SimpleTooltip>
    </span>
  );
};

const DrillDownMenuItem = ({ campaignId, isAmpdCampaign }) => {
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip
        tooltip={
          isAmpdCampaign
            ? "Go to campaign keywords and daily impression metrics"
            : "Go to campaign keywords"
        }
      >
        <Link to={`../${CAMPAIGNS_PATH}/${campaignId}`}>
          <strong>Drill Down</strong>
          <Icon style={{ verticalAlign: "sub" }} name="level down alternate" />
        </Link>
      </SimpleTooltip>
    </span>
  );
};

const ProductsMenu = ({
  marketplaceInfo,
  asin,
  setMaxImpactedProducts,
  showNonBrandProducts,
  setShowNonBrandProducts,
  impactedProductCount,
  sampleImpactedProductCount,
  impactedProductsLoading,
  sortState
}) => {
  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0.5em"
      >
        <div
          onClick={() =>
            sortState.handleSortColumn?.(COLUMN_DATA_KEYS.campaignName)
          }
        >
          <SortHeaderText
            title="Product Name"
            isSortable={true}
            isSelected={sortState.sortColumn === COLUMN_DATA_KEYS.campaignName}
            isAscending={sortState.sortIsAscending}
          />
        </div>
        <SwitchToKeywordsMenuItem
          setMaxImpactedProducts={setMaxImpactedProducts}
        />
      </Flex>
      <Flex
        style={{ float: "right" }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ShowMoreProductsMenuItem
          setMaxImpactedProducts={setMaxImpactedProducts}
        />
        <ShowFewerProductsMenuItem
          setMaxImpactedProducts={setMaxImpactedProducts}
        />
        {isAmazonMarketplaceInfo(marketplaceInfo) && (
          <ProductPerformanceDeepLink
            asin={asin}
            amazonMarketplace={marketplaceInfo.marketplace}
          />
        )}
        {isWalmartMarketplaceInfo(marketplaceInfo) && (
          <ShowNonBrandProductsMenuItem
            showNonBrandProducts={showNonBrandProducts}
            setShowNonBrandProducts={setShowNonBrandProducts}
          />
        )}
      </Flex>

      <KeywordTextFlexWrapper>
        {impactedProductsLoading ? (
          <InlineLoadingSpinner />
        ) : (
          <p
            style={{
              margin: 0,
              fontStyle: "italic",
              paddingLeft: "1em"
            }}
          >
            {sampleImpactedProductCount}&nbsp;/&nbsp;
            {pluralize(impactedProductCount, "product")}
          </p>
        )}
      </KeywordTextFlexWrapper>
    </>
  );
};

const SwitchToKeywordsMenuItem = ({ setMaxImpactedProducts }) => {
  const handleShowNoProducts = () => {
    setMaxImpactedProducts(0);
  };

  return (
    <span>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Show keywords instead of products">
        <span>
          <LinkButton onClick={handleShowNoProducts}>
            Switch to Keyword Metrics
            <Icon style={{ paddingLeft: "0.4em", margin: 0 }} name="exchange" />
          </LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};

const ProductPerformanceDeepLink = ({ asin, amazonMarketplace }) => {
  if (
    !asin ||
    !amazonMarketplace ||
    amazonMarketplace === MARKETPLACE_UNKNOWN
  ) {
    return <></>;
  }

  const marketplaceName = stringForEnum(
    Amazon.Marketplace.Option,
    amazonMarketplace
  );

  const marketplaceParam = `${MARKETPLACE_QUERY_PARAM}=${marketplaceName}`;
  const productIdParam = `${PRODUCT_ID_QUERY_PARAM}=${asin}`;
  const productSearchParams = `?${marketplaceParam}&${productIdParam}`;

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="View this product's performance in the Products page.">
        <Link to={`../${PRODUCTS_PATH}${productSearchParams}`}>
          <strong>{`[${asin}] Performance`}</strong>
          <Icon style={{ verticalAlign: "sub" }} name="level down alternate" />
        </Link>
      </SimpleTooltip>
    </span>
  );
};

const ShowMoreProductsMenuItem = ({ setMaxImpactedProducts }) => {
  const handleMoreProducts = () =>
    setMaxImpactedProducts(max =>
      max ? max + INCREMENT_SAMPLE_ROWS : DEFAULT_SAMPLE_ROWS
    );

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Show more products">
        <span>
          <LinkButton onClick={handleMoreProducts}>Show More</LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};

const ShowFewerProductsMenuItem = ({ setMaxImpactedProducts }) => {
  const handleFewerProducts = () =>
    setMaxImpactedProducts(max => Math.max(max - INCREMENT_SAMPLE_ROWS, 0));

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip tooltip="Show fewer products">
        <span>
          <LinkButton onClick={handleFewerProducts}>Show Fewer</LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};

const ShowNonBrandProductsMenuItem = ({
  showNonBrandProducts,
  setShowNonBrandProducts
}) => {
  const handleNonBrandProducts = () =>
    setShowNonBrandProducts(!showNonBrandProducts);

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      &nbsp;&bull;&nbsp;
      <SimpleTooltip
        tooltip={
          showNonBrandProducts
            ? "Hide non-brand products"
            : "Show non-brand products"
        }
      >
        <span>
          <LinkButton onClick={handleNonBrandProducts}>
            {showNonBrandProducts ? "Hide" : "Show"} Unattributed
          </LinkButton>
        </span>
      </SimpleTooltip>
    </span>
  );
};

const SortHeaderText = ({
  title,
  tooltip,
  isSortable,
  isAscending,
  isSelected
}) => {
  return (
    <SortHeaderTextWrapper>
      <SimpleTooltip tooltip={tooltip} hoverable={true} disabled={!tooltip}>
        <div>{title}</div>
      </SimpleTooltip>
      {isSortable && (
        <SortingIcon isSelected={isSelected}>
          {!isSelected ? (
            <Icon name="sort" size="small" />
          ) : isAscending ? (
            <Icon name="angle up" size="small" />
          ) : (
            <Icon name="angle down" size="small" />
          )}
        </SortingIcon>
      )}
    </SortHeaderTextWrapper>
  );
};

export default CampaignsTableMenuRow;
