import moment from "moment";

import { useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetGoogleAdsChangeHistoryReply,
  GetGoogleAdsChangeHistoryRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { GoogleAdsChangeEventConstraints } from "Common/proto/warehousePb/googleAds_pb";
import { Campaign } from "Common/google/ads/googleads/v18/resources/campaign_pb";
import { CampaignBudget } from "Common/google/ads/googleads/v18/resources/campaign_budget_pb";
import { AdGroup } from "Common/google/ads/googleads/v18/resources/ad_group_pb";
import { AdGroupCriterion } from "Common/google/ads/googleads/v18/resources/ad_group_criterion_pb";
import { AdGroupCriterionStatusEnum } from "Common/google/ads/googleads/v18/enums/ad_group_criterion_status_pb";
import { CampaignStatusEnum } from "Common/google/ads/googleads/v18/enums/campaign_status_pb";
import { momentFromTimestampProto } from "Common/utils/DateUtilities";
import { getCurrencyMetricDef } from "Common/utils/money";
import { getCurrencyMinimumUnit } from "Common/utils/googleAds";
import { formatMetric } from "Common/utils/metrics";
import { stringForEnum } from "Common/utils/proto";

// Email address for changes made within the Ampd UI (or Ampd back-end).
export const AMPD_USER_EMAIL = "adwords-manager@metricstory.com";

type ResourceChangeHistoryEvent<TYPE> = {
  changeTime: moment.Moment | null;
  userEmail: string;
  oldResource: TYPE;
  newResource: TYPE;
};

export type CampaignChangeHistoryEvent = ResourceChangeHistoryEvent<
  Campaign.AsObject
>;
export type CampaignBudgetChangeHistoryEvent = ResourceChangeHistoryEvent<
  CampaignBudget.AsObject
>;
export type AdGroupChangeHistoryEvent = ResourceChangeHistoryEvent<
  AdGroup.AsObject
>;
export type KeywordChangeHistoryEvent = ResourceChangeHistoryEvent<
  AdGroupCriterion.AsObject
>;

export type UIChangeHistoryItem = {
  changeTime: moment.Moment | null;
  userEmail: string;
  briefText: string;
  text: string;
};

const fetchCampaignChangeHistory = async (
  siteAlias: string,
  campaignId: number
): Promise<GetGoogleAdsChangeHistoryReply.Campaign> => {
  const req = new GetGoogleAdsChangeHistoryRequest();
  req.setSiteAlias(siteAlias);
  req.setMaxEvents(1000);
  req.setUpdateChangeHistoryFirst(false);

  const constraints = new GoogleAdsChangeEventConstraints();
  const campaignConstraints = new GoogleAdsChangeEventConstraints.HierarchyConstraints();
  campaignConstraints.setIdsList([campaignId]);
  constraints.setCampaignConstraints(campaignConstraints);

  req.setConstraints(constraints);

  const reply = await GRPCWebClient.getGoogleAdsChangeHistory(req, {});
  for (const campaign of reply.getCampaignsList()) {
    if (campaign.getCampaignId() === campaignId) {
      return campaign;
    }
  }

  return new GetGoogleAdsChangeHistoryReply.Campaign();
};

// Queries for the change history of a single campaign.  The returned data is
// the reply Campaign proto that has NOT been turned into a JSON object yet
// and includes all the campaign's subobjects.
export const useCampaignChangeHistoryProto = (
  siteAlias: string,
  campaignId: string
): UseQueryResult<GetGoogleAdsChangeHistoryReply.Campaign, unknown> => {
  const halfHour = 1000 * 60 * 5; // 5 minutes

  return useQuery({
    queryKey: ["campaignChangeHistory", siteAlias, campaignId],
    staleTime: halfHour,
    cacheTime: halfHour,
    enabled: !!siteAlias && !!campaignId,
    queryFn: async () =>
      fetchCampaignChangeHistory(siteAlias, Number(campaignId))
  });
};

// Returns the change events for the specified campaign object itself (without
// subobjects).  Each event has the time of the change, the email of the user who made
// the change, and the before and after partial Google Ads Campaign JSON object.
// Only the fields that have been changed will be populated in the before and after
// objects, so default values in the after object should only be considered as
// the new value if the corresponding fields in the before object have non-default
// values.
// Note: A null return value means the history is still loading and any query error is
// ignored (resulting in an empty list return value).
export const useCampaignChangeHistory = (
  siteAlias: string,
  campaignId: string
): Array<CampaignChangeHistoryEvent> | null => {
  const { data: campaignChangeHistory, error } = useCampaignChangeHistoryProto(
    siteAlias,
    campaignId
  );

  const events = useMemo<Array<CampaignChangeHistoryEvent> | null>(() => {
    if (!campaignChangeHistory) {
      return null;
    }

    if (error) {
      return [];
    }

    return campaignChangeHistory.getEventsList().map(event => {
      const oldCampaign =
        event
          .getDetails()
          ?.getOldResource()
          ?.getCampaign() || new Campaign();
      const newCampaign =
        event
          .getDetails()
          ?.getNewResource()
          ?.getCampaign() || new Campaign();

      return {
        changeTime: momentFromTimestampProto(event.getChangeTime()),
        userEmail: event.getUserEmail(),
        oldResource: oldCampaign.toObject(),
        newResource: newCampaign.toObject()
      };
    });
  }, [campaignChangeHistory, error]);

  return events;
};

// Returns the change events for the budget of the specified campaign.
// Each event has the time of the change, the email of the user who made
// the change, and the before and after partial Google Ads Campaign JSON object.
// Only the fields that have been changed will be populated in the before and after
// objects, so default values in the after object should only be considered as
// the new value if the corresponding fields in the before object have non-default
// values.
// Note: A null return value means the history is still loading and any query error is
// ignored (resulting in an empty list return value).
export const useCampaignBudgetChangeHistory = (
  siteAlias: string,
  campaignId: string
): Array<CampaignBudgetChangeHistoryEvent> | null => {
  const { data: campaignChangeHistory, error } = useCampaignChangeHistoryProto(
    siteAlias,
    campaignId
  );

  const events = useMemo<Array<CampaignBudgetChangeHistoryEvent> | null>(() => {
    if (!campaignChangeHistory) {
      return null;
    }

    if (error) {
      return [];
    }

    return campaignChangeHistory.getEventsList().map(event => {
      const oldBudget =
        event
          .getDetails()
          ?.getOldResource()
          ?.getCampaignBudget() || new CampaignBudget();
      const newBudget =
        event
          .getDetails()
          ?.getNewResource()
          ?.getCampaignBudget() || new CampaignBudget();

      return {
        changeTime: momentFromTimestampProto(event.getChangeTime()),
        userEmail: event.getUserEmail(),
        oldResource: oldBudget.toObject(),
        newResource: newBudget.toObject()
      };
    });
  }, [campaignChangeHistory, error]);

  return events;
};

// Returns the change events for the specified ad group object itself (without
// subobjects).  Each event has the time of the change, the email of the user who made
// the change, and the before and after partial Google Ads Campaign JSON object.
// Only the fields that have been changed will be populated in the before and after
// objects, so default values in the after object should only be considered as
// the new value if the corresponding fields in the before object have non-default
// values.
// Note: A null return value means the history is still loading and any query error is
// ignored (resulting in an empty list return value).
export const useAdGroupChangeHistory = (
  siteAlias: string,
  campaignId: string,
  adGroupId: string
): Array<AdGroupChangeHistoryEvent> | null => {
  const { data: campaignChangeHistory, error } = useCampaignChangeHistoryProto(
    siteAlias,
    campaignId
  );

  const events = useMemo<Array<AdGroupChangeHistoryEvent> | null>(() => {
    if (!campaignChangeHistory) {
      return null;
    }

    if (error) {
      return [];
    }

    for (const adGroupChangeHistory of campaignChangeHistory.getAdGroupsList()) {
      if (adGroupChangeHistory.getAdGroupId() === Number(adGroupId)) {
        const events: Array<AdGroupChangeHistoryEvent> = adGroupChangeHistory
          .getEventsList()
          .map(event => {
            const oldAdGroup =
              event
                .getDetails()
                ?.getOldResource()
                ?.getAdGroup() || new AdGroup();
            const newAdGroup =
              event
                .getDetails()
                ?.getNewResource()
                ?.getAdGroup() || new AdGroup();

            return {
              changeTime: momentFromTimestampProto(event.getChangeTime()),
              userEmail: event.getUserEmail(),
              oldResource: oldAdGroup.toObject(),
              newResource: newAdGroup.toObject()
            };
          });

        return events;
      }
    }

    return [];
  }, [campaignChangeHistory, error, adGroupId]);

  return events;
};

// Returns the change events for the specified keyword object itself.  Each event
// has the time of the change, the email of the user who made the change, and the
// before and after partial Google Ads Campaign JSON object.  Only the fields that
// have been changed will be populated in the before and after objects, so default
// values in the after object should only be considered as the new value if the
// corresponding fields in the before object have non-default values.
// Note: A null return value means the history is still loading and any query error is
// ignored (resulting in an empty list return value).
export const useKeywordChangeHistory = (
  siteAlias: string,
  campaignId: string,
  adGroupId: string,
  criteriaId: string
): Array<KeywordChangeHistoryEvent> | null => {
  const { data: campaignChangeHistory, error } = useCampaignChangeHistoryProto(
    siteAlias,
    campaignId
  );

  const events = useMemo<Array<KeywordChangeHistoryEvent> | null>(() => {
    if (!campaignChangeHistory) {
      return null;
    }

    if (error) {
      return [];
    }

    for (const adGroupChangeHistory of campaignChangeHistory.getAdGroupsList()) {
      if (adGroupChangeHistory.getAdGroupId() === Number(adGroupId)) {
        for (const criteriaChangeHistory of adGroupChangeHistory.getAdGroupCriteriaList()) {
          if (criteriaChangeHistory.getCriteriaId() === Number(criteriaId)) {
            const events: Array<KeywordChangeHistoryEvent> = criteriaChangeHistory
              .getEventsList()
              .map(event => {
                const oldCriteria =
                  event
                    .getDetails()
                    ?.getOldResource()
                    ?.getAdGroupCriterion() || new AdGroupCriterion();
                const newCriteria =
                  event
                    .getDetails()
                    ?.getNewResource()
                    ?.getAdGroupCriterion() || new AdGroupCriterion();

                return {
                  changeTime: momentFromTimestampProto(event.getChangeTime()),
                  userEmail: event.getUserEmail(),
                  oldResource: oldCriteria.toObject(),
                  newResource: newCriteria.toObject()
                };
              });

            return events;
          }
        }
      }
    }

    return [];
  }, [campaignChangeHistory, error, adGroupId, criteriaId]);

  return events;
};

// Returns the UI change items that describe how the status has changed for the
// specified campaign.
export const useCampaignStatusHistory = (
  siteAlias: string,
  campaignId: string
): Array<UIChangeHistoryItem> | null => {
  const campaignChangeEvents = useCampaignChangeHistory(siteAlias, campaignId);

  const sortedItems = useMemo<Array<UIChangeHistoryItem> | null>(() => {
    if (campaignChangeEvents == null) {
      return null;
    }

    const items: Array<UIChangeHistoryItem> = [];

    campaignChangeEvents.forEach(event => {
      if (event.oldResource.status !== event.newResource.status) {
        const briefText =
          stringForEnum(
            CampaignStatusEnum.CampaignStatus,
            event.newResource.status
          ) || "";

        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText,
          text: `New Status: ${briefText}`
        });
      }
    });

    // Reverse sort so most recent changes are first.
    items.sort(
      (a, b) => (b.changeTime?.valueOf() || 0) - (a.changeTime?.valueOf() || 0)
    );

    return items;
  }, [campaignChangeEvents]);

  return sortedItems;
};

// Returns the UI change items that describe how the budget has changed for the
// specified campaign.
export const useCampaignBudgetHistory = (
  siteAlias: string,
  campaignId: string,
  currencyCode: string
): Array<UIChangeHistoryItem> | null => {
  const budgetChangeEvents = useCampaignBudgetChangeHistory(
    siteAlias,
    campaignId
  );

  const sortedItems = useMemo<Array<UIChangeHistoryItem> | null>(() => {
    if (budgetChangeEvents == null) {
      return null;
    }

    const items: Array<UIChangeHistoryItem> = [];

    const metricDef = getCurrencyMetricDef(currencyCode, true);

    budgetChangeEvents.forEach(event => {
      if (event.oldResource.amountMicros !== event.newResource.amountMicros) {
        const briefText =
          String(
            formatMetric(metricDef, event.newResource.amountMicros / 1e6)
          ) || "";

        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText,
          text: `New Daily Budget: ${briefText}`
        });
      }
    });

    // Reverse sort so most recent changes are first.
    items.sort(
      (a, b) => (b.changeTime?.valueOf() || 0) - (a.changeTime?.valueOf() || 0)
    );

    return items;
  }, [budgetChangeEvents, currencyCode]);

  return sortedItems;
};

// Returns the UI change items that describe how the bidding strategy and related
// bid values have changed for the specified campaign (and its primary ad group).
export const useCampaignBiddingStrategyHistory = (
  siteAlias: string,
  campaignId: string,
  adGroupId: string,
  currencyCode: string
): Array<UIChangeHistoryItem> | null => {
  const campaignChangeEvents = useCampaignChangeHistory(siteAlias, campaignId);
  const adGroupChangeEvents = useAdGroupChangeHistory(
    siteAlias,
    campaignId,
    adGroupId
  );

  const sortedItems = useMemo<Array<UIChangeHistoryItem> | null>(() => {
    if (campaignChangeEvents == null || adGroupChangeEvents == null) {
      return null;
    }

    const items: Array<UIChangeHistoryItem> = [];

    const metricDef = getCurrencyMetricDef(currencyCode, true);
    const currencyMinimumMicros = getCurrencyMinimumUnit(currencyCode);

    campaignChangeEvents.forEach(event => {
      if (!event.oldResource.targetSpend && event.newResource.targetSpend) {
        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText: "target spend",
          text: "Use Campaign Maximum Bid"
        });
      }
      if (
        event.oldResource.manualCpc !== event.newResource.manualCpc &&
        event.newResource.manualCpc
      ) {
        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText: "manual cpc",
          text: "Use Manual CPC Bids"
        });
      }
      if (
        event.oldResource.targetSpend?.cpcBidCeilingMicros !==
          event.newResource.targetSpend?.cpcBidCeilingMicros &&
        event.newResource.targetSpend?.cpcBidCeilingMicros
      ) {
        const briefText = String(
          formatMetric(
            metricDef,
            event.newResource.targetSpend?.cpcBidCeilingMicros / 1e6
          )
        );
        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText,
          text: `New Max: ${briefText}`
        });
      }
    });
    adGroupChangeEvents.forEach(event => {
      if (
        event.oldResource.cpcBidMicros !== event.newResource.cpcBidMicros &&
        event.newResource.cpcBidMicros > currencyMinimumMicros
      ) {
        const briefText = String(
          formatMetric(metricDef, event.newResource.cpcBidMicros / 1e6)
        );
        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText,
          text: `New Default: ${briefText}`
        });
      }
    });

    // Reverse sort so most recent changes are first.
    items.sort(
      (a, b) => (b.changeTime?.valueOf() || 0) - (a.changeTime?.valueOf() || 0)
    );

    return items;
  }, [campaignChangeEvents, adGroupChangeEvents, currencyCode]);

  return sortedItems;
};

// Returns the UI change items that describe how the status has changed for the
// specified keyword.
export const useKeywordStatusHistory = (
  siteAlias: string,
  campaignId: string,
  adGroupId: string,
  criteriaId: string
): Array<UIChangeHistoryItem> | null => {
  const keywordChangeEvents = useKeywordChangeHistory(
    siteAlias,
    campaignId,
    adGroupId,
    criteriaId
  );

  const sortedItems = useMemo<Array<UIChangeHistoryItem> | null>(() => {
    if (keywordChangeEvents == null) {
      return null;
    }

    const items: Array<UIChangeHistoryItem> = [];

    keywordChangeEvents.forEach(event => {
      if (event.oldResource.status !== event.newResource.status) {
        const briefText =
          stringForEnum(
            AdGroupCriterionStatusEnum.AdGroupCriterionStatus,
            event.newResource.status
          ) || "";
        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText,
          text: `New Status: ${briefText}`
        });
      }
    });

    // Reverse sort so most recent changes are first.
    items.sort(
      (a, b) => (b.changeTime?.valueOf() || 0) - (a.changeTime?.valueOf() || 0)
    );

    return items;
  }, [keywordChangeEvents]);

  return sortedItems;
};

// Returns the UI change items that describe how the CPC bid has changed for the
// specified keyword.
export const useKeywordCPCBidHistory = (
  siteAlias: string,
  currencyCode: string,
  campaignId: string,
  adGroupId: string,
  criteriaId: string
): Array<UIChangeHistoryItem> | null => {
  const keywordChangeEvents = useKeywordChangeHistory(
    siteAlias,
    campaignId,
    adGroupId,
    criteriaId
  );

  const sortedItems = useMemo<Array<UIChangeHistoryItem> | null>(() => {
    if (keywordChangeEvents == null) {
      return null;
    }

    const items: Array<UIChangeHistoryItem> = [];

    const metricDef = getCurrencyMetricDef(currencyCode, true);
    const currencyMinimumMicros = getCurrencyMinimumUnit(currencyCode);

    keywordChangeEvents.forEach(event => {
      if (event.oldResource.cpcBidMicros !== event.newResource.cpcBidMicros) {
        const briefText =
          event.newResource.cpcBidMicros > currencyMinimumMicros
            ? String(
                formatMetric(metricDef, event.newResource.cpcBidMicros / 1e6)
              )
            : "use default";
        items.push({
          changeTime: event.changeTime,
          userEmail: event.userEmail,
          briefText,
          text:
            event.newResource.cpcBidMicros > currencyMinimumMicros
              ? `New CPC Bid: ${briefText}`
              : "Use Default CPC Bid"
        });
      }
    });

    // Reverse sort so most recent changes are first.
    items.sort(
      (a, b) => (b.changeTime?.valueOf() || 0) - (a.changeTime?.valueOf() || 0)
    );

    return items;
  }, [keywordChangeEvents, currencyCode]);

  return sortedItems;
};
