// source: google/ads/googleads/v18/resources/ad_group.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_custom_parameter_pb = require('../../../../../google/ads/googleads/v18/common/custom_parameter_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_custom_parameter_pb);
var google_ads_googleads_v18_common_targeting_setting_pb = require('../../../../../google/ads/googleads/v18/common/targeting_setting_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_targeting_setting_pb);
var google_ads_googleads_v18_enums_ad_group_ad_rotation_mode_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_ad_rotation_mode_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_ad_rotation_mode_pb);
var google_ads_googleads_v18_enums_ad_group_primary_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_primary_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_primary_status_pb);
var google_ads_googleads_v18_enums_ad_group_primary_status_reason_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_primary_status_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_primary_status_reason_pb);
var google_ads_googleads_v18_enums_ad_group_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_status_pb);
var google_ads_googleads_v18_enums_ad_group_type_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_type_pb);
var google_ads_googleads_v18_enums_asset_field_type_pb = require('../../../../../google/ads/googleads/v18/enums/asset_field_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_field_type_pb);
var google_ads_googleads_v18_enums_asset_set_type_pb = require('../../../../../google/ads/googleads/v18/enums/asset_set_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_set_type_pb);
var google_ads_googleads_v18_enums_bidding_source_pb = require('../../../../../google/ads/googleads/v18/enums/bidding_source_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_bidding_source_pb);
var google_ads_googleads_v18_enums_targeting_dimension_pb = require('../../../../../google/ads/googleads/v18/enums/targeting_dimension_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_targeting_dimension_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroup', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.AdGroup.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroup.displayName = 'proto.google.ads.googleads.v18.resources.AdGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.displayName = 'proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AdGroup.repeatedFields_ = [6,49,54,58,63];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 34, 0),
    name: jspb.Message.getFieldWithDefault(msg, 35, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 12, 0),
    adRotationMode: jspb.Message.getFieldWithDefault(msg, 22, 0),
    baseAdGroup: jspb.Message.getFieldWithDefault(msg, 36, ""),
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 37, ""),
    urlCustomParametersList: jspb.Message.toObjectList(msg.getUrlCustomParametersList(),
    google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.toObject, includeInstance),
    campaign: jspb.Message.getFieldWithDefault(msg, 38, ""),
    cpcBidMicros: jspb.Message.getFieldWithDefault(msg, 39, 0),
    effectiveCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 57, 0),
    cpmBidMicros: jspb.Message.getFieldWithDefault(msg, 40, 0),
    targetCpaMicros: jspb.Message.getFieldWithDefault(msg, 41, 0),
    cpvBidMicros: jspb.Message.getFieldWithDefault(msg, 42, 0),
    targetCpmMicros: jspb.Message.getFieldWithDefault(msg, 43, 0),
    targetRoas: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    percentCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 45, 0),
    fixedCpmMicros: jspb.Message.getFieldWithDefault(msg, 64, 0),
    targetCpvMicros: jspb.Message.getFieldWithDefault(msg, 65, 0),
    optimizedTargetingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 59, false),
    excludeDemographicExpansion: jspb.Message.getBooleanFieldWithDefault(msg, 67, false),
    displayCustomBidDimension: jspb.Message.getFieldWithDefault(msg, 23, 0),
    finalUrlSuffix: jspb.Message.getFieldWithDefault(msg, 46, ""),
    targetingSetting: (f = msg.getTargetingSetting()) && google_ads_googleads_v18_common_targeting_setting_pb.TargetingSetting.toObject(includeInstance, f),
    audienceSetting: (f = msg.getAudienceSetting()) && proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.toObject(includeInstance, f),
    effectiveTargetCpaMicros: jspb.Message.getFieldWithDefault(msg, 47, 0),
    effectiveTargetCpaSource: jspb.Message.getFieldWithDefault(msg, 29, 0),
    effectiveTargetRoas: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    effectiveTargetRoasSource: jspb.Message.getFieldWithDefault(msg, 32, 0),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 49)) == null ? undefined : f,
    excludedParentAssetFieldTypesList: (f = jspb.Message.getRepeatedField(msg, 54)) == null ? undefined : f,
    excludedParentAssetSetTypesList: (f = jspb.Message.getRepeatedField(msg, 58)) == null ? undefined : f,
    primaryStatus: jspb.Message.getFieldWithDefault(msg, 62, 0),
    primaryStatusReasonsList: (f = jspb.Message.getRepeatedField(msg, 63)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup}
 */
proto.google.ads.googleads.v18.resources.AdGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroup;
  return proto.google.ads.googleads.v18.resources.AdGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup}
 */
proto.google.ads.googleads.v18.resources.AdGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupStatusEnum.AdGroupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupTypeEnum.AdGroupType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 22:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupAdRotationModeEnum.AdGroupAdRotationMode} */ (reader.readEnum());
      msg.setAdRotationMode(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseAdGroup(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter;
      reader.readMessage(value,google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.deserializeBinaryFromReader);
      msg.addUrlCustomParameters(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidMicros(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveCpcBidMicros(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpmBidMicros(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetCpaMicros(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpvBidMicros(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetCpmMicros(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetRoas(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentCpcBidMicros(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFixedCpmMicros(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetCpvMicros(value);
      break;
    case 59:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptimizedTargetingEnabled(value);
      break;
    case 67:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeDemographicExpansion(value);
      break;
    case 23:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.TargetingDimensionEnum.TargetingDimension} */ (reader.readEnum());
      msg.setDisplayCustomBidDimension(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrlSuffix(value);
      break;
    case 25:
      var value = new google_ads_googleads_v18_common_targeting_setting_pb.TargetingSetting;
      reader.readMessage(value,google_ads_googleads_v18_common_targeting_setting_pb.TargetingSetting.deserializeBinaryFromReader);
      msg.setTargetingSetting(value);
      break;
    case 56:
      var value = new proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.deserializeBinaryFromReader);
      msg.setAudienceSetting(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveTargetCpaMicros(value);
      break;
    case 29:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (reader.readEnum());
      msg.setEffectiveTargetCpaSource(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEffectiveTargetRoas(value);
      break;
    case 32:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (reader.readEnum());
      msg.setEffectiveTargetRoasSource(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 54:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExcludedParentAssetFieldTypes(values[i]);
      }
      break;
    case 58:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.AssetSetTypeEnum.AssetSetType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExcludedParentAssetSetTypes(values[i]);
      }
      break;
    case 62:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusEnum.AdGroupPrimaryStatus} */ (reader.readEnum());
      msg.setPrimaryStatus(value);
      break;
    case 63:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusReasonEnum.AdGroupPrimaryStatusReason>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrimaryStatusReasons(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeInt64(
      34,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 35));
  if (f != null) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getAdRotationMode();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 36));
  if (f != null) {
    writer.writeString(
      36,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 37));
  if (f != null) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getUrlCustomParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 38));
  if (f != null) {
    writer.writeString(
      38,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 39));
  if (f != null) {
    writer.writeInt64(
      39,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 57));
  if (f != null) {
    writer.writeInt64(
      57,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 41));
  if (f != null) {
    writer.writeInt64(
      41,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 42));
  if (f != null) {
    writer.writeInt64(
      42,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 43));
  if (f != null) {
    writer.writeInt64(
      43,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 44));
  if (f != null) {
    writer.writeDouble(
      44,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 45));
  if (f != null) {
    writer.writeInt64(
      45,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 64));
  if (f != null) {
    writer.writeInt64(
      64,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 65));
  if (f != null) {
    writer.writeInt64(
      65,
      f
    );
  }
  f = message.getOptimizedTargetingEnabled();
  if (f) {
    writer.writeBool(
      59,
      f
    );
  }
  f = message.getExcludeDemographicExpansion();
  if (f) {
    writer.writeBool(
      67,
      f
    );
  }
  f = message.getDisplayCustomBidDimension();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 46));
  if (f != null) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getTargetingSetting();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_ads_googleads_v18_common_targeting_setting_pb.TargetingSetting.serializeBinaryToWriter
    );
  }
  f = message.getAudienceSetting();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 47));
  if (f != null) {
    writer.writeInt64(
      47,
      f
    );
  }
  f = message.getEffectiveTargetCpaSource();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 48));
  if (f != null) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getEffectiveTargetRoasSource();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      49,
      f
    );
  }
  f = message.getExcludedParentAssetFieldTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      54,
      f
    );
  }
  f = message.getExcludedParentAssetSetTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      58,
      f
    );
  }
  f = message.getPrimaryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      62,
      f
    );
  }
  f = message.getPrimaryStatusReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      63,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    useAudienceGrouped: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting;
  return proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseAudienceGrouped(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseAudienceGrouped();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool use_audience_grouped = 1;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.prototype.getUseAudienceGrouped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting.prototype.setUseAudienceGrouped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 34;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setId = function(value) {
  return jspb.Message.setField(this, 34, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearId = function() {
  return jspb.Message.setField(this, 34, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasId = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional string name = 35;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setName = function(value) {
  return jspb.Message.setField(this, 35, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearName = function() {
  return jspb.Message.setField(this, 35, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasName = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupStatusEnum.AdGroupStatus status = 5;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupStatusEnum.AdGroupStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupStatusEnum.AdGroupStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupStatusEnum.AdGroupStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupTypeEnum.AdGroupType type = 12;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupTypeEnum.AdGroupType}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupTypeEnum.AdGroupType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupTypeEnum.AdGroupType} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupAdRotationModeEnum.AdGroupAdRotationMode ad_rotation_mode = 22;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupAdRotationModeEnum.AdGroupAdRotationMode}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getAdRotationMode = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupAdRotationModeEnum.AdGroupAdRotationMode} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupAdRotationModeEnum.AdGroupAdRotationMode} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setAdRotationMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string base_ad_group = 36;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getBaseAdGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setBaseAdGroup = function(value) {
  return jspb.Message.setField(this, 36, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearBaseAdGroup = function() {
  return jspb.Message.setField(this, 36, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasBaseAdGroup = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional string tracking_url_template = 37;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setField(this, 37, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearTrackingUrlTemplate = function() {
  return jspb.Message.setField(this, 37, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasTrackingUrlTemplate = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * repeated google.ads.googleads.v18.common.CustomParameter url_custom_parameters = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getUrlCustomParametersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setUrlCustomParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.CustomParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.CustomParameter}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.addUrlCustomParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.CustomParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearUrlCustomParametersList = function() {
  return this.setUrlCustomParametersList([]);
};


/**
 * optional string campaign = 38;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setCampaign = function(value) {
  return jspb.Message.setField(this, 38, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearCampaign = function() {
  return jspb.Message.setField(this, 38, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional int64 cpc_bid_micros = 39;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 39, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearCpcBidMicros = function() {
  return jspb.Message.setField(this, 39, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasCpcBidMicros = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional int64 effective_cpc_bid_micros = 57;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getEffectiveCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setEffectiveCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 57, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearEffectiveCpcBidMicros = function() {
  return jspb.Message.setField(this, 57, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasEffectiveCpcBidMicros = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional int64 cpm_bid_micros = 40;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getCpmBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setCpmBidMicros = function(value) {
  return jspb.Message.setField(this, 40, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearCpmBidMicros = function() {
  return jspb.Message.setField(this, 40, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasCpmBidMicros = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional int64 target_cpa_micros = 41;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getTargetCpaMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setTargetCpaMicros = function(value) {
  return jspb.Message.setField(this, 41, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearTargetCpaMicros = function() {
  return jspb.Message.setField(this, 41, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasTargetCpaMicros = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional int64 cpv_bid_micros = 42;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getCpvBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setCpvBidMicros = function(value) {
  return jspb.Message.setField(this, 42, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearCpvBidMicros = function() {
  return jspb.Message.setField(this, 42, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasCpvBidMicros = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional int64 target_cpm_micros = 43;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getTargetCpmMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setTargetCpmMicros = function(value) {
  return jspb.Message.setField(this, 43, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearTargetCpmMicros = function() {
  return jspb.Message.setField(this, 43, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasTargetCpmMicros = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional double target_roas = 44;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getTargetRoas = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setTargetRoas = function(value) {
  return jspb.Message.setField(this, 44, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearTargetRoas = function() {
  return jspb.Message.setField(this, 44, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasTargetRoas = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional int64 percent_cpc_bid_micros = 45;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getPercentCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setPercentCpcBidMicros = function(value) {
  return jspb.Message.setField(this, 45, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearPercentCpcBidMicros = function() {
  return jspb.Message.setField(this, 45, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasPercentCpcBidMicros = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional int64 fixed_cpm_micros = 64;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getFixedCpmMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setFixedCpmMicros = function(value) {
  return jspb.Message.setField(this, 64, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearFixedCpmMicros = function() {
  return jspb.Message.setField(this, 64, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasFixedCpmMicros = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional int64 target_cpv_micros = 65;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getTargetCpvMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setTargetCpvMicros = function(value) {
  return jspb.Message.setField(this, 65, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearTargetCpvMicros = function() {
  return jspb.Message.setField(this, 65, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasTargetCpvMicros = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional bool optimized_targeting_enabled = 59;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getOptimizedTargetingEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 59, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setOptimizedTargetingEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 59, value);
};


/**
 * optional bool exclude_demographic_expansion = 67;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getExcludeDemographicExpansion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 67, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setExcludeDemographicExpansion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 67, value);
};


/**
 * optional google.ads.googleads.v18.enums.TargetingDimensionEnum.TargetingDimension display_custom_bid_dimension = 23;
 * @return {!proto.google.ads.googleads.v18.enums.TargetingDimensionEnum.TargetingDimension}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getDisplayCustomBidDimension = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.TargetingDimensionEnum.TargetingDimension} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.TargetingDimensionEnum.TargetingDimension} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setDisplayCustomBidDimension = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional string final_url_suffix = 46;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getFinalUrlSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setFinalUrlSuffix = function(value) {
  return jspb.Message.setField(this, 46, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearFinalUrlSuffix = function() {
  return jspb.Message.setField(this, 46, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasFinalUrlSuffix = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.ads.googleads.v18.common.TargetingSetting targeting_setting = 25;
 * @return {?proto.google.ads.googleads.v18.common.TargetingSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getTargetingSetting = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.TargetingSetting} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_targeting_setting_pb.TargetingSetting, 25));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.TargetingSetting|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setTargetingSetting = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearTargetingSetting = function() {
  return this.setTargetingSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasTargetingSetting = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional AudienceSetting audience_setting = 56;
 * @return {?proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getAudienceSetting = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting, 56));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.AdGroup.AudienceSetting|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setAudienceSetting = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearAudienceSetting = function() {
  return this.setAudienceSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasAudienceSetting = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional int64 effective_target_cpa_micros = 47;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getEffectiveTargetCpaMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setEffectiveTargetCpaMicros = function(value) {
  return jspb.Message.setField(this, 47, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearEffectiveTargetCpaMicros = function() {
  return jspb.Message.setField(this, 47, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasEffectiveTargetCpaMicros = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource effective_target_cpa_source = 29;
 * @return {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getEffectiveTargetCpaSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setEffectiveTargetCpaSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional double effective_target_roas = 48;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getEffectiveTargetRoas = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setEffectiveTargetRoas = function(value) {
  return jspb.Message.setField(this, 48, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearEffectiveTargetRoas = function() {
  return jspb.Message.setField(this, 48, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.hasEffectiveTargetRoas = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource effective_target_roas_source = 32;
 * @return {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getEffectiveTargetRoasSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BiddingSourceEnum.BiddingSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setEffectiveTargetRoasSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * repeated string labels = 49;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 49));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 49, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 49, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType excluded_parent_asset_field_types = 54;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType>}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getExcludedParentAssetFieldTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType>} */ (jspb.Message.getRepeatedField(this, 54));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setExcludedParentAssetFieldTypesList = function(value) {
  return jspb.Message.setField(this, 54, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.addExcludedParentAssetFieldTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 54, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearExcludedParentAssetFieldTypesList = function() {
  return this.setExcludedParentAssetFieldTypesList([]);
};


/**
 * repeated google.ads.googleads.v18.enums.AssetSetTypeEnum.AssetSetType excluded_parent_asset_set_types = 58;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.AssetSetTypeEnum.AssetSetType>}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getExcludedParentAssetSetTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.AssetSetTypeEnum.AssetSetType>} */ (jspb.Message.getRepeatedField(this, 58));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.AssetSetTypeEnum.AssetSetType>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setExcludedParentAssetSetTypesList = function(value) {
  return jspb.Message.setField(this, 58, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetSetTypeEnum.AssetSetType} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.addExcludedParentAssetSetTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 58, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearExcludedParentAssetSetTypesList = function() {
  return this.setExcludedParentAssetSetTypesList([]);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupPrimaryStatusEnum.AdGroupPrimaryStatus primary_status = 62;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusEnum.AdGroupPrimaryStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getPrimaryStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusEnum.AdGroupPrimaryStatus} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusEnum.AdGroupPrimaryStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setPrimaryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 62, value);
};


/**
 * repeated google.ads.googleads.v18.enums.AdGroupPrimaryStatusReasonEnum.AdGroupPrimaryStatusReason primary_status_reasons = 63;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusReasonEnum.AdGroupPrimaryStatusReason>}
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.getPrimaryStatusReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusReasonEnum.AdGroupPrimaryStatusReason>} */ (jspb.Message.getRepeatedField(this, 63));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusReasonEnum.AdGroupPrimaryStatusReason>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.setPrimaryStatusReasonsList = function(value) {
  return jspb.Message.setField(this, 63, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupPrimaryStatusReasonEnum.AdGroupPrimaryStatusReason} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.addPrimaryStatusReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 63, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroup} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroup.prototype.clearPrimaryStatusReasonsList = function() {
  return this.setPrimaryStatusReasonsList([]);
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
