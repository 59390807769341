// source: google/ads/googleads/v18/resources/ad_group_bid_modifier.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_criteria_pb = require('../../../../../google/ads/googleads/v18/common/criteria_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_criteria_pb);
var google_ads_googleads_v18_enums_bid_modifier_source_pb = require('../../../../../google/ads/googleads/v18/enums/bid_modifier_source_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_bid_modifier_source_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupBidModifier', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupBidModifier.CriterionCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupBidModifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupBidModifier.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupBidModifier';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_ = [[5,6,7,8,11,17]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.CriterionCase = {
  CRITERION_NOT_SET: 0,
  HOTEL_DATE_SELECTION_TYPE: 5,
  HOTEL_ADVANCE_BOOKING_WINDOW: 6,
  HOTEL_LENGTH_OF_STAY: 7,
  HOTEL_CHECK_IN_DAY: 8,
  DEVICE: 11,
  HOTEL_CHECK_IN_DATE_RANGE: 17
};

/**
 * @return {proto.google.ads.googleads.v18.resources.AdGroupBidModifier.CriterionCase}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getCriterionCase = function() {
  return /** @type {proto.google.ads.googleads.v18.resources.AdGroupBidModifier.CriterionCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupBidModifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adGroup: jspb.Message.getFieldWithDefault(msg, 13, ""),
    criterionId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    bidModifier: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    baseAdGroup: jspb.Message.getFieldWithDefault(msg, 16, ""),
    bidModifierSource: jspb.Message.getFieldWithDefault(msg, 10, 0),
    hotelDateSelectionType: (f = msg.getHotelDateSelectionType()) && google_ads_googleads_v18_common_criteria_pb.HotelDateSelectionTypeInfo.toObject(includeInstance, f),
    hotelAdvanceBookingWindow: (f = msg.getHotelAdvanceBookingWindow()) && google_ads_googleads_v18_common_criteria_pb.HotelAdvanceBookingWindowInfo.toObject(includeInstance, f),
    hotelLengthOfStay: (f = msg.getHotelLengthOfStay()) && google_ads_googleads_v18_common_criteria_pb.HotelLengthOfStayInfo.toObject(includeInstance, f),
    hotelCheckInDay: (f = msg.getHotelCheckInDay()) && google_ads_googleads_v18_common_criteria_pb.HotelCheckInDayInfo.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && google_ads_googleads_v18_common_criteria_pb.DeviceInfo.toObject(includeInstance, f),
    hotelCheckInDateRange: (f = msg.getHotelCheckInDateRange()) && google_ads_googleads_v18_common_criteria_pb.HotelCheckInDateRangeInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupBidModifier;
  return proto.google.ads.googleads.v18.resources.AdGroupBidModifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroup(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriterionId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBidModifier(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseAdGroup(value);
      break;
    case 10:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.BidModifierSourceEnum.BidModifierSource} */ (reader.readEnum());
      msg.setBidModifierSource(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_criteria_pb.HotelDateSelectionTypeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.HotelDateSelectionTypeInfo.deserializeBinaryFromReader);
      msg.setHotelDateSelectionType(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_criteria_pb.HotelAdvanceBookingWindowInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.HotelAdvanceBookingWindowInfo.deserializeBinaryFromReader);
      msg.setHotelAdvanceBookingWindow(value);
      break;
    case 7:
      var value = new google_ads_googleads_v18_common_criteria_pb.HotelLengthOfStayInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.HotelLengthOfStayInfo.deserializeBinaryFromReader);
      msg.setHotelLengthOfStay(value);
      break;
    case 8:
      var value = new google_ads_googleads_v18_common_criteria_pb.HotelCheckInDayInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.HotelCheckInDayInfo.deserializeBinaryFromReader);
      msg.setHotelCheckInDay(value);
      break;
    case 11:
      var value = new google_ads_googleads_v18_common_criteria_pb.DeviceInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.DeviceInfo.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 17:
      var value = new google_ads_googleads_v18_common_criteria_pb.HotelCheckInDateRangeInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.HotelCheckInDateRangeInfo.deserializeBinaryFromReader);
      msg.setHotelCheckInDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupBidModifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBidModifierSource();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getHotelDateSelectionType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v18_common_criteria_pb.HotelDateSelectionTypeInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelAdvanceBookingWindow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_criteria_pb.HotelAdvanceBookingWindowInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelLengthOfStay();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_ads_googleads_v18_common_criteria_pb.HotelLengthOfStayInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelCheckInDay();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_ads_googleads_v18_common_criteria_pb.HotelCheckInDayInfo.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_ads_googleads_v18_common_criteria_pb.DeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelCheckInDateRange();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_ads_googleads_v18_common_criteria_pb.HotelCheckInDateRangeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_group = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getAdGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setAdGroup = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearAdGroup = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasAdGroup = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 criterion_id = 14;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getCriterionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setCriterionId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearCriterionId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasCriterionId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional double bid_modifier = 15;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getBidModifier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setBidModifier = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearBidModifier = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasBidModifier = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string base_ad_group = 16;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getBaseAdGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setBaseAdGroup = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearBaseAdGroup = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasBaseAdGroup = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.ads.googleads.v18.enums.BidModifierSourceEnum.BidModifierSource bid_modifier_source = 10;
 * @return {!proto.google.ads.googleads.v18.enums.BidModifierSourceEnum.BidModifierSource}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getBidModifierSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.BidModifierSourceEnum.BidModifierSource} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.BidModifierSourceEnum.BidModifierSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setBidModifierSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional google.ads.googleads.v18.common.HotelDateSelectionTypeInfo hotel_date_selection_type = 5;
 * @return {?proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getHotelDateSelectionType = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.HotelDateSelectionTypeInfo, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelDateSelectionTypeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setHotelDateSelectionType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearHotelDateSelectionType = function() {
  return this.setHotelDateSelectionType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasHotelDateSelectionType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo hotel_advance_booking_window = 6;
 * @return {?proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getHotelAdvanceBookingWindow = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.HotelAdvanceBookingWindowInfo, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelAdvanceBookingWindowInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setHotelAdvanceBookingWindow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearHotelAdvanceBookingWindow = function() {
  return this.setHotelAdvanceBookingWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasHotelAdvanceBookingWindow = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelLengthOfStayInfo hotel_length_of_stay = 7;
 * @return {?proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getHotelLengthOfStay = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.HotelLengthOfStayInfo, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelLengthOfStayInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setHotelLengthOfStay = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearHotelLengthOfStay = function() {
  return this.setHotelLengthOfStay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasHotelLengthOfStay = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelCheckInDayInfo hotel_check_in_day = 8;
 * @return {?proto.google.ads.googleads.v18.common.HotelCheckInDayInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getHotelCheckInDay = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelCheckInDayInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.HotelCheckInDayInfo, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelCheckInDayInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setHotelCheckInDay = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearHotelCheckInDay = function() {
  return this.setHotelCheckInDay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasHotelCheckInDay = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.ads.googleads.v18.common.DeviceInfo device = 11;
 * @return {?proto.google.ads.googleads.v18.common.DeviceInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getDevice = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DeviceInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.DeviceInfo, 11));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DeviceInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setDevice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelCheckInDateRangeInfo hotel_check_in_date_range = 17;
 * @return {?proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.getHotelCheckInDateRange = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_criteria_pb.HotelCheckInDateRangeInfo, 17));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelCheckInDateRangeInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.setHotelCheckInDateRange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.google.ads.googleads.v18.resources.AdGroupBidModifier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupBidModifier} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.clearHotelCheckInDateRange = function() {
  return this.setHotelCheckInDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupBidModifier.prototype.hasHotelCheckInDateRange = function() {
  return jspb.Message.getField(this, 17) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
