// source: google/ads/googleads/v18/resources/asset.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_asset_types_pb = require('../../../../../google/ads/googleads/v18/common/asset_types_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_asset_types_pb);
var google_ads_googleads_v18_common_custom_parameter_pb = require('../../../../../google/ads/googleads/v18/common/custom_parameter_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_custom_parameter_pb);
var google_ads_googleads_v18_common_policy_pb = require('../../../../../google/ads/googleads/v18/common/policy_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_policy_pb);
var google_ads_googleads_v18_enums_asset_field_type_pb = require('../../../../../google/ads/googleads/v18/enums/asset_field_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_field_type_pb);
var google_ads_googleads_v18_enums_asset_source_pb = require('../../../../../google/ads/googleads/v18/enums/asset_source_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_source_pb);
var google_ads_googleads_v18_enums_asset_type_pb = require('../../../../../google/ads/googleads/v18/enums/asset_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_type_pb);
var google_ads_googleads_v18_enums_policy_approval_status_pb = require('../../../../../google/ads/googleads/v18/enums/policy_approval_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_approval_status_pb);
var google_ads_googleads_v18_enums_policy_review_status_pb = require('../../../../../google/ads/googleads/v18/enums/policy_review_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_review_status_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Asset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Asset.AssetDataCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AssetPolicySummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.Asset.repeatedFields_, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.resources.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.Asset.displayName = 'proto.google.ads.googleads.v18.resources.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.displayName = 'proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.AssetPolicySummary.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AssetPolicySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AssetPolicySummary.displayName = 'proto.google.ads.googleads.v18.resources.AssetPolicySummary';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Asset.repeatedFields_ = [14,16,18,40];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Asset.oneofGroups_ = [[5,6,7,8,9,10,15,20,21,22,23,24,25,26,27,28,29,30,31,32,33,50,35,36,37,39,41]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.Asset.AssetDataCase = {
  ASSET_DATA_NOT_SET: 0,
  YOUTUBE_VIDEO_ASSET: 5,
  MEDIA_BUNDLE_ASSET: 6,
  IMAGE_ASSET: 7,
  TEXT_ASSET: 8,
  LEAD_FORM_ASSET: 9,
  BOOK_ON_GOOGLE_ASSET: 10,
  PROMOTION_ASSET: 15,
  CALLOUT_ASSET: 20,
  STRUCTURED_SNIPPET_ASSET: 21,
  SITELINK_ASSET: 22,
  PAGE_FEED_ASSET: 23,
  DYNAMIC_EDUCATION_ASSET: 24,
  MOBILE_APP_ASSET: 25,
  HOTEL_CALLOUT_ASSET: 26,
  CALL_ASSET: 27,
  PRICE_ASSET: 28,
  CALL_TO_ACTION_ASSET: 29,
  DYNAMIC_REAL_ESTATE_ASSET: 30,
  DYNAMIC_CUSTOM_ASSET: 31,
  DYNAMIC_HOTELS_AND_RENTALS_ASSET: 32,
  DYNAMIC_FLIGHTS_ASSET: 33,
  DEMAND_GEN_CAROUSEL_CARD_ASSET: 50,
  DYNAMIC_TRAVEL_ASSET: 35,
  DYNAMIC_LOCAL_ASSET: 36,
  DYNAMIC_JOBS_ASSET: 37,
  LOCATION_ASSET: 39,
  HOTEL_PROPERTY_ASSET: 41
};

/**
 * @return {proto.google.ads.googleads.v18.resources.Asset.AssetDataCase}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getAssetDataCase = function() {
  return /** @type {proto.google.ads.googleads.v18.resources.Asset.AssetDataCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 11, 0),
    name: jspb.Message.getFieldWithDefault(msg, 12, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    finalUrlsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    finalMobileUrlsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    urlCustomParametersList: jspb.Message.toObjectList(msg.getUrlCustomParametersList(),
    google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.toObject, includeInstance),
    finalUrlSuffix: jspb.Message.getFieldWithDefault(msg, 19, ""),
    source: jspb.Message.getFieldWithDefault(msg, 38, 0),
    policySummary: (f = msg.getPolicySummary()) && proto.google.ads.googleads.v18.resources.AssetPolicySummary.toObject(includeInstance, f),
    fieldTypePolicySummariesList: jspb.Message.toObjectList(msg.getFieldTypePolicySummariesList(),
    proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.toObject, includeInstance),
    youtubeVideoAsset: (f = msg.getYoutubeVideoAsset()) && google_ads_googleads_v18_common_asset_types_pb.YoutubeVideoAsset.toObject(includeInstance, f),
    mediaBundleAsset: (f = msg.getMediaBundleAsset()) && google_ads_googleads_v18_common_asset_types_pb.MediaBundleAsset.toObject(includeInstance, f),
    imageAsset: (f = msg.getImageAsset()) && google_ads_googleads_v18_common_asset_types_pb.ImageAsset.toObject(includeInstance, f),
    textAsset: (f = msg.getTextAsset()) && google_ads_googleads_v18_common_asset_types_pb.TextAsset.toObject(includeInstance, f),
    leadFormAsset: (f = msg.getLeadFormAsset()) && google_ads_googleads_v18_common_asset_types_pb.LeadFormAsset.toObject(includeInstance, f),
    bookOnGoogleAsset: (f = msg.getBookOnGoogleAsset()) && google_ads_googleads_v18_common_asset_types_pb.BookOnGoogleAsset.toObject(includeInstance, f),
    promotionAsset: (f = msg.getPromotionAsset()) && google_ads_googleads_v18_common_asset_types_pb.PromotionAsset.toObject(includeInstance, f),
    calloutAsset: (f = msg.getCalloutAsset()) && google_ads_googleads_v18_common_asset_types_pb.CalloutAsset.toObject(includeInstance, f),
    structuredSnippetAsset: (f = msg.getStructuredSnippetAsset()) && google_ads_googleads_v18_common_asset_types_pb.StructuredSnippetAsset.toObject(includeInstance, f),
    sitelinkAsset: (f = msg.getSitelinkAsset()) && google_ads_googleads_v18_common_asset_types_pb.SitelinkAsset.toObject(includeInstance, f),
    pageFeedAsset: (f = msg.getPageFeedAsset()) && google_ads_googleads_v18_common_asset_types_pb.PageFeedAsset.toObject(includeInstance, f),
    dynamicEducationAsset: (f = msg.getDynamicEducationAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicEducationAsset.toObject(includeInstance, f),
    mobileAppAsset: (f = msg.getMobileAppAsset()) && google_ads_googleads_v18_common_asset_types_pb.MobileAppAsset.toObject(includeInstance, f),
    hotelCalloutAsset: (f = msg.getHotelCalloutAsset()) && google_ads_googleads_v18_common_asset_types_pb.HotelCalloutAsset.toObject(includeInstance, f),
    callAsset: (f = msg.getCallAsset()) && google_ads_googleads_v18_common_asset_types_pb.CallAsset.toObject(includeInstance, f),
    priceAsset: (f = msg.getPriceAsset()) && google_ads_googleads_v18_common_asset_types_pb.PriceAsset.toObject(includeInstance, f),
    callToActionAsset: (f = msg.getCallToActionAsset()) && google_ads_googleads_v18_common_asset_types_pb.CallToActionAsset.toObject(includeInstance, f),
    dynamicRealEstateAsset: (f = msg.getDynamicRealEstateAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicRealEstateAsset.toObject(includeInstance, f),
    dynamicCustomAsset: (f = msg.getDynamicCustomAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicCustomAsset.toObject(includeInstance, f),
    dynamicHotelsAndRentalsAsset: (f = msg.getDynamicHotelsAndRentalsAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicHotelsAndRentalsAsset.toObject(includeInstance, f),
    dynamicFlightsAsset: (f = msg.getDynamicFlightsAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicFlightsAsset.toObject(includeInstance, f),
    demandGenCarouselCardAsset: (f = msg.getDemandGenCarouselCardAsset()) && google_ads_googleads_v18_common_asset_types_pb.DemandGenCarouselCardAsset.toObject(includeInstance, f),
    dynamicTravelAsset: (f = msg.getDynamicTravelAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicTravelAsset.toObject(includeInstance, f),
    dynamicLocalAsset: (f = msg.getDynamicLocalAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicLocalAsset.toObject(includeInstance, f),
    dynamicJobsAsset: (f = msg.getDynamicJobsAsset()) && google_ads_googleads_v18_common_asset_types_pb.DynamicJobsAsset.toObject(includeInstance, f),
    locationAsset: (f = msg.getLocationAsset()) && google_ads_googleads_v18_common_asset_types_pb.LocationAsset.toObject(includeInstance, f),
    hotelPropertyAsset: (f = msg.getHotelPropertyAsset()) && google_ads_googleads_v18_common_asset_types_pb.HotelPropertyAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.Asset}
 */
proto.google.ads.googleads.v18.resources.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.Asset;
  return proto.google.ads.googleads.v18.resources.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.Asset}
 */
proto.google.ads.googleads.v18.resources.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetTypeEnum.AssetType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalUrls(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalMobileUrls(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 18:
      var value = new google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter;
      reader.readMessage(value,google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.deserializeBinaryFromReader);
      msg.addUrlCustomParameters(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrlSuffix(value);
      break;
    case 38:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 13:
      var value = new proto.google.ads.googleads.v18.resources.AssetPolicySummary;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AssetPolicySummary.deserializeBinaryFromReader);
      msg.setPolicySummary(value);
      break;
    case 40:
      var value = new proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.deserializeBinaryFromReader);
      msg.addFieldTypePolicySummaries(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_asset_types_pb.YoutubeVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.YoutubeVideoAsset.deserializeBinaryFromReader);
      msg.setYoutubeVideoAsset(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_asset_types_pb.MediaBundleAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.MediaBundleAsset.deserializeBinaryFromReader);
      msg.setMediaBundleAsset(value);
      break;
    case 7:
      var value = new google_ads_googleads_v18_common_asset_types_pb.ImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.ImageAsset.deserializeBinaryFromReader);
      msg.setImageAsset(value);
      break;
    case 8:
      var value = new google_ads_googleads_v18_common_asset_types_pb.TextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.TextAsset.deserializeBinaryFromReader);
      msg.setTextAsset(value);
      break;
    case 9:
      var value = new google_ads_googleads_v18_common_asset_types_pb.LeadFormAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.LeadFormAsset.deserializeBinaryFromReader);
      msg.setLeadFormAsset(value);
      break;
    case 10:
      var value = new google_ads_googleads_v18_common_asset_types_pb.BookOnGoogleAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.BookOnGoogleAsset.deserializeBinaryFromReader);
      msg.setBookOnGoogleAsset(value);
      break;
    case 15:
      var value = new google_ads_googleads_v18_common_asset_types_pb.PromotionAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.PromotionAsset.deserializeBinaryFromReader);
      msg.setPromotionAsset(value);
      break;
    case 20:
      var value = new google_ads_googleads_v18_common_asset_types_pb.CalloutAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.CalloutAsset.deserializeBinaryFromReader);
      msg.setCalloutAsset(value);
      break;
    case 21:
      var value = new google_ads_googleads_v18_common_asset_types_pb.StructuredSnippetAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.StructuredSnippetAsset.deserializeBinaryFromReader);
      msg.setStructuredSnippetAsset(value);
      break;
    case 22:
      var value = new google_ads_googleads_v18_common_asset_types_pb.SitelinkAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.SitelinkAsset.deserializeBinaryFromReader);
      msg.setSitelinkAsset(value);
      break;
    case 23:
      var value = new google_ads_googleads_v18_common_asset_types_pb.PageFeedAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.PageFeedAsset.deserializeBinaryFromReader);
      msg.setPageFeedAsset(value);
      break;
    case 24:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicEducationAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicEducationAsset.deserializeBinaryFromReader);
      msg.setDynamicEducationAsset(value);
      break;
    case 25:
      var value = new google_ads_googleads_v18_common_asset_types_pb.MobileAppAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.MobileAppAsset.deserializeBinaryFromReader);
      msg.setMobileAppAsset(value);
      break;
    case 26:
      var value = new google_ads_googleads_v18_common_asset_types_pb.HotelCalloutAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.HotelCalloutAsset.deserializeBinaryFromReader);
      msg.setHotelCalloutAsset(value);
      break;
    case 27:
      var value = new google_ads_googleads_v18_common_asset_types_pb.CallAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.CallAsset.deserializeBinaryFromReader);
      msg.setCallAsset(value);
      break;
    case 28:
      var value = new google_ads_googleads_v18_common_asset_types_pb.PriceAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.PriceAsset.deserializeBinaryFromReader);
      msg.setPriceAsset(value);
      break;
    case 29:
      var value = new google_ads_googleads_v18_common_asset_types_pb.CallToActionAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.CallToActionAsset.deserializeBinaryFromReader);
      msg.setCallToActionAsset(value);
      break;
    case 30:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicRealEstateAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicRealEstateAsset.deserializeBinaryFromReader);
      msg.setDynamicRealEstateAsset(value);
      break;
    case 31:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicCustomAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicCustomAsset.deserializeBinaryFromReader);
      msg.setDynamicCustomAsset(value);
      break;
    case 32:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicHotelsAndRentalsAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicHotelsAndRentalsAsset.deserializeBinaryFromReader);
      msg.setDynamicHotelsAndRentalsAsset(value);
      break;
    case 33:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicFlightsAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicFlightsAsset.deserializeBinaryFromReader);
      msg.setDynamicFlightsAsset(value);
      break;
    case 50:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DemandGenCarouselCardAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DemandGenCarouselCardAsset.deserializeBinaryFromReader);
      msg.setDemandGenCarouselCardAsset(value);
      break;
    case 35:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicTravelAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicTravelAsset.deserializeBinaryFromReader);
      msg.setDynamicTravelAsset(value);
      break;
    case 36:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicLocalAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicLocalAsset.deserializeBinaryFromReader);
      msg.setDynamicLocalAsset(value);
      break;
    case 37:
      var value = new google_ads_googleads_v18_common_asset_types_pb.DynamicJobsAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.DynamicJobsAsset.deserializeBinaryFromReader);
      msg.setDynamicJobsAsset(value);
      break;
    case 39:
      var value = new google_ads_googleads_v18_common_asset_types_pb.LocationAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.LocationAsset.deserializeBinaryFromReader);
      msg.setLocationAsset(value);
      break;
    case 41:
      var value = new google_ads_googleads_v18_common_asset_types_pb.HotelPropertyAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_types_pb.HotelPropertyAsset.deserializeBinaryFromReader);
      msg.setHotelPropertyAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFinalUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getFinalMobileUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUrlCustomParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      38,
      f
    );
  }
  f = message.getPolicySummary();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.google.ads.googleads.v18.resources.AssetPolicySummary.serializeBinaryToWriter
    );
  }
  f = message.getFieldTypePolicySummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      40,
      f,
      proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeVideoAsset();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v18_common_asset_types_pb.YoutubeVideoAsset.serializeBinaryToWriter
    );
  }
  f = message.getMediaBundleAsset();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_asset_types_pb.MediaBundleAsset.serializeBinaryToWriter
    );
  }
  f = message.getImageAsset();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_ads_googleads_v18_common_asset_types_pb.ImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getTextAsset();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_ads_googleads_v18_common_asset_types_pb.TextAsset.serializeBinaryToWriter
    );
  }
  f = message.getLeadFormAsset();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_ads_googleads_v18_common_asset_types_pb.LeadFormAsset.serializeBinaryToWriter
    );
  }
  f = message.getBookOnGoogleAsset();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_ads_googleads_v18_common_asset_types_pb.BookOnGoogleAsset.serializeBinaryToWriter
    );
  }
  f = message.getPromotionAsset();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_ads_googleads_v18_common_asset_types_pb.PromotionAsset.serializeBinaryToWriter
    );
  }
  f = message.getCalloutAsset();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_ads_googleads_v18_common_asset_types_pb.CalloutAsset.serializeBinaryToWriter
    );
  }
  f = message.getStructuredSnippetAsset();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_ads_googleads_v18_common_asset_types_pb.StructuredSnippetAsset.serializeBinaryToWriter
    );
  }
  f = message.getSitelinkAsset();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_ads_googleads_v18_common_asset_types_pb.SitelinkAsset.serializeBinaryToWriter
    );
  }
  f = message.getPageFeedAsset();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_ads_googleads_v18_common_asset_types_pb.PageFeedAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicEducationAsset();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicEducationAsset.serializeBinaryToWriter
    );
  }
  f = message.getMobileAppAsset();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_ads_googleads_v18_common_asset_types_pb.MobileAppAsset.serializeBinaryToWriter
    );
  }
  f = message.getHotelCalloutAsset();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_ads_googleads_v18_common_asset_types_pb.HotelCalloutAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallAsset();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_ads_googleads_v18_common_asset_types_pb.CallAsset.serializeBinaryToWriter
    );
  }
  f = message.getPriceAsset();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_ads_googleads_v18_common_asset_types_pb.PriceAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallToActionAsset();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_ads_googleads_v18_common_asset_types_pb.CallToActionAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicRealEstateAsset();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicRealEstateAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicCustomAsset();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicCustomAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicHotelsAndRentalsAsset();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicHotelsAndRentalsAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicFlightsAsset();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicFlightsAsset.serializeBinaryToWriter
    );
  }
  f = message.getDemandGenCarouselCardAsset();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DemandGenCarouselCardAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicTravelAsset();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicTravelAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicLocalAsset();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicLocalAsset.serializeBinaryToWriter
    );
  }
  f = message.getDynamicJobsAsset();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_ads_googleads_v18_common_asset_types_pb.DynamicJobsAsset.serializeBinaryToWriter
    );
  }
  f = message.getLocationAsset();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_ads_googleads_v18_common_asset_types_pb.LocationAsset.serializeBinaryToWriter
    );
  }
  f = message.getHotelPropertyAsset();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_ads_googleads_v18_common_asset_types_pb.HotelPropertyAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 11;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string name = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setName = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearName = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AssetTypeEnum.AssetType type = 4;
 * @return {!proto.google.ads.googleads.v18.enums.AssetTypeEnum.AssetType}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetTypeEnum.AssetType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetTypeEnum.AssetType} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string final_urls = 14;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getFinalUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setFinalUrlsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.addFinalUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearFinalUrlsList = function() {
  return this.setFinalUrlsList([]);
};


/**
 * repeated string final_mobile_urls = 16;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getFinalMobileUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setFinalMobileUrlsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.addFinalMobileUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearFinalMobileUrlsList = function() {
  return this.setFinalMobileUrlsList([]);
};


/**
 * optional string tracking_url_template = 17;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearTrackingUrlTemplate = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasTrackingUrlTemplate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated google.ads.googleads.v18.common.CustomParameter url_custom_parameters = 18;
 * @return {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getUrlCustomParametersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter, 18));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setUrlCustomParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.CustomParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.CustomParameter}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.addUrlCustomParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.google.ads.googleads.v18.common.CustomParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearUrlCustomParametersList = function() {
  return this.setUrlCustomParametersList([]);
};


/**
 * optional string final_url_suffix = 19;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getFinalUrlSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setFinalUrlSuffix = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearFinalUrlSuffix = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasFinalUrlSuffix = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource source = 38;
 * @return {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 38, value);
};


/**
 * optional AssetPolicySummary policy_summary = 13;
 * @return {?proto.google.ads.googleads.v18.resources.AssetPolicySummary}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getPolicySummary = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.AssetPolicySummary} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.AssetPolicySummary, 13));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.AssetPolicySummary|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setPolicySummary = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearPolicySummary = function() {
  return this.setPolicySummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasPolicySummary = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated AssetFieldTypePolicySummary field_type_policy_summaries = 40;
 * @return {!Array<!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary>}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getFieldTypePolicySummariesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary, 40));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary>} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setFieldTypePolicySummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 40, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.addFieldTypePolicySummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 40, opt_value, proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearFieldTypePolicySummariesList = function() {
  return this.setFieldTypePolicySummariesList([]);
};


/**
 * optional google.ads.googleads.v18.common.YoutubeVideoAsset youtube_video_asset = 5;
 * @return {?proto.google.ads.googleads.v18.common.YoutubeVideoAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getYoutubeVideoAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.YoutubeVideoAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.YoutubeVideoAsset, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.YoutubeVideoAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setYoutubeVideoAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearYoutubeVideoAsset = function() {
  return this.setYoutubeVideoAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasYoutubeVideoAsset = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.ads.googleads.v18.common.MediaBundleAsset media_bundle_asset = 6;
 * @return {?proto.google.ads.googleads.v18.common.MediaBundleAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getMediaBundleAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MediaBundleAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.MediaBundleAsset, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MediaBundleAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setMediaBundleAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearMediaBundleAsset = function() {
  return this.setMediaBundleAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasMediaBundleAsset = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.common.ImageAsset image_asset = 7;
 * @return {?proto.google.ads.googleads.v18.common.ImageAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getImageAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.ImageAsset, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setImageAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearImageAsset = function() {
  return this.setImageAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasImageAsset = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.common.TextAsset text_asset = 8;
 * @return {?proto.google.ads.googleads.v18.common.TextAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getTextAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.TextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.TextAsset, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.TextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setTextAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearTextAsset = function() {
  return this.setTextAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasTextAsset = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.ads.googleads.v18.common.LeadFormAsset lead_form_asset = 9;
 * @return {?proto.google.ads.googleads.v18.common.LeadFormAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getLeadFormAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LeadFormAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.LeadFormAsset, 9));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LeadFormAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setLeadFormAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearLeadFormAsset = function() {
  return this.setLeadFormAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasLeadFormAsset = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.ads.googleads.v18.common.BookOnGoogleAsset book_on_google_asset = 10;
 * @return {?proto.google.ads.googleads.v18.common.BookOnGoogleAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getBookOnGoogleAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.BookOnGoogleAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.BookOnGoogleAsset, 10));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.BookOnGoogleAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setBookOnGoogleAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearBookOnGoogleAsset = function() {
  return this.setBookOnGoogleAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasBookOnGoogleAsset = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.ads.googleads.v18.common.PromotionAsset promotion_asset = 15;
 * @return {?proto.google.ads.googleads.v18.common.PromotionAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getPromotionAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PromotionAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.PromotionAsset, 15));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PromotionAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setPromotionAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearPromotionAsset = function() {
  return this.setPromotionAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasPromotionAsset = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.ads.googleads.v18.common.CalloutAsset callout_asset = 20;
 * @return {?proto.google.ads.googleads.v18.common.CalloutAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getCalloutAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CalloutAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.CalloutAsset, 20));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CalloutAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setCalloutAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearCalloutAsset = function() {
  return this.setCalloutAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasCalloutAsset = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.ads.googleads.v18.common.StructuredSnippetAsset structured_snippet_asset = 21;
 * @return {?proto.google.ads.googleads.v18.common.StructuredSnippetAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getStructuredSnippetAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.StructuredSnippetAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.StructuredSnippetAsset, 21));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.StructuredSnippetAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setStructuredSnippetAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearStructuredSnippetAsset = function() {
  return this.setStructuredSnippetAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasStructuredSnippetAsset = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.ads.googleads.v18.common.SitelinkAsset sitelink_asset = 22;
 * @return {?proto.google.ads.googleads.v18.common.SitelinkAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getSitelinkAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.SitelinkAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.SitelinkAsset, 22));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.SitelinkAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setSitelinkAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearSitelinkAsset = function() {
  return this.setSitelinkAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasSitelinkAsset = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.ads.googleads.v18.common.PageFeedAsset page_feed_asset = 23;
 * @return {?proto.google.ads.googleads.v18.common.PageFeedAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getPageFeedAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PageFeedAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.PageFeedAsset, 23));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PageFeedAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setPageFeedAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearPageFeedAsset = function() {
  return this.setPageFeedAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasPageFeedAsset = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicEducationAsset dynamic_education_asset = 24;
 * @return {?proto.google.ads.googleads.v18.common.DynamicEducationAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicEducationAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicEducationAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicEducationAsset, 24));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicEducationAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicEducationAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicEducationAsset = function() {
  return this.setDynamicEducationAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicEducationAsset = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.ads.googleads.v18.common.MobileAppAsset mobile_app_asset = 25;
 * @return {?proto.google.ads.googleads.v18.common.MobileAppAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getMobileAppAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.MobileAppAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.MobileAppAsset, 25));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.MobileAppAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setMobileAppAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearMobileAppAsset = function() {
  return this.setMobileAppAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasMobileAppAsset = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelCalloutAsset hotel_callout_asset = 26;
 * @return {?proto.google.ads.googleads.v18.common.HotelCalloutAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getHotelCalloutAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelCalloutAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.HotelCalloutAsset, 26));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelCalloutAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setHotelCalloutAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearHotelCalloutAsset = function() {
  return this.setHotelCalloutAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasHotelCalloutAsset = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.ads.googleads.v18.common.CallAsset call_asset = 27;
 * @return {?proto.google.ads.googleads.v18.common.CallAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getCallAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CallAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.CallAsset, 27));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CallAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setCallAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearCallAsset = function() {
  return this.setCallAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasCallAsset = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.ads.googleads.v18.common.PriceAsset price_asset = 28;
 * @return {?proto.google.ads.googleads.v18.common.PriceAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getPriceAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PriceAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.PriceAsset, 28));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PriceAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setPriceAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearPriceAsset = function() {
  return this.setPriceAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasPriceAsset = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.ads.googleads.v18.common.CallToActionAsset call_to_action_asset = 29;
 * @return {?proto.google.ads.googleads.v18.common.CallToActionAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getCallToActionAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CallToActionAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.CallToActionAsset, 29));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CallToActionAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setCallToActionAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearCallToActionAsset = function() {
  return this.setCallToActionAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasCallToActionAsset = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicRealEstateAsset dynamic_real_estate_asset = 30;
 * @return {?proto.google.ads.googleads.v18.common.DynamicRealEstateAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicRealEstateAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicRealEstateAsset, 30));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicRealEstateAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicRealEstateAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicRealEstateAsset = function() {
  return this.setDynamicRealEstateAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicRealEstateAsset = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicCustomAsset dynamic_custom_asset = 31;
 * @return {?proto.google.ads.googleads.v18.common.DynamicCustomAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicCustomAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicCustomAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicCustomAsset, 31));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicCustomAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicCustomAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicCustomAsset = function() {
  return this.setDynamicCustomAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicCustomAsset = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset dynamic_hotels_and_rentals_asset = 32;
 * @return {?proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicHotelsAndRentalsAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicHotelsAndRentalsAsset, 32));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicHotelsAndRentalsAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicHotelsAndRentalsAsset = function() {
  return this.setDynamicHotelsAndRentalsAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicHotelsAndRentalsAsset = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicFlightsAsset dynamic_flights_asset = 33;
 * @return {?proto.google.ads.googleads.v18.common.DynamicFlightsAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicFlightsAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicFlightsAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicFlightsAsset, 33));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicFlightsAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicFlightsAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicFlightsAsset = function() {
  return this.setDynamicFlightsAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicFlightsAsset = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.ads.googleads.v18.common.DemandGenCarouselCardAsset demand_gen_carousel_card_asset = 50;
 * @return {?proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDemandGenCarouselCardAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DemandGenCarouselCardAsset, 50));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDemandGenCarouselCardAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDemandGenCarouselCardAsset = function() {
  return this.setDemandGenCarouselCardAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDemandGenCarouselCardAsset = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicTravelAsset dynamic_travel_asset = 35;
 * @return {?proto.google.ads.googleads.v18.common.DynamicTravelAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicTravelAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicTravelAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicTravelAsset, 35));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicTravelAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicTravelAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicTravelAsset = function() {
  return this.setDynamicTravelAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicTravelAsset = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicLocalAsset dynamic_local_asset = 36;
 * @return {?proto.google.ads.googleads.v18.common.DynamicLocalAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicLocalAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicLocalAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicLocalAsset, 36));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicLocalAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicLocalAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicLocalAsset = function() {
  return this.setDynamicLocalAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicLocalAsset = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.ads.googleads.v18.common.DynamicJobsAsset dynamic_jobs_asset = 37;
 * @return {?proto.google.ads.googleads.v18.common.DynamicJobsAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getDynamicJobsAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DynamicJobsAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.DynamicJobsAsset, 37));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DynamicJobsAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setDynamicJobsAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearDynamicJobsAsset = function() {
  return this.setDynamicJobsAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasDynamicJobsAsset = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.ads.googleads.v18.common.LocationAsset location_asset = 39;
 * @return {?proto.google.ads.googleads.v18.common.LocationAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getLocationAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LocationAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.LocationAsset, 39));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LocationAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setLocationAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearLocationAsset = function() {
  return this.setLocationAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasLocationAsset = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelPropertyAsset hotel_property_asset = 41;
 * @return {?proto.google.ads.googleads.v18.common.HotelPropertyAsset}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.getHotelPropertyAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelPropertyAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_asset_types_pb.HotelPropertyAsset, 41));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelPropertyAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
*/
proto.google.ads.googleads.v18.resources.Asset.prototype.setHotelPropertyAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.google.ads.googleads.v18.resources.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Asset} returns this
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.clearHotelPropertyAsset = function() {
  return this.setHotelPropertyAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Asset.prototype.hasHotelPropertyAsset = function() {
  return jspb.Message.getField(this, 41) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetFieldType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetSource: jspb.Message.getFieldWithDefault(msg, 2, 0),
    policySummaryInfo: (f = msg.getPolicySummaryInfo()) && proto.google.ads.googleads.v18.resources.AssetPolicySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary;
  return proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} */ (reader.readEnum());
      msg.setAssetFieldType(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (reader.readEnum());
      msg.setAssetSource(value);
      break;
    case 3:
      var value = new proto.google.ads.googleads.v18.resources.AssetPolicySummary;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AssetPolicySummary.deserializeBinaryFromReader);
      msg.setPolicySummaryInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPolicySummaryInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.google.ads.googleads.v18.resources.AssetPolicySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType asset_field_type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.getAssetFieldType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} value
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.setAssetFieldType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.clearAssetFieldType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.hasAssetFieldType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource asset_source = 2;
 * @return {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.getAssetSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} value
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.setAssetSource = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.clearAssetSource = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.hasAssetSource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AssetPolicySummary policy_summary_info = 3;
 * @return {?proto.google.ads.googleads.v18.resources.AssetPolicySummary}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.getPolicySummaryInfo = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.AssetPolicySummary} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.AssetPolicySummary, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.AssetPolicySummary|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} returns this
*/
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.setPolicySummaryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.clearPolicySummaryInfo = function() {
  return this.setPolicySummaryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AssetFieldTypePolicySummary.prototype.hasPolicySummaryInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AssetPolicySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyTopicEntriesList: jspb.Message.toObjectList(msg.getPolicyTopicEntriesList(),
    google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry.toObject, includeInstance),
    reviewStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AssetPolicySummary}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AssetPolicySummary;
  return proto.google.ads.googleads.v18.resources.AssetPolicySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AssetPolicySummary}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry;
      reader.readMessage(value,google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry.deserializeBinaryFromReader);
      msg.addPolicyTopicEntries(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (reader.readEnum());
      msg.setReviewStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (reader.readEnum());
      msg.setApprovalStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AssetPolicySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyTopicEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry.serializeBinaryToWriter
    );
  }
  f = message.getReviewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated google.ads.googleads.v18.common.PolicyTopicEntry policy_topic_entries = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEntry>}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.getPolicyTopicEntriesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEntry>} value
 * @return {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} returns this
*/
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.setPolicyTopicEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.addPolicyTopicEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.PolicyTopicEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.clearPolicyTopicEntriesList = function() {
  return this.setPolicyTopicEntriesList([]);
};


/**
 * optional google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus review_status = 2;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.getReviewStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.setReviewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus approval_status = 3;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus}
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.getApprovalStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AssetPolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AssetPolicySummary.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
