import _ from "lodash";
import { titleCase } from "change-case";

import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Icon,
  List,
  Modal,
  Message
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import queryString from "query-string";

import { useSession } from "ExtensionV2/queries/useSession";

import OneClickButton from "Common/components/OneClickButton";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import LinkStyleButton from "./LinkStyleButton";
import LinkToAmazonSellerButton from "../../Common/components/LinkToAmazonSellerButton";
import {
  getAccountTypeByEnum,
  getAmazonMarketplaceInfo,
  getNameFromRegion
} from "Common/utils/amazon";
import { randomString, compareCaseInsens } from "Common/utils/strings";
import { sendGAEvent } from "./GA";

import { stringForEnum } from "Common/utils/proto";
import { Amazon } from "Common/proto/common/amazon_pb";
import { extractErrorMessage } from "Common/errors/error";

/*
  Supports connecting Ampd to the Amazon Selling Partner Api at the
    Amazon Region level on behalf of the authorizing user. This connection
    data is saved in the backend on the Site under
    amazonInfo.amazonSellerAccounts

  Notes:
    - Connection is multi-phase operation, consisting of:
        1. Call to Amazon SellerCentral domain endpoint for user to authorize
           Ampd. Response from this is an auth-code that can be used for
           this marketplace's Region (eg: NORTH_AMERICA))
        2. Call to grpcweb to save the auth-code (aka: refresh token) and
           region to the Site. The server now has access to query Selling
           Partner Api on behalf of this user. Backend operations have their
           own auth flor that utilizes the refresh token.
    - The UI only supports connecting one Selling Partner region, but this is
       an artificial limitation - the backend can support multiple.
    - The user will first authorize Ampd through a SellerCentral endpoint.
       Any marketplace could work, but the best user experience is to use a
       SellerCentral endpoint for one of their current marketplaces. We can
       then use the marketplace to determine the region for the backend
       server to connect with for pulling data.
    - A region can pull data for any marketplace in it, so one marketplace
       connection in a region covers authorization for any marketplace in
       the region.
    - This supports Amazon Vendor account connections, facilitated by Vendor
       Central Oauth through a vendorcentral hostname.
*/

const AmazonSellerConnection = ({
  forceHideDisconnectLink = false,
  gaCategory
}) => {
  const {
    currentSite,
    disconnectDataSourceMutation,
    connectDataSourceMutation
  } = useSession();
  const { siteAlias, amazonInfo } = currentSite;
  const {
    sellerAccounts,
    advertisingProfiles,
    advertisingAccounts
  } = amazonInfo;

  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [accountToDisconnect, setAccountToDisconnect] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState(null);

  const nonce = randomString(16);
  const reqState = queryString.stringify({ siteAlias, nonce });
  const gaEvent = title => sendGAEvent(gaCategory, title, siteAlias);

  const {
    mutate: disconnectDataSource,
    isLoading: disconnectDataSourceIsLoading,
    error: disconnectDataSourceError
  } = disconnectDataSourceMutation;

  const {
    mutate: connectDataSource,
    isLoading: connectDataSourceIsLoading,
    error: connectDataSourceError
  } = connectDataSourceMutation;

  const linkError = disconnectDataSourceError || connectDataSourceError;
  useEffect(() => {
    if (linkError) {
      setDisconnectModalOpen(false);
      console.error(
        `Error linking Seller DataSource: ${extractErrorMessage(linkError)}`
      );
    }
  }, [linkError]);

  const dropdownOptions = useMemo(() => {
    const allowedAccountTypes = [
      Amazon.AdvertisingProfile.AccountType.Option.SELLER,
      Amazon.AdvertisingProfile.AccountType.Option.VENDOR
    ];

    const options = createDropdownOptions(
      advertisingProfiles,
      allowedAccountTypes
    );

    if (!selectedDropdown) {
      setSelectedDropdown(options[0]);
    }

    return options;
  }, [advertisingProfiles, selectedDropdown]);

  // If OAuth returns authorized, use this to save the auth into to Site
  const onAuthorized = async response => {
    const { state: responseState, code, sellingPartnerId, popup } =
      response || {};

    if (responseState !== reqState) {
      console.error("LinkToAmazonSeller: state mismatch error");
    }

    connectDataSource({
      siteAlias,
      dataSource: {
        amazonSeller: {
          sellingPartnerId,
          profileIdStr: selectedDropdown?.data?.profileIdStr,
          regionEnumOption: selectedDropdown?.data?.region,
          code
        }
      }
    });

    popup.close();
  };

  // Confirm triggers call to remove SellerAccount info from saved Site data
  const handleDisconnect = async e => {
    e.stopPropagation(); // button really a link
    disconnectDataSource({
      removeDataSourceReq: {
        siteAlias,
        amazonSeller: {
          sellingPartnerId: accountToDisconnect.sellingPartnerId,
          region: accountToDisconnect.regionEnumOption
        }
      }
    });
    setDisconnectModalOpen(false);
  };

  // "Disconnect" triggers a confirmation modal
  const handleOpenDisconnectModal = (e, account) => {
    e.stopPropagation(); // button really a link

    setAccountToDisconnect(account);
    setDisconnectModalOpen(true);

    gaEvent("Opened disconnect from Amazon Seller modal");
  };

  const handleRegionSelect = (e, { value }) => {
    e.stopPropagation();

    setSelectedDropdown(dropdownOptions[value]);
    gaEvent("Change Connect Seller Account Region");
  };

  // Cancelling the disconnect from "disconnect confirmation"
  const handleCancelDisconnect = e => {
    e.stopPropagation(); // button really a link

    setAccountToDisconnect(null);
    setDisconnectModalOpen(false);
    gaEvent("Cancelled disconnect from Amazon Seller");
  };

  const placeholderText = dropdownOptions?.length
    ? "Select Account Profile"
    : "(No Supported Accounts)";

  // Component: Connect Site to Amazon Seller (Seller Not Yet Connected)
  const ConnectSeller = (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        <>
          <Dropdown
            placeholder={placeholderText}
            options={dropdownOptions}
            onChange={handleRegionSelect}
            value={selectedDropdown?.value}
            scrolling
            selection
          />
          <LinkToAmazonSellerButton
            siteAlias={siteAlias}
            state={reqState}
            onAuthorized={onAuthorized}
            hostname={selectedDropdown?.data?.hostname}
            gaCategory={gaCategory}
          />
        </>
      </Flex>
      <p style={{ marginTop: "0.5em" }}>
        Connect your Amazon Seller or Vendor account for expanded insights and
        capabilities in your Ampd account.
      </p>
      {linkError != null && (
        <Message error>
          There was a problem connecting your account. Please try again in a few
          moments or contact us if this problem persists.
        </Message>
      )}
    </>
  );

  // Component: Site's Amazon Seller Account Region connection
  // & link to disconnect (remove connection data from Site)
  const SellerConnected = (
    <>
      {sellerAccounts?.map(account => {
        const regionString = stringForEnum(
          Amazon.Region.Option,
          account.regionEnumOption
        );
        const accountId = account.sellingPartnerId;
        const displayRegion = getNameFromRegion(regionString);
        const profileNames = advertisingProfiles
          .filter(p => p.accountId === accountId)
          .map(p => p.name)
          .filter((name, idx, arr) => arr.indexOf(name) === idx)
          .sort()
          .join(", ");

        return (
          <React.Fragment key={`${accountId}-${account.regionEnumOption}`}>
            <p>{`${accountId} (${displayRegion}): ${profileNames}`}</p>
            {!forceHideDisconnectLink && (
              <p style={{ marginTop: 10 }}>
                You can&nbsp;
                <LinkStyleButton
                  onClick={e => handleOpenDisconnectModal(e, account)}
                >
                  disconnect
                </LinkStyleButton>
                &nbsp;at any time.
              </p>
            )}
          </React.Fragment>
        );
      })}
    </>
  );

  // Component: Selling Partner Disconnection Modal (ie: "Are You Sure?" modal)
  const DisconnectSellerConfirmModal = (
    <Modal open={disconnectModalOpen} size="small">
      <Modal.Header>Disconnect from Amazon</Modal.Header>
      <Modal.Content>
        <Icon name="warning sign" color="yellow" /> If you disconnect your
        Amazon account:
        <List bulleted>
          <List.Item>You will lose some Ampd sales insights.</List.Item>
          <List.Item>You may lose some Ampd automation capabilities.</List.Item>
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancelDisconnect}> Cancel </Button>
        <OneClickButton negative onClick={handleDisconnect}>
          Disconnect
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );

  if (!advertisingAccounts.length) {
    return (
      <p>
        No Amazon Seller/Vendor accounts were found. Make sure you have logged
        in with the correct Amazon Advertising account.
      </p>
    );
  }

  const isLoading = connectDataSourceIsLoading || disconnectDataSourceIsLoading;

  // Render the appropriate Seller Connection info box & support disconnect modal
  // Show Disconnect UI if have SellersAccount(s) OR Connect UI if none
  // Re-check if alias or seller accounts changes
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : !_.isEmpty(sellerAccounts) ? (
        SellerConnected
      ) : (
        ConnectSeller
      )}

      {DisconnectSellerConfirmModal}
    </>
  );
};

const createDropdownOptions = (advertisingProfiles, allowedAccountTypes) => {
  return advertisingProfiles
    .filter(profile => allowedAccountTypes.includes(profile.accountType))
    .sort(optionsByRegionThenProfileName)
    .map((profile, idx) => {
      const marketplaceInfo = getAmazonMarketplaceInfo(profile.marketplace);
      // Marketplace info is required
      if (!marketplaceInfo) {
        return null;
      }

      const accountTypeName = getAccountTypeByEnum(profile.accountType);

      // The hostname that will be called for oauth logins - varies by account
      // type & marketplace.
      const hostname =
        profile.accountType ===
        Amazon.AdvertisingProfile.AccountType.Option.VENDOR
          ? marketplaceInfo.vendorCentralDomain
          : marketplaceInfo.sellerCentralDomain;

      return {
        key: profile.profileIdStr,
        text: buildDropdownText(profile, marketplaceInfo),
        value: idx,
        description: titleCase(accountTypeName),
        data: {
          hostname: hostname,
          region: marketplaceInfo.region,
          profileName: profile.name,
          profileIdStr: profile.profileIdStr
        }
      };
    })
    .filter(Boolean); // remove any nulls
};

// Alphabetically sort the profiles within a region for display in dropdown
const optionsByRegionThenProfileName = (profileA, profileB) => {
  const byRegion = (a, b) => {
    const aMarketplace = getAmazonMarketplaceInfo(a.marketplace);
    const bMarketplace = getAmazonMarketplaceInfo(b.marketplace);
    const aRegion = stringForEnum(
      Amazon.Region.Option,
      aMarketplace?.regionEnumOption
    );
    const bRegion = stringForEnum(
      Amazon.Region.Option,
      bMarketplace?.regionEnumOption
    );

    return aRegion - bRegion; // group similar
  };
  const byName = (a, b) => compareCaseInsens(a.name, b.name);

  // Sort first by region, then by name
  return byRegion(profileA, profileB) || byName(profileA, profileB);
};

// Region-based profile selection to connect by
// eg: "North America: Assured Signs (United States)"
const buildDropdownText = (profile, marketplaceInfo) => {
  const profileName = profile.name;
  const marketplaceName = marketplaceInfo.name;
  const regionEnum = stringForEnum(
    Amazon.Region.Option,
    marketplaceInfo.region
  );
  const regionName = getNameFromRegion(regionEnum);

  return `${regionName}: ${profileName} (${marketplaceName})`;
};

export default AmazonSellerConnection;
