import _ from "lodash";

import { Button, Dropdown, Segment } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom-v5-compat";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { useSearchParams } from "react-router-dom-v5-compat";

import {
  amazonUSMarketplaceInfo,
  extractAmazonURLInfoFromString
} from "Common/utils/amazon";
import { ampdRed } from "ExtensionV2/styles/colors";
import {
  COLUMN_DATA_KEYS,
  COLUMN_DISPLAY_NAME_FROM_DATA_KEY,
  COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY,
  COLUMN_TOOLTIP_FROM_DATA_KEY,
  calculateDerivedMetrics,
  ALL_BUT_REMOVED_STATUS,
  REMOVED_STATUS,
  filterBasedOnStatus,
  getMetricsAccumulator,
  accumulateMetrics
} from "../../components/MetricColumns";
import { DatePickerLabel as DropdownLabel } from "ExtensionV2/components/GlobalDatePicker";
import { sendGAEvent } from "ExtensionV2/components/GA";
import AmpdDataTable, {
  AMPD_SELECTED_ROW_FOCUS_CLASS,
  AMPD_SELECTED_ROW_HEAD_CLASS,
  defaultSortComparator
} from "ExtensionV2/components/AmpdDataTable";
import CampaignsTableRow from "ExtensionV2/components/CampaignsTableRow";
import CampaignsTableTotalRow from "ExtensionV2/components/CampaignsTableTotalRow";
import { DEFAULT_SAMPLE_ROWS } from "ExtensionV2/components/CampaignsTableMenuRow";
import {
  getSortedSampleOfImpactedProducts,
  SHOW_PRODUCT_SUBCATEGORY_IN_TABLE,
  SHOW_BRAND_MEMBERSHIP_IN_TABLE,
  SHOW_PRODUCT_ID_IN_TABLE
} from "ExtensionV2/components/CampaignsTableImpactedProductRow";
import {
  useAmpdDataTableOptions,
  AmpdDataTableOptionsButton
} from "ExtensionV2/components/AmpdDataTableOptions";
import CampaignEditor from "ExtensionV2/components/campaignEditor/CampaignEditor";
import { GlobalDatePicker } from "ExtensionV2/components/GlobalDatePicker";
import GoogleAdsConnectionErrorMessage from "../../components/GoogleAdsConnectionErrorMessage";
import {
  getStoredCampaignsPerformanceGraphOption,
  getStoredCampaignStatusOption,
  setStoredCampaignStatusOption
} from "Common/utils/savedTablePreferences";
import CampaignsTableRowIssues from "ExtensionV2/components/CampaignsTableRowIssues";
import { campaignControls, headerDropdown } from "ExtensionV2/styles/zIndexes";
import { useColumnSort } from "ExtensionV2/components/table/useColumnSort";
import ObjectFilterButton, {
  filterObjectMap,
  IconInButtonWithoutText
} from "ExtensionV2/components/ObjectFilterButton";

import {
  AMAZON_AMPD_CAMPAIGN_TYPE,
  NON_AMPD_CAMPAIGN_TYPE,
  CAMPAIGN_TOTAL_METRIC_COLUMNS,
  IMPACTED_PRODUCT_COLUMNS,
  campaignStatusOptions,
  campaignTypeOptionsWithWalmart,
  campaignTypeOptionsLegacy,
  WALMART_AMPD_CAMPAIGN_TYPE,
  ANY_AMPD_CAMPAIGN_TYPE,
  ALL_CAMPAIGN_TYPES
} from "./CampaignsPage";
import { getBidAutomationStatusInfo } from "../../components/bidAutomation/getBidAutomationStatusInfo";
import { pluralize } from "Common/utils/strings";
import { convertMicrosToCurrencyUnit } from "../../../Common/utils/money";
import {
  extractWalmartURLInfoFromString,
  walmartUSMarketplaceInfo
} from "../../../Common/utils/walmart";
import { useHasWalmartUIEnabledFeature } from "../../../Common/utils/featureFlags";
import { useCanUserAddCampaigns } from "../../../Common/utils/siteUIBehavior";
import {
  getMarketplaceInfoForDomain,
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo
} from "../../../Common/utils/marketplace";
import {
  CAMPAIGN_ID_QUERY_PARAM,
  MARKETPLACE_QUERY_PARAM
} from "../../ExtensionV2";
import GoogleAdsPerformanceChart, {
  GoogleAdsPerformanceChartControls
} from "../../components/GoogleAdsPerformanceChart";
import { Retailer } from "../../../Common/proto/common/retailer_pb";
import SimpleTooltip from "../../components/SimpleTooltip";
import { convertValueToCurrency } from "../../../Common/utils/googleAds";

export const CAMPAIGN_NAME_FILTER_QUERY_PARAM = "names";

export const CampaignsPageWrapper = styled.div`
  padding: 0.5em;
  height: 100%;
  width: 100%;
  min-width: 35em;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  overflow-x: auto;
`;

export const CampaignControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
  margin-bottom: 1em;
  border-bottom: 2px solid ${ampdRed};
  gap: 1em;
  width: 100%;
  z-index: ${headerDropdown};
`;

const CAMPAIGN_DASHBOARD_GA_CATEGORY = "Ampd: Campaign Dashboard";
const FREEZE_COLUMNS_MAP = { [COLUMN_DATA_KEYS.campaignName]: "0px" };

// Returns a truncated list of keywords after sorting them with the specified
// sort column and direction.
const getSortedSampleOfKeywords = (
  keywordRows,
  sortColumn,
  sortIsAscending,
  maxCount
) => {
  // Since we are sorting the keywords within the campaigns table, sort by
  // keyword text if the sort column is campaign name.
  const sortedKeywordRows = keywordRows.sort((a, b) => {
    let compare = defaultSortComparator(
      a,
      b,
      sortColumn === COLUMN_DATA_KEYS.campaignName
        ? COLUMN_DATA_KEYS.keywords
        : sortColumn,
      sortIsAscending
    );

    // If the sort column values compare equal, further sort by keyword text
    // (always ascending if not the primary sort column).
    if (sortColumn !== COLUMN_DATA_KEYS.campaignName && compare === 0) {
      compare = defaultSortComparator(a, b, COLUMN_DATA_KEYS.keywords, true);
    }

    return compare;
  });

  return sortedKeywordRows.slice(0, maxCount);
};

const getAdTarget = urlString => {
  const walmartUrlInfo = extractWalmartURLInfoFromString(urlString);
  if (walmartUrlInfo.marketplaceInfo) {
    if (walmartUrlInfo.itemId) {
      return `Walmart: ${walmartUrlInfo.itemId}`;
    } else if (walmartUrlInfo.searchPhrase) {
      return `Walmart: '${walmartUrlInfo.searchPhrase}'`;
    }
  }

  const AMAZON_STOREFRONT_URL_MATCHER = "/stores/page/";
  if (!urlString) {
    return;
  }

  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    console.error(`Invalid Ad Target found: ${urlString}, error: ${e.message}`);
    return;
  }

  const { pathAndQuery, asin } = extractAmazonURLInfoFromString(url.toString());

  switch (true) {
    case pathAndQuery?.includes(AMAZON_STOREFRONT_URL_MATCHER):
      return "Storefront";
    case asin && !url.search:
      return "Product";
    default:
      return "Custom";
  }
};

const CampaignsPageRenderer = ({
  tableOptionsArgs,
  selectedCampaignType,
  setSelectedCampaignType,
  campaignIdsByCampaignType,
  campaignDataByCampaignId,
  campaignConfigurationsByCampaignId,
  campaignConfigurationsLoading,
  refetchCampaignConfiguration,
  currencyCode,
  disabledCampaignIds,
  disabledCriteriaIds,
  googleAdsAccount,
  refetchCampaignKeyword,
  setShowKeywordsForCampaignId,
  showKeywordsForCampaignId,
  siteAlias,
  campaignCompareMetricsMap,
  campaignCompareMetricsLoading,
  setQueryRemovedCampaigns,
  loadImpactedProducts,
  impactedProductsLoading,
  impactedProducts,
  refreshMetrics
}) => {
  const canAddCampaigns = useCanUserAddCampaigns();

  const dataLoading = campaignConfigurationsLoading;

  const navigate = useNavigate();

  const {
    showFractions,
    showUnconvertedRevenue,
    selectableOptionsMap,
    selectedColumns,
    handleSelectColumnOption,
    handleResetTableOptions,
    handleToggleShowFraction,
    handleToggleShowUnconvertedRevenue
  } = useAmpdDataTableOptions(tableOptionsArgs);

  const [showPerformanceChart, setShowPerformanceChart] = useState(
    getStoredCampaignsPerformanceGraphOption()
  );
  const [performanceChartComponent, setPerformanceChartComponent] = useState(
    null
  );

  // Only offer the performance chart for internal users for now.
  const havePerformanceChart = showPerformanceChart;

  const [expandedCampaignId, setExpandedCampaignId] = useState(null);
  const [
    selectedCampaignStatusOption,
    setSelectedCampaignStatusOption
  ] = useState(getStoredCampaignStatusOption() || ALL_BUT_REMOVED_STATUS);
  useEffect(() => {
    if (setQueryRemovedCampaigns) {
      setQueryRemovedCampaigns(selectedCampaignStatusOption === REMOVED_STATUS);
    }
  }, [setQueryRemovedCampaigns, selectedCampaignStatusOption]);

  const hasWalmartUI = useHasWalmartUIEnabledFeature();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // When the application first mounts the search params from useSearchParams
    // are also getting written to by the GlobalDatePicker, so there will be a
    // race condition with both components trying to write to the same search
    // params object and one of the params will bet lost. For now the work
    // around is to use the URLSearchParams object directly.
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlCampaignId = urlSearchParams.get(CAMPAIGN_ID_QUERY_PARAM);
    if (
      urlCampaignId &&
      campaignDataByCampaignId?.[urlCampaignId] &&
      urlCampaignId !== showKeywordsForCampaignId
    ) {
      setShowKeywordsForCampaignId(urlCampaignId);

      const { itemizedCampaignConfiguration } =
        campaignDataByCampaignId[urlCampaignId] || {};
      const marketplaceInfo = itemizedCampaignConfiguration?.marketplaceInfo;

      if (marketplaceInfo?.domain) {
        if (
          urlSearchParams.get(MARKETPLACE_QUERY_PARAM) !==
          marketplaceInfo.domain
        ) {
          urlSearchParams.set(MARKETPLACE_QUERY_PARAM, marketplaceInfo.domain);
          setSearchParams(urlSearchParams);
        }
      } else {
        if (urlSearchParams.get(MARKETPLACE_QUERY_PARAM)) {
          urlSearchParams.delete(MARKETPLACE_QUERY_PARAM);
          setSearchParams(urlSearchParams);
        }
      }
    }

    if (
      selectedCampaignType === AMAZON_AMPD_CAMPAIGN_TYPE &&
      !urlSearchParams.get(MARKETPLACE_QUERY_PARAM)
    ) {
      urlSearchParams.set(
        MARKETPLACE_QUERY_PARAM,
        amazonUSMarketplaceInfo.domain
      );
      setSearchParams(urlSearchParams);
    } else if (
      selectedCampaignType === WALMART_AMPD_CAMPAIGN_TYPE &&
      !urlSearchParams.get(MARKETPLACE_QUERY_PARAM)
    ) {
      urlSearchParams.set(
        MARKETPLACE_QUERY_PARAM,
        walmartUSMarketplaceInfo.domain
      );
      setSearchParams(urlSearchParams);
    }
  }, [
    campaignDataByCampaignId,
    showKeywordsForCampaignId,
    searchParams,
    setSearchParams,
    selectedCampaignType,
    setShowKeywordsForCampaignId
  ]);

  // Set the selectedCampaignType based on the type of campaign that is currently
  // selected.  If no campaign is selected and the user has not yet explicitly
  // selected a campaign type, the initial campaign type should be AMAZON unless
  // the Walmart UI is enabled and there are any Walmart Ampd campaigns.
  useEffect(() => {
    if (showKeywordsForCampaignId) {
      const strictCampaignType = getCampaignTypeOfCampaign(
        campaignIdsByCampaignType,
        showKeywordsForCampaignId,
        hasWalmartUI
      );

      // Since a specific campaign is selected, switch to the appropriate type
      // of campaigns, unless the current type is ALL.
      if (strictCampaignType) {
        setSelectedCampaignType(campaignType =>
          campaignType !== ALL_CAMPAIGN_TYPES
            ? strictCampaignType
            : campaignType
        );
      }
    } else if (
      campaignIdsByCampaignType[AMAZON_AMPD_CAMPAIGN_TYPE] !== undefined &&
      (_.size(campaignIdsByCampaignType[AMAZON_AMPD_CAMPAIGN_TYPE]) > 0 ||
        !hasWalmartUI ||
        _.size(campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE]) === 0)
    ) {
      // If the current type has never been set one way or the other, then default
      // to the amazon type unless the Walmart UI is enabled and there are walmart
      // campaigns, but no amazon campaigns.
      setSelectedCampaignType(campaignType =>
        campaignType === ANY_AMPD_CAMPAIGN_TYPE
          ? AMAZON_AMPD_CAMPAIGN_TYPE
          : campaignType
      );
    } else if (
      campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE] !== undefined
    ) {
      // Finally, as long as campaignIdsByCampaignType has id lists, switch to walmart campaigns
      // if the current type has never been set one way or the other.
      setSelectedCampaignType(campaignType =>
        campaignType === ANY_AMPD_CAMPAIGN_TYPE
          ? WALMART_AMPD_CAMPAIGN_TYPE
          : campaignType
      );
    }
  }, [
    campaignIdsByCampaignType,
    hasWalmartUI,
    showKeywordsForCampaignId,
    setSelectedCampaignType
  ]);

  const availableCampaignTypeOptions = useMemo(() => {
    return (hasWalmartUI
      ? campaignTypeOptionsWithWalmart
      : campaignTypeOptionsLegacy
    ).map(option => {
      return {
        key: option.key,
        text: option.text,
        value: option.value,
        description: campaignIdsByCampaignType[option.value]?.length
      };
    });
  }, [campaignIdsByCampaignType, hasWalmartUI]);

  const eligibleCampaigns = useMemo(() => {
    const filteredCampaigns = { ...campaignDataByCampaignId };

    for (const campaignId in campaignDataByCampaignId) {
      const { itemizedCampaignConfiguration } = campaignDataByCampaignId[
        campaignId
      ];

      // Filter by status
      const status = itemizedCampaignConfiguration.campaignStatus;
      if (filterBasedOnStatus(selectedCampaignStatusOption, status)) {
        delete filteredCampaigns[campaignId];
      }

      // Filter if campaign was created by Ampd
      switch (selectedCampaignType) {
        case ANY_AMPD_CAMPAIGN_TYPE:
          if (!itemizedCampaignConfiguration.isAmpdCampaign) {
            delete filteredCampaigns[campaignId];
          }
          break;
        case AMAZON_AMPD_CAMPAIGN_TYPE:
          if (!itemizedCampaignConfiguration.isAmpdCampaignForAmazon) {
            delete filteredCampaigns[campaignId];
          }
          break;
        case WALMART_AMPD_CAMPAIGN_TYPE:
          if (!itemizedCampaignConfiguration.isAmpdCampaignForWalmart) {
            delete filteredCampaigns[campaignId];
          }
          break;
        case NON_AMPD_CAMPAIGN_TYPE:
          if (itemizedCampaignConfiguration.isAmpdCampaign) {
            delete filteredCampaigns[campaignId];
          }
          break;
      }
    }
    return filteredCampaigns;
  }, [
    campaignDataByCampaignId,
    selectedCampaignStatusOption,
    selectedCampaignType
  ]);

  const nameFilter = searchParams.get(CAMPAIGN_NAME_FILTER_QUERY_PARAM) || "";
  const setNameFilter = useCallback(
    filter => {
      if (!filter) {
        searchParams.delete(CAMPAIGN_NAME_FILTER_QUERY_PARAM);
      } else {
        searchParams.set(CAMPAIGN_NAME_FILTER_QUERY_PARAM, filter);
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [filteredCampaigns, filteredCampaignIds] = useMemo(() => {
    const _filteredCampaigns = filterObjectMap(
      eligibleCampaigns,
      nameFilter,
      getNameFromCampaignData
    );

    return [_filteredCampaigns, Object.keys(_filteredCampaigns)];
  }, [eligibleCampaigns, nameFilter]);

  const campaignsWithGoogleAdsIssues = useMemo(() => {
    const campaignsWithIssues = {};
    if (campaignConfigurationsByCampaignId) {
      for (const [
        campaignId,
        ampdCampaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        // Call component as function (instead of in JSX form) so the return value can be checked for null.
        const errorComponent = CampaignsTableRowIssues({
          ampdCampaignConfiguration: ampdCampaignConfiguration
        });

        if (errorComponent) {
          campaignsWithIssues[campaignId] = errorComponent;
        }
      }
    }

    return campaignsWithIssues;
  }, [campaignConfigurationsByCampaignId]);

  const bidAutomationStatusInfoByCampaignIdMap = useMemo(() => {
    const statusInfoMap = new Map();
    if (campaignConfigurationsByCampaignId) {
      for (const [
        campaignId,
        ampdCampaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        const statusInfo = getBidAutomationStatusInfo(
          ampdCampaignConfiguration
        );

        statusInfoMap.set(campaignId, statusInfo);
      }
    }
    return statusInfoMap;
  }, [campaignConfigurationsByCampaignId]);

  // Only things relevant to the actual table itself need to go here, which pretty means much only
  // values that need to be sortable. If there are values that the table row must know about that
  // are not relevant to the table itself, use the (row) => component mapping function.
  const [dataRows, totalRowData] = useMemo(() => {
    let rows = [];
    let totalMetrics = getMetricsAccumulator();
    totalMetrics[COLUMN_DATA_KEYS.dailyBudget] = 0;
    totalMetrics.costCurrencyCode = currencyCode;
    let knownBudgetIds = new Set();
    let totalCompareMetrics = null;
    if (campaignCompareMetricsMap) {
      totalCompareMetrics = getMetricsAccumulator();
    }

    for (const campaignId in filteredCampaigns) {
      const {
        itemizedCampaignConfiguration,
        metrics,
        compareMetrics,
        compareMetricsLoading
      } = filteredCampaigns[campaignId];

      // Sum all Total metric columns for now.  The metrics that are not themselves
      // summable but are derived from summable metrics will be calculated after
      // the loop.  Treat all null and NaNs as zeros.
      if (metrics) {
        accumulateMetrics(totalMetrics, metrics);
      }
      if (totalCompareMetrics && compareMetrics) {
        accumulateMetrics(totalCompareMetrics, compareMetrics);
      }

      // Only add the budget for enabled campaigns to the total budget value, because
      // those are the only ones that are active.
      if (itemizedCampaignConfiguration.isCampaignEnabled) {
        const budgetId = itemizedCampaignConfiguration.campaignBudgetId;
        if (budgetId && !knownBudgetIds.has(budgetId)) {
          let addAmount = itemizedCampaignConfiguration.campaignBudget;
          if (itemizedCampaignConfiguration.currencyCode !== currencyCode) {
            addAmount = convertValueToCurrency({
              value: addAmount,
              fromCurrencyCode: itemizedCampaignConfiguration.currencyCode,
              toCurrencyCode: currencyCode
            });
          }

          totalMetrics[COLUMN_DATA_KEYS.dailyBudget] += addAmount;
        }

        knownBudgetIds = knownBudgetIds.add(budgetId);
      }

      rows.push({
        ...metrics,
        compareMetricsLoading,
        compareMetrics: compareMetrics,

        // Define attributes after metrics unpacking.
        id: String(itemizedCampaignConfiguration.campaignId),
        customerId: String(itemizedCampaignConfiguration.customerId),
        [COLUMN_DATA_KEYS.campaignName]:
          itemizedCampaignConfiguration.campaignName,
        [COLUMN_DATA_KEYS.status]: itemizedCampaignConfiguration.campaignStatus,
        [COLUMN_DATA_KEYS.dailyBudget]:
          itemizedCampaignConfiguration.campaignBudget,

        // Put the (first) ad group's effective default cpc bid in the campaign's
        // cpcBid data column, and the campaign's max cpc bid in the effectiveCpcBid
        // data column.
        [COLUMN_DATA_KEYS.adGroupId]: String(
          itemizedCampaignConfiguration.adGroupId
        ),
        [COLUMN_DATA_KEYS.cpcBid]: convertMicrosToCurrencyUnit(
          itemizedCampaignConfiguration.adGroupMaxCPCMicros
        ),
        [COLUMN_DATA_KEYS.effectiveCpcBid]: convertMicrosToCurrencyUnit(
          itemizedCampaignConfiguration.campaignMaxCPCMicros
        ),
        [COLUMN_DATA_KEYS.biddingStrategy]:
          itemizedCampaignConfiguration.campaignBiddingStrategy,
        [COLUMN_DATA_KEYS.campaignStartDate]:
          itemizedCampaignConfiguration.campaignStartDate,
        [COLUMN_DATA_KEYS.adTarget]: getAdTarget(
          itemizedCampaignConfiguration.finalURL
        ),
        [COLUMN_DATA_KEYS.criticalInformation]:
          campaignsWithGoogleAdsIssues[campaignId],
        [COLUMN_DATA_KEYS.bidAutomationStatus]: bidAutomationStatusInfoByCampaignIdMap.get(
          campaignId
        )
      });
    }

    // Now calculate all derived Total column values.
    calculateDerivedMetrics(totalMetrics, CAMPAIGN_TOTAL_METRIC_COLUMNS);

    if (totalCompareMetrics) {
      calculateDerivedMetrics(
        totalCompareMetrics,
        CAMPAIGN_TOTAL_METRIC_COLUMNS
      );

      totalMetrics.compareMetricsLoading = campaignCompareMetricsLoading;
      totalMetrics.compareMetrics = totalCompareMetrics;
    }

    return [rows, totalMetrics];
  }, [
    currencyCode,
    campaignsWithGoogleAdsIssues,
    bidAutomationStatusInfoByCampaignIdMap,
    filteredCampaigns,
    campaignCompareMetricsMap,
    campaignCompareMetricsLoading
  ]);

  const sampleKeywordSortState = useColumnSort(
    COLUMN_DATA_KEYS.cost,
    COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY
  );
  const [maxSampleKeywords, setMaxSampleKeywords] = useState(
    DEFAULT_SAMPLE_ROWS
  );

  const impactedProductsSortState = useColumnSort(
    COLUMN_DATA_KEYS.revenue,
    COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY
  );
  const [impactedProductsTableFlags, setImpactedProductsTableFlags] = useState(
    SHOW_PRODUCT_ID_IN_TABLE |
      SHOW_BRAND_MEMBERSHIP_IN_TABLE |
      SHOW_PRODUCT_SUBCATEGORY_IN_TABLE
  );

  const [maxImpactedProducts, setMaxImpactedProducts] = useState(0);
  const [showNonBrandProducts, setShowNonBrandProducts] = useState(false);

  useEffect(() => {
    if (maxImpactedProducts > 0 && loadImpactedProducts) {
      loadImpactedProducts();
    }
  }, [maxImpactedProducts, loadImpactedProducts]);

  const mapCampaignObjectToComponent = useCallback(
    (campaignRowData, columns, rowIndex) => {
      const {
        itemizedCampaignConfiguration,
        keywords
      } = campaignDataByCampaignId[campaignRowData.id];

      let enabledKeywordCount = -1;
      let pausedKeywordCount = -1;
      let sampleKeywordRows = [];
      let allKeywordRows = [];
      let impactedProductRows = [];
      let allImpactedProductRows = [];
      if (showKeywordsForCampaignId === campaignRowData.id) {
        const keywordsById = keywords.data;
        const keywordRowIds = Object.keys(keywordsById);
        allKeywordRows = keywordRowIds.map(id => {
          return {
            id,
            ...keywordsById[id]
          };
        });

        enabledKeywordCount = allKeywordRows.filter(
          kw => kw.status === "ENABLED"
        ).length;
        pausedKeywordCount = allKeywordRows.length - enabledKeywordCount;

        sampleKeywordRows = getSortedSampleOfKeywords(
          allKeywordRows,
          sampleKeywordSortState.sortColumn,
          sampleKeywordSortState.sortIsAscending,
          maxSampleKeywords
        );

        allImpactedProductRows = (
          impactedProducts?.[showKeywordsForCampaignId] || []
        )
          .map((dashboardMetrics, index) => {
            if (!showNonBrandProducts) {
              if (
                dashboardMetrics.brandMembership ===
                Retailer.BrandMembership.Option.NON_BRAND
              ) {
                return null;
              }
            }

            const id =
              `${dashboardMetrics.productName}|${dashboardMetrics.productAsin}|${dashboardMetrics.parentAsin}` +
              `|${dashboardMetrics.brandName}|${dashboardMetrics.productGroup}` +
              `|${dashboardMetrics.productCategory}|${dashboardMetrics.productSubcategory}|${index}`;

            const impactedProductObject = {
              id,
              ...dashboardMetrics,
              [COLUMN_DATA_KEYS.campaignName]: dashboardMetrics.productName,
              [COLUMN_DATA_KEYS.status]: dashboardMetrics.productAsin,
              [COLUMN_DATA_KEYS.dailyBudget]:
                dashboardMetrics.productSubcategory,
              [COLUMN_DATA_KEYS.clicks]: dashboardMetrics.detailPageViewClicks
            };

            calculateDerivedMetrics(
              impactedProductObject,
              IMPACTED_PRODUCT_COLUMNS
            );

            return impactedProductObject;
          })
          .filter(Boolean);

        impactedProductRows = getSortedSampleOfImpactedProducts(
          allImpactedProductRows,
          impactedProductsSortState.sortColumn,
          impactedProductsSortState.sortIsAscending,
          maxImpactedProducts,
          impactedProductsTableFlags
        );
      }

      return (
        <CampaignsTableRow
          campaignObject={campaignRowData}
          columns={columns}
          costCurrencyCode={campaignRowData?.costCurrencyCode || currencyCode}
          disabled={disabledCampaignIds.has(campaignRowData.id)}
          expandedCampaignId={expandedCampaignId}
          googleAdsAccountId={googleAdsAccount?.customerId}
          key={campaignRowData.id}
          sampleKeywordsSortState={sampleKeywordSortState}
          setMaxSampleKeywords={setMaxSampleKeywords}
          sampleKeywordRows={sampleKeywordRows}
          enabledKeywordCount={enabledKeywordCount}
          pausedKeywordCount={pausedKeywordCount}
          allKeywords={allKeywordRows}
          keywordsLoading={keywords.keywordsLoading}
          maxImpactedProducts={maxImpactedProducts}
          impactedProductsSortState={impactedProductsSortState}
          impactedProductsTableFlags={impactedProductsTableFlags}
          setImpactedProductsTableFlags={setImpactedProductsTableFlags}
          setMaxImpactedProducts={setMaxImpactedProducts}
          showNonBrandProducts={showNonBrandProducts}
          setShowNonBrandProducts={setShowNonBrandProducts}
          impactedProductRows={impactedProductRows}
          allImpactedProducts={allImpactedProductRows}
          impactedProductsLoading={impactedProductsLoading}
          disabledCriteriaIds={disabledCriteriaIds}
          refetchCampaignKeyword={refetchCampaignKeyword}
          revenueCurrencyCode={
            campaignRowData?.revenueCurrencyCode || currencyCode
          }
          rowIndex={rowIndex}
          setExpandedCampaignId={setExpandedCampaignId}
          setShowKeywordsForCampaignId={setShowKeywordsForCampaignId}
          showFractions={showFractions}
          showKeywordsForCampaignId={showKeywordsForCampaignId}
          showUnconvertedRevenue={showUnconvertedRevenue}
          siteAlias={siteAlias}
          campaignConfigurationsByCampaignId={
            campaignConfigurationsByCampaignId
          }
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          refreshMetrics={refreshMetrics}
        />
      );
    },
    [
      campaignDataByCampaignId,
      currencyCode,
      disabledCampaignIds,
      expandedCampaignId,
      googleAdsAccount?.customerId,
      setShowKeywordsForCampaignId,
      showFractions,
      showKeywordsForCampaignId,
      showUnconvertedRevenue,
      sampleKeywordSortState,
      maxSampleKeywords,
      setMaxSampleKeywords,
      impactedProductsLoading,
      impactedProducts,
      impactedProductsSortState,
      impactedProductsTableFlags,
      maxImpactedProducts,
      setMaxImpactedProducts,
      showNonBrandProducts,
      setShowNonBrandProducts,
      siteAlias,
      disabledCriteriaIds,
      refetchCampaignKeyword,
      campaignConfigurationsByCampaignId,
      refetchCampaignConfiguration,
      refreshMetrics
    ]
  );

  // Scroll the selected campaign into view.  First, see if there is a focus
  // element to center vertically.  If not, center the campaign row itself.
  useEffect(() => {
    if (showKeywordsForCampaignId) {
      let elements = document.getElementsByClassName(
        AMPD_SELECTED_ROW_FOCUS_CLASS
      );
      if (_.isEmpty(elements)) {
        elements = document.getElementsByClassName(
          AMPD_SELECTED_ROW_HEAD_CLASS
        );
      }
      for (const element of elements) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        });
      }
    }
  }, [showKeywordsForCampaignId, dataRows]);

  const mapCampaignTotalsToComponent = useCallback(
    (totalRowData, columns, rowIndex) => {
      return (
        <CampaignsTableTotalRow
          key="campaignTotals"
          totalRowData={totalRowData}
          title={`Total (${pluralize(_.size(filteredCampaigns), "campaign")})`}
          columns={columns}
          costCurrencyCode={totalRowData?.costCurrencyCode || currencyCode}
          revenueCurrencyCode={
            totalRowData?.revenueCurrencyCode || currencyCode
          }
          rowIndex={rowIndex}
          showFractions={showFractions}
        />
      );
    },
    [currencyCode, showFractions, filteredCampaigns]
  );

  const getCreateCampaignButton = (size, compact) => {
    let to = `/t/${siteAlias}/dashboard/create-campaign`;

    let marketplaceDomain = searchParams.get(MARKETPLACE_QUERY_PARAM);
    if (!marketplaceDomain) {
      marketplaceDomain = amazonUSMarketplaceInfo.domain;
    }

    to += `?marketplace=${marketplaceDomain}`;

    return (
      <SimpleTooltip tooltip="Create a new campaign">
        <Button
          color="green"
          size={compact ? "mini" : size}
          onClick={() => navigate(to)}
        >
          {compact ? (
            <IconInButtonWithoutText name="plus" />
          ) : (
            <span>Create a new campaign</span>
          )}
        </Button>
      </SimpleTooltip>
    );
  };

  const tableColumns = useMemo(() => {
    let columns = [...selectedColumns];
    if (Object.keys(campaignsWithGoogleAdsIssues).length) {
      // put the issues column to the right of campaign name column
      columns.splice(1, 0, COLUMN_DATA_KEYS.criticalInformation);
    }

    return columns;
  }, [campaignsWithGoogleAdsIssues, selectedColumns]);

  const expandedCampaignTray = useMemo(() => {
    if (!expandedCampaignId) {
      return null;
    }

    return (
      <CampaignEditor
        campaignConfigurationsByCampaignId={campaignConfigurationsByCampaignId}
        campaignConfigurationsLoading={campaignConfigurationsLoading}
        campaignId={expandedCampaignId}
        setExpandedCampaignId={setExpandedCampaignId}
        refetchCampaignConfiguration={refetchCampaignConfiguration}
      />
    );
  }, [
    campaignConfigurationsByCampaignId,
    campaignConfigurationsLoading,
    refetchCampaignConfiguration,
    expandedCampaignId
  ]);

  const handleUpdateCampaignStatusFilter = (_e, { value }) => {
    setStoredCampaignStatusOption(value);
    sendGAEvent(
      CAMPAIGN_DASHBOARD_GA_CATEGORY,
      "Filter by Status",
      siteAlias,
      value
    );

    setSelectedCampaignStatusOption(value);
  };

  const handleUpdateCampaignTypeFilter = (_e, { value }) => {
    setSelectedCampaignType(value);

    if (value === WALMART_AMPD_CAMPAIGN_TYPE) {
      if (
        !isWalmartMarketplaceInfo(
          getMarketplaceInfoForDomain(searchParams.get(MARKETPLACE_QUERY_PARAM))
        )
      ) {
        searchParams.set(
          MARKETPLACE_QUERY_PARAM,
          walmartUSMarketplaceInfo.domain
        );
      }
    } else if (value === AMAZON_AMPD_CAMPAIGN_TYPE) {
      if (
        !isAmazonMarketplaceInfo(
          getMarketplaceInfoForDomain(searchParams.get(MARKETPLACE_QUERY_PARAM))
        )
      ) {
        searchParams.set(
          MARKETPLACE_QUERY_PARAM,
          amazonUSMarketplaceInfo.domain
        );
      }
    } else {
      searchParams.delete(MARKETPLACE_QUERY_PARAM);
    }

    setSearchParams(searchParams);
  };

  // New users may have existing Adwords Campaigns associated with their Adwords Account, but
  // not have any Ampd Campaigns. In this case we still want to show the big green Create Campaign
  // button.
  const hasNoAmpdCampaignsForFilter =
    Object.keys(eligibleCampaigns || {}).length === 0 &&
    selectedCampaignType === AMAZON_AMPD_CAMPAIGN_TYPE;
  const hasNoCampaigns = !campaignConfigurationsByCampaignId?.size;
  const shouldShowEmptyContent =
    !dataLoading && (hasNoAmpdCampaignsForFilter || hasNoCampaigns);

  let dashboardTableMetricsRetailer = Retailer.Option.UNKNOWN;
  if (selectedCampaignType === AMAZON_AMPD_CAMPAIGN_TYPE) {
    dashboardTableMetricsRetailer = Retailer.Option.AMAZON;
  } else if (selectedCampaignType === WALMART_AMPD_CAMPAIGN_TYPE) {
    dashboardTableMetricsRetailer = Retailer.Option.WALMART;
  }

  return (
    <>
      {expandedCampaignId && expandedCampaignTray}

      <CampaignsPageWrapper>
        {/* Filters */}
        <CampaignControlsWrapper>
          <div style={{ maxWidth: "17em", zIndex: headerDropdown }}>
            <DropdownLabel>Campaign Type</DropdownLabel>
            <Dropdown
              loading={dataLoading}
              disabled={dataLoading}
              selection
              options={availableCampaignTypeOptions}
              value={selectedCampaignType}
              onChange={handleUpdateCampaignTypeFilter}
            />
          </div>

          <div style={{ maxWidth: "17em", zIndex: headerDropdown }}>
            <DropdownLabel>Campaign Status</DropdownLabel>
            <Dropdown
              selection
              options={campaignStatusOptions}
              value={selectedCampaignStatusOption}
              onChange={handleUpdateCampaignStatusFilter}
            />
          </div>

          <GlobalDatePicker offerCompare={true} />
        </CampaignControlsWrapper>

        {/* Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: havePerformanceChart ? "nowrap" : "wrap",
            justifyContent: "space-between",
            alignContent: "center",
            marginBottom: showPerformanceChart ? "0.25em" : "1em",
            width: "100%",
            zIndex: campaignControls
          }}
        >
          <div>
            {canAddCampaigns &&
              getCreateCampaignButton(
                havePerformanceChart ? "medium" : "large",
                havePerformanceChart
              )}
          </div>

          <div
            style={
              havePerformanceChart
                ? { flexGrow: "1", flexShrink: "1", minWidth: 0 }
                : { marginLeft: "auto" }
            }
          >
            <GoogleAdsPerformanceChartControls
              campaignIds={filteredCampaignIds}
              retailer={dashboardTableMetricsRetailer}
              retailerCampaignIds={
                campaignIdsByCampaignType[selectedCampaignType]
              }
              showPerformanceChart={showPerformanceChart}
              setShowPerformanceChart={setShowPerformanceChart}
              setPerformanceChartComponent={setPerformanceChartComponent}
            />
          </div>

          <div
            style={{
              marginLeft: "1em",
              display: "inline"
            }}
          >
            <ObjectFilterButton
              title={"Campaign name"}
              objectMap={eligibleCampaigns}
              nameFilter={nameFilter}
              getName={getNameFromCampaignData}
              onUpdateNameFilter={setNameFilter}
              compact={havePerformanceChart}
            />
          </div>

          <div style={{ marginLeft: "1em", display: "inline" }}>
            <AmpdDataTableOptionsButton
              columnOptionsMap={selectableOptionsMap}
              columnTitles={COLUMN_DISPLAY_NAME_FROM_DATA_KEY}
              onToggleColumn={handleSelectColumnOption}
              onToggleShowFractions={handleToggleShowFraction}
              onToggleShowUnconvertedRevenue={
                handleToggleShowUnconvertedRevenue
              }
              onResetTableOptions={handleResetTableOptions}
              showFractions={showFractions}
              showUnconvertedRevenue={showUnconvertedRevenue}
              compact={havePerformanceChart}
            />
          </div>
        </div>

        {/* Performance Chart */}
        <GoogleAdsPerformanceChart
          performanceChartComponent={performanceChartComponent}
        />

        {/* The Campaigns Table */}
        <div
          style={{
            overflow: "auto",
            height: "100%"
          }}
        >
          <GoogleAdsConnectionErrorMessage />
          <AmpdDataTable
            columnDataNames={tableColumns}
            columnDisplayNamesMap={COLUMN_DISPLAY_NAME_FROM_DATA_KEY}
            columnTooltipMap={COLUMN_TOOLTIP_FROM_DATA_KEY}
            dataRows={dataRows}
            mapDataRowToComponent={mapCampaignObjectToComponent}
            totalData={totalRowData}
            mapTotalDataToComponent={mapCampaignTotalsToComponent}
            isLoading={dataLoading}
            freezeColumnsMap={FREEZE_COLUMNS_MAP}
            defaultSortColumn={COLUMN_DATA_KEYS.cost}
            defaultSortDirections={COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY}
            emptyContent={
              shouldShowEmptyContent && (
                <Segment style={{ maxWidth: "92vw" }}>
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      margin: "auto",
                      maxWidth: "31em"
                    }}
                  >
                    <h2 style={{ textAlign: "center" }}>
                      To get started with Ampd, create a new campaign
                    </h2>

                    {canAddCampaigns && getCreateCampaignButton("huge", false)}

                    <p style={{ textAlign: "center", marginTop: "2em" }}>
                      Need help getting started? Try signing up for{" "}
                      <Link to={`/t/${siteAlias}/dashboard/learn`}>
                        live training
                      </Link>{" "}
                      or{" "}
                      <a
                        href={"https://servicehub.ampd.io/knowledge"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        visit our FAQ page.
                      </a>
                    </p>
                  </div>
                </Segment>
              )
            }
          />
        </div>
      </CampaignsPageWrapper>
    </>
  );
};

// Returns the name for the campaign objects kept in the campaignAndAdDataByCampaignId
// map.
function getNameFromCampaignData(campaignData) {
  return campaignData?.itemizedCampaignConfiguration?.campaignName || "";
}

// Returns the campaign type for the specified campaignId.
function getCampaignTypeOfCampaign(
  campaignIdsByCampaignType,
  campaignId,
  hasWalmartUI
) {
  if (!campaignId) {
    return null;
  }

  if (
    campaignIdsByCampaignType[AMAZON_AMPD_CAMPAIGN_TYPE]?.includes(campaignId)
  ) {
    return AMAZON_AMPD_CAMPAIGN_TYPE;
  }

  if (
    campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE]?.includes(campaignId)
  ) {
    return hasWalmartUI ? WALMART_AMPD_CAMPAIGN_TYPE : ALL_CAMPAIGN_TYPES;
  }

  if (campaignIdsByCampaignType[NON_AMPD_CAMPAIGN_TYPE]?.includes(campaignId)) {
    return NON_AMPD_CAMPAIGN_TYPE;
  }

  return null;
}

export default CampaignsPageRenderer;
