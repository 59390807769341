import _ from "lodash";

import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { toUpperCase } from "Common/utils/strings";
import { useUpdateWalmartAttributionCategories } from "ExtensionV2/queries/useUpdateWalmartAttributionCategories";
import { RefetchCampaignConfigurationsResult } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import { CAMPAIGN_DASHBOARD_GA_CATEGORY } from "ExtensionV2/ExtensionV2";

const WalmartAttributionCategoriesEditButton: React.FC<{
  siteAlias: string;
  googleAdsAccountId: string;
  campaignId: string;
  actionId: string;
  category: string;
  categoriesToInfer: Array<string>;
  categoriesToIgnore: Array<string>;
  setWillChange: (willChange: boolean) => void;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  refreshMetrics: () => Promise<void>;
}> = ({
  siteAlias,
  googleAdsAccountId,
  campaignId,
  actionId,
  category,
  categoriesToInfer,
  categoriesToIgnore,
  setWillChange,
  refetchCampaignConfiguration,
  refreshMetrics
}) => {
  const categoryConsidered = categoriesToInfer.includes(toUpperCase(category));

  const {
    mutateAsync: updateWalmartAttributionCategories
  } = useUpdateWalmartAttributionCategories();

  const [updating, setUpdating] = useState(false);

  const handleClick = async (): Promise<void> => {
    let newCategoriesToInfer, newCategoriesToIgnore;
    if (categoryConsidered) {
      newCategoriesToIgnore = _.uniq([...categoriesToIgnore, category]);
      newCategoriesToInfer = _.filter(
        categoriesToInfer,
        cat => cat !== category
      );
    } else {
      newCategoriesToInfer = _.uniq([...categoriesToInfer, category]);
      newCategoriesToIgnore = _.filter(
        categoriesToIgnore,
        cat => cat !== category
      );
    }

    setUpdating(true);
    if (setWillChange) {
      setWillChange(true);
    }

    try {
      await updateWalmartAttributionCategories({
        siteAlias,
        gaCategory: CAMPAIGN_DASHBOARD_GA_CATEGORY,
        googleAdsAccountId,
        actionId,
        newCategoriesToInfer,
        newCategoriesToIgnore
      });

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(campaignId);
      }

      if (refreshMetrics) {
        await refreshMetrics();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
      if (setWillChange) {
        setWillChange(false);
      }
    }
  };

  return (
    <>
      <SimpleTooltip
        tooltip={
          categoryConsidered
            ? "OPD purchases with this category are inferred to belong to the brand"
            : "This category is ignored for OPD purchases"
        }
      >
        <Icon
          size="large"
          loading={updating}
          name={
            updating
              ? "spinner"
              : categoryConsidered
              ? "toggle on"
              : "toggle off"
          }
          onClick={handleClick}
        />
      </SimpleTooltip>
    </>
  );
};

export default WalmartAttributionCategoriesEditButton;
