// source: google/ads/googleads/v18/resources/campaign_asset.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_asset_policy_pb = require('../../../../../google/ads/googleads/v18/common/asset_policy_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_asset_policy_pb);
var google_ads_googleads_v18_enums_asset_field_type_pb = require('../../../../../google/ads/googleads/v18/enums/asset_field_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_field_type_pb);
var google_ads_googleads_v18_enums_asset_link_primary_status_pb = require('../../../../../google/ads/googleads/v18/enums/asset_link_primary_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_link_primary_status_pb);
var google_ads_googleads_v18_enums_asset_link_primary_status_reason_pb = require('../../../../../google/ads/googleads/v18/enums/asset_link_primary_status_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_link_primary_status_reason_pb);
var google_ads_googleads_v18_enums_asset_link_status_pb = require('../../../../../google/ads/googleads/v18/enums/asset_link_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_link_status_pb);
var google_ads_googleads_v18_enums_asset_source_pb = require('../../../../../google/ads/googleads/v18/enums/asset_source_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_source_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.CampaignAsset', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.CampaignAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.CampaignAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.CampaignAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.CampaignAsset.displayName = 'proto.google.ads.googleads.v18.resources.CampaignAsset';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.CampaignAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.CampaignAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaign: jspb.Message.getFieldWithDefault(msg, 6, ""),
    asset: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    source: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    primaryStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    primaryStatusDetailsList: jspb.Message.toObjectList(msg.getPrimaryStatusDetailsList(),
    google_ads_googleads_v18_common_asset_policy_pb.AssetLinkPrimaryStatusDetails.toObject, includeInstance),
    primaryStatusReasonsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.CampaignAsset;
  return proto.google.ads.googleads.v18.resources.CampaignAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.CampaignAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    case 8:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetLinkStatusEnum.AssetLinkStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} */ (reader.readEnum());
      msg.setPrimaryStatus(value);
      break;
    case 10:
      var value = new google_ads_googleads_v18_common_asset_policy_pb.AssetLinkPrimaryStatusDetails;
      reader.readMessage(value,google_ads_googleads_v18_common_asset_policy_pb.AssetLinkPrimaryStatusDetails.deserializeBinaryFromReader);
      msg.addPrimaryStatusDetails(value);
      break;
    case 11:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrimaryStatusReasons(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.CampaignAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.CampaignAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPrimaryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPrimaryStatusDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      google_ads_googleads_v18_common_asset_policy_pb.AssetLinkPrimaryStatusDetails.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryStatusReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string campaign = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setCampaign = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.clearCampaign = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string asset = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setAsset = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.clearAsset = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType field_type = 4;
 * @return {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getFieldType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetFieldTypeEnum.AssetFieldType} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource source = 8;
 * @return {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetSourceEnum.AssetSource} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.ads.googleads.v18.enums.AssetLinkStatusEnum.AssetLinkStatus status = 5;
 * @return {!proto.google.ads.googleads.v18.enums.AssetLinkStatusEnum.AssetLinkStatus}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetLinkStatusEnum.AssetLinkStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetLinkStatusEnum.AssetLinkStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.ads.googleads.v18.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus primary_status = 9;
 * @return {!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getPrimaryStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setPrimaryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails primary_status_details = 10;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails>}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getPrimaryStatusDetailsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_asset_policy_pb.AssetLinkPrimaryStatusDetails, 10));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails>} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
*/
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setPrimaryStatusDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.addPrimaryStatusDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.google.ads.googleads.v18.common.AssetLinkPrimaryStatusDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.clearPrimaryStatusDetailsList = function() {
  return this.setPrimaryStatusDetailsList([]);
};


/**
 * repeated google.ads.googleads.v18.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason primary_status_reasons = 11;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason>}
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.getPrimaryStatusReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason>} value
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.setPrimaryStatusReasonsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.addPrimaryStatusReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.CampaignAsset} returns this
 */
proto.google.ads.googleads.v18.resources.CampaignAsset.prototype.clearPrimaryStatusReasonsList = function() {
  return this.setPrimaryStatusReasonsList([]);
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
