import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import {
  GetCampaignConfigurationsReply,
  GetCampaignConfigurationsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";

// First returns the stored configurations (which is usually faster) and then returns
// the up-to-date configurations when they're ready. This lets us show the user
// a quick response while we wait for the up-to-date configurations.
export const useFacebookCampaignConfigurations = ({
  siteAlias
}: {
  siteAlias: string;
}): {
  data:
    | Array<GetCampaignConfigurationsReply.CampaignConfiguration.AsObject>
    | undefined;
  isLoading: boolean;
  isFetchingUpToDateConfigs: boolean;
  error: unknown;
} => {
  const storedQuery = useStoredFacebookCampaignConfigurations({ siteAlias });
  const upToDateQuery = useUpToDateFacebookCampaignConfigurations({
    siteAlias
  });

  return {
    data: upToDateQuery.data || storedQuery.data,
    isLoading: upToDateQuery.isLoading && storedQuery.isLoading,
    isFetchingUpToDateConfigs: upToDateQuery.isFetching,
    error: upToDateQuery.error || storedQuery.error
  };
};

const staleTime = 10 * 60 * 1_000; // 10 minutes
const cacheTime = 11 * 60 * 1_000; // 11 minutes

export const STORED_FACEBOOK_CAMPAIGN_CONFIGURATIONS_KEY =
  "storedFacebookCampaignConfigurations";
export const useStoredFacebookCampaignConfigurations = ({
  siteAlias
}: {
  siteAlias: string;
}): UseQueryResult<
  GetCampaignConfigurationsReply.CampaignConfiguration.AsObject[],
  unknown
> => {
  return useQuery({
    queryKey: [STORED_FACEBOOK_CAMPAIGN_CONFIGURATIONS_KEY, siteAlias],
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled: !!siteAlias,
    queryFn: async (): Promise<
      Array<GetCampaignConfigurationsReply.CampaignConfiguration.AsObject>
    > => {
      const req = new GetCampaignConfigurationsRequest();
      req.setSiteAlias(siteAlias);
      req.setCampaignPlatform(CampaignPlatform.Option.FACEBOOK);
      req.setReturnStoredAmpdCampaignConfigurations(true);

      const ampdCampaigns: Array<GetCampaignConfigurationsReply.CampaignConfiguration> = [];
      await streamProcessor(
        GRPCWebCallbackClient.getCampaignConfigurations(req),
        (reply: GetCampaignConfigurationsReply) => {
          if (
            reply.getType() ===
            GetCampaignConfigurationsReply.CampaignConfigurationType.Option
              .STORED
          ) {
            ampdCampaigns.push(...reply.getCampaignConfigurationsList());
          }
        }
      );

      return ampdCampaigns.map(c => c.toObject());
    }
  });
};

export const useUpToDateFacebookCampaignConfigurations = ({
  siteAlias
}: {
  siteAlias: string;
}): UseQueryResult<
  GetCampaignConfigurationsReply.CampaignConfiguration.AsObject[],
  unknown
> => {
  return useQuery({
    queryKey: ["upToDateFacebookCampaignConfigurations", siteAlias],
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled: !!siteAlias,
    queryFn: async (): Promise<
      Array<GetCampaignConfigurationsReply.CampaignConfiguration.AsObject>
    > => {
      const req = new GetCampaignConfigurationsRequest();
      req.setSiteAlias(siteAlias);
      req.setCampaignPlatform(CampaignPlatform.Option.FACEBOOK);

      req.setReturnUpToDateAmpdCampaignConfigurations(true);
      req.setDoUpdateStoredAmpdCampaigns(true);

      const ampdCampaigns: Array<GetCampaignConfigurationsReply.CampaignConfiguration> = [];
      await streamProcessor(
        GRPCWebCallbackClient.getCampaignConfigurations(req),
        (reply: GetCampaignConfigurationsReply) => {
          if (
            reply.getType() ===
            GetCampaignConfigurationsReply.CampaignConfigurationType.Option
              .UP_TO_DATE
          ) {
            ampdCampaigns.push(...reply.getCampaignConfigurationsList());
          }
        }
      );

      return ampdCampaigns.map(c => c.toObject());
    }
  });
};
