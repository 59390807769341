// source: proto/ampdPb/googleAdsConfiguration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.AdConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.BiddingSource', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.BiddingSource.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.GeoTargetType', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.Label', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.ReviewStatus', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.Option', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsConfiguration.TextAsset', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsResourceStatus', null, global);
goog.exportSymbol('proto.ampdPb.GoogleAdsResourceStatus.Option', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.GoogleAdsConfiguration.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.displayName = 'proto.ampdPb.GoogleAdsConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.displayName = 'proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.displayName = 'proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.displayName = 'proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.AdConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.displayName = 'proto.ampdPb.GoogleAdsConfiguration.AdConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.displayName = 'proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.Label.displayName = 'proto.ampdPb.GoogleAdsConfiguration.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.displayName = 'proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.ReviewStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.displayName = 'proto.ampdPb.GoogleAdsConfiguration.ReviewStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.displayName = 'proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.displayName = 'proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.BiddingSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.BiddingSource.displayName = 'proto.ampdPb.GoogleAdsConfiguration.BiddingSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.GeoTargetType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.displayName = 'proto.ampdPb.GoogleAdsConfiguration.GeoTargetType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.TextAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.TextAsset.displayName = 'proto.ampdPb.GoogleAdsConfiguration.TextAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.displayName = 'proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.GoogleAdsResourceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.GoogleAdsResourceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.GoogleAdsResourceStatus.displayName = 'proto.ampdPb.GoogleAdsResourceStatus';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.GoogleAdsConfiguration.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    campaignConfiguration: (f = msg.getCampaignConfiguration()) && proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.toObject(includeInstance, f),
    budgetConfiguration: (f = msg.getBudgetConfiguration()) && proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.toObject(includeInstance, f),
    adGroupConfigurationsList: jspb.Message.toObjectList(msg.getAdGroupConfigurationsList(),
    proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration;
  return proto.ampdPb.GoogleAdsConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = new proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.deserializeBinaryFromReader);
      msg.setCampaignConfiguration(value);
      break;
    case 3:
      var value = new proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.deserializeBinaryFromReader);
      msg.setBudgetConfiguration(value);
      break;
    case 4:
      var value = new proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.deserializeBinaryFromReader);
      msg.addAdGroupConfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCampaignConfiguration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getBudgetConfiguration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.repeatedFields_ = [5,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    servingStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.ampdPb.GoogleAdsConfiguration.Label.toObject, includeInstance),
    startDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    biddingStrategy: jspb.Message.getFieldWithDefault(msg, 8, 0),
    maxCpcMicros: jspb.Message.getFieldWithDefault(msg, 9, 0),
    otherBiddingStrategy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    geoTargetLocationIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    geoTargetType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    negativeGeoTargetLocationIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    negativeKeywordConfigurationsList: jspb.Message.toObjectList(msg.getNegativeKeywordConfigurationsList(),
    proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration;
  return proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignName(value);
      break;
    case 3:
      var value = /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.Option} */ (reader.readEnum());
      msg.setServingStatus(value);
      break;
    case 5:
      var value = new proto.ampdPb.GoogleAdsConfiguration.Label;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 8:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.Option} */ (reader.readEnum());
      msg.setBiddingStrategy(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxCpcMicros(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherBiddingStrategy(value);
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGeoTargetLocationIds(values[i]);
      }
      break;
    case 14:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.Option} */ (reader.readEnum());
      msg.setGeoTargetType(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNegativeGeoTargetLocationIds(values[i]);
      }
      break;
    case 13:
      var value = new proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.deserializeBinaryFromReader);
      msg.addNegativeKeywordConfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCampaignName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getServingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ampdPb.GoogleAdsConfiguration.Label.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBiddingStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMaxCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getOtherBiddingStrategy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGeoTargetLocationIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getGeoTargetType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getNegativeGeoTargetLocationIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getNegativeKeywordConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string campaign_name = 2;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GoogleAdsResourceStatus.Option status = 3;
 * @return {!proto.ampdPb.GoogleAdsResourceStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsResourceStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CampaignServingStatus.Option serving_status = 4;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getServingStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setServingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated Label labels = 5;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.Label, 5));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ampdPb.GoogleAdsConfiguration.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string start_date = 6;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string end_date = 7;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional CampaignBiddingStrategy.Option bidding_strategy = 8;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getBiddingStrategy = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setBiddingStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 max_cpc_micros = 9;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getMaxCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setMaxCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string other_bidding_strategy = 10;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getOtherBiddingStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setOtherBiddingStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated int64 geo_target_location_ids = 11;
 * @return {!Array<number>}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getGeoTargetLocationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setGeoTargetLocationIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.addGeoTargetLocationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.clearGeoTargetLocationIdsList = function() {
  return this.setGeoTargetLocationIdsList([]);
};


/**
 * optional GeoTargetType.Option geo_target_type = 14;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getGeoTargetType = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.Option} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setGeoTargetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * repeated int64 negative_geo_target_location_ids = 12;
 * @return {!Array<number>}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getNegativeGeoTargetLocationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setNegativeGeoTargetLocationIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.addNegativeGeoTargetLocationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.clearNegativeGeoTargetLocationIdsList = function() {
  return this.setNegativeGeoTargetLocationIdsList([]);
};


/**
 * repeated KeywordConfiguration negative_keyword_configurations = 13;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.getNegativeKeywordConfigurationsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, 13));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.setNegativeKeywordConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.addNegativeKeywordConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration.prototype.clearNegativeKeywordConfigurationsList = function() {
  return this.setNegativeKeywordConfigurationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    budgetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    budgetName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    otherBudgetType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    otherBudgetPeriod: jspb.Message.getFieldWithDefault(msg, 5, ""),
    budgetAmountMicros: jspb.Message.getFieldWithDefault(msg, 6, 0),
    recommendedBudgetAmountMicros: jspb.Message.getFieldWithDefault(msg, 7, 0),
    explicitlyShared: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    campaignCount: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration;
  return proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBudgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBudgetName(value);
      break;
    case 3:
      var value = /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherBudgetType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherBudgetPeriod(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBudgetAmountMicros(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecommendedBudgetAmountMicros(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExplicitlyShared(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBudgetId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBudgetName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOtherBudgetType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOtherBudgetPeriod();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBudgetAmountMicros();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getRecommendedBudgetAmountMicros();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getExplicitlyShared();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCampaignCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional int64 budget_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getBudgetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setBudgetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string budget_name = 2;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getBudgetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setBudgetName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GoogleAdsResourceStatus.Option status = 3;
 * @return {!proto.ampdPb.GoogleAdsResourceStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsResourceStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string other_budget_type = 4;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getOtherBudgetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setOtherBudgetType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string other_budget_period = 5;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getOtherBudgetPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setOtherBudgetPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 budget_amount_micros = 6;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getBudgetAmountMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setBudgetAmountMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 recommended_budget_amount_micros = 7;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getRecommendedBudgetAmountMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setRecommendedBudgetAmountMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool explicitly_shared = 8;
 * @return {boolean}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getExplicitlyShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setExplicitlyShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 campaign_count = 9;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.getCampaignCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration.prototype.setCampaignCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adGroupName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.ampdPb.GoogleAdsConfiguration.Label.toObject, includeInstance),
    cpcBidMicros: jspb.Message.getFieldWithDefault(msg, 8, 0),
    effectiveCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 9, 0),
    adConfigurationsList: jspb.Message.toObjectList(msg.getAdConfigurationsList(),
    proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.toObject, includeInstance),
    keywordConfigurationsList: jspb.Message.toObjectList(msg.getKeywordConfigurationsList(),
    proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.toObject, includeInstance),
    negativeKeywordConfigurationsList: jspb.Message.toObjectList(msg.getNegativeKeywordConfigurationsList(),
    proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration;
  return proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupName(value);
      break;
    case 3:
      var value = /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.ampdPb.GoogleAdsConfiguration.Label;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidMicros(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveCpcBidMicros(value);
      break;
    case 5:
      var value = new proto.ampdPb.GoogleAdsConfiguration.AdConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.deserializeBinaryFromReader);
      msg.addAdConfigurations(value);
      break;
    case 6:
      var value = new proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.deserializeBinaryFromReader);
      msg.addKeywordConfigurations(value);
      break;
    case 7:
      var value = new proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.deserializeBinaryFromReader);
      msg.addNegativeKeywordConfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAdGroupName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ampdPb.GoogleAdsConfiguration.Label.serializeBinaryToWriter
    );
  }
  f = message.getCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEffectiveCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAdConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getKeywordConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getNegativeKeywordConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ad_group_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ad_group_name = 2;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getAdGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setAdGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GoogleAdsResourceStatus.Option status = 3;
 * @return {!proto.ampdPb.GoogleAdsResourceStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsResourceStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated Label labels = 4;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.Label, 4));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ampdPb.GoogleAdsConfiguration.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int64 cpc_bid_micros = 8;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 effective_cpc_bid_micros = 9;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getEffectiveCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setEffectiveCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated AdConfiguration ad_configurations = 5;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getAdConfigurationsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.AdConfiguration, 5));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setAdConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.addAdConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ampdPb.GoogleAdsConfiguration.AdConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.clearAdConfigurationsList = function() {
  return this.setAdConfigurationsList([]);
};


/**
 * repeated KeywordConfiguration keyword_configurations = 6;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getKeywordConfigurationsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, 6));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setKeywordConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.addKeywordConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.clearKeywordConfigurationsList = function() {
  return this.setKeywordConfigurationsList([]);
};


/**
 * repeated KeywordConfiguration negative_keyword_configurations = 7;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.getNegativeKeywordConfigurationsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, 7));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.setNegativeKeywordConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.addNegativeKeywordConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration.prototype.clearNegativeKeywordConfigurationsList = function() {
  return this.setNegativeKeywordConfigurationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.repeatedFields_ = [16,3,4,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reviewStatus: jspb.Message.getFieldWithDefault(msg, 14, 0),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 15, 0),
    policyMessagesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.ampdPb.GoogleAdsConfiguration.Label.toObject, includeInstance),
    finalUrlsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    finalUrlSuffix: jspb.Message.getFieldWithDefault(msg, 5, ""),
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displayUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    displayUrlPath1: jspb.Message.getFieldWithDefault(msg, 8, ""),
    displayUrlPath2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    proto.ampdPb.GoogleAdsConfiguration.TextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.ampdPb.GoogleAdsConfiguration.TextAsset.toObject, includeInstance),
    usesAmpdAttribution: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    advertiserIdStr: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.AdConfiguration;
  return proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.Option} */ (reader.readEnum());
      msg.setReviewStatus(value);
      break;
    case 15:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.Option} */ (reader.readEnum());
      msg.setApprovalStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addPolicyMessages(value);
      break;
    case 3:
      var value = new proto.ampdPb.GoogleAdsConfiguration.Label;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalUrls(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrlSuffix(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUrlPath1(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUrlPath2(value);
      break;
    case 10:
      var value = new proto.ampdPb.GoogleAdsConfiguration.TextAsset;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.TextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 11:
      var value = new proto.ampdPb.GoogleAdsConfiguration.TextAsset;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.TextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsesAmpdAttribution(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertiserIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReviewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getPolicyMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ampdPb.GoogleAdsConfiguration.Label.serializeBinaryToWriter
    );
  }
  f = message.getFinalUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getFinalUrlSuffix();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTrackingUrlTemplate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplayUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDisplayUrlPath1();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDisplayUrlPath2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ampdPb.GoogleAdsConfiguration.TextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.ampdPb.GoogleAdsConfiguration.TextAsset.serializeBinaryToWriter
    );
  }
  f = message.getUsesAmpdAttribution();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 ad_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getAdId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setAdId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GoogleAdsResourceStatus.Option status = 2;
 * @return {!proto.ampdPb.GoogleAdsResourceStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsResourceStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ReviewStatus.Option review_status = 14;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getReviewStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setReviewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional ApprovalStatus.Option approval_status = 15;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getApprovalStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated string policy_messages = 16;
 * @return {!Array<string>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getPolicyMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setPolicyMessagesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.addPolicyMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.clearPolicyMessagesList = function() {
  return this.setPolicyMessagesList([]);
};


/**
 * repeated Label labels = 3;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.Label, 3));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ampdPb.GoogleAdsConfiguration.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated string final_urls = 4;
 * @return {!Array<string>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getFinalUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setFinalUrlsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.addFinalUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.clearFinalUrlsList = function() {
  return this.setFinalUrlsList([]);
};


/**
 * optional string final_url_suffix = 5;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getFinalUrlSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setFinalUrlSuffix = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tracking_url_template = 6;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string display_url = 7;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getDisplayUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setDisplayUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string display_url_path1 = 8;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getDisplayUrlPath1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setDisplayUrlPath1 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string display_url_path2 = 9;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getDisplayUrlPath2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setDisplayUrlPath2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated TextAsset headlines = 10;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.TextAsset>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.TextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.TextAsset, 10));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.TextAsset>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.TextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.TextAsset}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ampdPb.GoogleAdsConfiguration.TextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated TextAsset descriptions = 11;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.TextAsset>}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.TextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.TextAsset, 11));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.TextAsset>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.TextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.TextAsset}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.ampdPb.GoogleAdsConfiguration.TextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional bool uses_ampd_attribution = 12;
 * @return {boolean}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getUsesAmpdAttribution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setUsesAmpdAttribution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string advertiser_id_str = 13;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.getAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.AdConfiguration.prototype.setAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.ampdPb.GoogleAdsConfiguration.Label.toObject, includeInstance),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    matchType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cpcBidMicros: jspb.Message.getFieldWithDefault(msg, 8, 0),
    effectiveCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 9, 0),
    effectiveCpcBidSource: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration;
  return proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriteriaId(value);
      break;
    case 2:
      var value = /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.ampdPb.GoogleAdsConfiguration.Label;
      reader.readMessage(value,proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.Option} */ (reader.readEnum());
      msg.setMatchType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidMicros(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveCpcBidMicros(value);
      break;
    case 10:
      var value = /** @type {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource.Option} */ (reader.readEnum());
      msg.setEffectiveCpcBidSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ampdPb.GoogleAdsConfiguration.Label.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMatchType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEffectiveCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEffectiveCpcBidSource();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional int64 criteria_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getCriteriaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GoogleAdsResourceStatus.Option status = 2;
 * @return {!proto.ampdPb.GoogleAdsResourceStatus.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.GoogleAdsResourceStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsResourceStatus.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated Label labels = 3;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.Label, 3));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.Label>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ampdPb.GoogleAdsConfiguration.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional KeywordMatchType.Option match_type = 5;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getMatchType = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setMatchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 cpc_bid_micros = 8;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 effective_cpc_bid_micros = 9;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getEffectiveCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setEffectiveCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional BiddingSource.Option effective_cpc_bid_source = 10;
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource.Option}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.getEffectiveCpcBidSource = function() {
  return /** @type {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource.Option} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource.Option} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordConfiguration.prototype.setEffectiveCpcBidSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label}
 */
proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.Label;
  return proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label}
 */
proto.ampdPb.GoogleAdsConfiguration.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLabelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 label_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.Label.prototype.getLabelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.Label.prototype.setLabelId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.Label.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.Label} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.Label.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus;
  return proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignServingStatus.Option = {
  SERVING: 0,
  NONE: 1,
  ENDED: 2,
  PENDING: 3,
  SUSPENDED: 4,
  UNKNOWN: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus}
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.ReviewStatus;
  return proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus}
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ReviewStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.ReviewStatus.Option = {
  REVIEWED: 0,
  REVIEW_IN_PROGRESS: 1,
  UNDER_APPEAL: 2,
  ELIGIBLE_MAY_SERVE: 3,
  UNKNOWN: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus}
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus;
  return proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus}
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.ApprovalStatus.Option = {
  APPROVED: 0,
  DISAPPROVED: 1,
  APPROVED_LIMITED: 2,
  AREA_OF_INTEREST_ONLY: 3,
  UNKNOWN: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy;
  return proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.CampaignBiddingStrategy.Option = {
  TARGET_SPEND: 0,
  MAXIMIZE_CONVERSIONS: 1,
  MANUAL_CPC: 2,
  MANUAL_CPC_ENHANCED_BID: 3,
  UNKNOWN: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.BiddingSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource}
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.BiddingSource;
  return proto.ampdPb.GoogleAdsConfiguration.BiddingSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource}
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.BiddingSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.BiddingSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.BiddingSource.Option = {
  CAMPAIGN_BIDDING_STRATEGY: 0,
  AD_GROUP: 1,
  CRITERIA: 2,
  UNKNOWN: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType}
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.GeoTargetType;
  return proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType}
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.GeoTargetType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.GeoTargetType.Option = {
  PRESENCE: 0,
  SEARCH_INTEREST: 1,
  PRESENCE_OR_INTEREST: 2,
  UNKNOWN: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.TextAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.TextAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pinPosition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.TextAsset}
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.TextAsset;
  return proto.ampdPb.GoogleAdsConfiguration.TextAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.TextAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.TextAsset}
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPinPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.TextAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.TextAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPinPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.TextAsset} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 pin_position = 2;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.prototype.getPinPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration.TextAsset} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.TextAsset.prototype.setPinPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType;
  return proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsConfiguration.KeywordMatchType.Option = {
  EXACT: 0,
  PHRASE: 1,
  BROAD: 2,
  UNKNOWN: 3
};

/**
 * optional int64 customer_id = 1;
 * @return {number}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional CampaignConfiguration campaign_configuration = 2;
 * @return {?proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.getCampaignConfiguration = function() {
  return /** @type{?proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration, 2));
};


/**
 * @param {?proto.ampdPb.GoogleAdsConfiguration.CampaignConfiguration|undefined} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.prototype.setCampaignConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.clearCampaignConfiguration = function() {
  return this.setCampaignConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.hasCampaignConfiguration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BudgetConfiguration budget_configuration = 3;
 * @return {?proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.getBudgetConfiguration = function() {
  return /** @type{?proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration, 3));
};


/**
 * @param {?proto.ampdPb.GoogleAdsConfiguration.BudgetConfiguration|undefined} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.prototype.setBudgetConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.clearBudgetConfiguration = function() {
  return this.setBudgetConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.hasBudgetConfiguration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated AdGroupConfiguration ad_group_configurations = 4;
 * @return {!Array<!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration>}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.getAdGroupConfigurationsList = function() {
  return /** @type{!Array<!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration, 4));
};


/**
 * @param {!Array<!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration>} value
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
*/
proto.ampdPb.GoogleAdsConfiguration.prototype.setAdGroupConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration}
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.addAdGroupConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ampdPb.GoogleAdsConfiguration.AdGroupConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.GoogleAdsConfiguration} returns this
 */
proto.ampdPb.GoogleAdsConfiguration.prototype.clearAdGroupConfigurationsList = function() {
  return this.setAdGroupConfigurationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.GoogleAdsResourceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.GoogleAdsResourceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.GoogleAdsResourceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsResourceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.GoogleAdsResourceStatus}
 */
proto.ampdPb.GoogleAdsResourceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.GoogleAdsResourceStatus;
  return proto.ampdPb.GoogleAdsResourceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.GoogleAdsResourceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.GoogleAdsResourceStatus}
 */
proto.ampdPb.GoogleAdsResourceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.GoogleAdsResourceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.GoogleAdsResourceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.GoogleAdsResourceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.GoogleAdsResourceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.GoogleAdsResourceStatus.Option = {
  ENABLED: 0,
  PAUSED: 1,
  REMOVED: 2,
  UNKNOWN: 3
};

goog.object.extend(exports, proto.ampdPb);
