// source: google/ads/googleads/v18/resources/feed.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_enums_affiliate_location_feed_relationship_type_pb = require('../../../../../google/ads/googleads/v18/enums/affiliate_location_feed_relationship_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_affiliate_location_feed_relationship_type_pb);
var google_ads_googleads_v18_enums_feed_attribute_type_pb = require('../../../../../google/ads/googleads/v18/enums/feed_attribute_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_feed_attribute_type_pb);
var google_ads_googleads_v18_enums_feed_origin_pb = require('../../../../../google/ads/googleads/v18/enums/feed_origin_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_feed_origin_pb);
var google_ads_googleads_v18_enums_feed_status_pb = require('../../../../../google/ads/googleads/v18/enums/feed_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_feed_status_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Feed', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Feed.SystemFeedGenerationDataCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.FeedAttribute', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.FeedAttributeOperation', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.FeedAttributeOperation.Operator', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.Feed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.Feed.repeatedFields_, proto.google.ads.googleads.v18.resources.Feed.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.resources.Feed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.Feed.displayName = 'proto.google.ads.googleads.v18.resources.Feed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.displayName = 'proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.displayName = 'proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.displayName = 'proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.FeedAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.FeedAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.FeedAttribute.displayName = 'proto.google.ads.googleads.v18.resources.FeedAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.FeedAttributeOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.FeedAttributeOperation.displayName = 'proto.google.ads.googleads.v18.resources.FeedAttributeOperation';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Feed.repeatedFields_ = [4,9];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Feed.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.Feed.SystemFeedGenerationDataCase = {
  SYSTEM_FEED_GENERATION_DATA_NOT_SET: 0,
  PLACES_LOCATION_FEED_DATA: 6,
  AFFILIATE_LOCATION_FEED_DATA: 7
};

/**
 * @return {proto.google.ads.googleads.v18.resources.Feed.SystemFeedGenerationDataCase}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getSystemFeedGenerationDataCase = function() {
  return /** @type {proto.google.ads.googleads.v18.resources.Feed.SystemFeedGenerationDataCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.resources.Feed.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.Feed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.Feed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 11, 0),
    name: jspb.Message.getFieldWithDefault(msg, 12, ""),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.google.ads.googleads.v18.resources.FeedAttribute.toObject, includeInstance),
    attributeOperationsList: jspb.Message.toObjectList(msg.getAttributeOperationsList(),
    proto.google.ads.googleads.v18.resources.FeedAttributeOperation.toObject, includeInstance),
    origin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    placesLocationFeedData: (f = msg.getPlacesLocationFeedData()) && proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.toObject(includeInstance, f),
    affiliateLocationFeedData: (f = msg.getAffiliateLocationFeedData()) && proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.Feed}
 */
proto.google.ads.googleads.v18.resources.Feed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.Feed;
  return proto.google.ads.googleads.v18.resources.Feed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.Feed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.Feed}
 */
proto.google.ads.googleads.v18.resources.Feed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.resources.FeedAttribute;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.FeedAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 9:
      var value = new proto.google.ads.googleads.v18.resources.FeedAttributeOperation;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.FeedAttributeOperation.deserializeBinaryFromReader);
      msg.addAttributeOperations(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.FeedOriginEnum.FeedOrigin} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 8:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.FeedStatusEnum.FeedStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.deserializeBinaryFromReader);
      msg.setPlacesLocationFeedData(value);
      break;
    case 7:
      var value = new proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.deserializeBinaryFromReader);
      msg.setAffiliateLocationFeedData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.Feed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.Feed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.google.ads.googleads.v18.resources.FeedAttribute.serializeBinaryToWriter
    );
  }
  f = message.getAttributeOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.google.ads.googleads.v18.resources.FeedAttributeOperation.serializeBinaryToWriter
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPlacesLocationFeedData();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.serializeBinaryToWriter
    );
  }
  f = message.getAffiliateLocationFeedData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.toObject = function(includeInstance, msg) {
  var f, obj = {
    oauthInfo: (f = msg.getOauthInfo()) && proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.toObject(includeInstance, f),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessAccountId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    businessNameFilter: jspb.Message.getFieldWithDefault(msg, 9, ""),
    categoryFiltersList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    labelFiltersList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData;
  return proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.deserializeBinaryFromReader);
      msg.setOauthInfo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessAccountId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessNameFilter(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryFilters(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabelFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOauthInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessAccountId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCategoryFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getLabelFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    httpMethod: jspb.Message.getFieldWithDefault(msg, 4, ""),
    httpRequestUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    httpAuthorizationHeader: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo;
  return proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHttpMethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHttpRequestUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHttpAuthorizationHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string http_method = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.getHttpMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.setHttpMethod = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.clearHttpMethod = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.hasHttpMethod = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string http_request_url = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.getHttpRequestUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.setHttpRequestUrl = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.clearHttpRequestUrl = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.hasHttpRequestUrl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string http_authorization_header = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.getHttpAuthorizationHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.setHttpAuthorizationHeader = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.clearHttpAuthorizationHeader = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo.prototype.hasHttpAuthorizationHeader = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OAuthInfo oauth_info = 1;
 * @return {?proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.getOauthInfo = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.OAuthInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
*/
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.setOauthInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.clearOauthInfo = function() {
  return this.setOauthInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.hasOauthInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string email_address = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.setEmailAddress = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.clearEmailAddress = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.hasEmailAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string business_account_id = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.getBusinessAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.setBusinessAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string business_name_filter = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.getBusinessNameFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.setBusinessNameFilter = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.clearBusinessNameFilter = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.hasBusinessNameFilter = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string category_filters = 11;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.getCategoryFiltersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.setCategoryFiltersList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.addCategoryFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.clearCategoryFiltersList = function() {
  return this.setCategoryFiltersList([]);
};


/**
 * repeated string label_filters = 12;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.getLabelFiltersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.setLabelFiltersList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.addLabelFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData.prototype.clearLabelFiltersList = function() {
  return this.setLabelFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    relationshipType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData}
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData;
  return proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData}
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChainIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AffiliateLocationFeedRelationshipTypeEnum.AffiliateLocationFeedRelationshipType} */ (reader.readEnum());
      msg.setRelationshipType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getRelationshipType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated int64 chain_ids = 3;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.getChainIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.setChainIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.addChainIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.clearChainIdsList = function() {
  return this.setChainIdsList([]);
};


/**
 * optional google.ads.googleads.v18.enums.AffiliateLocationFeedRelationshipTypeEnum.AffiliateLocationFeedRelationshipType relationship_type = 2;
 * @return {!proto.google.ads.googleads.v18.enums.AffiliateLocationFeedRelationshipTypeEnum.AffiliateLocationFeedRelationshipType}
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.getRelationshipType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AffiliateLocationFeedRelationshipTypeEnum.AffiliateLocationFeedRelationshipType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AffiliateLocationFeedRelationshipTypeEnum.AffiliateLocationFeedRelationshipType} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData.prototype.setRelationshipType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 11;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.setId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.clearId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.hasId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string name = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.setName = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.clearName = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.hasName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated FeedAttribute attributes = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.resources.FeedAttribute>}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.resources.FeedAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.resources.FeedAttribute, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.resources.FeedAttribute>} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
*/
proto.google.ads.googleads.v18.resources.Feed.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.resources.FeedAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * repeated FeedAttributeOperation attribute_operations = 9;
 * @return {!Array<!proto.google.ads.googleads.v18.resources.FeedAttributeOperation>}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getAttributeOperationsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.resources.FeedAttributeOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.resources.FeedAttributeOperation, 9));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.resources.FeedAttributeOperation>} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
*/
proto.google.ads.googleads.v18.resources.Feed.prototype.setAttributeOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.addAttributeOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.google.ads.googleads.v18.resources.FeedAttributeOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.clearAttributeOperationsList = function() {
  return this.setAttributeOperationsList([]);
};


/**
 * optional google.ads.googleads.v18.enums.FeedOriginEnum.FeedOrigin origin = 5;
 * @return {!proto.google.ads.googleads.v18.enums.FeedOriginEnum.FeedOrigin}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getOrigin = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.FeedOriginEnum.FeedOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.FeedOriginEnum.FeedOrigin} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.ads.googleads.v18.enums.FeedStatusEnum.FeedStatus status = 8;
 * @return {!proto.google.ads.googleads.v18.enums.FeedStatusEnum.FeedStatus}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.FeedStatusEnum.FeedStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.FeedStatusEnum.FeedStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional PlacesLocationFeedData places_location_feed_data = 6;
 * @return {?proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getPlacesLocationFeedData = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.Feed.PlacesLocationFeedData|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
*/
proto.google.ads.googleads.v18.resources.Feed.prototype.setPlacesLocationFeedData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.resources.Feed.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.clearPlacesLocationFeedData = function() {
  return this.setPlacesLocationFeedData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.hasPlacesLocationFeedData = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AffiliateLocationFeedData affiliate_location_feed_data = 7;
 * @return {?proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.getAffiliateLocationFeedData = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.Feed.AffiliateLocationFeedData|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
*/
proto.google.ads.googleads.v18.resources.Feed.prototype.setAffiliateLocationFeedData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.google.ads.googleads.v18.resources.Feed.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Feed} returns this
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.clearAffiliateLocationFeedData = function() {
  return this.setAffiliateLocationFeedData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Feed.prototype.hasAffiliateLocationFeedData = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.FeedAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isPartOfKey: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.FeedAttribute;
  return proto.google.ads.googleads.v18.resources.FeedAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.FeedAttributeTypeEnum.FeedAttributeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPartOfKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.FeedAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 5;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.setId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.clearId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.hasId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.setName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.clearName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.hasName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.enums.FeedAttributeTypeEnum.FeedAttributeType type = 3;
 * @return {!proto.google.ads.googleads.v18.enums.FeedAttributeTypeEnum.FeedAttributeType}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.FeedAttributeTypeEnum.FeedAttributeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.FeedAttributeTypeEnum.FeedAttributeType} value
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_part_of_key = 7;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.getIsPartOfKey = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.setIsPartOfKey = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttribute} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.clearIsPartOfKey = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.FeedAttribute.prototype.hasIsPartOfKey = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.FeedAttributeOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    operator: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: (f = msg.getValue()) && proto.google.ads.googleads.v18.resources.FeedAttribute.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.FeedAttributeOperation;
  return proto.google.ads.googleads.v18.resources.FeedAttributeOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation.Operator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.resources.FeedAttribute;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.FeedAttribute.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.FeedAttributeOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.resources.FeedAttribute.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.Operator = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ADD: 2
};

/**
 * optional Operator operator = 1;
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation.Operator}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.getOperator = function() {
  return /** @type {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation.Operator} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation.Operator} value
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional FeedAttribute value = 2;
 * @return {?proto.google.ads.googleads.v18.resources.FeedAttribute}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.getValue = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.FeedAttribute} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.FeedAttribute, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.FeedAttribute|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation} returns this
*/
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.FeedAttributeOperation} returns this
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.FeedAttributeOperation.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
