import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { groupBy } from "lodash";
import { useMemo } from "react";

import { Facebook } from "Common/proto/common/facebook_pb";
import {
  GetFacebookMarketingResourcesReply,
  GetFacebookMarketingResourcesRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Facebook_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { Retailer } from "Common/proto/common/retailer_pb";
import {
  adTrackingStatus,
  CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS
} from "Common/utils/facebook";

export const STORED_FACEBOOK_MARKETING_RESOURCES_KEY =
  "storedFacebookMarketingResources";

export const getFacebookMarketingQueryKey = (
  siteAlias: string,
  fields: string[],
  adAccountId = "",
  campaignIds: string[] = [],
  adSetIds: string[] = [],
  adIds: string[] = []
): Array<string | Array<string>> => [
  STORED_FACEBOOK_MARKETING_RESOURCES_KEY,
  siteAlias,
  fields,
  adAccountId,
  campaignIds,
  adSetIds,
  adIds
];

export const useFacebookMarketingResources = ({
  enabled,
  fields,
  adAccountId = "",
  campaignIds = [],
  adSetIds = [],
  adIds = []
}: {
  enabled: boolean;
  fields: string[];
  adAccountId?: string;
  campaignIds?: string[];
  adSetIds?: string[];
  adIds?: string[];
}): UseQueryResult<GetFacebookMarketingResourcesReply.AsObject, unknown> => {
  const siteAlias = getSiteAliasFromURL("/t");

  return useQuery({
    queryKey: getFacebookMarketingQueryKey(
      siteAlias,
      fields,
      adAccountId,
      campaignIds,
      adSetIds,
      adIds
    ),
    staleTime: 15 * 60 * 1_000, // 15 minutes
    enabled: enabled && !!siteAlias,
    queryFn: async () => {
      const req = new GetFacebookMarketingResourcesRequest();
      req.setSiteAlias(siteAlias);

      if (adAccountId) {
        req.setAdAccountId(adAccountId);
      }

      if (campaignIds.length) {
        req.setCampaignIdsList(campaignIds);
      }

      if (adSetIds.length) {
        req.setAdSetIdsList(adSetIds);
      }

      if (adIds.length) {
        req.setAdIdsList(adIds);
      }

      req.setFieldsList(fields);

      const reply = await GRPCWebClient.getFacebookMarketingResources(req, {});
      return reply.toObject();
    }
  });
};

export type FacebookAd = Facebook.API.Ad.AsObject & {
  retailer: Retailer.Option;
  hasTracking: boolean;
};

export type FacebookAdSet = Facebook.API.AdSet.AsObject & {
  ads: Array<FacebookAd>;
};

export type FacebookCampaign = Facebook.API.Campaign.AsObject & {
  adSets: Array<FacebookAdSet>;
  ads: Array<FacebookAd>;
};

export type FacebookMarketingResources = Record<
  string, // campaignId
  FacebookCampaign
>;

// GRPCWebClient.getFacebookMarketingResources returns 4 flat lists
// of resources: adAccounts, campaigns, adSets, amd ads. Its often
// helpful work with the resources in a nested format. adAccounts is
// omitted because it is not generally useful.
export const useNestedFacebookMarketingResources = (): {
  data: FacebookMarketingResources | undefined;
  isLoading: boolean;
  isFetching: boolean;
  error: unknown;
} => {
  const marketingResourcesResponse = useFacebookMarketingResources({
    enabled: true,
    fields: CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS
  });

  const nestedResources = useMemo(() => {
    if (!marketingResourcesResponse.data) {
      return;
    }

    // sort alphabetically by name
    const ads = marketingResourcesResponse.data.adsList
      .map(ad => apiAdToFacebookAd(ad))
      .sort((a, b) => a.name.localeCompare(b.name));

    const adSetsByCampaignId = groupBy(
      marketingResourcesResponse.data.adSetsList,
      (adSet: Facebook.API.AdSet.AsObject) => adSet.campaignId
    );

    const adsByAdSetId = groupBy(
      ads,
      (ad: Facebook.API.Ad.AsObject) => ad.adSetId
    );

    const adsByCampaignId = groupBy(
      ads,
      (ad: Facebook.API.Ad.AsObject) => ad.campaignId
    );

    const campaignsById: FacebookMarketingResources = {};
    for (const campaign of marketingResourcesResponse.data.campaignsList) {
      campaignsById[campaign.id] = {
        ...campaign,
        adSets:
          adSetsByCampaignId[campaign.id]?.map(adSet => ({
            ...adSet,
            ads: adsByAdSetId[adSet.id] || []
          })) || [],
        ads: adsByCampaignId[campaign.id] || []
      };
    }
    return campaignsById;
  }, [marketingResourcesResponse]);

  return {
    ...marketingResourcesResponse,
    data: nestedResources
  };
};

// Takes an API Facebook.API.Ad and processes it to get some derived
// data about the ad's tracking status that is helpful for the UI.
function apiAdToFacebookAd(ad: Facebook.API.Ad.AsObject): FacebookAd {
  const {
    isAmazonAd,
    isWalmartAd,
    hasAmazonAttributionTags,
    hasWalmartAttributionTags
  } = adTrackingStatus(ad);

  let retailer = Retailer.Option.UNKNOWN;
  let hasTracking = false;
  if (isAmazonAd) {
    retailer = Retailer.Option.AMAZON;
    hasTracking = hasAmazonAttributionTags;
  } else if (isWalmartAd) {
    retailer = Retailer.Option.WALMART;
    hasTracking = hasWalmartAttributionTags;
  }

  return {
    ...ad,
    retailer,
    hasTracking
  };
}
