import { titleCase } from "change-case";
import { Amazon } from "Common/proto/common/amazon_pb";
import {
  AMPD_ATTRIBUTION_SANDBOX_PROFILE_ID,
  AttributionAdvertiser,
  getAccountTypeByEnum,
  getMarketplaceTypeByEnum,
  getRelevantAttributionAdvertisers
} from "Common/utils/amazon";
import { useWantsProOperatorFeatures } from "Common/utils/featureFlags";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import React, { useMemo, useState } from "react";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { uniqWith, isEqual } from "lodash";
import { None } from "Common/utils/tsUtils";

export function AmazonAttributionAdvertiserSelector({
  onChange,
  onBlur,
  initialSelectedAttributionAdvertiser,
  marketplace
}: {
  onChange?: (changedTo: AttributionAdvertiser | None) => void;
  onBlur?: () => void;
  initialSelectedAttributionAdvertiser?: AttributionAdvertiser | None;
  marketplace?: Amazon.Marketplace.Option;
}): JSX.Element {
  const {
    amazonInfo: { advertisingProfiles }
  } = useSessionSite();

  const isAmpdOperator = useWantsProOperatorFeatures();

  const [
    selectedAttributionAdvertiser,
    setSelectedAttributionAdvertiser
  ] = useState<AttributionAdvertiser | None>(
    initialSelectedAttributionAdvertiser || undefined
  );

  const [
    relevantAttributionAdvertisers,
    attributionProfileDropdownOptions
  ] = useMemo(() => {
    const relevantAttributionAdvertisers = getRelevantAttributionAdvertisers(
      advertisingProfiles,
      marketplace || Amazon.Marketplace.Option.UNKNOWN,
      isAmpdOperator // Include dummy Advertiser objects for internal users
    );

    const dropdownOptions: Array<DropdownItemProps> = getAttributionDropdownOptions(
      relevantAttributionAdvertisers
    );

    dropdownOptions.unshift({
      key: "NONE",
      value: undefined,
      text: "",
      description: ""
    });

    // If there is no selected advertiser, or the selected advertiser is not found
    // among the relevant advertisers, just select the first and notify the callback.
    if (
      selectedAttributionAdvertiser &&
      !relevantAttributionAdvertisers
        .map(advertiser => advertiser.advertiserIdStr)
        .includes(selectedAttributionAdvertiser?.advertiserIdStr)
    ) {
      setSelectedAttributionAdvertiser(undefined);
    }

    return [relevantAttributionAdvertisers, dropdownOptions];
  }, [
    isAmpdOperator,
    advertisingProfiles,
    marketplace,
    selectedAttributionAdvertiser
  ]);

  const handleChange = (
    _event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    const selected = relevantAttributionAdvertisers.find(
      advertiser => dropdownDataKey(advertiser) === data.value
    );

    setSelectedAttributionAdvertiser(selected);

    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <Dropdown
      search
      selection
      fluid
      onChange={handleChange}
      onBlur={onBlur}
      value={dropdownDataKey(selectedAttributionAdvertiser)}
      options={attributionProfileDropdownOptions}
      placeholder="Select an Amazon advertising profile"
    />
  );
}

const getAttributionDropdownOptions = (
  attributionAdvertisers: Array<AttributionAdvertiser>
): Array<{ key: string; value: string; text: string; description: string }> => {
  const attributionOptions = uniqWith(attributionAdvertisers, isEqual)
    .filter(
      advertiser =>
        (!advertiser.isDeprecated &&
          advertiser.profileAccountType !==
            Amazon.AdvertisingProfile.AccountType.Option.AGENCY) ||
        advertiser.profileIdStr === AMPD_ATTRIBUTION_SANDBOX_PROFILE_ID
    )
    .map(getAttributionDropdownOption);

  return attributionOptions.sort((a, b) => a.text.localeCompare(b.text));
};

const getAttributionDropdownOption = (
  advertiser: AttributionAdvertiser
): {
  key: string;
  value: string;
  text: string;
  description: string;
  marketplace: Amazon.Marketplace.Option;
} => {
  // Show both AdvertiserName & Profile info, but if they're the same
  // just show the profile info.
  let title = advertiser.advertiserName || advertiser.profileName;
  if (title !== advertiser.profileName) {
    title = `${advertiser.advertiserName} (Profile: ${advertiser.profileName} [${advertiser.profileIdStr}])`;
  } else {
    title = `${title} [${advertiser.profileIdStr}]`;
  }

  const accountType = titleCase(
    getAccountTypeByEnum(advertiser.profileAccountType) || ""
  );
  const marketplace = titleCase(
    getMarketplaceTypeByEnum(advertiser.marketplace) || ""
  );

  let description =
    advertiser.profileAccountType ===
    Amazon.AdvertisingProfile.AccountType.Option.UNSPECIFIED
      ? marketplace
      : `${accountType}, ${marketplace}`;

  if (
    advertiser.profileAccountType ===
    Amazon.AdvertisingProfile.AccountType.Option.SELLER
  ) {
    description = `★ ${description}`;
  }

  return {
    key: dropdownDataKey(advertiser),
    value: dropdownDataKey(advertiser),
    marketplace: advertiser.marketplace,
    text: title,
    description
  };
};

const dropdownDataKey = (
  attributionAdvertiser: AttributionAdvertiser | None
): string => {
  if (!attributionAdvertiser) {
    return "";
  }
  return `${attributionAdvertiser.marketplace}-${attributionAdvertiser.advertiserIdStr}`;
};
