// source: google/ads/googleads/v18/common/policy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_enums_policy_topic_entry_type_pb = require('../../../../../google/ads/googleads/v18/enums/policy_topic_entry_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_topic_entry_type_pb);
var google_ads_googleads_v18_enums_policy_topic_evidence_destination_mismatch_url_type_pb = require('../../../../../google/ads/googleads/v18/enums/policy_topic_evidence_destination_mismatch_url_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_topic_evidence_destination_mismatch_url_type_pb);
var google_ads_googleads_v18_enums_policy_topic_evidence_destination_not_working_device_pb = require('../../../../../google/ads/googleads/v18/enums/policy_topic_evidence_destination_not_working_device_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_topic_evidence_destination_not_working_device_pb);
var google_ads_googleads_v18_enums_policy_topic_evidence_destination_not_working_dns_error_type_pb = require('../../../../../google/ads/googleads/v18/enums/policy_topic_evidence_destination_not_working_dns_error_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_topic_evidence_destination_not_working_dns_error_type_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicConstraint', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ValueCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEntry', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.ReasonCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.ValueCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyValidationParameter', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PolicyViolationKey', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyViolationKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyViolationKey.displayName = 'proto.google.ads.googleads.v18.common.PolicyViolationKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyValidationParameter.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyValidationParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyValidationParameter.displayName = 'proto.google.ads.googleads.v18.common.PolicyValidationParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyTopicEntry.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEntry.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEvidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEvidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicConstraint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.displayName = 'proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyViolationKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyViolationKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    violatingText: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyViolationKey;
  return proto.google.ads.googleads.v18.common.PolicyViolationKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyViolationKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setViolatingText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyViolationKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyViolationKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string policy_name = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.getPolicyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey} returns this
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.setPolicyName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey} returns this
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.clearPolicyName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.hasPolicyName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string violating_text = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.getViolatingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey} returns this
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.setViolatingText = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey} returns this
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.clearViolatingText = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyViolationKey.prototype.hasViolatingText = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.repeatedFields_ = [3,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyValidationParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignorablePolicyTopicsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    exemptPolicyViolationKeysList: jspb.Message.toObjectList(msg.getExemptPolicyViolationKeysList(),
    proto.google.ads.googleads.v18.common.PolicyViolationKey.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyValidationParameter;
  return proto.google.ads.googleads.v18.common.PolicyValidationParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIgnorablePolicyTopics(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.PolicyViolationKey;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyViolationKey.deserializeBinaryFromReader);
      msg.addExemptPolicyViolationKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyValidationParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIgnorablePolicyTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getExemptPolicyViolationKeysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.PolicyViolationKey.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string ignorable_policy_topics = 3;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.getIgnorablePolicyTopicsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} returns this
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.setIgnorablePolicyTopicsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} returns this
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.addIgnorablePolicyTopics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} returns this
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.clearIgnorablePolicyTopicsList = function() {
  return this.setIgnorablePolicyTopicsList([]);
};


/**
 * repeated PolicyViolationKey exempt_policy_violation_keys = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PolicyViolationKey>}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.getExemptPolicyViolationKeysList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PolicyViolationKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.PolicyViolationKey, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PolicyViolationKey>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} returns this
*/
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.setExemptPolicyViolationKeysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PolicyViolationKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyViolationKey}
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.addExemptPolicyViolationKeys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.PolicyViolationKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyValidationParameter} returns this
 */
proto.google.ads.googleads.v18.common.PolicyValidationParameter.prototype.clearExemptPolicyViolationKeysList = function() {
  return this.setExemptPolicyViolationKeysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    evidencesList: jspb.Message.toObjectList(msg.getEvidencesList(),
    proto.google.ads.googleads.v18.common.PolicyTopicEvidence.toObject, includeInstance),
    constraintsList: jspb.Message.toObjectList(msg.getConstraintsList(),
    proto.google.ads.googleads.v18.common.PolicyTopicConstraint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEntry;
  return proto.google.ads.googleads.v18.common.PolicyTopicEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEntryTypeEnum.PolicyTopicEntryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicEvidence.deserializeBinaryFromReader);
      msg.addEvidences(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicConstraint.deserializeBinaryFromReader);
      msg.addConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEvidencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicEvidence.serializeBinaryToWriter
    );
  }
  f = message.getConstraintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string topic = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.setTopic = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.clearTopic = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.ads.googleads.v18.enums.PolicyTopicEntryTypeEnum.PolicyTopicEntryType type = 2;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyTopicEntryTypeEnum.PolicyTopicEntryType}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEntryTypeEnum.PolicyTopicEntryType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyTopicEntryTypeEnum.PolicyTopicEntryType} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated PolicyTopicEvidence evidences = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEvidence>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.getEvidencesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEvidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEvidence>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.setEvidencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.addEvidences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.PolicyTopicEvidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.clearEvidencesList = function() {
  return this.setEvidencesList([]);
};


/**
 * repeated PolicyTopicConstraint constraints = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicConstraint>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.getConstraintsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PolicyTopicConstraint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicConstraint>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.setConstraintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.addConstraints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.PolicyTopicConstraint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEntry.prototype.clearConstraintsList = function() {
  return this.setConstraintsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_ = [[3,4,9,6,7,8]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.ValueCase = {
  VALUE_NOT_SET: 0,
  WEBSITE_LIST: 3,
  TEXT_LIST: 4,
  LANGUAGE_CODE: 9,
  DESTINATION_TEXT_LIST: 6,
  DESTINATION_MISMATCH: 7,
  DESTINATION_NOT_WORKING: 8
};

/**
 * @return {proto.google.ads.googleads.v18.common.PolicyTopicEvidence.ValueCase}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getValueCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.PolicyTopicEvidence.ValueCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteList: (f = msg.getWebsiteList()) && proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.toObject(includeInstance, f),
    textList: (f = msg.getTextList()) && proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.toObject(includeInstance, f),
    languageCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    destinationTextList: (f = msg.getDestinationTextList()) && proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.toObject(includeInstance, f),
    destinationMismatch: (f = msg.getDestinationMismatch()) && proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.toObject(includeInstance, f),
    destinationNotWorking: (f = msg.getDestinationNotWorking()) && proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence;
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.deserializeBinaryFromReader);
      msg.setWebsiteList(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.deserializeBinaryFromReader);
      msg.setTextList(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 6:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.deserializeBinaryFromReader);
      msg.setDestinationTextList(value);
      break;
    case 7:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.deserializeBinaryFromReader);
      msg.setDestinationMismatch(value);
      break;
    case 8:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.deserializeBinaryFromReader);
      msg.setDestinationNotWorking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.serializeBinaryToWriter
    );
  }
  f = message.getTextList();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDestinationTextList();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.serializeBinaryToWriter
    );
  }
  f = message.getDestinationMismatch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.serializeBinaryToWriter
    );
  }
  f = message.getDestinationNotWorking();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.toObject = function(includeInstance, msg) {
  var f, obj = {
    textsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList;
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addTexts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string texts = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.prototype.getTextsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.prototype.setTextsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.prototype.addTexts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList.prototype.clearTextsList = function() {
  return this.setTextsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.toObject = function(includeInstance, msg) {
  var f, obj = {
    websitesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList;
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addWebsites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string websites = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.prototype.getWebsitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.prototype.setWebsitesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.prototype.addWebsites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList.prototype.clearWebsitesList = function() {
  return this.setWebsitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationTextsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList;
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationTexts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationTextsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string destination_texts = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.prototype.getDestinationTextsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.prototype.setDestinationTextsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.prototype.addDestinationTexts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList.prototype.clearDestinationTextsList = function() {
  return this.setDestinationTextsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch;
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationMismatchUrlTypeEnum.PolicyTopicEvidenceDestinationMismatchUrlType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUrlTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationMismatchUrlTypeEnum.PolicyTopicEvidenceDestinationMismatchUrlType url_types = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationMismatchUrlTypeEnum.PolicyTopicEvidenceDestinationMismatchUrlType>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.prototype.getUrlTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationMismatchUrlTypeEnum.PolicyTopicEvidenceDestinationMismatchUrlType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationMismatchUrlTypeEnum.PolicyTopicEvidenceDestinationMismatchUrlType>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.prototype.setUrlTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationMismatchUrlTypeEnum.PolicyTopicEvidenceDestinationMismatchUrlType} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.prototype.addUrlTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch.prototype.clearUrlTypesList = function() {
  return this.setUrlTypesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_ = [[1,6]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.ReasonCase = {
  REASON_NOT_SET: 0,
  DNS_ERROR_TYPE: 1,
  HTTP_ERROR_CODE: 6
};

/**
 * @return {proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.ReasonCase}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.getReasonCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.ReasonCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.toObject = function(includeInstance, msg) {
  var f, obj = {
    expandedUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    device: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastCheckedDateTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dnsErrorType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    httpErrorCode: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking;
  return proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpandedUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDeviceEnum.PolicyTopicEvidenceDestinationNotWorkingDevice} */ (reader.readEnum());
      msg.setDevice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastCheckedDateTime(value);
      break;
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDnsErrorTypeEnum.PolicyTopicEvidenceDestinationNotWorkingDnsErrorType} */ (reader.readEnum());
      msg.setDnsErrorType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHttpErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDnsErrorTypeEnum.PolicyTopicEvidenceDestinationNotWorkingDnsErrorType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string expanded_url = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.getExpandedUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.setExpandedUrl = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.clearExpandedUrl = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.hasExpandedUrl = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDeviceEnum.PolicyTopicEvidenceDestinationNotWorkingDevice device = 4;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDeviceEnum.PolicyTopicEvidenceDestinationNotWorkingDevice}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.getDevice = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDeviceEnum.PolicyTopicEvidenceDestinationNotWorkingDevice} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDeviceEnum.PolicyTopicEvidenceDestinationNotWorkingDevice} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.setDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string last_checked_date_time = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.getLastCheckedDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.setLastCheckedDateTime = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.clearLastCheckedDateTime = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.hasLastCheckedDateTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDnsErrorTypeEnum.PolicyTopicEvidenceDestinationNotWorkingDnsErrorType dns_error_type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDnsErrorTypeEnum.PolicyTopicEvidenceDestinationNotWorkingDnsErrorType}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.getDnsErrorType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDnsErrorTypeEnum.PolicyTopicEvidenceDestinationNotWorkingDnsErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyTopicEvidenceDestinationNotWorkingDnsErrorTypeEnum.PolicyTopicEvidenceDestinationNotWorkingDnsErrorType} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.setDnsErrorType = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.clearDnsErrorType = function() {
  return jspb.Message.setOneofField(this, 1, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.hasDnsErrorType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 http_error_code = 6;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.getHttpErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.setHttpErrorCode = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.clearHttpErrorCode = function() {
  return jspb.Message.setOneofField(this, 6, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking.prototype.hasHttpErrorCode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional WebsiteList website_list = 3;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getWebsiteList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.WebsiteList|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.setWebsiteList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.clearWebsiteList = function() {
  return this.setWebsiteList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.hasWebsiteList = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TextList text_list = 4;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getTextList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.TextList|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.setTextList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.clearTextList = function() {
  return this.setTextList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.hasTextList = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string language_code = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.setLanguageCode = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.clearLanguageCode = function() {
  return jspb.Message.setOneofField(this, 9, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.hasLanguageCode = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DestinationTextList destination_text_list = 6;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getDestinationTextList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationTextList|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.setDestinationTextList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.clearDestinationTextList = function() {
  return this.setDestinationTextList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.hasDestinationTextList = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DestinationMismatch destination_mismatch = 7;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getDestinationMismatch = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationMismatch|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.setDestinationMismatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.clearDestinationMismatch = function() {
  return this.setDestinationMismatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.hasDestinationMismatch = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DestinationNotWorking destination_not_working = 8;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.getDestinationNotWorking = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicEvidence.DestinationNotWorking|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.setDestinationNotWorking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.google.ads.googleads.v18.common.PolicyTopicEvidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEvidence} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.clearDestinationNotWorking = function() {
  return this.setDestinationNotWorking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicEvidence.prototype.hasDestinationNotWorking = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ValueCase = {
  VALUE_NOT_SET: 0,
  COUNTRY_CONSTRAINT_LIST: 1,
  RESELLER_CONSTRAINT: 2,
  CERTIFICATE_MISSING_IN_COUNTRY_LIST: 3,
  CERTIFICATE_DOMAIN_MISMATCH_IN_COUNTRY_LIST: 4
};

/**
 * @return {proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ValueCase}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.getValueCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ValueCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryConstraintList: (f = msg.getCountryConstraintList()) && proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.toObject(includeInstance, f),
    resellerConstraint: (f = msg.getResellerConstraint()) && proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.toObject(includeInstance, f),
    certificateMissingInCountryList: (f = msg.getCertificateMissingInCountryList()) && proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.toObject(includeInstance, f),
    certificateDomainMismatchInCountryList: (f = msg.getCertificateDomainMismatchInCountryList()) && proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint;
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.deserializeBinaryFromReader);
      msg.setCountryConstraintList(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.deserializeBinaryFromReader);
      msg.setResellerConstraint(value);
      break;
    case 3:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.deserializeBinaryFromReader);
      msg.setCertificateMissingInCountryList(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.deserializeBinaryFromReader);
      msg.setCertificateDomainMismatchInCountryList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryConstraintList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.serializeBinaryToWriter
    );
  }
  f = message.getResellerConstraint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.serializeBinaryToWriter
    );
  }
  f = message.getCertificateMissingInCountryList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.serializeBinaryToWriter
    );
  }
  f = message.getCertificateDomainMismatchInCountryList();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalTargetedCountries: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countriesList: jspb.Message.toObjectList(msg.getCountriesList(),
    proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList;
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTargetedCountries(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.deserializeBinaryFromReader);
      msg.addCountries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_targeted_countries = 3;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.getTotalTargetedCountries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.setTotalTargetedCountries = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.clearTotalTargetedCountries = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.hasTotalTargetedCountries = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated CountryConstraint countries = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint>}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.getCountriesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint>} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.setCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.addCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList.prototype.clearCountriesList = function() {
  return this.setCountriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint;
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryCriterion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint;
  return proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCriterion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string country_criterion = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.prototype.getCountryCriterion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.prototype.setCountryCriterion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.prototype.clearCountryCriterion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraint.prototype.hasCountryCriterion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CountryConstraintList country_constraint_list = 1;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.getCountryConstraintList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.setCountryConstraintList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.clearCountryConstraintList = function() {
  return this.setCountryConstraintList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.hasCountryConstraintList = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResellerConstraint reseller_constraint = 2;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.getResellerConstraint = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.ResellerConstraint|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.setResellerConstraint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.clearResellerConstraint = function() {
  return this.setResellerConstraint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.hasResellerConstraint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CountryConstraintList certificate_missing_in_country_list = 3;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.getCertificateMissingInCountryList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.setCertificateMissingInCountryList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.clearCertificateMissingInCountryList = function() {
  return this.setCertificateMissingInCountryList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.hasCertificateMissingInCountryList = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CountryConstraintList certificate_domain_mismatch_in_country_list = 4;
 * @return {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.getCertificateDomainMismatchInCountryList = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.PolicyTopicConstraint.CountryConstraintList|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
*/
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.setCertificateDomainMismatchInCountryList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.google.ads.googleads.v18.common.PolicyTopicConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicConstraint} returns this
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.clearCertificateDomainMismatchInCountryList = function() {
  return this.setCertificateDomainMismatchInCountryList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PolicyTopicConstraint.prototype.hasCertificateDomainMismatchInCountryList = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.common);
