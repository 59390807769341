// source: google/ads/googleads/v18/common/ad_type_infos.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_ad_asset_pb = require('../../../../../google/ads/googleads/v18/common/ad_asset_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_ad_asset_pb);
var google_ads_googleads_v18_enums_call_conversion_reporting_state_pb = require('../../../../../google/ads/googleads/v18/enums/call_conversion_reporting_state_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_call_conversion_reporting_state_pb);
var google_ads_googleads_v18_enums_display_ad_format_setting_pb = require('../../../../../google/ads/googleads/v18/enums/display_ad_format_setting_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_display_ad_format_setting_pb);
var google_ads_googleads_v18_enums_display_upload_product_type_pb = require('../../../../../google/ads/googleads/v18/enums/display_upload_product_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_display_upload_product_type_pb);
var google_ads_googleads_v18_enums_legacy_app_install_ad_app_store_pb = require('../../../../../google/ads/googleads/v18/enums/legacy_app_install_ad_app_store_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_legacy_app_install_ad_app_store_pb);
var google_ads_googleads_v18_enums_mime_type_pb = require('../../../../../google/ads/googleads/v18/enums/mime_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_mime_type_pb);
var google_ads_googleads_v18_enums_video_thumbnail_pb = require('../../../../../google/ads/googleads/v18/enums/video_thumbnail_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_video_thumbnail_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AppAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AppEngagementAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CallAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DemandGenProductAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DisplayUploadAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.MediaAssetCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ExpandedTextAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ImageAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ImageAdInfo.ImageCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.InFeedVideoAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LocalAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ShoppingProductAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.SmartCampaignAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.TextAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.TravelAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoAdInfo.FormatCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.TextAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.TextAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.TextAdInfo.displayName = 'proto.google.ads.googleads.v18.common.TextAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ExpandedTextAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ExpandedTextAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelAdInfo.displayName = 'proto.google.ads.googleads.v18.common.HotelAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.TravelAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.TravelAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.TravelAdInfo.displayName = 'proto.google.ads.googleads.v18.common.TravelAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ShoppingProductAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ShoppingProductAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ImageAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.ImageAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ImageAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ImageAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.displayName = 'proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.displayName = 'proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.displayName = 'proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.displayName = 'proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.InFeedVideoAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.displayName = 'proto.google.ads.googleads.v18.common.InFeedVideoAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.VideoAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.VideoAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.VideoAdInfo.displayName = 'proto.google.ads.googleads.v18.common.VideoAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.displayName = 'proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.displayName = 'proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AppAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.AppAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AppAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AppAdInfo.displayName = 'proto.google.ads.googleads.v18.common.AppAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.AppEngagementAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AppEngagementAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AppEngagementAdInfo.displayName = 'proto.google.ads.googleads.v18.common.AppEngagementAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.displayName = 'proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.displayName = 'proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.displayName = 'proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LocalAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.LocalAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LocalAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LocalAdInfo.displayName = 'proto.google.ads.googleads.v18.common.LocalAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.DisplayUploadAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.displayName = 'proto.google.ads.googleads.v18.common.DisplayUploadAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.displayName = 'proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.SmartCampaignAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.displayName = 'proto.google.ads.googleads.v18.common.SmartCampaignAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CallAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CallAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CallAdInfo.displayName = 'proto.google.ads.googleads.v18.common.CallAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.displayName = 'proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.displayName = 'proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.displayName = 'proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DemandGenProductAdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.displayName = 'proto.google.ads.googleads.v18.common.DemandGenProductAdInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.TextAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.TextAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TextAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headline: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.TextAdInfo;
  return proto.google.ads.googleads.v18.common.TextAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.TextAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.TextAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.TextAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TextAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string headline = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.clearHeadline = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.hasHeadline = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description1 = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.getDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.setDescription1 = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.clearDescription1 = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.hasDescription1 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string description2 = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.setDescription2 = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.TextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.clearDescription2 = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.TextAdInfo.prototype.hasDescription2 = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinePart1: jspb.Message.getFieldWithDefault(msg, 8, ""),
    headlinePart2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    headlinePart3: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 12, ""),
    path1: jspb.Message.getFieldWithDefault(msg, 13, ""),
    path2: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ExpandedTextAdInfo;
  return proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadlinePart1(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadlinePart2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadlinePart3(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath1(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string headline_part1 = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getHeadlinePart1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setHeadlinePart1 = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearHeadlinePart1 = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasHeadlinePart1 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string headline_part2 = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getHeadlinePart2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setHeadlinePart2 = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearHeadlinePart2 = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasHeadlinePart2 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string headline_part3 = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getHeadlinePart3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setHeadlinePart3 = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearHeadlinePart3 = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasHeadlinePart3 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string description2 = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setDescription2 = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearDescription2 = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasDescription2 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string path1 = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getPath1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setPath1 = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearPath1 = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasPath1 = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string path2 = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.getPath2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.setPath2 = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.clearPath2 = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedTextAdInfo.prototype.hasPath2 = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo;
  return proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description2 = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.setDescription2 = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.clearDescription2 = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo.prototype.hasDescription2 = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelAdInfo}
 */
proto.google.ads.googleads.v18.common.HotelAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelAdInfo;
  return proto.google.ads.googleads.v18.common.HotelAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelAdInfo}
 */
proto.google.ads.googleads.v18.common.HotelAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.TravelAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.TravelAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.TravelAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TravelAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.TravelAdInfo}
 */
proto.google.ads.googleads.v18.common.TravelAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.TravelAdInfo;
  return proto.google.ads.googleads.v18.common.TravelAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.TravelAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.TravelAdInfo}
 */
proto.google.ads.googleads.v18.common.TravelAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.TravelAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.TravelAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.TravelAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TravelAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo}
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo;
  return proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo}
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ShoppingProductAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingProductAdInfo}
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ShoppingProductAdInfo;
  return proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ShoppingProductAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingProductAdInfo}
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ShoppingProductAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ShoppingProductAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headline: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo}
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo;
  return proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo}
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string headline = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.prototype.clearHeadline = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo.prototype.hasHeadline = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_ = [[22,13,14]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.ImageCase = {
  IMAGE_NOT_SET: 0,
  IMAGE_ASSET: 22,
  DATA: 13,
  AD_ID_TO_COPY_IMAGE_FROM: 14
};

/**
 * @return {proto.google.ads.googleads.v18.common.ImageAdInfo.ImageCase}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getImageCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.ImageAdInfo.ImageCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ImageAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ImageAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    pixelWidth: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pixelHeight: jspb.Message.getFieldWithDefault(msg, 16, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    previewPixelWidth: jspb.Message.getFieldWithDefault(msg, 18, 0),
    previewPixelHeight: jspb.Message.getFieldWithDefault(msg, 19, 0),
    previewImageUrl: jspb.Message.getFieldWithDefault(msg, 20, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    name: jspb.Message.getFieldWithDefault(msg, 21, ""),
    imageAsset: (f = msg.getImageAsset()) && google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject(includeInstance, f),
    data: msg.getData_asB64(),
    adIdToCopyImageFrom: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ImageAdInfo;
  return proto.google.ads.googleads.v18.common.ImageAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ImageAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPixelWidth(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPixelHeight(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreviewPixelWidth(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreviewPixelHeight(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviewImageUrl(value);
      break;
    case 10:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType} */ (reader.readEnum());
      msg.setMimeType(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 22:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.setImageAsset(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdIdToCopyImageFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ImageAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ImageAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMimeType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getImageAsset();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional int64 pixel_width = 15;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getPixelWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setPixelWidth = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearPixelWidth = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasPixelWidth = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 pixel_height = 16;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getPixelHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setPixelHeight = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearPixelHeight = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasPixelHeight = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string image_url = 17;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setImageUrl = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearImageUrl = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasImageUrl = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int64 preview_pixel_width = 18;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getPreviewPixelWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setPreviewPixelWidth = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearPreviewPixelWidth = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasPreviewPixelWidth = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional int64 preview_pixel_height = 19;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getPreviewPixelHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setPreviewPixelHeight = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearPreviewPixelHeight = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasPreviewPixelHeight = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string preview_image_url = 20;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getPreviewImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setPreviewImageUrl = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearPreviewImageUrl = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasPreviewImageUrl = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.ads.googleads.v18.enums.MimeTypeEnum.MimeType mime_type = 10;
 * @return {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getMimeType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string name = 21;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setName = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearName = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional AdImageAsset image_asset = 22;
 * @return {?proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getImageAsset = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 22));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setImageAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearImageAsset = function() {
  return this.setImageAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasImageAsset = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bytes data = 13;
 * @return {!(string|Uint8Array)}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes data = 13;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 13, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasData = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 ad_id_to_copy_image_from = 14;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.getAdIdToCopyImageFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.setAdIdToCopyImageFrom = function(value) {
  return jspb.Message.setOneofField(this, 14, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.clearAdIdToCopyImageFrom = function() {
  return jspb.Message.setOneofField(this, 14, proto.google.ads.googleads.v18.common.ImageAdInfo.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAdInfo.prototype.hasAdIdToCopyImageFrom = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    companionBanner: (f = msg.getCompanionBanner()) && google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject(includeInstance, f),
    actionButtonLabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actionHeadline: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo;
  return proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.setCompanionBanner(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionButtonLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionHeadline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanionBanner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getActionButtonLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActionHeadline();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional AdImageAsset companion_banner = 3;
 * @return {?proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.getCompanionBanner = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.setCompanionBanner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.clearCompanionBanner = function() {
  return this.setCompanionBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.hasCompanionBanner = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string action_button_label = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.getActionButtonLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.setActionButtonLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string action_headline = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.getActionHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.prototype.setActionHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    companionBanner: (f = msg.getCompanionBanner()) && google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject(includeInstance, f),
    actionButtonLabel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionHeadline: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo;
  return proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.setCompanionBanner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionButtonLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionHeadline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanionBanner();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getActionButtonLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionHeadline();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional AdImageAsset companion_banner = 5;
 * @return {?proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.getCompanionBanner = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.setCompanionBanner = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.clearCompanionBanner = function() {
  return this.setCompanionBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.hasCompanionBanner = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string action_button_label = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.getActionButtonLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.setActionButtonLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action_headline = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.getActionHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.prototype.setActionHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionButtonLabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actionHeadline: jspb.Message.getFieldWithDefault(msg, 5, ""),
    companionBanner: (f = msg.getCompanionBanner()) && google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo;
  return proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionButtonLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionHeadline(value);
      break;
    case 7:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.setCompanionBanner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionButtonLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActionHeadline();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompanionBanner();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string action_button_label = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.getActionButtonLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.setActionButtonLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string action_headline = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.getActionHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.setActionHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AdImageAsset companion_banner = 7;
 * @return {?proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.getCompanionBanner = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.setCompanionBanner = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.clearCompanionBanner = function() {
  return this.setCompanionBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.prototype.hasCompanionBanner = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headline: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo;
  return proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadline();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string headline = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headline: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.InFeedVideoAdInfo;
  return proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.VideoThumbnailEnum.VideoThumbnail} */ (reader.readEnum());
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadline();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThumbnail();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string headline = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description1 = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.getDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.setDescription1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description2 = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.setDescription2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.ads.googleads.v18.enums.VideoThumbnailEnum.VideoThumbnail thumbnail = 4;
 * @return {!proto.google.ads.googleads.v18.enums.VideoThumbnailEnum.VideoThumbnail}
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.getThumbnail = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.VideoThumbnailEnum.VideoThumbnail} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.VideoThumbnailEnum.VideoThumbnail} value
 * @return {!proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_ = [[2,3,4,5,9]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.FormatCase = {
  FORMAT_NOT_SET: 0,
  IN_STREAM: 2,
  BUMPER: 3,
  OUT_STREAM: 4,
  NON_SKIPPABLE: 5,
  IN_FEED: 9
};

/**
 * @return {proto.google.ads.googleads.v18.common.VideoAdInfo.FormatCase}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getFormatCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.VideoAdInfo.FormatCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.VideoAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.VideoAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    video: (f = msg.getVideo()) && google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject(includeInstance, f),
    inStream: (f = msg.getInStream()) && proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.toObject(includeInstance, f),
    bumper: (f = msg.getBumper()) && proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.toObject(includeInstance, f),
    outStream: (f = msg.getOutStream()) && proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.toObject(includeInstance, f),
    nonSkippable: (f = msg.getNonSkippable()) && proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.toObject(includeInstance, f),
    inFeed: (f = msg.getInFeed()) && proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.VideoAdInfo;
  return proto.google.ads.googleads.v18.common.VideoAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.VideoAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.deserializeBinaryFromReader);
      msg.setInStream(value);
      break;
    case 3:
      var value = new proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.deserializeBinaryFromReader);
      msg.setBumper(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.deserializeBinaryFromReader);
      msg.setOutStream(value);
      break;
    case 5:
      var value = new proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.deserializeBinaryFromReader);
      msg.setNonSkippable(value);
      break;
    case 9:
      var value = new proto.google.ads.googleads.v18.common.InFeedVideoAdInfo;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.deserializeBinaryFromReader);
      msg.setInFeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.VideoAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.VideoAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
  f = message.getInStream();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getBumper();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getOutStream();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getNonSkippable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getInFeed();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.google.ads.googleads.v18.common.InFeedVideoAdInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdVideoAsset video = 8;
 * @return {?proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getVideo = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdVideoAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdVideoAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.setVideo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional VideoTrueViewInStreamAdInfo in_stream = 2;
 * @return {?proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getInStream = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.VideoTrueViewInStreamAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.setInStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.clearInStream = function() {
  return this.setInStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.hasInStream = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VideoBumperInStreamAdInfo bumper = 3;
 * @return {?proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getBumper = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.VideoBumperInStreamAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.setBumper = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.clearBumper = function() {
  return this.setBumper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.hasBumper = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional VideoOutstreamAdInfo out_stream = 4;
 * @return {?proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getOutStream = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.VideoOutstreamAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.setOutStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.clearOutStream = function() {
  return this.setOutStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.hasOutStream = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional VideoNonSkippableInStreamAdInfo non_skippable = 5;
 * @return {?proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getNonSkippable = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.VideoNonSkippableInStreamAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.setNonSkippable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.clearNonSkippable = function() {
  return this.setNonSkippable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.hasNonSkippable = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional InFeedVideoAdInfo in_feed = 9;
 * @return {?proto.google.ads.googleads.v18.common.InFeedVideoAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.getInFeed = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.InFeedVideoAdInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.InFeedVideoAdInfo, 9));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.InFeedVideoAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.setInFeed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.google.ads.googleads.v18.common.VideoAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.VideoAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.clearInFeed = function() {
  return this.setInFeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.VideoAdInfo.prototype.hasInFeed = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    longHeadlinesList: jspb.Message.toObjectList(msg.getLongHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    callToActionsList: jspb.Message.toObjectList(msg.getCallToActionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance),
    companionBannersList: jspb.Message.toObjectList(msg.getCompanionBannersList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    breadcrumb1: jspb.Message.getFieldWithDefault(msg, 7, ""),
    breadcrumb2: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo;
  return proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addLongHeadlines(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addCallToActions(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addCompanionBanners(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreadcrumb1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreadcrumb2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getLongHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallToActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
  f = message.getCompanionBannersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getBreadcrumb1();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBreadcrumb2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset long_headlines = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getLongHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setLongHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.addLongHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.clearLongHeadlinesList = function() {
  return this.setLongHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdTextAsset call_to_actions = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getCallToActionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setCallToActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.addCallToActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.clearCallToActionsList = function() {
  return this.setCallToActionsList([]);
};


/**
 * repeated AdVideoAsset videos = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.clearVideosList = function() {
  return this.setVideosList([]);
};


/**
 * repeated AdImageAsset companion_banners = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getCompanionBannersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setCompanionBannersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.addCompanionBanners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.clearCompanionBannersList = function() {
  return this.setCompanionBannersList([]);
};


/**
 * optional string breadcrumb1 = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getBreadcrumb1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setBreadcrumb1 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string breadcrumb2 = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.getBreadcrumb2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo.prototype.setBreadcrumb2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    path1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    path2: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo;
  return proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string path1 = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.getPath1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.setPath1 = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.clearPath1 = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.hasPath1 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string path2 = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.getPath2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.setPath2 = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.clearPath2 = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo.prototype.hasPath2 = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortHeadline: jspb.Message.getFieldWithDefault(msg, 16, ""),
    longHeadline: jspb.Message.getFieldWithDefault(msg, 17, ""),
    description: jspb.Message.getFieldWithDefault(msg, 18, ""),
    businessName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    allowFlexibleColor: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    accentColor: jspb.Message.getFieldWithDefault(msg, 21, ""),
    mainColor: jspb.Message.getFieldWithDefault(msg, 22, ""),
    callToActionText: jspb.Message.getFieldWithDefault(msg, 23, ""),
    logoImage: jspb.Message.getFieldWithDefault(msg, 24, ""),
    squareLogoImage: jspb.Message.getFieldWithDefault(msg, 25, ""),
    marketingImage: jspb.Message.getFieldWithDefault(msg, 26, ""),
    squareMarketingImage: jspb.Message.getFieldWithDefault(msg, 27, ""),
    formatSetting: jspb.Message.getFieldWithDefault(msg, 13, 0),
    pricePrefix: jspb.Message.getFieldWithDefault(msg, 28, ""),
    promoText: jspb.Message.getFieldWithDefault(msg, 29, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo;
  return proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortHeadline(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongHeadline(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowFlexibleColor(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccentColor(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainColor(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionText(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoImage(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSquareLogoImage(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketingImage(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSquareMarketingImage(value);
      break;
    case 13:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting} */ (reader.readEnum());
      msg.setFormatSetting(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricePrefix(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeBool(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(
      24,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeString(
      25,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeString(
      26,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getFormatSetting();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeString(
      28,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * optional string short_headline = 16;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getShortHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setShortHeadline = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearShortHeadline = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasShortHeadline = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string long_headline = 17;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getLongHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setLongHeadline = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearLongHeadline = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasLongHeadline = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string description = 18;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string business_name = 19;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearBusinessName = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasBusinessName = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool allow_flexible_color = 20;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getAllowFlexibleColor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setAllowFlexibleColor = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearAllowFlexibleColor = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasAllowFlexibleColor = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string accent_color = 21;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getAccentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setAccentColor = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearAccentColor = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasAccentColor = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string main_color = 22;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getMainColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setMainColor = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearMainColor = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasMainColor = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string call_to_action_text = 23;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getCallToActionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setCallToActionText = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearCallToActionText = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasCallToActionText = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string logo_image = 24;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getLogoImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setLogoImage = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearLogoImage = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasLogoImage = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string square_logo_image = 25;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getSquareLogoImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setSquareLogoImage = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearSquareLogoImage = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasSquareLogoImage = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string marketing_image = 26;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getMarketingImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setMarketingImage = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearMarketingImage = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasMarketingImage = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string square_marketing_image = 27;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getSquareMarketingImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setSquareMarketingImage = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearSquareMarketingImage = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasSquareMarketingImage = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting format_setting = 13;
 * @return {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getFormatSetting = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setFormatSetting = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string price_prefix = 28;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getPricePrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setPricePrefix = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearPricePrefix = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasPricePrefix = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string promo_text = 29;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.getPromoText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.setPromoText = function(value) {
  return jspb.Message.setField(this, 29, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.clearPromoText = function() {
  return jspb.Message.setField(this, 29, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo.prototype.hasPromoText = function() {
  return jspb.Message.getField(this, 29) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.AppAdInfo.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AppAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AppAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    mandatoryAdText: (f = msg.getMandatoryAdText()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    youtubeVideosList: jspb.Message.toObjectList(msg.getYoutubeVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance),
    html5MediaBundlesList: jspb.Message.toObjectList(msg.getHtml5MediaBundlesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AppAdInfo;
  return proto.google.ads.googleads.v18.common.AppAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AppAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setMandatoryAdText(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addYoutubeVideos(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset.deserializeBinaryFromReader);
      msg.addHtml5MediaBundles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AppAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AppAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMandatoryAdText();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
  f = message.getHtml5MediaBundlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdTextAsset mandatory_ad_text = 1;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.getMandatoryAdText = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.setMandatoryAdText = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.clearMandatoryAdText = function() {
  return this.setMandatoryAdText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.hasMandatoryAdText = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AdTextAsset headlines = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdImageAsset images = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated AdVideoAsset youtube_videos = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.getYoutubeVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.setYoutubeVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.addYoutubeVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.clearYoutubeVideosList = function() {
  return this.setYoutubeVideosList([]);
};


/**
 * repeated AdMediaBundleAsset html5_media_bundles = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdMediaBundleAsset>}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.getHtml5MediaBundlesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdMediaBundleAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdMediaBundleAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.setHtml5MediaBundlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdMediaBundleAsset}
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.addHtml5MediaBundles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.AdMediaBundleAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppAdInfo.prototype.clearHtml5MediaBundlesList = function() {
  return this.setHtml5MediaBundlesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AppEngagementAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AppEngagementAdInfo;
  return proto.google.ads.googleads.v18.common.AppEngagementAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AppEngagementAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdImageAsset images = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated AdVideoAsset videos = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.setVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppEngagementAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppEngagementAdInfo.prototype.clearVideosList = function() {
  return this.setVideosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    youtubeVideosList: jspb.Message.toObjectList(msg.getYoutubeVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo;
  return proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addYoutubeVideos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdImageAsset images = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated AdVideoAsset youtube_videos = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.getYoutubeVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.setYoutubeVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.addYoutubeVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo.prototype.clearYoutubeVideosList = function() {
  return this.setYoutubeVideosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    appStore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    headline: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description1: jspb.Message.getFieldWithDefault(msg, 8, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo;
  return proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LegacyAppInstallAdAppStoreEnum.LegacyAppInstallAdAppStore} */ (reader.readEnum());
      msg.setAppStore(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription1(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAppStore();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string app_id = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.setAppId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.clearAppId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.hasAppId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.enums.LegacyAppInstallAdAppStoreEnum.LegacyAppInstallAdAppStore app_store = 2;
 * @return {!proto.google.ads.googleads.v18.enums.LegacyAppInstallAdAppStoreEnum.LegacyAppInstallAdAppStore}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.getAppStore = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LegacyAppInstallAdAppStoreEnum.LegacyAppInstallAdAppStore} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LegacyAppInstallAdAppStoreEnum.LegacyAppInstallAdAppStore} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.setAppStore = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string headline = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.clearHeadline = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.hasHeadline = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string description1 = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.getDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.setDescription1 = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.clearDescription1 = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.hasDescription1 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string description2 = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.setDescription2 = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.clearDescription2 = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo.prototype.hasDescription2 = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.repeatedFields_ = [1,2,3,4,5,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketingImagesList: jspb.Message.toObjectList(msg.getMarketingImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    squareMarketingImagesList: jspb.Message.toObjectList(msg.getSquareMarketingImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    logoImagesList: jspb.Message.toObjectList(msg.getLogoImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    squareLogoImagesList: jspb.Message.toObjectList(msg.getSquareLogoImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    longHeadline: (f = msg.getLongHeadline()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    youtubeVideosList: jspb.Message.toObjectList(msg.getYoutubeVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance),
    businessName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    mainColor: jspb.Message.getFieldWithDefault(msg, 18, ""),
    accentColor: jspb.Message.getFieldWithDefault(msg, 19, ""),
    allowFlexibleColor: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    callToActionText: jspb.Message.getFieldWithDefault(msg, 21, ""),
    pricePrefix: jspb.Message.getFieldWithDefault(msg, 22, ""),
    promoText: jspb.Message.getFieldWithDefault(msg, 23, ""),
    formatSetting: jspb.Message.getFieldWithDefault(msg, 16, 0),
    controlSpec: (f = msg.getControlSpec()) && proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo;
  return proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addMarketingImages(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addSquareMarketingImages(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addLogoImages(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addSquareLogoImages(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setLongHeadline(value);
      break;
    case 7:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 8:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addYoutubeVideos(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainColor(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccentColor(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowFlexibleColor(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionText(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricePrefix(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoText(value);
      break;
    case 16:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting} */ (reader.readEnum());
      msg.setFormatSetting(value);
      break;
    case 24:
      var value = new proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.deserializeBinaryFromReader);
      msg.setControlSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketingImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getSquareMarketingImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getLogoImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getSquareLogoImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getLongHeadline();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getYoutubeVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeBool(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFormatSetting();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getControlSpec();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdImageAsset marketing_images = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getMarketingImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setMarketingImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addMarketingImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearMarketingImagesList = function() {
  return this.setMarketingImagesList([]);
};


/**
 * repeated AdImageAsset square_marketing_images = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getSquareMarketingImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setSquareMarketingImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addSquareMarketingImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearSquareMarketingImagesList = function() {
  return this.setSquareMarketingImagesList([]);
};


/**
 * repeated AdImageAsset logo_images = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getLogoImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setLogoImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addLogoImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearLogoImagesList = function() {
  return this.setLogoImagesList([]);
};


/**
 * repeated AdImageAsset square_logo_images = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getSquareLogoImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setSquareLogoImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addSquareLogoImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearSquareLogoImagesList = function() {
  return this.setSquareLogoImagesList([]);
};


/**
 * repeated AdTextAsset headlines = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * optional AdTextAsset long_headline = 6;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getLongHeadline = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setLongHeadline = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearLongHeadline = function() {
  return this.setLongHeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasLongHeadline = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated AdTextAsset descriptions = 7;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 7));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdVideoAsset youtube_videos = 8;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getYoutubeVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 8));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setYoutubeVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.addYoutubeVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearYoutubeVideosList = function() {
  return this.setYoutubeVideosList([]);
};


/**
 * optional string business_name = 17;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearBusinessName = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasBusinessName = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string main_color = 18;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getMainColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setMainColor = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearMainColor = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasMainColor = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string accent_color = 19;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getAccentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setAccentColor = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearAccentColor = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasAccentColor = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool allow_flexible_color = 20;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getAllowFlexibleColor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setAllowFlexibleColor = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearAllowFlexibleColor = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasAllowFlexibleColor = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string call_to_action_text = 21;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getCallToActionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setCallToActionText = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearCallToActionText = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasCallToActionText = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string price_prefix = 22;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getPricePrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setPricePrefix = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearPricePrefix = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasPricePrefix = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string promo_text = 23;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getPromoText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setPromoText = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearPromoText = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasPromoText = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting format_setting = 16;
 * @return {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getFormatSetting = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DisplayAdFormatSettingEnum.DisplayAdFormatSetting} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setFormatSetting = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional ResponsiveDisplayAdControlSpec control_spec = 24;
 * @return {?proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.getControlSpec = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec, 24));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.setControlSpec = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.clearControlSpec = function() {
  return this.setControlSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo.prototype.hasControlSpec = function() {
  return jspb.Message.getField(this, 24) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LocalAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LocalAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    callToActionsList: jspb.Message.toObjectList(msg.getCallToActionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    marketingImagesList: jspb.Message.toObjectList(msg.getMarketingImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    logoImagesList: jspb.Message.toObjectList(msg.getLogoImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance),
    path1: jspb.Message.getFieldWithDefault(msg, 9, ""),
    path2: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LocalAdInfo;
  return proto.google.ads.googleads.v18.common.LocalAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LocalAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addCallToActions(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addMarketingImages(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addLogoImages(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath1(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LocalAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LocalAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallToActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getMarketingImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getLogoImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdTextAsset call_to_actions = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getCallToActionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setCallToActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.addCallToActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearCallToActionsList = function() {
  return this.setCallToActionsList([]);
};


/**
 * repeated AdImageAsset marketing_images = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getMarketingImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setMarketingImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.addMarketingImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearMarketingImagesList = function() {
  return this.setMarketingImagesList([]);
};


/**
 * repeated AdImageAsset logo_images = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getLogoImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setLogoImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.addLogoImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearLogoImagesList = function() {
  return this.setLogoImagesList([]);
};


/**
 * repeated AdVideoAsset videos = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearVideosList = function() {
  return this.setVideosList([]);
};


/**
 * optional string path1 = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getPath1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setPath1 = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearPath1 = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.hasPath1 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string path2 = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.getPath2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.setPath2 = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LocalAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.clearPath2 = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LocalAdInfo.prototype.hasPath2 = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.MediaAssetCase = {
  MEDIA_ASSET_NOT_SET: 0,
  MEDIA_BUNDLE: 2
};

/**
 * @return {proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.MediaAssetCase}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.getMediaAssetCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.MediaAssetCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayUploadProductType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mediaBundle: (f = msg.getMediaBundle()) && google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DisplayUploadAdInfo;
  return proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DisplayUploadProductTypeEnum.DisplayUploadProductType} */ (reader.readEnum());
      msg.setDisplayUploadProductType(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset.deserializeBinaryFromReader);
      msg.setMediaBundle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayUploadProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMediaBundle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.DisplayUploadProductTypeEnum.DisplayUploadProductType display_upload_product_type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.DisplayUploadProductTypeEnum.DisplayUploadProductType}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.getDisplayUploadProductType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DisplayUploadProductTypeEnum.DisplayUploadProductType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DisplayUploadProductTypeEnum.DisplayUploadProductType} value
 * @return {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.setDisplayUploadProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional AdMediaBundleAsset media_bundle = 2;
 * @return {?proto.google.ads.googleads.v18.common.AdMediaBundleAsset}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.getMediaBundle = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdMediaBundleAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdMediaBundleAsset, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdMediaBundleAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.setMediaBundle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.clearMediaBundle = function() {
  return this.setMediaBundle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DisplayUploadAdInfo.prototype.hasMediaBundle = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    enableAssetEnhancements: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    enableAutogenVideo: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec;
  return proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableAssetEnhancements(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableAutogenVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnableAssetEnhancements();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getEnableAutogenVideo();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool enable_asset_enhancements = 1;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.prototype.getEnableAssetEnhancements = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.prototype.setEnableAssetEnhancements = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool enable_autogen_video = 2;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.prototype.getEnableAutogenVideo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec} returns this
 */
proto.google.ads.googleads.v18.common.ResponsiveDisplayAdControlSpec.prototype.setEnableAutogenVideo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.SmartCampaignAdInfo;
  return proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.SmartCampaignAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CallAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CallAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CallAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    headline1: jspb.Message.getFieldWithDefault(msg, 11, ""),
    headline2: jspb.Message.getFieldWithDefault(msg, 12, ""),
    description1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 5, ""),
    callTracked: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    disableCallConversion: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    phoneNumberVerificationUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    conversionAction: jspb.Message.getFieldWithDefault(msg, 9, ""),
    conversionReportingState: jspb.Message.getFieldWithDefault(msg, 10, 0),
    path1: jspb.Message.getFieldWithDefault(msg, 13, ""),
    path2: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CallAdInfo;
  return proto.google.ads.googleads.v18.common.CallAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CallAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline1(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCallTracked(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableCallConversion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberVerificationUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversionAction(value);
      break;
    case 10:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState} */ (reader.readEnum());
      msg.setConversionReportingState(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath1(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CallAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CallAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CallAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHeadline1();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHeadline2();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDescription1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription2();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCallTracked();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDisableCallConversion();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPhoneNumberVerificationUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConversionAction();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getConversionReportingState();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPath1();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPath2();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string country_code = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_name = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string headline1 = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getHeadline1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setHeadline1 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string headline2 = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getHeadline2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setHeadline2 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string description1 = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setDescription1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description2 = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setDescription2 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool call_tracked = 6;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getCallTracked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setCallTracked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool disable_call_conversion = 7;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getDisableCallConversion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setDisableCallConversion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string phone_number_verification_url = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getPhoneNumberVerificationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setPhoneNumberVerificationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string conversion_action = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getConversionAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setConversionAction = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState conversion_reporting_state = 10;
 * @return {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getConversionReportingState = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setConversionReportingState = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string path1 = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getPath1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setPath1 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string path2 = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.getPath2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.CallAdInfo.prototype.setPath2 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketingImagesList: jspb.Message.toObjectList(msg.getMarketingImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    squareMarketingImagesList: jspb.Message.toObjectList(msg.getSquareMarketingImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    portraitMarketingImagesList: jspb.Message.toObjectList(msg.getPortraitMarketingImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    logoImagesList: jspb.Message.toObjectList(msg.getLogoImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    businessName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    callToActionText: jspb.Message.getFieldWithDefault(msg, 8, ""),
    leadFormOnly: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo;
  return proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addMarketingImages(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addSquareMarketingImages(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addPortraitMarketingImages(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addLogoImages(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionText(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLeadFormOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketingImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getSquareMarketingImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getPortraitMarketingImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getLogoImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * repeated AdImageAsset marketing_images = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getMarketingImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setMarketingImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.addMarketingImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearMarketingImagesList = function() {
  return this.setMarketingImagesList([]);
};


/**
 * repeated AdImageAsset square_marketing_images = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getSquareMarketingImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setSquareMarketingImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.addSquareMarketingImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearSquareMarketingImagesList = function() {
  return this.setSquareMarketingImagesList([]);
};


/**
 * repeated AdImageAsset portrait_marketing_images = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getPortraitMarketingImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setPortraitMarketingImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.addPortraitMarketingImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearPortraitMarketingImagesList = function() {
  return this.setPortraitMarketingImagesList([]);
};


/**
 * repeated AdImageAsset logo_images = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getLogoImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setLogoImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.addLogoImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearLogoImagesList = function() {
  return this.setLogoImagesList([]);
};


/**
 * repeated AdTextAsset headlines = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string business_name = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearBusinessName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.hasBusinessName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string call_to_action_text = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getCallToActionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setCallToActionText = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearCallToActionText = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.hasCallToActionText = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool lead_form_only = 9;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.getLeadFormOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.setLeadFormOnly = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.clearLeadFormOnly = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo.prototype.hasLeadFormOnly = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logoImage: (f = msg.getLogoImage()) && google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject(includeInstance, f),
    headline: (f = msg.getHeadline()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    callToActionText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    carouselCardsList: jspb.Message.toObjectList(msg.getCarouselCardsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdDemandGenCarouselCardAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo;
  return proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.setLogoImage(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setHeadline(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionText(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdDemandGenCarouselCardAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdDemandGenCarouselCardAsset.deserializeBinaryFromReader);
      msg.addCarouselCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogoImage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getHeadline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallToActionText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCarouselCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdDemandGenCarouselCardAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AdImageAsset logo_image = 2;
 * @return {?proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.getLogoImage = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.setLogoImage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.clearLogoImage = function() {
  return this.setLogoImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.hasLogoImage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdTextAsset headline = 3;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.getHeadline = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.clearHeadline = function() {
  return this.setHeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.hasHeadline = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AdTextAsset description = 4;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.getDescription = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string call_to_action_text = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.getCallToActionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.setCallToActionText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated AdDemandGenCarouselCardAsset carousel_cards = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.getCarouselCardsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdDemandGenCarouselCardAsset, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.setCarouselCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.addCarouselCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.AdDemandGenCarouselCardAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo.prototype.clearCarouselCardsList = function() {
  return this.setCarouselCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.repeatedFields_ = [1,2,3,4,5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headlinesList: jspb.Message.toObjectList(msg.getHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    longHeadlinesList: jspb.Message.toObjectList(msg.getLongHeadlinesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject, includeInstance),
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.toObject, includeInstance),
    logoImagesList: jspb.Message.toObjectList(msg.getLogoImagesList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject, includeInstance),
    breadcrumb1: jspb.Message.getFieldWithDefault(msg, 6, ""),
    breadcrumb2: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessName: (f = msg.getBusinessName()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    callToActionsList: jspb.Message.toObjectList(msg.getCallToActionsList(),
    google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo;
  return proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addHeadlines(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addLongHeadlines(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.addLogoImages(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreadcrumb1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreadcrumb2(value);
      break;
    case 8:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setBusinessName(value);
      break;
    case 9:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset.deserializeBinaryFromReader);
      msg.addCallToActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getLongHeadlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset.serializeBinaryToWriter
    );
  }
  f = message.getLogoImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getBreadcrumb1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBreadcrumb2();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallToActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdTextAsset headlines = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.addHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearHeadlinesList = function() {
  return this.setHeadlinesList([]);
};


/**
 * repeated AdTextAsset long_headlines = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getLongHeadlinesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setLongHeadlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.addLongHeadlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearLongHeadlinesList = function() {
  return this.setLongHeadlinesList([]);
};


/**
 * repeated AdTextAsset descriptions = 3;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdTextAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdTextAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v18.common.AdTextAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * repeated AdVideoAsset videos = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdVideoAsset, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdVideoAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdVideoAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdVideoAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdVideoAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearVideosList = function() {
  return this.setVideosList([]);
};


/**
 * repeated AdImageAsset logo_images = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getLogoImagesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdImageAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setLogoImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdImageAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.addLogoImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdImageAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearLogoImagesList = function() {
  return this.setLogoImagesList([]);
};


/**
 * optional string breadcrumb1 = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getBreadcrumb1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setBreadcrumb1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string breadcrumb2 = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getBreadcrumb2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setBreadcrumb2 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AdTextAsset business_name = 8;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getBusinessName = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 8));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearBusinessName = function() {
  return this.setBusinessName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.hasBusinessName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated AdCallToActionAsset call_to_actions = 9;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdCallToActionAsset>}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.getCallToActionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdCallToActionAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset, 9));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdCallToActionAsset>} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.setCallToActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdCallToActionAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdCallToActionAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.addCallToActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.google.ads.googleads.v18.common.AdCallToActionAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo.prototype.clearCallToActionsList = function() {
  return this.setCallToActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    headline: (f = msg.getHeadline()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    logoImage: (f = msg.getLogoImage()) && google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.toObject(includeInstance, f),
    breadcrumb1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    breadcrumb2: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessName: (f = msg.getBusinessName()) && google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.toObject(includeInstance, f),
    callToAction: (f = msg.getCallToAction()) && google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DemandGenProductAdInfo;
  return proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setHeadline(value);
      break;
    case 2:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.deserializeBinaryFromReader);
      msg.setLogoImage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreadcrumb1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreadcrumb2(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.deserializeBinaryFromReader);
      msg.setBusinessName(value);
      break;
    case 7:
      var value = new google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset.deserializeBinaryFromReader);
      msg.setCallToAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getLogoImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset.serializeBinaryToWriter
    );
  }
  f = message.getBreadcrumb1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBreadcrumb2();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset.serializeBinaryToWriter
    );
  }
  f = message.getCallToAction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdTextAsset headline = 1;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getHeadline = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setHeadline = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.clearHeadline = function() {
  return this.setHeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.hasHeadline = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdTextAsset description = 2;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getDescription = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdImageAsset logo_image = 3;
 * @return {?proto.google.ads.googleads.v18.common.AdImageAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getLogoImage = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdImageAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdImageAsset, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdImageAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setLogoImage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.clearLogoImage = function() {
  return this.setLogoImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.hasLogoImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string breadcrumb1 = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getBreadcrumb1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setBreadcrumb1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string breadcrumb2 = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getBreadcrumb2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setBreadcrumb2 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AdTextAsset business_name = 6;
 * @return {?proto.google.ads.googleads.v18.common.AdTextAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getBusinessName = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdTextAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdTextAsset, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdTextAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setBusinessName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.clearBusinessName = function() {
  return this.setBusinessName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.hasBusinessName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AdCallToActionAsset call_to_action = 7;
 * @return {?proto.google.ads.googleads.v18.common.AdCallToActionAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.getCallToAction = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AdCallToActionAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_asset_pb.AdCallToActionAsset, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AdCallToActionAsset|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
*/
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.setCallToAction = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.clearCallToAction = function() {
  return this.setCallToAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.DemandGenProductAdInfo.prototype.hasCallToAction = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.common);
