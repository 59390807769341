// source: proto/edge/grpcwebPb/grpcweb_DashboardTable.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_cache_pb = require('../../../proto/common/cache_pb.js');
goog.object.extend(proto, proto_common_cache_pb);
var proto_common_campaignPlatform_pb = require('../../../proto/common/campaignPlatform_pb.js');
goog.object.extend(proto, proto_common_campaignPlatform_pb);
var proto_common_datetime_pb = require('../../../proto/common/datetime_pb.js');
goog.object.extend(proto, proto_common_datetime_pb);
var proto_common_retailer_pb = require('../../../proto/common/retailer_pb.js');
goog.object.extend(proto, proto_common_retailer_pb);
goog.exportSymbol('proto.grpcwebPb.DashboardTable', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.AdGroupsFilter', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.CampaignsFilter', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.CriteriaFilter', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.ObjectFilter', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.ObjectFilter.TypeCase', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.ObjectType', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.ObjectType.Option', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTable.PlatformAccountsFilter', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardTableMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSiteDashboardTableMetricsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSiteDashboardTableMetricsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.displayName = 'proto.grpcwebPb.DashboardTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable.ObjectType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardTable.ObjectType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.ObjectType.displayName = 'proto.grpcwebPb.DashboardTable.ObjectType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable.ObjectFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.DashboardTable.ObjectFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.ObjectFilter.displayName = 'proto.grpcwebPb.DashboardTable.ObjectFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.DashboardTable.PlatformAccountsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.displayName = 'proto.grpcwebPb.DashboardTable.PlatformAccountsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.DashboardTable.CampaignsFilter.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.DashboardTable.CampaignsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.CampaignsFilter.displayName = 'proto.grpcwebPb.DashboardTable.CampaignsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.DashboardTable.AdGroupsFilter.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.DashboardTable.AdGroupsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.AdGroupsFilter.displayName = 'proto.grpcwebPb.DashboardTable.AdGroupsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.DashboardTable.CriteriaFilter.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.DashboardTable.CriteriaFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTable.CriteriaFilter.displayName = 'proto.grpcwebPb.DashboardTable.CriteriaFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetSiteDashboardTableMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.displayName = 'proto.grpcwebPb.GetSiteDashboardTableMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetSiteDashboardTableMetricsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetSiteDashboardTableMetricsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSiteDashboardTableMetricsReply.displayName = 'proto.grpcwebPb.GetSiteDashboardTableMetricsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardTableMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardTableMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardTableMetrics.displayName = 'proto.grpcwebPb.DashboardTableMetrics';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable}
 */
proto.grpcwebPb.DashboardTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable;
  return proto.grpcwebPb.DashboardTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable}
 */
proto.grpcwebPb.DashboardTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.ObjectType.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.ObjectType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable.ObjectType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.ObjectType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectType}
 */
proto.grpcwebPb.DashboardTable.ObjectType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable.ObjectType;
  return proto.grpcwebPb.DashboardTable.ObjectType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable.ObjectType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectType}
 */
proto.grpcwebPb.DashboardTable.ObjectType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.ObjectType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.ObjectType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable.ObjectType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.ObjectType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.DashboardTable.ObjectType.Option = {
  UNSPECIFIED: 0,
  PLATFORM_ACCOUNT: 1,
  CAMPAIGN: 2,
  AD_GROUP: 3,
  AD_GROUP_AD: 4,
  AD_GROUP_CRITERIA: 5,
  CAMPAIGN_CRITERIA: 6,
  SEARCH_TERM: 7,
  IMPACTED_PRODUCT: 8
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.TypeCase = {
  TYPE_NOT_SET: 0,
  ACCOUNTS_FILTER: 1,
  CAMPAIGNS_FILTER: 2,
  AD_GROUPS_FILTER: 3,
  CRITERIA_FILTER: 4
};

/**
 * @return {proto.grpcwebPb.DashboardTable.ObjectFilter.TypeCase}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.getTypeCase = function() {
  return /** @type {proto.grpcwebPb.DashboardTable.ObjectFilter.TypeCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.ObjectFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable.ObjectFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsFilter: (f = msg.getAccountsFilter()) && proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.toObject(includeInstance, f),
    campaignsFilter: (f = msg.getCampaignsFilter()) && proto.grpcwebPb.DashboardTable.CampaignsFilter.toObject(includeInstance, f),
    adGroupsFilter: (f = msg.getAdGroupsFilter()) && proto.grpcwebPb.DashboardTable.AdGroupsFilter.toObject(includeInstance, f),
    criteriaFilter: (f = msg.getCriteriaFilter()) && proto.grpcwebPb.DashboardTable.CriteriaFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable.ObjectFilter;
  return proto.grpcwebPb.DashboardTable.ObjectFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable.ObjectFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.DashboardTable.PlatformAccountsFilter;
      reader.readMessage(value,proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.deserializeBinaryFromReader);
      msg.setAccountsFilter(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.DashboardTable.CampaignsFilter;
      reader.readMessage(value,proto.grpcwebPb.DashboardTable.CampaignsFilter.deserializeBinaryFromReader);
      msg.setCampaignsFilter(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.DashboardTable.AdGroupsFilter;
      reader.readMessage(value,proto.grpcwebPb.DashboardTable.AdGroupsFilter.deserializeBinaryFromReader);
      msg.setAdGroupsFilter(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.DashboardTable.CriteriaFilter;
      reader.readMessage(value,proto.grpcwebPb.DashboardTable.CriteriaFilter.deserializeBinaryFromReader);
      msg.setCriteriaFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.ObjectFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable.ObjectFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.serializeBinaryToWriter
    );
  }
  f = message.getCampaignsFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.DashboardTable.CampaignsFilter.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupsFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.DashboardTable.AdGroupsFilter.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.DashboardTable.CriteriaFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlatformAccountsFilter accounts_filter = 1;
 * @return {?proto.grpcwebPb.DashboardTable.PlatformAccountsFilter}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.getAccountsFilter = function() {
  return /** @type{?proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardTable.PlatformAccountsFilter, 1));
};


/**
 * @param {?proto.grpcwebPb.DashboardTable.PlatformAccountsFilter|undefined} value
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
*/
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.setAccountsFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.clearAccountsFilter = function() {
  return this.setAccountsFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.hasAccountsFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CampaignsFilter campaigns_filter = 2;
 * @return {?proto.grpcwebPb.DashboardTable.CampaignsFilter}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.getCampaignsFilter = function() {
  return /** @type{?proto.grpcwebPb.DashboardTable.CampaignsFilter} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardTable.CampaignsFilter, 2));
};


/**
 * @param {?proto.grpcwebPb.DashboardTable.CampaignsFilter|undefined} value
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
*/
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.setCampaignsFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.clearCampaignsFilter = function() {
  return this.setCampaignsFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.hasCampaignsFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdGroupsFilter ad_groups_filter = 3;
 * @return {?proto.grpcwebPb.DashboardTable.AdGroupsFilter}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.getAdGroupsFilter = function() {
  return /** @type{?proto.grpcwebPb.DashboardTable.AdGroupsFilter} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardTable.AdGroupsFilter, 3));
};


/**
 * @param {?proto.grpcwebPb.DashboardTable.AdGroupsFilter|undefined} value
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
*/
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.setAdGroupsFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.clearAdGroupsFilter = function() {
  return this.setAdGroupsFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.hasAdGroupsFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CriteriaFilter criteria_filter = 4;
 * @return {?proto.grpcwebPb.DashboardTable.CriteriaFilter}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.getCriteriaFilter = function() {
  return /** @type{?proto.grpcwebPb.DashboardTable.CriteriaFilter} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardTable.CriteriaFilter, 4));
};


/**
 * @param {?proto.grpcwebPb.DashboardTable.CriteriaFilter|undefined} value
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
*/
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.setCriteriaFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.grpcwebPb.DashboardTable.ObjectFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter} returns this
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.clearCriteriaFilter = function() {
  return this.setCriteriaFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardTable.ObjectFilter.prototype.hasCriteriaFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignPlatform: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platformAccountIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter}
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable.PlatformAccountsFilter;
  return proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter}
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CampaignPlatform.Option} */ (reader.readEnum());
      msg.setCampaignPlatform(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlatformAccountIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPlatformAccountIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional common.CampaignPlatform.Option campaign_platform = 1;
 * @return {!proto.common.CampaignPlatform.Option}
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.getCampaignPlatform = function() {
  return /** @type {!proto.common.CampaignPlatform.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CampaignPlatform.Option} value
 * @return {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.setCampaignPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string platform_account_ids = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.getPlatformAccountIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.setPlatformAccountIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.addPlatformAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardTable.PlatformAccountsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.PlatformAccountsFilter.prototype.clearPlatformAccountIdsList = function() {
  return this.setPlatformAccountIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.CampaignsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable.CampaignsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    excludeCampaignIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter}
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable.CampaignsFilter;
  return proto.grpcwebPb.DashboardTable.CampaignsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable.CampaignsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter}
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaignIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addExcludeCampaignIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.CampaignsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable.CampaignsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getExcludeCampaignIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string campaign_ids = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.getCampaignIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.setCampaignIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.addCampaignIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.clearCampaignIdsList = function() {
  return this.setCampaignIdsList([]);
};


/**
 * repeated string exclude_campaign_ids = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.getExcludeCampaignIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.setExcludeCampaignIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.addExcludeCampaignIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardTable.CampaignsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CampaignsFilter.prototype.clearExcludeCampaignIdsList = function() {
  return this.setExcludeCampaignIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.AdGroupsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable.AdGroupsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable.AdGroupsFilter}
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable.AdGroupsFilter;
  return proto.grpcwebPb.DashboardTable.AdGroupsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable.AdGroupsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable.AdGroupsFilter}
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.AdGroupsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable.AdGroupsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ad_group_ids = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.prototype.getAdGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.DashboardTable.AdGroupsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.prototype.setAdGroupIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTable.AdGroupsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.prototype.addAdGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardTable.AdGroupsFilter} returns this
 */
proto.grpcwebPb.DashboardTable.AdGroupsFilter.prototype.clearAdGroupIdsList = function() {
  return this.setAdGroupIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTable.CriteriaFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTable.CriteriaFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTable.CriteriaFilter}
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTable.CriteriaFilter;
  return proto.grpcwebPb.DashboardTable.CriteriaFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTable.CriteriaFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTable.CriteriaFilter}
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCriteriaIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTable.CriteriaFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTable.CriteriaFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string criteria_ids = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.prototype.getCriteriaIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.DashboardTable.CriteriaFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.prototype.setCriteriaIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTable.CriteriaFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.prototype.addCriteriaIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardTable.CriteriaFilter} returns this
 */
proto.grpcwebPb.DashboardTable.CriteriaFilter.prototype.clearCriteriaIdsList = function() {
  return this.setCriteriaIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    queryByDay: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    retailer: jspb.Message.getFieldWithDefault(msg, 6, 0),
    objectTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.grpcwebPb.DashboardTable.ObjectFilter.toObject, includeInstance),
    skipConvertingRevenueValues: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    cacheBehavior: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSiteDashboardTableMetricsRequest;
  return proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueryByDay(value);
      break;
    case 6:
      var value = /** @type {!proto.common.Retailer.Option} */ (reader.readEnum());
      msg.setRetailer(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.grpcwebPb.DashboardTable.ObjectType.Option>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addObjectTypes(values[i]);
      }
      break;
    case 4:
      var value = new proto.grpcwebPb.DashboardTable.ObjectFilter;
      reader.readMessage(value,proto.grpcwebPb.DashboardTable.ObjectFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipConvertingRevenueValues(value);
      break;
    case 8:
      var value = /** @type {!proto.common.CacheBehavior.Option} */ (reader.readEnum());
      msg.setCacheBehavior(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getQueryByDay();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRetailer();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getObjectTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grpcwebPb.DashboardTable.ObjectFilter.serializeBinaryToWriter
    );
  }
  f = message.getSkipConvertingRevenueValues();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCacheBehavior();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.DateRangeV2 date_range = 2;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 2));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
*/
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool query_by_day = 7;
 * @return {boolean}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getQueryByDay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setQueryByDay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional common.Retailer.Option retailer = 6;
 * @return {!proto.common.Retailer.Option}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getRetailer = function() {
  return /** @type {!proto.common.Retailer.Option} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.Retailer.Option} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setRetailer = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated DashboardTable.ObjectType.Option object_types = 3;
 * @return {!Array<!proto.grpcwebPb.DashboardTable.ObjectType.Option>}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getObjectTypesList = function() {
  return /** @type {!Array<!proto.grpcwebPb.DashboardTable.ObjectType.Option>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardTable.ObjectType.Option>} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setObjectTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.grpcwebPb.DashboardTable.ObjectType.Option} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.addObjectTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.clearObjectTypesList = function() {
  return this.setObjectTypesList([]);
};


/**
 * repeated DashboardTable.ObjectFilter filters = 4;
 * @return {!Array<!proto.grpcwebPb.DashboardTable.ObjectFilter>}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardTable.ObjectFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.DashboardTable.ObjectFilter, 4));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardTable.ObjectFilter>} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
*/
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardTable.ObjectFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTable.ObjectFilter}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpcwebPb.DashboardTable.ObjectFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional bool skip_converting_revenue_values = 5;
 * @return {boolean}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getSkipConvertingRevenueValues = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setSkipConvertingRevenueValues = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional common.CacheBehavior.Option cache_behavior = 8;
 * @return {!proto.common.CacheBehavior.Option}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.getCacheBehavior = function() {
  return /** @type {!proto.common.CacheBehavior.Option} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.CacheBehavior.Option} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsRequest} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsRequest.prototype.setCacheBehavior = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSiteDashboardTableMetricsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardTableMetricsList: jspb.Message.toObjectList(msg.getDashboardTableMetricsList(),
    proto.grpcwebPb.DashboardTableMetrics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSiteDashboardTableMetricsReply;
  return proto.grpcwebPb.GetSiteDashboardTableMetricsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.DashboardTableMetrics;
      reader.readMessage(value,proto.grpcwebPb.DashboardTableMetrics.deserializeBinaryFromReader);
      msg.addDashboardTableMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSiteDashboardTableMetricsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardTableMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.DashboardTableMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardTableMetrics dashboard_table_metrics = 1;
 * @return {!Array<!proto.grpcwebPb.DashboardTableMetrics>}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.prototype.getDashboardTableMetricsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardTableMetrics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.DashboardTableMetrics, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardTableMetrics>} value
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply} returns this
*/
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.prototype.setDashboardTableMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardTableMetrics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardTableMetrics}
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.prototype.addDashboardTableMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.DashboardTableMetrics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSiteDashboardTableMetricsReply} returns this
 */
proto.grpcwebPb.GetSiteDashboardTableMetricsReply.prototype.clearDashboardTableMetricsList = function() {
  return this.setDashboardTableMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardTableMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardTableMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTableMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    objectType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignPlatform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    retailer: jspb.Message.getFieldWithDefault(msg, 44, 0),
    platformAccountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    costCurrencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    revenueCurrencyCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    queryText: jspb.Message.getFieldWithDefault(msg, 11, ""),
    productAsin: jspb.Message.getFieldWithDefault(msg, 12, ""),
    parentAsin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    brandName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    brandMembership: jspb.Message.getFieldWithDefault(msg, 15, 0),
    productName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    productGroup: jspb.Message.getFieldWithDefault(msg, 17, ""),
    productCategory: jspb.Message.getFieldWithDefault(msg, 18, ""),
    productSubcategory: jspb.Message.getFieldWithDefault(msg, 19, ""),
    impressions: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    clicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    detailPageViewClicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    addToCartClicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    conversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    unitsSold: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    cost: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    brandReferralBonus: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    adjustedCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    attributedClicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 45, 0.0),
    newToBrandConversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    newToBrandRevenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    newToBrandUnitsSold: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    unconvertedRevenueCurrencyCode: jspb.Message.getFieldWithDefault(msg, 33, ""),
    unconvertedRevenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    unconvertedBrandReferralBonus: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    unconvertedNewToBrandRevenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    impressionShare: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    lostImpressionShareLowRank: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    lostImpressionShareLowBudget: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    clickShare: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    firstPageCpc: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    firstPositionCpc: jspb.Message.getFloatingPointFieldWithDefault(msg, 42, 0.0),
    topOfPageCpc: jspb.Message.getFloatingPointFieldWithDefault(msg, 43, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardTableMetrics}
 */
proto.grpcwebPb.DashboardTableMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardTableMetrics;
  return proto.grpcwebPb.DashboardTableMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardTableMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardTableMetrics}
 */
proto.grpcwebPb.DashboardTableMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var value = /** @type {!proto.grpcwebPb.DashboardTable.ObjectType.Option} */ (reader.readEnum());
      msg.setObjectType(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CampaignPlatform.Option} */ (reader.readEnum());
      msg.setCampaignPlatform(value);
      break;
    case 44:
      var value = /** @type {!proto.common.Retailer.Option} */ (reader.readEnum());
      msg.setRetailer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatformAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCurrencyCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevenueCurrencyCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryText(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductAsin(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentAsin(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandName(value);
      break;
    case 15:
      var value = /** @type {!proto.common.Retailer.BrandMembership.Option} */ (reader.readEnum());
      msg.setBrandMembership(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductGroup(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductCategory(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductSubcategory(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setImpressions(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClicks(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDetailPageViewClicks(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAddToCartClicks(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConversions(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnitsSold(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCost(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBrandReferralBonus(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdjustedCost(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAttributedClicks(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNewToBrandConversions(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNewToBrandRevenue(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNewToBrandUnitsSold(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnconvertedRevenueCurrencyCode(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnconvertedRevenue(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnconvertedBrandReferralBonus(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnconvertedNewToBrandRevenue(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setImpressionShare(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLostImpressionShareLowRank(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLostImpressionShareLowBudget(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClickShare(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFirstPageCpc(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFirstPositionCpc(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTopOfPageCpc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardTableMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardTableMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardTableMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCampaignPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRetailer();
  if (f !== 0.0) {
    writer.writeEnum(
      44,
      f
    );
  }
  f = message.getPlatformAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCostCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRevenueCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQueryText();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProductAsin();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getParentAsin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBrandName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBrandMembership();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getProductGroup();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getProductCategory();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getProductSubcategory();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getImpressions();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getClicks();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getDetailPageViewClicks();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getAddToCartClicks();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getConversions();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getUnitsSold();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getCost();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getBrandReferralBonus();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getAdjustedCost();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getAttributedClicks();
  if (f !== 0.0) {
    writer.writeDouble(
      45,
      f
    );
  }
  f = message.getNewToBrandConversions();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getNewToBrandRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getNewToBrandUnitsSold();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getUnconvertedRevenueCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getUnconvertedRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getUnconvertedBrandReferralBonus();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getUnconvertedNewToBrandRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getImpressionShare();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getLostImpressionShareLowRank();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getLostImpressionShareLowBudget();
  if (f !== 0.0) {
    writer.writeDouble(
      39,
      f
    );
  }
  f = message.getClickShare();
  if (f !== 0.0) {
    writer.writeDouble(
      40,
      f
    );
  }
  f = message.getFirstPageCpc();
  if (f !== 0.0) {
    writer.writeDouble(
      41,
      f
    );
  }
  f = message.getFirstPositionCpc();
  if (f !== 0.0) {
    writer.writeDouble(
      42,
      f
    );
  }
  f = message.getTopOfPageCpc();
  if (f !== 0.0) {
    writer.writeDouble(
      43,
      f
    );
  }
};


/**
 * optional common.DateRangeV2 date_range = 1;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 1));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
*/
proto.grpcwebPb.DashboardTableMetrics.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardTable.ObjectType.Option object_type = 2;
 * @return {!proto.grpcwebPb.DashboardTable.ObjectType.Option}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getObjectType = function() {
  return /** @type {!proto.grpcwebPb.DashboardTable.ObjectType.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grpcwebPb.DashboardTable.ObjectType.Option} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.CampaignPlatform.Option campaign_platform = 3;
 * @return {!proto.common.CampaignPlatform.Option}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getCampaignPlatform = function() {
  return /** @type {!proto.common.CampaignPlatform.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CampaignPlatform.Option} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setCampaignPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional common.Retailer.Option retailer = 44;
 * @return {!proto.common.Retailer.Option}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getRetailer = function() {
  return /** @type {!proto.common.Retailer.Option} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {!proto.common.Retailer.Option} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setRetailer = function(value) {
  return jspb.Message.setProto3EnumField(this, 44, value);
};


/**
 * optional string platform_account_id = 4;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getPlatformAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setPlatformAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cost_currency_code = 6;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getCostCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setCostCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string revenue_currency_code = 7;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getRevenueCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setRevenueCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string campaign_id = 8;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ad_group_id = 9;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string criteria_id = 10;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string query_text = 11;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getQueryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setQueryText = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string product_asin = 12;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getProductAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setProductAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string parent_asin = 13;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getParentAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setParentAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string brand_name = 14;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getBrandName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setBrandName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional common.Retailer.BrandMembership.Option brand_membership = 15;
 * @return {!proto.common.Retailer.BrandMembership.Option}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getBrandMembership = function() {
  return /** @type {!proto.common.Retailer.BrandMembership.Option} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.common.Retailer.BrandMembership.Option} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setBrandMembership = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string product_name = 16;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string product_group = 17;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getProductGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setProductGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string product_category = 18;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getProductCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setProductCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string product_subcategory = 19;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getProductSubcategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setProductSubcategory = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional double impressions = 20;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getImpressions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setImpressions = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double clicks = 21;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double detail_page_view_clicks = 22;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getDetailPageViewClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setDetailPageViewClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double add_to_cart_clicks = 23;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getAddToCartClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setAddToCartClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double conversions = 24;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double units_sold = 25;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getUnitsSold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setUnitsSold = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double cost = 26;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double revenue = 27;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double brand_referral_bonus = 28;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getBrandReferralBonus = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setBrandReferralBonus = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double adjusted_cost = 29;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getAdjustedCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setAdjustedCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double attributed_clicks = 45;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getAttributedClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 45, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setAttributedClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 45, value);
};


/**
 * optional double new_to_brand_conversions = 30;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getNewToBrandConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setNewToBrandConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double new_to_brand_revenue = 31;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getNewToBrandRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setNewToBrandRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double new_to_brand_units_sold = 32;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getNewToBrandUnitsSold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setNewToBrandUnitsSold = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional string unconverted_revenue_currency_code = 33;
 * @return {string}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getUnconvertedRevenueCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setUnconvertedRevenueCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional double unconverted_revenue = 34;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getUnconvertedRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setUnconvertedRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double unconverted_brand_referral_bonus = 35;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getUnconvertedBrandReferralBonus = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setUnconvertedBrandReferralBonus = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double unconverted_new_to_brand_revenue = 36;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getUnconvertedNewToBrandRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setUnconvertedNewToBrandRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double impression_share = 37;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getImpressionShare = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setImpressionShare = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double lost_impression_share_low_rank = 38;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getLostImpressionShareLowRank = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setLostImpressionShareLowRank = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional double lost_impression_share_low_budget = 39;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getLostImpressionShareLowBudget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setLostImpressionShareLowBudget = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional double click_share = 40;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getClickShare = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setClickShare = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional double first_page_cpc = 41;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getFirstPageCpc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setFirstPageCpc = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional double first_position_cpc = 42;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getFirstPositionCpc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setFirstPositionCpc = function(value) {
  return jspb.Message.setProto3FloatField(this, 42, value);
};


/**
 * optional double top_of_page_cpc = 43;
 * @return {number}
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.getTopOfPageCpc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 43, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardTableMetrics} returns this
 */
proto.grpcwebPb.DashboardTableMetrics.prototype.setTopOfPageCpc = function(value) {
  return jspb.Message.setProto3FloatField(this, 43, value);
};


goog.object.extend(exports, proto.grpcwebPb);
