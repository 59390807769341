// source: google/ads/googleads/v18/common/asset_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_criteria_pb = require('../../../../../google/ads/googleads/v18/common/criteria_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_criteria_pb);
var google_ads_googleads_v18_common_feed_common_pb = require('../../../../../google/ads/googleads/v18/common/feed_common_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_feed_common_pb);
var google_ads_googleads_v18_enums_call_conversion_reporting_state_pb = require('../../../../../google/ads/googleads/v18/enums/call_conversion_reporting_state_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_call_conversion_reporting_state_pb);
var google_ads_googleads_v18_enums_call_to_action_type_pb = require('../../../../../google/ads/googleads/v18/enums/call_to_action_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_call_to_action_type_pb);
var google_ads_googleads_v18_enums_lead_form_call_to_action_type_pb = require('../../../../../google/ads/googleads/v18/enums/lead_form_call_to_action_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_lead_form_call_to_action_type_pb);
var google_ads_googleads_v18_enums_lead_form_desired_intent_pb = require('../../../../../google/ads/googleads/v18/enums/lead_form_desired_intent_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_lead_form_desired_intent_pb);
var google_ads_googleads_v18_enums_lead_form_field_user_input_type_pb = require('../../../../../google/ads/googleads/v18/enums/lead_form_field_user_input_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_lead_form_field_user_input_type_pb);
var google_ads_googleads_v18_enums_lead_form_post_submit_call_to_action_type_pb = require('../../../../../google/ads/googleads/v18/enums/lead_form_post_submit_call_to_action_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_lead_form_post_submit_call_to_action_type_pb);
var google_ads_googleads_v18_enums_location_ownership_type_pb = require('../../../../../google/ads/googleads/v18/enums/location_ownership_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_location_ownership_type_pb);
var google_ads_googleads_v18_enums_mime_type_pb = require('../../../../../google/ads/googleads/v18/enums/mime_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_mime_type_pb);
var google_ads_googleads_v18_enums_mobile_app_vendor_pb = require('../../../../../google/ads/googleads/v18/enums/mobile_app_vendor_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_mobile_app_vendor_pb);
var google_ads_googleads_v18_enums_price_extension_price_qualifier_pb = require('../../../../../google/ads/googleads/v18/enums/price_extension_price_qualifier_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_price_extension_price_qualifier_pb);
var google_ads_googleads_v18_enums_price_extension_price_unit_pb = require('../../../../../google/ads/googleads/v18/enums/price_extension_price_unit_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_price_extension_price_unit_pb);
var google_ads_googleads_v18_enums_price_extension_type_pb = require('../../../../../google/ads/googleads/v18/enums/price_extension_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_price_extension_type_pb);
var google_ads_googleads_v18_enums_promotion_extension_discount_modifier_pb = require('../../../../../google/ads/googleads/v18/enums/promotion_extension_discount_modifier_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_promotion_extension_discount_modifier_pb);
var google_ads_googleads_v18_enums_promotion_extension_occasion_pb = require('../../../../../google/ads/googleads/v18/enums/promotion_extension_occasion_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_promotion_extension_occasion_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.common.BookOnGoogleAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.BusinessProfileLocation', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CallAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CallToActionAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.CalloutAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicCustomAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicEducationAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicFlightsAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicJobsAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicLocalAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicRealEstateAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.DynamicTravelAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelCalloutAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.HotelPropertyAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ImageAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.ImageDimension', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.AnswersCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.DeliveryDetailsCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormField', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormField.AnswersCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.LocationAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.MediaBundleAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.MobileAppAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PageFeedAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PriceAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PriceOffering', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PromotionAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PromotionAsset.DiscountTypeCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.PromotionAsset.PromotionTriggerCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.SitelinkAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.StructuredSnippetAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.TextAsset', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.WebhookDelivery', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.common.YoutubeVideoAsset', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.YoutubeVideoAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.YoutubeVideoAsset.displayName = 'proto.google.ads.googleads.v18.common.YoutubeVideoAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.MediaBundleAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.MediaBundleAsset.displayName = 'proto.google.ads.googleads.v18.common.MediaBundleAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ImageAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ImageAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ImageAsset.displayName = 'proto.google.ads.googleads.v18.common.ImageAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.ImageDimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.ImageDimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.ImageDimension.displayName = 'proto.google.ads.googleads.v18.common.ImageDimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.TextAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.TextAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.TextAsset.displayName = 'proto.google.ads.googleads.v18.common.TextAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LeadFormAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.LeadFormAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LeadFormAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LeadFormAsset.displayName = 'proto.google.ads.googleads.v18.common.LeadFormAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LeadFormField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.LeadFormField.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.LeadFormField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LeadFormField.displayName = 'proto.google.ads.googleads.v18.common.LeadFormField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.displayName = 'proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.displayName = 'proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.displayName = 'proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.WebhookDelivery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.WebhookDelivery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.WebhookDelivery.displayName = 'proto.google.ads.googleads.v18.common.WebhookDelivery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.BookOnGoogleAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.BookOnGoogleAsset.displayName = 'proto.google.ads.googleads.v18.common.BookOnGoogleAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PromotionAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PromotionAsset.repeatedFields_, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.common.PromotionAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PromotionAsset.displayName = 'proto.google.ads.googleads.v18.common.PromotionAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CalloutAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.CalloutAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CalloutAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CalloutAsset.displayName = 'proto.google.ads.googleads.v18.common.CalloutAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.StructuredSnippetAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.StructuredSnippetAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.StructuredSnippetAsset.displayName = 'proto.google.ads.googleads.v18.common.StructuredSnippetAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.SitelinkAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.SitelinkAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.SitelinkAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.SitelinkAsset.displayName = 'proto.google.ads.googleads.v18.common.SitelinkAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PageFeedAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PageFeedAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PageFeedAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PageFeedAsset.displayName = 'proto.google.ads.googleads.v18.common.PageFeedAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicEducationAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicEducationAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicEducationAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicEducationAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.MobileAppAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.MobileAppAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.MobileAppAsset.displayName = 'proto.google.ads.googleads.v18.common.MobileAppAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelCalloutAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelCalloutAsset.displayName = 'proto.google.ads.googleads.v18.common.HotelCalloutAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CallAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.CallAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CallAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CallAsset.displayName = 'proto.google.ads.googleads.v18.common.CallAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PriceAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.PriceAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PriceAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PriceAsset.displayName = 'proto.google.ads.googleads.v18.common.PriceAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.PriceOffering = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.PriceOffering, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.PriceOffering.displayName = 'proto.google.ads.googleads.v18.common.PriceOffering';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.CallToActionAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.CallToActionAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.CallToActionAsset.displayName = 'proto.google.ads.googleads.v18.common.CallToActionAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicRealEstateAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicRealEstateAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicCustomAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicCustomAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicCustomAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicCustomAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicFlightsAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicFlightsAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicFlightsAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicFlightsAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.displayName = 'proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicTravelAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicTravelAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicTravelAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicTravelAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicLocalAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicLocalAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicLocalAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicLocalAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.DynamicJobsAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.DynamicJobsAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.DynamicJobsAsset.displayName = 'proto.google.ads.googleads.v18.common.DynamicJobsAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.LocationAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.LocationAsset.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.LocationAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.LocationAsset.displayName = 'proto.google.ads.googleads.v18.common.LocationAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.common.BusinessProfileLocation.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.BusinessProfileLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.BusinessProfileLocation.displayName = 'proto.google.ads.googleads.v18.common.BusinessProfileLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.common.HotelPropertyAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.common.HotelPropertyAsset.displayName = 'proto.google.ads.googleads.v18.common.HotelPropertyAsset';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.YoutubeVideoAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    youtubeVideoId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    youtubeVideoTitle: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.YoutubeVideoAsset;
  return proto.google.ads.googleads.v18.common.YoutubeVideoAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubeVideoId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubeVideoTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.YoutubeVideoAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYoutubeVideoTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string youtube_video_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.getYoutubeVideoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset} returns this
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.setYoutubeVideoId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset} returns this
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.clearYoutubeVideoId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.hasYoutubeVideoId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string youtube_video_title = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.getYoutubeVideoTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.YoutubeVideoAsset} returns this
 */
proto.google.ads.googleads.v18.common.YoutubeVideoAsset.prototype.setYoutubeVideoTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.MediaBundleAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.MediaBundleAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.MediaBundleAsset}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.MediaBundleAsset;
  return proto.google.ads.googleads.v18.common.MediaBundleAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.MediaBundleAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.MediaBundleAsset}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.MediaBundleAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.MediaBundleAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.google.ads.googleads.v18.common.MediaBundleAsset} returns this
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.setData = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.MediaBundleAsset} returns this
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.clearData = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.MediaBundleAsset.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ImageAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ImageAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ImageAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    fileSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mimeType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fullSize: (f = msg.getFullSize()) && proto.google.ads.googleads.v18.common.ImageDimension.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset}
 */
proto.google.ads.googleads.v18.common.ImageAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ImageAsset;
  return proto.google.ads.googleads.v18.common.ImageAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ImageAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset}
 */
proto.google.ads.googleads.v18.common.ImageAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileSize(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType} */ (reader.readEnum());
      msg.setMimeType(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.ImageDimension;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.ImageDimension.deserializeBinaryFromReader);
      msg.setFullSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ImageAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ImageAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ImageAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMimeType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFullSize();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.ImageDimension.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes data = 5;
 * @return {!(string|Uint8Array)}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes data = 5;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.setData = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.clearData = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 file_size = 6;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.getFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.setFileSize = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.clearFileSize = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.hasFileSize = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.enums.MimeTypeEnum.MimeType mime_type = 3;
 * @return {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.getMimeType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.MimeTypeEnum.MimeType} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ImageDimension full_size = 4;
 * @return {?proto.google.ads.googleads.v18.common.ImageDimension}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.getFullSize = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ImageDimension} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.ImageDimension, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ImageDimension|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
*/
proto.google.ads.googleads.v18.common.ImageAsset.prototype.setFullSize = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageAsset} returns this
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.clearFullSize = function() {
  return this.setFullSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageAsset.prototype.hasFullSize = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.ImageDimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.ImageDimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ImageDimension.toObject = function(includeInstance, msg) {
  var f, obj = {
    heightPixels: jspb.Message.getFieldWithDefault(msg, 4, 0),
    widthPixels: jspb.Message.getFieldWithDefault(msg, 5, 0),
    url: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension}
 */
proto.google.ads.googleads.v18.common.ImageDimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.ImageDimension;
  return proto.google.ads.googleads.v18.common.ImageDimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.ImageDimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension}
 */
proto.google.ads.googleads.v18.common.ImageDimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeightPixels(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidthPixels(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.ImageDimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.ImageDimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.ImageDimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 height_pixels = 4;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.getHeightPixels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension} returns this
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.setHeightPixels = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension} returns this
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.clearHeightPixels = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.hasHeightPixels = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 width_pixels = 5;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.getWidthPixels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension} returns this
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.setWidthPixels = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension} returns this
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.clearWidthPixels = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.hasWidthPixels = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension} returns this
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.setUrl = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.ImageDimension} returns this
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.clearUrl = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.ImageDimension.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.TextAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.TextAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.TextAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TextAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.TextAsset}
 */
proto.google.ads.googleads.v18.common.TextAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.TextAsset;
  return proto.google.ads.googleads.v18.common.TextAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.TextAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.TextAsset}
 */
proto.google.ads.googleads.v18.common.TextAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.TextAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.TextAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.TextAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.TextAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.TextAsset.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.TextAsset} returns this
 */
proto.google.ads.googleads.v18.common.TextAsset.prototype.setText = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.TextAsset} returns this
 */
proto.google.ads.googleads.v18.common.TextAsset.prototype.clearText = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.TextAsset.prototype.hasText = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.repeatedFields_ = [8,23,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LeadFormAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LeadFormAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    callToActionType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    callToActionDescription: jspb.Message.getFieldWithDefault(msg, 18, ""),
    headline: jspb.Message.getFieldWithDefault(msg, 12, ""),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    privacyPolicyUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    postSubmitHeadline: jspb.Message.getFieldWithDefault(msg, 15, ""),
    postSubmitDescription: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.google.ads.googleads.v18.common.LeadFormField.toObject, includeInstance),
    customQuestionFieldsList: jspb.Message.toObjectList(msg.getCustomQuestionFieldsList(),
    proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.toObject, includeInstance),
    deliveryMethodsList: jspb.Message.toObjectList(msg.getDeliveryMethodsList(),
    proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.toObject, includeInstance),
    postSubmitCallToActionType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    backgroundImageAsset: jspb.Message.getFieldWithDefault(msg, 20, ""),
    desiredIntent: jspb.Message.getFieldWithDefault(msg, 21, 0),
    customDisclosure: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LeadFormAsset;
  return proto.google.ads.googleads.v18.common.LeadFormAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 17:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LeadFormCallToActionTypeEnum.LeadFormCallToActionType} */ (reader.readEnum());
      msg.setCallToActionType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivacyPolicyUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostSubmitHeadline(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostSubmitDescription(value);
      break;
    case 8:
      var value = new proto.google.ads.googleads.v18.common.LeadFormField;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.LeadFormField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 23:
      var value = new proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.deserializeBinaryFromReader);
      msg.addCustomQuestionFields(value);
      break;
    case 9:
      var value = new proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.deserializeBinaryFromReader);
      msg.addDeliveryMethods(value);
      break;
    case 19:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LeadFormPostSubmitCallToActionTypeEnum.LeadFormPostSubmitCallToActionType} */ (reader.readEnum());
      msg.setPostSubmitCallToActionType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundImageAsset(value);
      break;
    case 21:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LeadFormDesiredIntentEnum.LeadFormDesiredIntent} */ (reader.readEnum());
      msg.setDesiredIntent(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomDisclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LeadFormAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCallToActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getCallToActionDescription();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getHeadline();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPrivacyPolicyUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.google.ads.googleads.v18.common.LeadFormField.serializeBinaryToWriter
    );
  }
  f = message.getCustomQuestionFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.serializeBinaryToWriter
    );
  }
  f = message.getPostSubmitCallToActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDesiredIntent();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string business_name = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setBusinessName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.ads.googleads.v18.enums.LeadFormCallToActionTypeEnum.LeadFormCallToActionType call_to_action_type = 17;
 * @return {!proto.google.ads.googleads.v18.enums.LeadFormCallToActionTypeEnum.LeadFormCallToActionType}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getCallToActionType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LeadFormCallToActionTypeEnum.LeadFormCallToActionType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LeadFormCallToActionTypeEnum.LeadFormCallToActionType} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setCallToActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string call_to_action_description = 18;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getCallToActionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setCallToActionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string headline = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string privacy_policy_url = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getPrivacyPolicyUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setPrivacyPolicyUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string post_submit_headline = 15;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getPostSubmitHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setPostSubmitHeadline = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearPostSubmitHeadline = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.hasPostSubmitHeadline = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string post_submit_description = 16;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getPostSubmitDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setPostSubmitDescription = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearPostSubmitDescription = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.hasPostSubmitDescription = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated LeadFormField fields = 8;
 * @return {!Array<!proto.google.ads.googleads.v18.common.LeadFormField>}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.LeadFormField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.LeadFormField, 8));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.LeadFormField>} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
*/
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.LeadFormField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.ads.googleads.v18.common.LeadFormField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * repeated LeadFormCustomQuestionField custom_question_fields = 23;
 * @return {!Array<!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField>}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getCustomQuestionFieldsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField, 23));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField>} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
*/
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setCustomQuestionFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.addCustomQuestionFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearCustomQuestionFieldsList = function() {
  return this.setCustomQuestionFieldsList([]);
};


/**
 * repeated LeadFormDeliveryMethod delivery_methods = 9;
 * @return {!Array<!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod>}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getDeliveryMethodsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod, 9));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod>} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
*/
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setDeliveryMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.addDeliveryMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearDeliveryMethodsList = function() {
  return this.setDeliveryMethodsList([]);
};


/**
 * optional google.ads.googleads.v18.enums.LeadFormPostSubmitCallToActionTypeEnum.LeadFormPostSubmitCallToActionType post_submit_call_to_action_type = 19;
 * @return {!proto.google.ads.googleads.v18.enums.LeadFormPostSubmitCallToActionTypeEnum.LeadFormPostSubmitCallToActionType}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getPostSubmitCallToActionType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LeadFormPostSubmitCallToActionTypeEnum.LeadFormPostSubmitCallToActionType} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LeadFormPostSubmitCallToActionTypeEnum.LeadFormPostSubmitCallToActionType} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setPostSubmitCallToActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string background_image_asset = 20;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getBackgroundImageAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setBackgroundImageAsset = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearBackgroundImageAsset = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.hasBackgroundImageAsset = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.ads.googleads.v18.enums.LeadFormDesiredIntentEnum.LeadFormDesiredIntent desired_intent = 21;
 * @return {!proto.google.ads.googleads.v18.enums.LeadFormDesiredIntentEnum.LeadFormDesiredIntent}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getDesiredIntent = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LeadFormDesiredIntentEnum.LeadFormDesiredIntent} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LeadFormDesiredIntentEnum.LeadFormDesiredIntent} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setDesiredIntent = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional string custom_disclosure = 22;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.getCustomDisclosure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.setCustomDisclosure = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormAsset} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.clearCustomDisclosure = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormAsset.prototype.hasCustomDisclosure = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.LeadFormField.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.LeadFormField.AnswersCase = {
  ANSWERS_NOT_SET: 0,
  SINGLE_CHOICE_ANSWERS: 2,
  HAS_LOCATION_ANSWER: 3
};

/**
 * @return {proto.google.ads.googleads.v18.common.LeadFormField.AnswersCase}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.getAnswersCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.LeadFormField.AnswersCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.LeadFormField.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LeadFormField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LeadFormField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormField.toObject = function(includeInstance, msg) {
  var f, obj = {
    inputType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    singleChoiceAnswers: (f = msg.getSingleChoiceAnswers()) && proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.toObject(includeInstance, f),
    hasLocationAnswer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField}
 */
proto.google.ads.googleads.v18.common.LeadFormField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LeadFormField;
  return proto.google.ads.googleads.v18.common.LeadFormField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField}
 */
proto.google.ads.googleads.v18.common.LeadFormField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LeadFormFieldUserInputTypeEnum.LeadFormFieldUserInputType} */ (reader.readEnum());
      msg.setInputType(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.deserializeBinaryFromReader);
      msg.setSingleChoiceAnswers(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLocationAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LeadFormField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInputType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSingleChoiceAnswers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.LeadFormFieldUserInputTypeEnum.LeadFormFieldUserInputType input_type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.LeadFormFieldUserInputTypeEnum.LeadFormFieldUserInputType}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.getInputType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LeadFormFieldUserInputTypeEnum.LeadFormFieldUserInputType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LeadFormFieldUserInputTypeEnum.LeadFormFieldUserInputType} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.setInputType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional LeadFormSingleChoiceAnswers single_choice_answers = 2;
 * @return {?proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.getSingleChoiceAnswers = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField} returns this
*/
proto.google.ads.googleads.v18.common.LeadFormField.prototype.setSingleChoiceAnswers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v18.common.LeadFormField.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.clearSingleChoiceAnswers = function() {
  return this.setSingleChoiceAnswers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.hasSingleChoiceAnswers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool has_location_answer = 3;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.getHasLocationAnswer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.setHasLocationAnswer = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.google.ads.googleads.v18.common.LeadFormField.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.clearHasLocationAnswer = function() {
  return jspb.Message.setOneofField(this, 3, proto.google.ads.googleads.v18.common.LeadFormField.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormField.prototype.hasHasLocationAnswer = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.AnswersCase = {
  ANSWERS_NOT_SET: 0,
  SINGLE_CHOICE_ANSWERS: 2,
  HAS_LOCATION_ANSWER: 3
};

/**
 * @return {proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.AnswersCase}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.getAnswersCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.AnswersCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.toObject = function(includeInstance, msg) {
  var f, obj = {
    customQuestionText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    singleChoiceAnswers: (f = msg.getSingleChoiceAnswers()) && proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.toObject(includeInstance, f),
    hasLocationAnswer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField;
  return proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomQuestionText(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.deserializeBinaryFromReader);
      msg.setSingleChoiceAnswers(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLocationAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomQuestionText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSingleChoiceAnswers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string custom_question_text = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.getCustomQuestionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.setCustomQuestionText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LeadFormSingleChoiceAnswers single_choice_answers = 2;
 * @return {?proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.getSingleChoiceAnswers = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers, 2));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} returns this
*/
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.setSingleChoiceAnswers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.clearSingleChoiceAnswers = function() {
  return this.setSingleChoiceAnswers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.hasSingleChoiceAnswers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool has_location_answer = 3;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.getHasLocationAnswer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.setHasLocationAnswer = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.clearHasLocationAnswer = function() {
  return jspb.Message.setOneofField(this, 3, proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormCustomQuestionField.prototype.hasHasLocationAnswer = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers}
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers;
  return proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers}
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string answers = 1;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.prototype.getAnswersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.prototype.setAnswersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.prototype.addAnswers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormSingleChoiceAnswers.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.DeliveryDetailsCase = {
  DELIVERY_DETAILS_NOT_SET: 0,
  WEBHOOK: 1
};

/**
 * @return {proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.DeliveryDetailsCase}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.getDeliveryDetailsCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.DeliveryDetailsCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    webhook: (f = msg.getWebhook()) && proto.google.ads.googleads.v18.common.WebhookDelivery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod;
  return proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v18.common.WebhookDelivery;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.WebhookDelivery.deserializeBinaryFromReader);
      msg.setWebhook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebhook();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v18.common.WebhookDelivery.serializeBinaryToWriter
    );
  }
};


/**
 * optional WebhookDelivery webhook = 1;
 * @return {?proto.google.ads.googleads.v18.common.WebhookDelivery}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.getWebhook = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.WebhookDelivery} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.common.WebhookDelivery, 1));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.WebhookDelivery|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod} returns this
*/
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.setWebhook = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod} returns this
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.clearWebhook = function() {
  return this.setWebhook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.LeadFormDeliveryMethod.prototype.hasWebhook = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.WebhookDelivery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.WebhookDelivery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertiserWebhookUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    googleSecret: jspb.Message.getFieldWithDefault(msg, 5, ""),
    payloadSchemaVersion: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.WebhookDelivery;
  return proto.google.ads.googleads.v18.common.WebhookDelivery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.WebhookDelivery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertiserWebhookUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoogleSecret(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadSchemaVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.WebhookDelivery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.WebhookDelivery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string advertiser_webhook_url = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.getAdvertiserWebhookUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery} returns this
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.setAdvertiserWebhookUrl = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery} returns this
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.clearAdvertiserWebhookUrl = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.hasAdvertiserWebhookUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string google_secret = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.getGoogleSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery} returns this
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.setGoogleSecret = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery} returns this
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.clearGoogleSecret = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.hasGoogleSecret = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 payload_schema_version = 6;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.getPayloadSchemaVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery} returns this
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.setPayloadSchemaVersion = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.WebhookDelivery} returns this
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.clearPayloadSchemaVersion = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.WebhookDelivery.prototype.hasPayloadSchemaVersion = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.BookOnGoogleAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.BookOnGoogleAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.BookOnGoogleAsset}
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.BookOnGoogleAsset;
  return proto.google.ads.googleads.v18.common.BookOnGoogleAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.BookOnGoogleAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.BookOnGoogleAsset}
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.BookOnGoogleAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.BookOnGoogleAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BookOnGoogleAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PromotionAsset.repeatedFields_ = [13];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_ = [[3,4],[5,6]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.DiscountTypeCase = {
  DISCOUNT_TYPE_NOT_SET: 0,
  PERCENT_OFF: 3,
  MONEY_AMOUNT_OFF: 4
};

/**
 * @return {proto.google.ads.googleads.v18.common.PromotionAsset.DiscountTypeCase}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getDiscountTypeCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.PromotionAsset.DiscountTypeCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.PromotionTriggerCase = {
  PROMOTION_TRIGGER_NOT_SET: 0,
  PROMOTION_CODE: 5,
  ORDERS_OVER_AMOUNT: 6
};

/**
 * @return {proto.google.ads.googleads.v18.common.PromotionAsset.PromotionTriggerCase}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getPromotionTriggerCase = function() {
  return /** @type {proto.google.ads.googleads.v18.common.PromotionAsset.PromotionTriggerCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PromotionAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PromotionAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PromotionAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    promotionTarget: jspb.Message.getFieldWithDefault(msg, 1, ""),
    discountModifier: jspb.Message.getFieldWithDefault(msg, 2, 0),
    redemptionStartDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    redemptionEndDate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    occasion: jspb.Message.getFieldWithDefault(msg, 9, 0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    adScheduleTargetsList: jspb.Message.toObjectList(msg.getAdScheduleTargetsList(),
    google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.toObject, includeInstance),
    percentOff: jspb.Message.getFieldWithDefault(msg, 3, 0),
    moneyAmountOff: (f = msg.getMoneyAmountOff()) && google_ads_googleads_v18_common_feed_common_pb.Money.toObject(includeInstance, f),
    promotionCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ordersOverAmount: (f = msg.getOrdersOverAmount()) && google_ads_googleads_v18_common_feed_common_pb.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PromotionAsset;
  return proto.google.ads.googleads.v18.common.PromotionAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PromotionAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionTarget(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PromotionExtensionDiscountModifierEnum.PromotionExtensionDiscountModifier} */ (reader.readEnum());
      msg.setDiscountModifier(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedemptionStartDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedemptionEndDate(value);
      break;
    case 9:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PromotionExtensionOccasionEnum.PromotionExtensionOccasion} */ (reader.readEnum());
      msg.setOccasion(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 13:
      var value = new google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.deserializeBinaryFromReader);
      msg.addAdScheduleTargets(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentOff(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_feed_common_pb.Money;
      reader.readMessage(value,google_ads_googleads_v18_common_feed_common_pb.Money.deserializeBinaryFromReader);
      msg.setMoneyAmountOff(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionCode(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_feed_common_pb.Money;
      reader.readMessage(value,google_ads_googleads_v18_common_feed_common_pb.Money.deserializeBinaryFromReader);
      msg.setOrdersOverAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PromotionAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PromotionAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PromotionAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromotionTarget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiscountModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRedemptionStartDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRedemptionEndDate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOccasion();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAdScheduleTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMoneyAmountOff();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_ads_googleads_v18_common_feed_common_pb.Money.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrdersOverAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_feed_common_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional string promotion_target = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getPromotionTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setPromotionTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.PromotionExtensionDiscountModifierEnum.PromotionExtensionDiscountModifier discount_modifier = 2;
 * @return {!proto.google.ads.googleads.v18.enums.PromotionExtensionDiscountModifierEnum.PromotionExtensionDiscountModifier}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getDiscountModifier = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PromotionExtensionDiscountModifierEnum.PromotionExtensionDiscountModifier} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PromotionExtensionDiscountModifierEnum.PromotionExtensionDiscountModifier} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setDiscountModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string redemption_start_date = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getRedemptionStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setRedemptionStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string redemption_end_date = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getRedemptionEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setRedemptionEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.ads.googleads.v18.enums.PromotionExtensionOccasionEnum.PromotionExtensionOccasion occasion = 9;
 * @return {!proto.google.ads.googleads.v18.enums.PromotionExtensionOccasionEnum.PromotionExtensionOccasion}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getOccasion = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PromotionExtensionOccasionEnum.PromotionExtensionOccasion} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PromotionExtensionOccasionEnum.PromotionExtensionOccasion} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setOccasion = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string language_code = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string start_date = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string end_date = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated AdScheduleInfo ad_schedule_targets = 13;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getAdScheduleTargetsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo, 13));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
*/
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setAdScheduleTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.addAdScheduleTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.google.ads.googleads.v18.common.AdScheduleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.clearAdScheduleTargetsList = function() {
  return this.setAdScheduleTargetsList([]);
};


/**
 * optional int64 percent_off = 3;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getPercentOff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setPercentOff = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.clearPercentOff = function() {
  return jspb.Message.setOneofField(this, 3, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.hasPercentOff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Money money_amount_off = 4;
 * @return {?proto.google.ads.googleads.v18.common.Money}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getMoneyAmountOff = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.Money} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_feed_common_pb.Money, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.Money|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
*/
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setMoneyAmountOff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.clearMoneyAmountOff = function() {
  return this.setMoneyAmountOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.hasMoneyAmountOff = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string promotion_code = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getPromotionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setPromotionCode = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.clearPromotionCode = function() {
  return jspb.Message.setOneofField(this, 5, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.hasPromotionCode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Money orders_over_amount = 6;
 * @return {?proto.google.ads.googleads.v18.common.Money}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.getOrdersOverAmount = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.Money} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_feed_common_pb.Money, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.Money|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
*/
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.setOrdersOverAmount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.common.PromotionAsset.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PromotionAsset} returns this
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.clearOrdersOverAmount = function() {
  return this.setOrdersOverAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PromotionAsset.prototype.hasOrdersOverAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.CalloutAsset.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CalloutAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CalloutAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CalloutAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    calloutText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adScheduleTargetsList: jspb.Message.toObjectList(msg.getAdScheduleTargetsList(),
    google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CalloutAsset;
  return proto.google.ads.googleads.v18.common.CalloutAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CalloutAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalloutText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.deserializeBinaryFromReader);
      msg.addAdScheduleTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CalloutAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CalloutAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CalloutAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalloutText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdScheduleTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string callout_text = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.getCalloutText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset} returns this
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.setCalloutText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string start_date = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset} returns this
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_date = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset} returns this
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AdScheduleInfo ad_schedule_targets = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.getAdScheduleTargetsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset} returns this
*/
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.setAdScheduleTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.addAdScheduleTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.AdScheduleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.CalloutAsset} returns this
 */
proto.google.ads.googleads.v18.common.CalloutAsset.prototype.clearAdScheduleTargetsList = function() {
  return this.setAdScheduleTargetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.StructuredSnippetAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset}
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.StructuredSnippetAsset;
  return proto.google.ads.googleads.v18.common.StructuredSnippetAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset}
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.StructuredSnippetAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string header = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.getHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} returns this
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.setHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} returns this
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} returns this
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.StructuredSnippetAsset} returns this
 */
proto.google.ads.googleads.v18.common.StructuredSnippetAsset.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.SitelinkAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.SitelinkAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adScheduleTargetsList: jspb.Message.toObjectList(msg.getAdScheduleTargetsList(),
    google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.SitelinkAsset;
  return proto.google.ads.googleads.v18.common.SitelinkAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.SitelinkAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.deserializeBinaryFromReader);
      msg.addAdScheduleTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.SitelinkAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.SitelinkAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdScheduleTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string link_text = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.getLinkText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.setLinkText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description1 = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.getDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.setDescription1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description2 = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.setDescription2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_date = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end_date = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated AdScheduleInfo ad_schedule_targets = 6;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.getAdScheduleTargetsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
*/
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.setAdScheduleTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.addAdScheduleTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v18.common.AdScheduleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.SitelinkAsset} returns this
 */
proto.google.ads.googleads.v18.common.SitelinkAsset.prototype.clearAdScheduleTargetsList = function() {
  return this.setAdScheduleTargetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PageFeedAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PageFeedAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PageFeedAsset}
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PageFeedAsset;
  return proto.google.ads.googleads.v18.common.PageFeedAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PageFeedAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PageFeedAsset}
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PageFeedAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PageFeedAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string page_url = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.getPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PageFeedAsset} returns this
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.setPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.PageFeedAsset} returns this
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PageFeedAsset} returns this
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PageFeedAsset} returns this
 */
proto.google.ads.googleads.v18.common.PageFeedAsset.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.repeatedFields_ = [8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicEducationAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    programName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    programDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    schoolName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 9, ""),
    similarProgramIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 11, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    thumbnailImageUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicEducationAsset;
  return proto.google.ads.googleads.v18.common.DynamicEducationAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarProgramIds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailImageUrl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicEducationAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgramDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSchoolName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSimilarProgramIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getThumbnailImageUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string location_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string program_name = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string program_description = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getProgramDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setProgramDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string school_name = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getSchoolName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setSchoolName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string contextual_keywords = 8;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * optional string android_app_link = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string similar_program_ids = 10;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getSimilarProgramIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setSimilarProgramIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.addSimilarProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.clearSimilarProgramIdsList = function() {
  return this.setSimilarProgramIdsList([]);
};


/**
 * optional string ios_app_link = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 ios_app_store_id = 12;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string thumbnail_image_url = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getThumbnailImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setThumbnailImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string image_url = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicEducationAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicEducationAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.MobileAppAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.MobileAppAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appStore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    linkText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.MobileAppAsset;
  return proto.google.ads.googleads.v18.common.MobileAppAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.MobileAppAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.MobileAppVendorEnum.MobileAppVendor} */ (reader.readEnum());
      msg.setAppStore(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.MobileAppAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.MobileAppAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppStore();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLinkText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.MobileAppVendorEnum.MobileAppVendor app_store = 2;
 * @return {!proto.google.ads.googleads.v18.enums.MobileAppVendorEnum.MobileAppVendor}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.getAppStore = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.MobileAppVendorEnum.MobileAppVendor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.MobileAppVendorEnum.MobileAppVendor} value
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.setAppStore = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string link_text = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.getLinkText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.setLinkText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_date = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end_date = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.MobileAppAsset} returns this
 */
proto.google.ads.googleads.v18.common.MobileAppAsset.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelCalloutAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelCalloutAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    languageCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelCalloutAsset}
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelCalloutAsset;
  return proto.google.ads.googleads.v18.common.HotelCalloutAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelCalloutAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelCalloutAsset}
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelCalloutAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelCalloutAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCalloutAsset} returns this
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language_code = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelCalloutAsset} returns this
 */
proto.google.ads.googleads.v18.common.HotelCalloutAsset.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.CallAsset.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CallAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CallAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CallAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    callConversionReportingState: jspb.Message.getFieldWithDefault(msg, 3, 0),
    callConversionAction: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adScheduleTargetsList: jspb.Message.toObjectList(msg.getAdScheduleTargetsList(),
    google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CallAsset}
 */
proto.google.ads.googleads.v18.common.CallAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CallAsset;
  return proto.google.ads.googleads.v18.common.CallAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CallAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CallAsset}
 */
proto.google.ads.googleads.v18.common.CallAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState} */ (reader.readEnum());
      msg.setCallConversionReportingState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallConversionAction(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.deserializeBinaryFromReader);
      msg.addAdScheduleTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CallAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CallAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CallAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCallConversionReportingState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCallConversionAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdScheduleTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string country_code = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAsset} returns this
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAsset} returns this
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState call_conversion_reporting_state = 3;
 * @return {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.getCallConversionReportingState = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CallConversionReportingStateEnum.CallConversionReportingState} value
 * @return {!proto.google.ads.googleads.v18.common.CallAsset} returns this
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.setCallConversionReportingState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string call_conversion_action = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.getCallConversionAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.CallAsset} returns this
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.setCallConversionAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated AdScheduleInfo ad_schedule_targets = 5;
 * @return {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.getAdScheduleTargetsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_criteria_pb.AdScheduleInfo, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.AdScheduleInfo>} value
 * @return {!proto.google.ads.googleads.v18.common.CallAsset} returns this
*/
proto.google.ads.googleads.v18.common.CallAsset.prototype.setAdScheduleTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.AdScheduleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.AdScheduleInfo}
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.addAdScheduleTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v18.common.AdScheduleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.CallAsset} returns this
 */
proto.google.ads.googleads.v18.common.CallAsset.prototype.clearAdScheduleTargetsList = function() {
  return this.setAdScheduleTargetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.PriceAsset.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PriceAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PriceAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PriceAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    priceQualifier: jspb.Message.getFieldWithDefault(msg, 2, 0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    priceOfferingsList: jspb.Message.toObjectList(msg.getPriceOfferingsList(),
    proto.google.ads.googleads.v18.common.PriceOffering.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset}
 */
proto.google.ads.googleads.v18.common.PriceAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PriceAsset;
  return proto.google.ads.googleads.v18.common.PriceAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PriceAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset}
 */
proto.google.ads.googleads.v18.common.PriceAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PriceExtensionTypeEnum.PriceExtensionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceQualifierEnum.PriceExtensionPriceQualifier} */ (reader.readEnum());
      msg.setPriceQualifier(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 4:
      var value = new proto.google.ads.googleads.v18.common.PriceOffering;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.PriceOffering.deserializeBinaryFromReader);
      msg.addPriceOfferings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PriceAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PriceAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PriceAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPriceQualifier();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPriceOfferingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.google.ads.googleads.v18.common.PriceOffering.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.PriceExtensionTypeEnum.PriceExtensionType type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.PriceExtensionTypeEnum.PriceExtensionType}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PriceExtensionTypeEnum.PriceExtensionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PriceExtensionTypeEnum.PriceExtensionType} value
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset} returns this
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.PriceExtensionPriceQualifierEnum.PriceExtensionPriceQualifier price_qualifier = 2;
 * @return {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceQualifierEnum.PriceExtensionPriceQualifier}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.getPriceQualifier = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceQualifierEnum.PriceExtensionPriceQualifier} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceQualifierEnum.PriceExtensionPriceQualifier} value
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset} returns this
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.setPriceQualifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string language_code = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset} returns this
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PriceOffering price_offerings = 4;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PriceOffering>}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.getPriceOfferingsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PriceOffering>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.PriceOffering, 4));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PriceOffering>} value
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset} returns this
*/
proto.google.ads.googleads.v18.common.PriceAsset.prototype.setPriceOfferingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PriceOffering=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering}
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.addPriceOfferings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.ads.googleads.v18.common.PriceOffering, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.PriceAsset} returns this
 */
proto.google.ads.googleads.v18.common.PriceAsset.prototype.clearPriceOfferingsList = function() {
  return this.setPriceOfferingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.PriceOffering.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.PriceOffering} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PriceOffering.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: (f = msg.getPrice()) && google_ads_googleads_v18_common_feed_common_pb.Money.toObject(includeInstance, f),
    unit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    finalUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    finalMobileUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering}
 */
proto.google.ads.googleads.v18.common.PriceOffering.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.PriceOffering;
  return proto.google.ads.googleads.v18.common.PriceOffering.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.PriceOffering} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering}
 */
proto.google.ads.googleads.v18.common.PriceOffering.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new google_ads_googleads_v18_common_feed_common_pb.Money;
      reader.readMessage(value,google_ads_googleads_v18_common_feed_common_pb.Money.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceUnitEnum.PriceExtensionPriceUnit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalMobileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.PriceOffering.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.PriceOffering} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.PriceOffering.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_ads_googleads_v18_common_feed_common_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFinalUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFinalMobileUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string header = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.getHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.setHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Money price = 3;
 * @return {?proto.google.ads.googleads.v18.common.Money}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.getPrice = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.Money} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_feed_common_pb.Money, 3));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.Money|undefined} value
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
*/
proto.google.ads.googleads.v18.common.PriceOffering.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.ads.googleads.v18.enums.PriceExtensionPriceUnitEnum.PriceExtensionPriceUnit unit = 4;
 * @return {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceUnitEnum.PriceExtensionPriceUnit}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.getUnit = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceUnitEnum.PriceExtensionPriceUnit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PriceExtensionPriceUnitEnum.PriceExtensionPriceUnit} value
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string final_url = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.getFinalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.setFinalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string final_mobile_url = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.getFinalMobileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.PriceOffering} returns this
 */
proto.google.ads.googleads.v18.common.PriceOffering.prototype.setFinalMobileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.CallToActionAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.CallToActionAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    callToAction: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.CallToActionAsset}
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.CallToActionAsset;
  return proto.google.ads.googleads.v18.common.CallToActionAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.CallToActionAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.CallToActionAsset}
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.CallToActionTypeEnum.CallToActionType} */ (reader.readEnum());
      msg.setCallToAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.CallToActionAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.CallToActionAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallToAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.CallToActionTypeEnum.CallToActionType call_to_action = 1;
 * @return {!proto.google.ads.googleads.v18.enums.CallToActionTypeEnum.CallToActionType}
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.prototype.getCallToAction = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.CallToActionTypeEnum.CallToActionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.CallToActionTypeEnum.CallToActionType} value
 * @return {!proto.google.ads.googleads.v18.common.CallToActionAsset} returns this
 */
proto.google.ads.googleads.v18.common.CallToActionAsset.prototype.setCallToAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.repeatedFields_ = [10,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listingName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    propertyType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    listingType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    formattedPrice: jspb.Message.getFieldWithDefault(msg, 11, ""),
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 12, ""),
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 13, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    similarListingIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicRealEstateAsset;
  return proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedPrice(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarListingIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListingName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPropertyType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getListingType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getFormattedPrice();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getSimilarListingIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
};


/**
 * optional string listing_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string listing_name = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getListingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setListingName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city_name = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string price = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string image_url = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string property_type = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getPropertyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string listing_type = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getListingType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setListingType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string contextual_keywords = 10;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * optional string formatted_price = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getFormattedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setFormattedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string android_app_link = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string ios_app_link = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 ios_app_store_id = 14;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated string similar_listing_ids = 15;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.getSimilarListingIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.setSimilarListingIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.addSimilarListingIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicRealEstateAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicRealEstateAsset.prototype.clearSimilarListingIdsList = function() {
  return this.setSimilarListingIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.repeatedFields_ = [13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicCustomAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itemSubtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemAddress: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemCategory: jspb.Message.getFieldWithDefault(msg, 7, ""),
    price: jspb.Message.getFieldWithDefault(msg, 8, ""),
    salePrice: jspb.Message.getFieldWithDefault(msg, 9, ""),
    formattedPrice: jspb.Message.getFieldWithDefault(msg, 10, ""),
    formattedSalePrice: jspb.Message.getFieldWithDefault(msg, 11, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 14, ""),
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 16, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    similarIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicCustomAsset;
  return proto.google.ads.googleads.v18.common.DynamicCustomAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemSubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemCategory(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalePrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedPrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSalePrice(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicCustomAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItemSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemCategory();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSalePrice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFormattedPrice();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFormattedSalePrice();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getSimilarIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id2 = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getId2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setId2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_title = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getItemTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setItemTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string item_subtitle = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getItemSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setItemSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string item_description = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getItemDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setItemDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string item_address = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getItemAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setItemAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string item_category = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getItemCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setItemCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string price = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sale_price = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string formatted_price = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getFormattedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setFormattedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string formatted_sale_price = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getFormattedSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setFormattedSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string image_url = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated string contextual_keywords = 13;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * optional string android_app_link = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ios_app_link = 16;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 ios_app_store_id = 17;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * repeated string similar_ids = 15;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.getSimilarIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.setSimilarIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.addSimilarIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicCustomAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicCustomAsset.prototype.clearSimilarIdsList = function() {
  return this.setSimilarIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.repeatedFields_ = [10,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: jspb.Message.getFieldWithDefault(msg, 6, ""),
    salePrice: jspb.Message.getFieldWithDefault(msg, 7, ""),
    starRating: jspb.Message.getFieldWithDefault(msg, 8, 0),
    category: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    address: jspb.Message.getFieldWithDefault(msg, 11, ""),
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 12, ""),
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 13, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    formattedPrice: jspb.Message.getFieldWithDefault(msg, 15, ""),
    formattedSalePrice: jspb.Message.getFieldWithDefault(msg, 16, ""),
    similarPropertyIdsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset;
  return proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalePrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStarRating(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedPrice(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSalePrice(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarPropertyIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSalePrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStarRating();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFormattedPrice();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFormattedSalePrice();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSimilarPropertyIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
};


/**
 * optional string property_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_name = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_name = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string price = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sale_price = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 star_rating = 8;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getStarRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setStarRating = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string category = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string contextual_keywords = 10;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * optional string address = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string android_app_link = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string ios_app_link = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 ios_app_store_id = 14;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string formatted_price = 15;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getFormattedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setFormattedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string formatted_sale_price = 16;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getFormattedSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setFormattedSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated string similar_property_ids = 17;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.getSimilarPropertyIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.setSimilarPropertyIdsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.addSimilarPropertyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicHotelsAndRentalsAsset.prototype.clearSimilarPropertyIdsList = function() {
  return this.setSimilarPropertyIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicFlightsAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flightDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    originName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    flightPrice: jspb.Message.getFieldWithDefault(msg, 7, ""),
    flightSalePrice: jspb.Message.getFieldWithDefault(msg, 8, ""),
    formattedPrice: jspb.Message.getFieldWithDefault(msg, 9, ""),
    formattedSalePrice: jspb.Message.getFieldWithDefault(msg, 10, ""),
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 11, ""),
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 12, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    similarDestinationIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    customMapping: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicFlightsAsset;
  return proto.google.ads.googleads.v18.common.DynamicFlightsAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightSalePrice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedPrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSalePrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarDestinationIds(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMapping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicFlightsAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOriginName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFlightPrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFlightSalePrice();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFormattedPrice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFormattedSalePrice();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getSimilarDestinationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getCustomMapping();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string destination_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getDestinationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setDestinationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string origin_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getOriginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setOriginId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_description = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getFlightDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setFlightDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string destination_name = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string origin_name = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getOriginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setOriginName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string flight_price = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getFlightPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setFlightPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string flight_sale_price = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getFlightSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setFlightSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string formatted_price = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getFormattedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setFormattedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string formatted_sale_price = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getFormattedSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setFormattedSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string android_app_link = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ios_app_link = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 ios_app_store_id = 13;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string similar_destination_ids = 14;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getSimilarDestinationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setSimilarDestinationIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.addSimilarDestinationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.clearSimilarDestinationIdsList = function() {
  return this.setSimilarDestinationIdsList([]);
};


/**
 * optional string custom_mapping = 15;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.getCustomMapping = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicFlightsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicFlightsAsset.prototype.setCustomMapping = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketingImageAsset: jspb.Message.getFieldWithDefault(msg, 1, ""),
    squareMarketingImageAsset: jspb.Message.getFieldWithDefault(msg, 2, ""),
    portraitMarketingImageAsset: jspb.Message.getFieldWithDefault(msg, 3, ""),
    headline: jspb.Message.getFieldWithDefault(msg, 4, ""),
    callToActionText: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset;
  return proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketingImageAsset(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSquareMarketingImageAsset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortraitMarketingImageAsset(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketingImageAsset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSquareMarketingImageAsset();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPortraitMarketingImageAsset();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHeadline();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCallToActionText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string marketing_image_asset = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.getMarketingImageAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.setMarketingImageAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string square_marketing_image_asset = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.getSquareMarketingImageAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.setSquareMarketingImageAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string portrait_marketing_image_asset = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.getPortraitMarketingImageAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.setPortraitMarketingImageAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string headline = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.setHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string call_to_action_text = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.getCallToActionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset} returns this
 */
proto.google.ads.googleads.v18.common.DemandGenCarouselCardAsset.prototype.setCallToActionText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.repeatedFields_ = [12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicTravelAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    originName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    price: jspb.Message.getFieldWithDefault(msg, 7, ""),
    salePrice: jspb.Message.getFieldWithDefault(msg, 8, ""),
    formattedPrice: jspb.Message.getFieldWithDefault(msg, 9, ""),
    formattedSalePrice: jspb.Message.getFieldWithDefault(msg, 10, ""),
    category: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    similarDestinationIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    imageUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 15, ""),
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 16, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicTravelAsset;
  return proto.google.ads.googleads.v18.common.DynamicTravelAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalePrice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedPrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSalePrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarDestinationIds(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicTravelAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOriginName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSalePrice();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFormattedPrice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFormattedSalePrice();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getSimilarDestinationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
};


/**
 * optional string destination_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getDestinationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setDestinationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string origin_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getOriginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setOriginId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_name = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string destination_address = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getDestinationAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setDestinationAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string origin_name = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getOriginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setOriginName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string price = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sale_price = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string formatted_price = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getFormattedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setFormattedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string formatted_sale_price = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getFormattedSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setFormattedSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string category = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string contextual_keywords = 12;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * repeated string similar_destination_ids = 13;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getSimilarDestinationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setSimilarDestinationIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.addSimilarDestinationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.clearSimilarDestinationIdsList = function() {
  return this.setSimilarDestinationIdsList([]);
};


/**
 * optional string image_url = 14;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string android_app_link = 15;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ios_app_link = 16;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 ios_app_store_id = 17;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicTravelAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicTravelAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.repeatedFields_ = [10,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicLocalAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    price: jspb.Message.getFieldWithDefault(msg, 5, ""),
    salePrice: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address: jspb.Message.getFieldWithDefault(msg, 8, ""),
    category: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    formattedPrice: jspb.Message.getFieldWithDefault(msg, 11, ""),
    formattedSalePrice: jspb.Message.getFieldWithDefault(msg, 12, ""),
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 13, ""),
    similarDealIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 15, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicLocalAsset;
  return proto.google.ads.googleads.v18.common.DynamicLocalAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalePrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedPrice(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSalePrice(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarDealIds(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicLocalAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSalePrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getFormattedPrice();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFormattedSalePrice();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSimilarDealIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_name = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getDealName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setDealName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subtitle = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string price = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sale_price = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string image_url = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 8;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string category = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string contextual_keywords = 10;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * optional string formatted_price = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getFormattedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setFormattedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string formatted_sale_price = 12;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getFormattedSalePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setFormattedSalePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string android_app_link = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string similar_deal_ids = 14;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getSimilarDealIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setSimilarDealIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.addSimilarDealIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.clearSimilarDealIdsList = function() {
  return this.setSimilarDealIdsList([]);
};


/**
 * optional string ios_app_link = 15;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 ios_app_store_id = 16;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicLocalAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicLocalAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.repeatedFields_ = [8,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.DynamicJobsAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobSubtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    jobCategory: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contextualKeywordsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    address: jspb.Message.getFieldWithDefault(msg, 9, ""),
    salary: jspb.Message.getFieldWithDefault(msg, 10, ""),
    androidAppLink: jspb.Message.getFieldWithDefault(msg, 11, ""),
    similarJobIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    iosAppLink: jspb.Message.getFieldWithDefault(msg, 13, ""),
    iosAppStoreId: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.DynamicJobsAsset;
  return proto.google.ads.googleads.v18.common.DynamicJobsAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobSubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobCategory(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addContextualKeywords(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalary(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidAppLink(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addSimilarJobIds(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosAppLink(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIosAppStoreId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.DynamicJobsAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJobCategory();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContextualKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSalary();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAndroidAppLink();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSimilarJobIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getIosAppLink();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIosAppStoreId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string location_id = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string job_title = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string job_subtitle = 4;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getJobSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setJobSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image_url = 6;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string job_category = 7;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getJobCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setJobCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string contextual_keywords = 8;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getContextualKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setContextualKeywordsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.addContextualKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.clearContextualKeywordsList = function() {
  return this.setContextualKeywordsList([]);
};


/**
 * optional string address = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string salary = 10;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getSalary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setSalary = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string android_app_link = 11;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getAndroidAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setAndroidAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string similar_job_ids = 12;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getSimilarJobIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setSimilarJobIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.addSimilarJobIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.clearSimilarJobIdsList = function() {
  return this.setSimilarJobIdsList([]);
};


/**
 * optional string ios_app_link = 13;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getIosAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setIosAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 ios_app_store_id = 14;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.getIosAppStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.DynamicJobsAsset} returns this
 */
proto.google.ads.googleads.v18.common.DynamicJobsAsset.prototype.setIosAppStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.LocationAsset.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.LocationAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.LocationAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocationAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProfileLocationsList: jspb.Message.toObjectList(msg.getBusinessProfileLocationsList(),
    proto.google.ads.googleads.v18.common.BusinessProfileLocation.toObject, includeInstance),
    locationOwnershipType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.LocationAsset}
 */
proto.google.ads.googleads.v18.common.LocationAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.LocationAsset;
  return proto.google.ads.googleads.v18.common.LocationAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.LocationAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.LocationAsset}
 */
proto.google.ads.googleads.v18.common.LocationAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v18.common.BusinessProfileLocation;
      reader.readMessage(value,proto.google.ads.googleads.v18.common.BusinessProfileLocation.deserializeBinaryFromReader);
      msg.addBusinessProfileLocations(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.LocationOwnershipTypeEnum.LocationOwnershipType} */ (reader.readEnum());
      msg.setLocationOwnershipType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.LocationAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.LocationAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.LocationAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProfileLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.google.ads.googleads.v18.common.BusinessProfileLocation.serializeBinaryToWriter
    );
  }
  f = message.getLocationOwnershipType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.LocationAsset} returns this
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated BusinessProfileLocation business_profile_locations = 2;
 * @return {!Array<!proto.google.ads.googleads.v18.common.BusinessProfileLocation>}
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.getBusinessProfileLocationsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.BusinessProfileLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.common.BusinessProfileLocation, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.BusinessProfileLocation>} value
 * @return {!proto.google.ads.googleads.v18.common.LocationAsset} returns this
*/
proto.google.ads.googleads.v18.common.LocationAsset.prototype.setBusinessProfileLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.BusinessProfileLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation}
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.addBusinessProfileLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v18.common.BusinessProfileLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.LocationAsset} returns this
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.clearBusinessProfileLocationsList = function() {
  return this.setBusinessProfileLocationsList([]);
};


/**
 * optional google.ads.googleads.v18.enums.LocationOwnershipTypeEnum.LocationOwnershipType location_ownership_type = 3;
 * @return {!proto.google.ads.googleads.v18.enums.LocationOwnershipTypeEnum.LocationOwnershipType}
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.getLocationOwnershipType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.LocationOwnershipTypeEnum.LocationOwnershipType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.LocationOwnershipTypeEnum.LocationOwnershipType} value
 * @return {!proto.google.ads.googleads.v18.common.LocationAsset} returns this
 */
proto.google.ads.googleads.v18.common.LocationAsset.prototype.setLocationOwnershipType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.BusinessProfileLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    storeCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    listingId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.BusinessProfileLocation;
  return proto.google.ads.googleads.v18.common.BusinessProfileLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setListingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.BusinessProfileLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStoreCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getListingId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated string labels = 1;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} returns this
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} returns this
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} returns this
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string store_code = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.getStoreCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} returns this
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.setStoreCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 listing_id = 3;
 * @return {number}
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.getListingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.common.BusinessProfileLocation} returns this
 */
proto.google.ads.googleads.v18.common.BusinessProfileLocation.prototype.setListingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.common.HotelPropertyAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.common.HotelPropertyAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hotelName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.common.HotelPropertyAsset}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.common.HotelPropertyAsset;
  return proto.google.ads.googleads.v18.common.HotelPropertyAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.common.HotelPropertyAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.common.HotelPropertyAsset}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.common.HotelPropertyAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.common.HotelPropertyAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHotelName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelPropertyAsset} returns this
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hotel_address = 2;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.getHotelAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelPropertyAsset} returns this
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.setHotelAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hotel_name = 3;
 * @return {string}
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.getHotelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.common.HotelPropertyAsset} returns this
 */
proto.google.ads.googleads.v18.common.HotelPropertyAsset.prototype.setHotelName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.google.ads.googleads.v18.common);
