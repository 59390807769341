// source: google/ads/googleads/v18/resources/ad.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_ad_type_infos_pb = require('../../../../../google/ads/googleads/v18/common/ad_type_infos_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_ad_type_infos_pb);
var google_ads_googleads_v18_common_custom_parameter_pb = require('../../../../../google/ads/googleads/v18/common/custom_parameter_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_custom_parameter_pb);
var google_ads_googleads_v18_common_final_app_url_pb = require('../../../../../google/ads/googleads/v18/common/final_app_url_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_final_app_url_pb);
var google_ads_googleads_v18_common_url_collection_pb = require('../../../../../google/ads/googleads/v18/common/url_collection_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_url_collection_pb);
var google_ads_googleads_v18_enums_ad_type_pb = require('../../../../../google/ads/googleads/v18/enums/ad_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_type_pb);
var google_ads_googleads_v18_enums_device_pb = require('../../../../../google/ads/googleads/v18/enums/device_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_device_pb);
var google_ads_googleads_v18_enums_system_managed_entity_source_pb = require('../../../../../google/ads/googleads/v18/enums/system_managed_entity_source_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_system_managed_entity_source_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Ad', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.Ad.AdDataCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.Ad.repeatedFields_, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v18.resources.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.Ad.displayName = 'proto.google.ads.googleads.v18.resources.Ad';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Ad.repeatedFields_ = [41,35,42,10,26];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v18.resources.Ad.oneofGroups_ = [[6,7,49,14,15,17,18,22,24,39,25,28,29,30,31,32,33,34,36,48,50,62,63,64,61,54]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v18.resources.Ad.AdDataCase = {
  AD_DATA_NOT_SET: 0,
  TEXT_AD: 6,
  EXPANDED_TEXT_AD: 7,
  CALL_AD: 49,
  EXPANDED_DYNAMIC_SEARCH_AD: 14,
  HOTEL_AD: 15,
  SHOPPING_SMART_AD: 17,
  SHOPPING_PRODUCT_AD: 18,
  IMAGE_AD: 22,
  VIDEO_AD: 24,
  VIDEO_RESPONSIVE_AD: 39,
  RESPONSIVE_SEARCH_AD: 25,
  LEGACY_RESPONSIVE_DISPLAY_AD: 28,
  APP_AD: 29,
  LEGACY_APP_INSTALL_AD: 30,
  RESPONSIVE_DISPLAY_AD: 31,
  LOCAL_AD: 32,
  DISPLAY_UPLOAD_AD: 33,
  APP_ENGAGEMENT_AD: 34,
  SHOPPING_COMPARISON_LISTING_AD: 36,
  SMART_CAMPAIGN_AD: 48,
  APP_PRE_REGISTRATION_AD: 50,
  DEMAND_GEN_MULTI_ASSET_AD: 62,
  DEMAND_GEN_CAROUSEL_AD: 63,
  DEMAND_GEN_VIDEO_RESPONSIVE_AD: 64,
  DEMAND_GEN_PRODUCT_AD: 61,
  TRAVEL_AD: 54
};

/**
 * @return {proto.google.ads.googleads.v18.resources.Ad.AdDataCase}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getAdDataCase = function() {
  return /** @type {proto.google.ads.googleads.v18.resources.Ad.AdDataCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 37, ""),
    id: jspb.Message.getFieldWithDefault(msg, 40, 0),
    finalUrlsList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f,
    finalAppUrlsList: jspb.Message.toObjectList(msg.getFinalAppUrlsList(),
    google_ads_googleads_v18_common_final_app_url_pb.FinalAppUrl.toObject, includeInstance),
    finalMobileUrlsList: (f = jspb.Message.getRepeatedField(msg, 42)) == null ? undefined : f,
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 43, ""),
    finalUrlSuffix: jspb.Message.getFieldWithDefault(msg, 44, ""),
    urlCustomParametersList: jspb.Message.toObjectList(msg.getUrlCustomParametersList(),
    google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.toObject, includeInstance),
    displayUrl: jspb.Message.getFieldWithDefault(msg, 45, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    addedByGoogleAds: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
    devicePreference: jspb.Message.getFieldWithDefault(msg, 20, 0),
    urlCollectionsList: jspb.Message.toObjectList(msg.getUrlCollectionsList(),
    google_ads_googleads_v18_common_url_collection_pb.UrlCollection.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 47, ""),
    systemManagedResourceSource: jspb.Message.getFieldWithDefault(msg, 27, 0),
    textAd: (f = msg.getTextAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.TextAdInfo.toObject(includeInstance, f),
    expandedTextAd: (f = msg.getExpandedTextAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedTextAdInfo.toObject(includeInstance, f),
    callAd: (f = msg.getCallAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.CallAdInfo.toObject(includeInstance, f),
    expandedDynamicSearchAd: (f = msg.getExpandedDynamicSearchAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedDynamicSearchAdInfo.toObject(includeInstance, f),
    hotelAd: (f = msg.getHotelAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.HotelAdInfo.toObject(includeInstance, f),
    shoppingSmartAd: (f = msg.getShoppingSmartAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingSmartAdInfo.toObject(includeInstance, f),
    shoppingProductAd: (f = msg.getShoppingProductAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingProductAdInfo.toObject(includeInstance, f),
    imageAd: (f = msg.getImageAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ImageAdInfo.toObject(includeInstance, f),
    videoAd: (f = msg.getVideoAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.VideoAdInfo.toObject(includeInstance, f),
    videoResponsiveAd: (f = msg.getVideoResponsiveAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.VideoResponsiveAdInfo.toObject(includeInstance, f),
    responsiveSearchAd: (f = msg.getResponsiveSearchAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveSearchAdInfo.toObject(includeInstance, f),
    legacyResponsiveDisplayAd: (f = msg.getLegacyResponsiveDisplayAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.LegacyResponsiveDisplayAdInfo.toObject(includeInstance, f),
    appAd: (f = msg.getAppAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.AppAdInfo.toObject(includeInstance, f),
    legacyAppInstallAd: (f = msg.getLegacyAppInstallAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.LegacyAppInstallAdInfo.toObject(includeInstance, f),
    responsiveDisplayAd: (f = msg.getResponsiveDisplayAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveDisplayAdInfo.toObject(includeInstance, f),
    localAd: (f = msg.getLocalAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.LocalAdInfo.toObject(includeInstance, f),
    displayUploadAd: (f = msg.getDisplayUploadAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.DisplayUploadAdInfo.toObject(includeInstance, f),
    appEngagementAd: (f = msg.getAppEngagementAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.AppEngagementAdInfo.toObject(includeInstance, f),
    shoppingComparisonListingAd: (f = msg.getShoppingComparisonListingAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingComparisonListingAdInfo.toObject(includeInstance, f),
    smartCampaignAd: (f = msg.getSmartCampaignAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.SmartCampaignAdInfo.toObject(includeInstance, f),
    appPreRegistrationAd: (f = msg.getAppPreRegistrationAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.AppPreRegistrationAdInfo.toObject(includeInstance, f),
    demandGenMultiAssetAd: (f = msg.getDemandGenMultiAssetAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenMultiAssetAdInfo.toObject(includeInstance, f),
    demandGenCarouselAd: (f = msg.getDemandGenCarouselAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenCarouselAdInfo.toObject(includeInstance, f),
    demandGenVideoResponsiveAd: (f = msg.getDemandGenVideoResponsiveAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenVideoResponsiveAdInfo.toObject(includeInstance, f),
    demandGenProductAd: (f = msg.getDemandGenProductAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenProductAdInfo.toObject(includeInstance, f),
    travelAd: (f = msg.getTravelAd()) && google_ads_googleads_v18_common_ad_type_infos_pb.TravelAdInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.Ad}
 */
proto.google.ads.googleads.v18.resources.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.Ad;
  return proto.google.ads.googleads.v18.resources.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.Ad}
 */
proto.google.ads.googleads.v18.resources.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalUrls(value);
      break;
    case 35:
      var value = new google_ads_googleads_v18_common_final_app_url_pb.FinalAppUrl;
      reader.readMessage(value,google_ads_googleads_v18_common_final_app_url_pb.FinalAppUrl.deserializeBinaryFromReader);
      msg.addFinalAppUrls(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalMobileUrls(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrlSuffix(value);
      break;
    case 10:
      var value = new google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter;
      reader.readMessage(value,google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.deserializeBinaryFromReader);
      msg.addUrlCustomParameters(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUrl(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdTypeEnum.AdType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByGoogleAds(value);
      break;
    case 20:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device} */ (reader.readEnum());
      msg.setDevicePreference(value);
      break;
    case 26:
      var value = new google_ads_googleads_v18_common_url_collection_pb.UrlCollection;
      reader.readMessage(value,google_ads_googleads_v18_common_url_collection_pb.UrlCollection.deserializeBinaryFromReader);
      msg.addUrlCollections(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 27:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.SystemManagedResourceSourceEnum.SystemManagedResourceSource} */ (reader.readEnum());
      msg.setSystemManagedResourceSource(value);
      break;
    case 6:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.TextAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.TextAdInfo.deserializeBinaryFromReader);
      msg.setTextAd(value);
      break;
    case 7:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedTextAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedTextAdInfo.deserializeBinaryFromReader);
      msg.setExpandedTextAd(value);
      break;
    case 49:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.CallAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.CallAdInfo.deserializeBinaryFromReader);
      msg.setCallAd(value);
      break;
    case 14:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedDynamicSearchAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedDynamicSearchAdInfo.deserializeBinaryFromReader);
      msg.setExpandedDynamicSearchAd(value);
      break;
    case 15:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.HotelAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.HotelAdInfo.deserializeBinaryFromReader);
      msg.setHotelAd(value);
      break;
    case 17:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingSmartAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingSmartAdInfo.deserializeBinaryFromReader);
      msg.setShoppingSmartAd(value);
      break;
    case 18:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingProductAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingProductAdInfo.deserializeBinaryFromReader);
      msg.setShoppingProductAd(value);
      break;
    case 22:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ImageAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ImageAdInfo.deserializeBinaryFromReader);
      msg.setImageAd(value);
      break;
    case 24:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.VideoAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.VideoAdInfo.deserializeBinaryFromReader);
      msg.setVideoAd(value);
      break;
    case 39:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.VideoResponsiveAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.VideoResponsiveAdInfo.deserializeBinaryFromReader);
      msg.setVideoResponsiveAd(value);
      break;
    case 25:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveSearchAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveSearchAdInfo.deserializeBinaryFromReader);
      msg.setResponsiveSearchAd(value);
      break;
    case 28:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.LegacyResponsiveDisplayAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.LegacyResponsiveDisplayAdInfo.deserializeBinaryFromReader);
      msg.setLegacyResponsiveDisplayAd(value);
      break;
    case 29:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.AppAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.AppAdInfo.deserializeBinaryFromReader);
      msg.setAppAd(value);
      break;
    case 30:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.LegacyAppInstallAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.LegacyAppInstallAdInfo.deserializeBinaryFromReader);
      msg.setLegacyAppInstallAd(value);
      break;
    case 31:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveDisplayAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveDisplayAdInfo.deserializeBinaryFromReader);
      msg.setResponsiveDisplayAd(value);
      break;
    case 32:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.LocalAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.LocalAdInfo.deserializeBinaryFromReader);
      msg.setLocalAd(value);
      break;
    case 33:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.DisplayUploadAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.DisplayUploadAdInfo.deserializeBinaryFromReader);
      msg.setDisplayUploadAd(value);
      break;
    case 34:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.AppEngagementAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.AppEngagementAdInfo.deserializeBinaryFromReader);
      msg.setAppEngagementAd(value);
      break;
    case 36:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingComparisonListingAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingComparisonListingAdInfo.deserializeBinaryFromReader);
      msg.setShoppingComparisonListingAd(value);
      break;
    case 48:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.SmartCampaignAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.SmartCampaignAdInfo.deserializeBinaryFromReader);
      msg.setSmartCampaignAd(value);
      break;
    case 50:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.AppPreRegistrationAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.AppPreRegistrationAdInfo.deserializeBinaryFromReader);
      msg.setAppPreRegistrationAd(value);
      break;
    case 62:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenMultiAssetAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenMultiAssetAdInfo.deserializeBinaryFromReader);
      msg.setDemandGenMultiAssetAd(value);
      break;
    case 63:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenCarouselAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenCarouselAdInfo.deserializeBinaryFromReader);
      msg.setDemandGenCarouselAd(value);
      break;
    case 64:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenVideoResponsiveAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenVideoResponsiveAdInfo.deserializeBinaryFromReader);
      msg.setDemandGenVideoResponsiveAd(value);
      break;
    case 61:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenProductAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenProductAdInfo.deserializeBinaryFromReader);
      msg.setDemandGenProductAd(value);
      break;
    case 54:
      var value = new google_ads_googleads_v18_common_ad_type_infos_pb.TravelAdInfo;
      reader.readMessage(value,google_ads_googleads_v18_common_ad_type_infos_pb.TravelAdInfo.deserializeBinaryFromReader);
      msg.setTravelAd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getFinalUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      41,
      f
    );
  }
  f = message.getFinalAppUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      google_ads_googleads_v18_common_final_app_url_pb.FinalAppUrl.serializeBinaryToWriter
    );
  }
  f = message.getFinalMobileUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      42,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 43));
  if (f != null) {
    writer.writeString(
      43,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 44));
  if (f != null) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getUrlCustomParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 45));
  if (f != null) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 46));
  if (f != null) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getDevicePreference();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getUrlCollectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      google_ads_googleads_v18_common_url_collection_pb.UrlCollection.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 47));
  if (f != null) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getSystemManagedResourceSource();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getTextAd();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.TextAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getExpandedTextAd();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedTextAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getCallAd();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.CallAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getExpandedDynamicSearchAd();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedDynamicSearchAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelAd();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.HotelAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getShoppingSmartAd();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingSmartAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getShoppingProductAd();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingProductAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getImageAd();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ImageAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getVideoAd();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.VideoAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getVideoResponsiveAd();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.VideoResponsiveAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getResponsiveSearchAd();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveSearchAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getLegacyResponsiveDisplayAd();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.LegacyResponsiveDisplayAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getAppAd();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.AppAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getLegacyAppInstallAd();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.LegacyAppInstallAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getResponsiveDisplayAd();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveDisplayAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocalAd();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.LocalAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getDisplayUploadAd();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.DisplayUploadAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getAppEngagementAd();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.AppEngagementAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getShoppingComparisonListingAd();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingComparisonListingAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getSmartCampaignAd();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.SmartCampaignAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getAppPreRegistrationAd();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.AppPreRegistrationAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getDemandGenMultiAssetAd();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenMultiAssetAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getDemandGenCarouselAd();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenCarouselAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getDemandGenVideoResponsiveAd();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenVideoResponsiveAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getDemandGenProductAd();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenProductAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getTravelAd();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      google_ads_googleads_v18_common_ad_type_infos_pb.TravelAdInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 37;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional int64 id = 40;
 * @return {number}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setId = function(value) {
  return jspb.Message.setField(this, 40, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearId = function() {
  return jspb.Message.setField(this, 40, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasId = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * repeated string final_urls = 41;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getFinalUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setFinalUrlsList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.addFinalUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearFinalUrlsList = function() {
  return this.setFinalUrlsList([]);
};


/**
 * repeated google.ads.googleads.v18.common.FinalAppUrl final_app_urls = 35;
 * @return {!Array<!proto.google.ads.googleads.v18.common.FinalAppUrl>}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getFinalAppUrlsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.FinalAppUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_final_app_url_pb.FinalAppUrl, 35));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.FinalAppUrl>} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setFinalAppUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.FinalAppUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.FinalAppUrl}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.addFinalAppUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.google.ads.googleads.v18.common.FinalAppUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearFinalAppUrlsList = function() {
  return this.setFinalAppUrlsList([]);
};


/**
 * repeated string final_mobile_urls = 42;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getFinalMobileUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 42));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setFinalMobileUrlsList = function(value) {
  return jspb.Message.setField(this, 42, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.addFinalMobileUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 42, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearFinalMobileUrlsList = function() {
  return this.setFinalMobileUrlsList([]);
};


/**
 * optional string tracking_url_template = 43;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setField(this, 43, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearTrackingUrlTemplate = function() {
  return jspb.Message.setField(this, 43, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasTrackingUrlTemplate = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional string final_url_suffix = 44;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getFinalUrlSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setFinalUrlSuffix = function(value) {
  return jspb.Message.setField(this, 44, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearFinalUrlSuffix = function() {
  return jspb.Message.setField(this, 44, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasFinalUrlSuffix = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * repeated google.ads.googleads.v18.common.CustomParameter url_custom_parameters = 10;
 * @return {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getUrlCustomParametersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_custom_parameter_pb.CustomParameter, 10));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.CustomParameter>} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setUrlCustomParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.CustomParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.CustomParameter}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.addUrlCustomParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.google.ads.googleads.v18.common.CustomParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearUrlCustomParametersList = function() {
  return this.setUrlCustomParametersList([]);
};


/**
 * optional string display_url = 45;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDisplayUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setDisplayUrl = function(value) {
  return jspb.Message.setField(this, 45, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearDisplayUrl = function() {
  return jspb.Message.setField(this, 45, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasDisplayUrl = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AdTypeEnum.AdType type = 5;
 * @return {!proto.google.ads.googleads.v18.enums.AdTypeEnum.AdType}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdTypeEnum.AdType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdTypeEnum.AdType} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool added_by_google_ads = 46;
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getAddedByGoogleAds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setAddedByGoogleAds = function(value) {
  return jspb.Message.setField(this, 46, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearAddedByGoogleAds = function() {
  return jspb.Message.setField(this, 46, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasAddedByGoogleAds = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.ads.googleads.v18.enums.DeviceEnum.Device device_preference = 20;
 * @return {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDevicePreference = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.DeviceEnum.Device} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setDevicePreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * repeated google.ads.googleads.v18.common.UrlCollection url_collections = 26;
 * @return {!Array<!proto.google.ads.googleads.v18.common.UrlCollection>}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getUrlCollectionsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.UrlCollection>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_url_collection_pb.UrlCollection, 26));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.UrlCollection>} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setUrlCollectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.UrlCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.UrlCollection}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.addUrlCollections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.google.ads.googleads.v18.common.UrlCollection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearUrlCollectionsList = function() {
  return this.setUrlCollectionsList([]);
};


/**
 * optional string name = 47;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setName = function(value) {
  return jspb.Message.setField(this, 47, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearName = function() {
  return jspb.Message.setField(this, 47, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasName = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional google.ads.googleads.v18.enums.SystemManagedResourceSourceEnum.SystemManagedResourceSource system_managed_resource_source = 27;
 * @return {!proto.google.ads.googleads.v18.enums.SystemManagedResourceSourceEnum.SystemManagedResourceSource}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getSystemManagedResourceSource = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.SystemManagedResourceSourceEnum.SystemManagedResourceSource} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.SystemManagedResourceSourceEnum.SystemManagedResourceSource} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.setSystemManagedResourceSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional google.ads.googleads.v18.common.TextAdInfo text_ad = 6;
 * @return {?proto.google.ads.googleads.v18.common.TextAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getTextAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.TextAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.TextAdInfo, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.TextAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setTextAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearTextAd = function() {
  return this.setTextAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasTextAd = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.common.ExpandedTextAdInfo expanded_text_ad = 7;
 * @return {?proto.google.ads.googleads.v18.common.ExpandedTextAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getExpandedTextAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ExpandedTextAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedTextAdInfo, 7));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ExpandedTextAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setExpandedTextAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearExpandedTextAd = function() {
  return this.setExpandedTextAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasExpandedTextAd = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.ads.googleads.v18.common.CallAdInfo call_ad = 49;
 * @return {?proto.google.ads.googleads.v18.common.CallAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getCallAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.CallAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.CallAdInfo, 49));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.CallAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setCallAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearCallAd = function() {
  return this.setCallAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasCallAd = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo expanded_dynamic_search_ad = 14;
 * @return {?proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getExpandedDynamicSearchAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ExpandedDynamicSearchAdInfo, 14));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ExpandedDynamicSearchAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setExpandedDynamicSearchAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearExpandedDynamicSearchAd = function() {
  return this.setExpandedDynamicSearchAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasExpandedDynamicSearchAd = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.ads.googleads.v18.common.HotelAdInfo hotel_ad = 15;
 * @return {?proto.google.ads.googleads.v18.common.HotelAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getHotelAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.HotelAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.HotelAdInfo, 15));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.HotelAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setHotelAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearHotelAd = function() {
  return this.setHotelAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasHotelAd = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.ads.googleads.v18.common.ShoppingSmartAdInfo shopping_smart_ad = 17;
 * @return {?proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getShoppingSmartAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingSmartAdInfo, 17));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ShoppingSmartAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setShoppingSmartAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearShoppingSmartAd = function() {
  return this.setShoppingSmartAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasShoppingSmartAd = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.ads.googleads.v18.common.ShoppingProductAdInfo shopping_product_ad = 18;
 * @return {?proto.google.ads.googleads.v18.common.ShoppingProductAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getShoppingProductAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ShoppingProductAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingProductAdInfo, 18));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ShoppingProductAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setShoppingProductAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearShoppingProductAd = function() {
  return this.setShoppingProductAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasShoppingProductAd = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.ads.googleads.v18.common.ImageAdInfo image_ad = 22;
 * @return {?proto.google.ads.googleads.v18.common.ImageAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getImageAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ImageAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ImageAdInfo, 22));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ImageAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setImageAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearImageAd = function() {
  return this.setImageAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasImageAd = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.ads.googleads.v18.common.VideoAdInfo video_ad = 24;
 * @return {?proto.google.ads.googleads.v18.common.VideoAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getVideoAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.VideoAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.VideoAdInfo, 24));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.VideoAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setVideoAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearVideoAd = function() {
  return this.setVideoAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasVideoAd = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.ads.googleads.v18.common.VideoResponsiveAdInfo video_responsive_ad = 39;
 * @return {?proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getVideoResponsiveAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.VideoResponsiveAdInfo, 39));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.VideoResponsiveAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setVideoResponsiveAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearVideoResponsiveAd = function() {
  return this.setVideoResponsiveAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasVideoResponsiveAd = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional google.ads.googleads.v18.common.ResponsiveSearchAdInfo responsive_search_ad = 25;
 * @return {?proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getResponsiveSearchAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveSearchAdInfo, 25));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ResponsiveSearchAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setResponsiveSearchAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearResponsiveSearchAd = function() {
  return this.setResponsiveSearchAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasResponsiveSearchAd = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo legacy_responsive_display_ad = 28;
 * @return {?proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getLegacyResponsiveDisplayAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.LegacyResponsiveDisplayAdInfo, 28));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LegacyResponsiveDisplayAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setLegacyResponsiveDisplayAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearLegacyResponsiveDisplayAd = function() {
  return this.setLegacyResponsiveDisplayAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasLegacyResponsiveDisplayAd = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.ads.googleads.v18.common.AppAdInfo app_ad = 29;
 * @return {?proto.google.ads.googleads.v18.common.AppAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getAppAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AppAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.AppAdInfo, 29));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AppAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setAppAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearAppAd = function() {
  return this.setAppAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasAppAd = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.ads.googleads.v18.common.LegacyAppInstallAdInfo legacy_app_install_ad = 30;
 * @return {?proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getLegacyAppInstallAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.LegacyAppInstallAdInfo, 30));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LegacyAppInstallAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setLegacyAppInstallAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearLegacyAppInstallAd = function() {
  return this.setLegacyAppInstallAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasLegacyAppInstallAd = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.ads.googleads.v18.common.ResponsiveDisplayAdInfo responsive_display_ad = 31;
 * @return {?proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getResponsiveDisplayAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ResponsiveDisplayAdInfo, 31));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ResponsiveDisplayAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setResponsiveDisplayAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearResponsiveDisplayAd = function() {
  return this.setResponsiveDisplayAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasResponsiveDisplayAd = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.ads.googleads.v18.common.LocalAdInfo local_ad = 32;
 * @return {?proto.google.ads.googleads.v18.common.LocalAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getLocalAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.LocalAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.LocalAdInfo, 32));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.LocalAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setLocalAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearLocalAd = function() {
  return this.setLocalAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasLocalAd = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.ads.googleads.v18.common.DisplayUploadAdInfo display_upload_ad = 33;
 * @return {?proto.google.ads.googleads.v18.common.DisplayUploadAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDisplayUploadAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DisplayUploadAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.DisplayUploadAdInfo, 33));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DisplayUploadAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setDisplayUploadAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearDisplayUploadAd = function() {
  return this.setDisplayUploadAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasDisplayUploadAd = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.ads.googleads.v18.common.AppEngagementAdInfo app_engagement_ad = 34;
 * @return {?proto.google.ads.googleads.v18.common.AppEngagementAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getAppEngagementAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AppEngagementAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.AppEngagementAdInfo, 34));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AppEngagementAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setAppEngagementAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearAppEngagementAd = function() {
  return this.setAppEngagementAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasAppEngagementAd = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo shopping_comparison_listing_ad = 36;
 * @return {?proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getShoppingComparisonListingAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.ShoppingComparisonListingAdInfo, 36));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.ShoppingComparisonListingAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setShoppingComparisonListingAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearShoppingComparisonListingAd = function() {
  return this.setShoppingComparisonListingAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasShoppingComparisonListingAd = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.ads.googleads.v18.common.SmartCampaignAdInfo smart_campaign_ad = 48;
 * @return {?proto.google.ads.googleads.v18.common.SmartCampaignAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getSmartCampaignAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.SmartCampaignAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.SmartCampaignAdInfo, 48));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.SmartCampaignAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setSmartCampaignAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearSmartCampaignAd = function() {
  return this.setSmartCampaignAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasSmartCampaignAd = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.ads.googleads.v18.common.AppPreRegistrationAdInfo app_pre_registration_ad = 50;
 * @return {?proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getAppPreRegistrationAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.AppPreRegistrationAdInfo, 50));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.AppPreRegistrationAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setAppPreRegistrationAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearAppPreRegistrationAd = function() {
  return this.setAppPreRegistrationAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasAppPreRegistrationAd = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo demand_gen_multi_asset_ad = 62;
 * @return {?proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDemandGenMultiAssetAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenMultiAssetAdInfo, 62));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DemandGenMultiAssetAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setDemandGenMultiAssetAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 62, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearDemandGenMultiAssetAd = function() {
  return this.setDemandGenMultiAssetAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasDemandGenMultiAssetAd = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional google.ads.googleads.v18.common.DemandGenCarouselAdInfo demand_gen_carousel_ad = 63;
 * @return {?proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDemandGenCarouselAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenCarouselAdInfo, 63));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DemandGenCarouselAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setDemandGenCarouselAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearDemandGenCarouselAd = function() {
  return this.setDemandGenCarouselAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasDemandGenCarouselAd = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo demand_gen_video_responsive_ad = 64;
 * @return {?proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDemandGenVideoResponsiveAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenVideoResponsiveAdInfo, 64));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DemandGenVideoResponsiveAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setDemandGenVideoResponsiveAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 64, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearDemandGenVideoResponsiveAd = function() {
  return this.setDemandGenVideoResponsiveAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasDemandGenVideoResponsiveAd = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional google.ads.googleads.v18.common.DemandGenProductAdInfo demand_gen_product_ad = 61;
 * @return {?proto.google.ads.googleads.v18.common.DemandGenProductAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getDemandGenProductAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.DemandGenProductAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.DemandGenProductAdInfo, 61));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.DemandGenProductAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setDemandGenProductAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 61, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearDemandGenProductAd = function() {
  return this.setDemandGenProductAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasDemandGenProductAd = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional google.ads.googleads.v18.common.TravelAdInfo travel_ad = 54;
 * @return {?proto.google.ads.googleads.v18.common.TravelAdInfo}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.getTravelAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.common.TravelAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_common_ad_type_infos_pb.TravelAdInfo, 54));
};


/**
 * @param {?proto.google.ads.googleads.v18.common.TravelAdInfo|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
*/
proto.google.ads.googleads.v18.resources.Ad.prototype.setTravelAd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.google.ads.googleads.v18.resources.Ad.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.Ad} returns this
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.clearTravelAd = function() {
  return this.setTravelAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.Ad.prototype.hasTravelAd = function() {
  return jspb.Message.getField(this, 54) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
