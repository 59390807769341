// source: proto/warehousePb/googleAds.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_resources_change_event_pb = require('../../google/ads/googleads/v18/resources/change_event_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_resources_change_event_pb);
var google_ads_googleads_v18_enums_change_event_resource_type_pb = require('../../google/ads/googleads/v18/enums/change_event_resource_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_change_event_resource_type_pb);
var google_ads_googleads_v18_enums_change_client_type_pb = require('../../google/ads/googleads/v18/enums/change_client_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_change_client_type_pb);
var google_ads_googleads_v18_enums_resource_change_operation_pb = require('../../google/ads/googleads/v18/enums/resource_change_operation_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_resource_change_operation_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.warehousePb.GoogleAdsCampaignInfo', null, global);
goog.exportSymbol('proto.warehousePb.GoogleAdsChangeEvent', null, global);
goog.exportSymbol('proto.warehousePb.GoogleAdsChangeEventConstraints', null, global);
goog.exportSymbol('proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints', null, global);
goog.exportSymbol('proto.warehousePb.GoogleAdsChangeEventDetails', null, global);
goog.exportSymbol('proto.warehousePb.GoogleAdsCustomerInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.warehousePb.GoogleAdsCustomerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.warehousePb.GoogleAdsCustomerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.warehousePb.GoogleAdsCustomerInfo.displayName = 'proto.warehousePb.GoogleAdsCustomerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.warehousePb.GoogleAdsCampaignInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.warehousePb.GoogleAdsCampaignInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.warehousePb.GoogleAdsCampaignInfo.displayName = 'proto.warehousePb.GoogleAdsCampaignInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.warehousePb.GoogleAdsChangeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.warehousePb.GoogleAdsChangeEvent.repeatedFields_, null);
};
goog.inherits(proto.warehousePb.GoogleAdsChangeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.warehousePb.GoogleAdsChangeEvent.displayName = 'proto.warehousePb.GoogleAdsChangeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.warehousePb.GoogleAdsChangeEventDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.warehousePb.GoogleAdsChangeEventDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.warehousePb.GoogleAdsChangeEventDetails.displayName = 'proto.warehousePb.GoogleAdsChangeEventDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.warehousePb.GoogleAdsChangeEventConstraints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.warehousePb.GoogleAdsChangeEventConstraints.repeatedFields_, null);
};
goog.inherits(proto.warehousePb.GoogleAdsChangeEventConstraints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.warehousePb.GoogleAdsChangeEventConstraints.displayName = 'proto.warehousePb.GoogleAdsChangeEventConstraints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.repeatedFields_, null);
};
goog.inherits(proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.displayName = 'proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.warehousePb.GoogleAdsCustomerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.warehousePb.GoogleAdsCustomerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsCustomerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isManager: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isTestAccount: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    autoTaggingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hasPartnersBadge: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    optimizationScore: (f = msg.getOptimizationScore()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    optimizationScoreWeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo}
 */
proto.warehousePb.GoogleAdsCustomerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.warehousePb.GoogleAdsCustomerInfo;
  return proto.warehousePb.GoogleAdsCustomerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.warehousePb.GoogleAdsCustomerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo}
 */
proto.warehousePb.GoogleAdsCustomerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManager(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestAccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoTaggingEnabled(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPartnersBadge(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setOptimizationScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOptimizationScoreWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.warehousePb.GoogleAdsCustomerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.warehousePb.GoogleAdsCustomerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsCustomerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsManager();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsTestAccount();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAutoTaggingEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHasPartnersBadge();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOptimizationScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getOptimizationScoreWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
};


/**
 * optional int64 customer_id = 1;
 * @return {number}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_manager = 3;
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getIsManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setIsManager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_test_account = 4;
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getIsTestAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setIsTestAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string timezone = 6;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool auto_tagging_enabled = 7;
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getAutoTaggingEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setAutoTaggingEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool has_partners_badge = 8;
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getHasPartnersBadge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setHasPartnersBadge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.DoubleValue optimization_score = 9;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getOptimizationScore = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 9));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
*/
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setOptimizationScore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.clearOptimizationScore = function() {
  return this.setOptimizationScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.hasOptimizationScore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double optimization_score_weight = 10;
 * @return {number}
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.getOptimizationScoreWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsCustomerInfo} returns this
 */
proto.warehousePb.GoogleAdsCustomerInfo.prototype.setOptimizationScoreWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.warehousePb.GoogleAdsCampaignInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.warehousePb.GoogleAdsCampaignInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsCampaignInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    servingStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountCurrencyCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    advertisingChannelType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    advertisingChannelSubType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo}
 */
proto.warehousePb.GoogleAdsCampaignInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.warehousePb.GoogleAdsCampaignInfo;
  return proto.warehousePb.GoogleAdsCampaignInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.warehousePb.GoogleAdsCampaignInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo}
 */
proto.warehousePb.GoogleAdsCampaignInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServingStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountCurrencyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisingChannelType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisingChannelSubType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.warehousePb.GoogleAdsCampaignInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.warehousePb.GoogleAdsCampaignInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsCampaignInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServingStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdvertisingChannelType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdvertisingChannelSubType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serving_status = 3;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getServingStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setServingStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_currency_code = 7;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getAccountCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setAccountCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string advertising_channel_type = 4;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getAdvertisingChannelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setAdvertisingChannelType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string advertising_channel_sub_type = 5;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getAdvertisingChannelSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setAdvertisingChannelSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string start_date = 6;
 * @return {string}
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsCampaignInfo} returns this
 */
proto.warehousePb.GoogleAdsCampaignInfo.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.warehousePb.GoogleAdsChangeEvent.repeatedFields_ = [101];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.warehousePb.GoogleAdsChangeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.warehousePb.GoogleAdsChangeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeTime: (f = msg.getChangeTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    changeEventId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    adId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    resourceType: jspb.Message.getFieldWithDefault(msg, 100, ""),
    changedFieldsList: (f = jspb.Message.getRepeatedField(msg, 101)) == null ? undefined : f,
    userEmail: jspb.Message.getFieldWithDefault(msg, 102, ""),
    details: (f = msg.getDetails()) && proto.warehousePb.GoogleAdsChangeEventDetails.toObject(includeInstance, f),
    oldResourceJson: jspb.Message.getFieldWithDefault(msg, 104, ""),
    newResourceJson: jspb.Message.getFieldWithDefault(msg, 105, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.warehousePb.GoogleAdsChangeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.warehousePb.GoogleAdsChangeEvent;
  return proto.warehousePb.GoogleAdsChangeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.warehousePb.GoogleAdsChangeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.warehousePb.GoogleAdsChangeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeEventId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriteriaId(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.addChangedFields(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 103:
      var value = new proto.warehousePb.GoogleAdsChangeEventDetails;
      reader.readMessage(value,proto.warehousePb.GoogleAdsChangeEventDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 104:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldResourceJson(value);
      break;
    case 105:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewResourceJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.warehousePb.GoogleAdsChangeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.warehousePb.GoogleAdsChangeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChangeEventId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getAdId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getCriteriaId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getChangedFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      101,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.warehousePb.GoogleAdsChangeEventDetails.serializeBinaryToWriter
    );
  }
  f = message.getOldResourceJson();
  if (f.length > 0) {
    writer.writeString(
      104,
      f
    );
  }
  f = message.getNewResourceJson();
  if (f.length > 0) {
    writer.writeString(
      105,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp change_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getChangeTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
*/
proto.warehousePb.GoogleAdsChangeEvent.prototype.setChangeTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.clearChangeTime = function() {
  return this.setChangeTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.hasChangeTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 customer_id = 2;
 * @return {number}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string change_event_id = 3;
 * @return {string}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getChangeEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setChangeEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 campaign_id = 11;
 * @return {number}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 ad_group_id = 12;
 * @return {number}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 ad_id = 13;
 * @return {number}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getAdId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setAdId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 criteria_id = 14;
 * @return {number}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getCriteriaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string resource_type = 100;
 * @return {string}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * repeated string changed_fields = 101;
 * @return {!Array<string>}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getChangedFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 101));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setChangedFieldsList = function(value) {
  return jspb.Message.setField(this, 101, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.addChangedFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 101, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.clearChangedFieldsList = function() {
  return this.setChangedFieldsList([]);
};


/**
 * optional string user_email = 102;
 * @return {string}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 102, value);
};


/**
 * optional GoogleAdsChangeEventDetails details = 103;
 * @return {?proto.warehousePb.GoogleAdsChangeEventDetails}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getDetails = function() {
  return /** @type{?proto.warehousePb.GoogleAdsChangeEventDetails} */ (
    jspb.Message.getWrapperField(this, proto.warehousePb.GoogleAdsChangeEventDetails, 103));
};


/**
 * @param {?proto.warehousePb.GoogleAdsChangeEventDetails|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
*/
proto.warehousePb.GoogleAdsChangeEvent.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional string old_resource_json = 104;
 * @return {string}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getOldResourceJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 104, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setOldResourceJson = function(value) {
  return jspb.Message.setProto3StringField(this, 104, value);
};


/**
 * optional string new_resource_json = 105;
 * @return {string}
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.getNewResourceJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 105, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsChangeEvent} returns this
 */
proto.warehousePb.GoogleAdsChangeEvent.prototype.setNewResourceJson = function(value) {
  return jspb.Message.setProto3StringField(this, 105, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.warehousePb.GoogleAdsChangeEventDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.warehousePb.GoogleAdsChangeEventDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEventDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    clientType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    oldResource: (f = msg.getOldResource()) && google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource.toObject(includeInstance, f),
    newResource: (f = msg.getNewResource()) && google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.warehousePb.GoogleAdsChangeEventDetails;
  return proto.warehousePb.GoogleAdsChangeEventDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.warehousePb.GoogleAdsChangeEventDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    case 4:
      var value = new google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource;
      reader.readMessage(value,google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource.deserializeBinaryFromReader);
      msg.setOldResource(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource;
      reader.readMessage(value,google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource.deserializeBinaryFromReader);
      msg.setNewResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.warehousePb.GoogleAdsChangeEventDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.warehousePb.GoogleAdsChangeEventDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEventDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOldResource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource.serializeBinaryToWriter
    );
  }
  f = message.getNewResource();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation operation = 2;
 * @return {!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.getOperation = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType client_type = 3;
 * @return {!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.getClientType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.ads.googleads.v18.resources.ChangeEvent.ChangedResource old_resource = 4;
 * @return {?proto.google.ads.googleads.v18.resources.ChangeEvent.ChangedResource}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.getOldResource = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.ChangeEvent.ChangedResource} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource, 4));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.ChangeEvent.ChangedResource|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
*/
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.setOldResource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.clearOldResource = function() {
  return this.setOldResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.hasOldResource = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.ads.googleads.v18.resources.ChangeEvent.ChangedResource new_resource = 5;
 * @return {?proto.google.ads.googleads.v18.resources.ChangeEvent.ChangedResource}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.getNewResource = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.ChangeEvent.ChangedResource} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_resources_change_event_pb.ChangeEvent.ChangedResource, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.ChangeEvent.ChangedResource|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
*/
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.setNewResource = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventDetails} returns this
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.clearNewResource = function() {
  return this.setNewResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventDetails.prototype.hasNewResource = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.repeatedFields_ = [5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.toObject = function(opt_includeInstance) {
  return proto.warehousePb.GoogleAdsChangeEventConstraints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.warehousePb.GoogleAdsChangeEventConstraints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignConstraints: (f = msg.getCampaignConstraints()) && proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.toObject(includeInstance, f),
    adGroupConstraints: (f = msg.getAdGroupConstraints()) && proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.toObject(includeInstance, f),
    adConstraints: (f = msg.getAdConstraints()) && proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.toObject(includeInstance, f),
    criteriaConstraints: (f = msg.getCriteriaConstraints()) && proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.toObject(includeInstance, f),
    resourceNamesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    changedFieldsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    userEmailsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    resourceTypesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    operationsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    clientTypesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.warehousePb.GoogleAdsChangeEventConstraints;
  return proto.warehousePb.GoogleAdsChangeEventConstraints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.warehousePb.GoogleAdsChangeEventConstraints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints;
      reader.readMessage(value,proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinaryFromReader);
      msg.setCampaignConstraints(value);
      break;
    case 2:
      var value = new proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints;
      reader.readMessage(value,proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinaryFromReader);
      msg.setAdGroupConstraints(value);
      break;
    case 3:
      var value = new proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints;
      reader.readMessage(value,proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinaryFromReader);
      msg.setAdConstraints(value);
      break;
    case 4:
      var value = new proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints;
      reader.readMessage(value,proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinaryFromReader);
      msg.setCriteriaConstraints(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addResourceNames(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addChangedFields(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addResourceTypes(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOperations(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClientTypes(values[i]);
      }
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.warehousePb.GoogleAdsChangeEventConstraints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.warehousePb.GoogleAdsChangeEventConstraints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignConstraints();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupConstraints();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.serializeBinaryToWriter
    );
  }
  f = message.getAdConstraints();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaConstraints();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.serializeBinaryToWriter
    );
  }
  f = message.getResourceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getChangedFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getResourceTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
  f = message.getClientTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.prototype.toObject = function(opt_includeInstance) {
  return proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints;
  return proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional HierarchyConstraints campaign_constraints = 1;
 * @return {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getCampaignConstraints = function() {
  return /** @type{?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} */ (
    jspb.Message.getWrapperField(this, proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints, 1));
};


/**
 * @param {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
*/
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setCampaignConstraints = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearCampaignConstraints = function() {
  return this.setCampaignConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.hasCampaignConstraints = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HierarchyConstraints ad_group_constraints = 2;
 * @return {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getAdGroupConstraints = function() {
  return /** @type{?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} */ (
    jspb.Message.getWrapperField(this, proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints, 2));
};


/**
 * @param {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
*/
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setAdGroupConstraints = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearAdGroupConstraints = function() {
  return this.setAdGroupConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.hasAdGroupConstraints = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HierarchyConstraints ad_constraints = 3;
 * @return {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getAdConstraints = function() {
  return /** @type{?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} */ (
    jspb.Message.getWrapperField(this, proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints, 3));
};


/**
 * @param {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
*/
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setAdConstraints = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearAdConstraints = function() {
  return this.setAdConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.hasAdConstraints = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HierarchyConstraints criteria_constraints = 4;
 * @return {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getCriteriaConstraints = function() {
  return /** @type{?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints} */ (
    jspb.Message.getWrapperField(this, proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints, 4));
};


/**
 * @param {?proto.warehousePb.GoogleAdsChangeEventConstraints.HierarchyConstraints|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
*/
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setCriteriaConstraints = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearCriteriaConstraints = function() {
  return this.setCriteriaConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.hasCriteriaConstraints = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string resource_names = 5;
 * @return {!Array<string>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getResourceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setResourceNamesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.addResourceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearResourceNamesList = function() {
  return this.setResourceNamesList([]);
};


/**
 * repeated string changed_fields = 6;
 * @return {!Array<string>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getChangedFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setChangedFieldsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.addChangedFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearChangedFieldsList = function() {
  return this.setChangedFieldsList([]);
};


/**
 * repeated string user_emails = 7;
 * @return {!Array<string>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};


/**
 * repeated google.ads.googleads.v18.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType resource_types = 8;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getResourceTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setResourceTypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.addResourceTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearResourceTypesList = function() {
  return this.setResourceTypesList([]);
};


/**
 * repeated google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation operations = 9;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getOperationsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setOperationsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ResourceChangeOperationEnum.ResourceChangeOperation} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.addOperations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType client_types = 10;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType>}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getClientTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType>} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setClientTypesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.ChangeClientTypeEnum.ChangeClientType} value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.addClientTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearClientTypesList = function() {
  return this.setClientTypesList([]);
};


/**
 * optional google.protobuf.Timestamp start_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
*/
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
*/
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.warehousePb.GoogleAdsChangeEventConstraints} returns this
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.warehousePb.GoogleAdsChangeEventConstraints.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.warehousePb);
