// source: google/ads/googleads/v18/resources/ad_group_ad.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v18_common_policy_pb = require('../../../../../google/ads/googleads/v18/common/policy_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_common_policy_pb);
var google_ads_googleads_v18_enums_ad_group_ad_primary_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_ad_primary_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_ad_primary_status_pb);
var google_ads_googleads_v18_enums_ad_group_ad_primary_status_reason_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_ad_primary_status_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_ad_primary_status_reason_pb);
var google_ads_googleads_v18_enums_ad_group_ad_status_pb = require('../../../../../google/ads/googleads/v18/enums/ad_group_ad_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_group_ad_status_pb);
var google_ads_googleads_v18_enums_ad_strength_pb = require('../../../../../google/ads/googleads/v18/enums/ad_strength_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_ad_strength_pb);
var google_ads_googleads_v18_enums_asset_automation_status_pb = require('../../../../../google/ads/googleads/v18/enums/asset_automation_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_automation_status_pb);
var google_ads_googleads_v18_enums_asset_automation_type_pb = require('../../../../../google/ads/googleads/v18/enums/asset_automation_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_asset_automation_type_pb);
var google_ads_googleads_v18_enums_policy_approval_status_pb = require('../../../../../google/ads/googleads/v18/enums/policy_approval_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_approval_status_pb);
var google_ads_googleads_v18_enums_policy_review_status_pb = require('../../../../../google/ads/googleads/v18/enums/policy_review_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_enums_policy_review_status_pb);
var google_ads_googleads_v18_resources_ad_pb = require('../../../../../google/ads/googleads/v18/resources/ad_pb.js');
goog.object.extend(proto, google_ads_googleads_v18_resources_ad_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupAd', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupAd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.AdGroupAd.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupAd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupAd.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupAd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.displayName = 'proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.repeatedFields_ = [13,10,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupAd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    adGroup: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ad: (f = msg.getAd()) && google_ads_googleads_v18_resources_ad_pb.Ad.toObject(includeInstance, f),
    policySummary: (f = msg.getPolicySummary()) && proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.toObject(includeInstance, f),
    adStrength: jspb.Message.getFieldWithDefault(msg, 7, 0),
    actionItemsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    labelsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    primaryStatus: jspb.Message.getFieldWithDefault(msg, 16, 0),
    primaryStatusReasonsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    adGroupAdAssetAutomationSettingsList: jspb.Message.toObjectList(msg.getAdGroupAdAssetAutomationSettingsList(),
    proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupAd;
  return proto.google.ads.googleads.v18.resources.AdGroupAd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupAdStatusEnum.AdGroupAdStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroup(value);
      break;
    case 5:
      var value = new google_ads_googleads_v18_resources_ad_pb.Ad;
      reader.readMessage(value,google_ads_googleads_v18_resources_ad_pb.Ad.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    case 6:
      var value = new proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.deserializeBinaryFromReader);
      msg.setPolicySummary(value);
      break;
    case 7:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdStrengthEnum.AdStrength} */ (reader.readEnum());
      msg.setAdStrength(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addActionItems(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 16:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusEnum.AdGroupAdPrimaryStatus} */ (reader.readEnum());
      msg.setPrimaryStatus(value);
      break;
    case 17:
      var values = /** @type {!Array<!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusReasonEnum.AdGroupAdPrimaryStatusReason>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrimaryStatusReasons(values[i]);
      }
      break;
    case 18:
      var value = new proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting;
      reader.readMessage(value,proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.deserializeBinaryFromReader);
      msg.addAdGroupAdAssetAutomationSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupAd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v18_resources_ad_pb.Ad.serializeBinaryToWriter
    );
  }
  f = message.getPolicySummary();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.serializeBinaryToWriter
    );
  }
  f = message.getAdStrength();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getActionItemsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getPrimaryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getPrimaryStatusReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      17,
      f
    );
  }
  f = message.getAdGroupAdAssetAutomationSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupAdStatusEnum.AdGroupAdStatus status = 3;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupAdStatusEnum.AdGroupAdStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupAdStatusEnum.AdGroupAdStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupAdStatusEnum.AdGroupAdStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string ad_group = 9;
 * @return {string}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getAdGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setAdGroup = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearAdGroup = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.hasAdGroup = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Ad ad = 5;
 * @return {?proto.google.ads.googleads.v18.resources.Ad}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getAd = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.Ad} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v18_resources_ad_pb.Ad, 5));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.Ad|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.hasAd = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AdGroupAdPolicySummary policy_summary = 6;
 * @return {?proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getPolicySummary = function() {
  return /** @type{?proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary, 6));
};


/**
 * @param {?proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary|undefined} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setPolicySummary = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearPolicySummary = function() {
  return this.setPolicySummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.hasPolicySummary = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AdStrengthEnum.AdStrength ad_strength = 7;
 * @return {!proto.google.ads.googleads.v18.enums.AdStrengthEnum.AdStrength}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getAdStrength = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdStrengthEnum.AdStrength} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdStrengthEnum.AdStrength} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setAdStrength = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated string action_items = 13;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getActionItemsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setActionItemsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.addActionItems = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearActionItemsList = function() {
  return this.setActionItemsList([]);
};


/**
 * repeated string labels = 10;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusEnum.AdGroupAdPrimaryStatus primary_status = 16;
 * @return {!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusEnum.AdGroupAdPrimaryStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getPrimaryStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusEnum.AdGroupAdPrimaryStatus} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusEnum.AdGroupAdPrimaryStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setPrimaryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * repeated google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusReasonEnum.AdGroupAdPrimaryStatusReason primary_status_reasons = 17;
 * @return {!Array<!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusReasonEnum.AdGroupAdPrimaryStatusReason>}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getPrimaryStatusReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusReasonEnum.AdGroupAdPrimaryStatusReason>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusReasonEnum.AdGroupAdPrimaryStatusReason>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setPrimaryStatusReasonsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AdGroupAdPrimaryStatusReasonEnum.AdGroupAdPrimaryStatusReason} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.addPrimaryStatusReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearPrimaryStatusReasonsList = function() {
  return this.setPrimaryStatusReasonsList([]);
};


/**
 * repeated AdGroupAdAssetAutomationSetting ad_group_ad_asset_automation_settings = 18;
 * @return {!Array<!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting>}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.getAdGroupAdAssetAutomationSettingsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting, 18));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.setAdGroupAdAssetAutomationSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.addAdGroupAdAssetAutomationSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAd} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAd.prototype.clearAdGroupAdAssetAutomationSettingsList = function() {
  return this.setAdGroupAdAssetAutomationSettingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyTopicEntriesList: jspb.Message.toObjectList(msg.getPolicyTopicEntriesList(),
    google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry.toObject, includeInstance),
    reviewStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary;
  return proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry;
      reader.readMessage(value,google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry.deserializeBinaryFromReader);
      msg.addPolicyTopicEntries(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (reader.readEnum());
      msg.setReviewStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (reader.readEnum());
      msg.setApprovalStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyTopicEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry.serializeBinaryToWriter
    );
  }
  f = message.getReviewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated google.ads.googleads.v18.common.PolicyTopicEntry policy_topic_entries = 1;
 * @return {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEntry>}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.getPolicyTopicEntriesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v18_common_policy_pb.PolicyTopicEntry, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v18.common.PolicyTopicEntry>} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} returns this
*/
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.setPolicyTopicEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v18.common.PolicyTopicEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v18.common.PolicyTopicEntry}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.addPolicyTopicEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v18.common.PolicyTopicEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.clearPolicyTopicEntriesList = function() {
  return this.setPolicyTopicEntriesList([]);
};


/**
 * optional google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus review_status = 2;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.getReviewStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyReviewStatusEnum.PolicyReviewStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.setReviewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus approval_status = 3;
 * @return {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.getApprovalStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdPolicySummary.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetAutomationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetAutomationStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting;
  return proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetAutomationTypeEnum.AssetAutomationType} */ (reader.readEnum());
      msg.setAssetAutomationType(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v18.enums.AssetAutomationStatusEnum.AssetAutomationStatus} */ (reader.readEnum());
      msg.setAssetAutomationStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.ads.googleads.v18.enums.AssetAutomationTypeEnum.AssetAutomationType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v18.enums.AssetAutomationStatusEnum.AssetAutomationStatus} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v18.enums.AssetAutomationTypeEnum.AssetAutomationType asset_automation_type = 1;
 * @return {!proto.google.ads.googleads.v18.enums.AssetAutomationTypeEnum.AssetAutomationType}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.getAssetAutomationType = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetAutomationTypeEnum.AssetAutomationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetAutomationTypeEnum.AssetAutomationType} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.setAssetAutomationType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.clearAssetAutomationType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.hasAssetAutomationType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.ads.googleads.v18.enums.AssetAutomationStatusEnum.AssetAutomationStatus asset_automation_status = 2;
 * @return {!proto.google.ads.googleads.v18.enums.AssetAutomationStatusEnum.AssetAutomationStatus}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.getAssetAutomationStatus = function() {
  return /** @type {!proto.google.ads.googleads.v18.enums.AssetAutomationStatusEnum.AssetAutomationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v18.enums.AssetAutomationStatusEnum.AssetAutomationStatus} value
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.setAssetAutomationStatus = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.clearAssetAutomationStatus = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v18.resources.AdGroupAdAssetAutomationSetting.prototype.hasAssetAutomationStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v18.resources);
